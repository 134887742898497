/****************************
 * Header Component
 ****************************/
//abhay@keyutech.com:03/03/22 - Commented the console on line number 268 and 282

import { Component, OnInit, Input } from "@angular/core";
import { AuthguardService } from "../../services/authguard.service";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { Observable } from "rxjs";
import { GiftcardService } from "../../services/giftcard.service";
import {StatesService} from "src/app/services/states.service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    providers: [NgbDropdownConfig]
})
export class HeaderComponent implements OnInit {
    loggedIn: string = "";
    // detect if user is logged in
    isloggedIn: boolean = false;
    @Input()
    toggle;
    toggleMyAccount: boolean = false;
    navToggle: boolean = false;
    userTypeChildParent: any;
    isUserTypeTeen: any;

    // toggle/collapse naigation in mobile view
    dropdowns: any[] = [];
    userInfo: any = undefined;
    notifications: string[] = [];
    hideShowNotifications: boolean = false;
    showNotifications: boolean = false;
    countryName : any;
    currencyValue: any;
    countries:any;
    constructor(
        private authService: AuthguardService,
        private config: NgbDropdownConfig,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cardService: GiftcardService,
        private statesService: StatesService
    ) {
        // ng-dropdown config
        config.placement = "bottom-right";
        config.autoClose = false;
       this.countryName = 'USA'
       sessionStorage.setItem("countryName", this.countryName); 
       this.onChange(this.countryName)
       this.statesService.getcountry().subscribe(response => {
        let body = (<any>response);
        if(body) {
          this.countries = body.respData;
          sessionStorage.setItem("countryId", this.countries.id)
        }
      }); 
    }

    ngOnInit() {
        AuthguardService.userType.subscribe(data => {
            if (data && data.type) {
                this.userTypeChildParent = data.type;
                if (this.userTypeChildParent == "child") {
                    this.isUserTypeTeen = 0;
                    let x = [
                        {
                            name: "My Account",
                            status: false,
                            menu: [
                                {
                                    name: "Dashboard",
                                    link: "/dashboard/home",
                                    click: ""
                                },
                                {
                                    name: "Teen Profile Settings",
                                    link: "/dashboard/teen-settings",
                                    click: ""
                                },
                                {
                                    name: "Logout",
                                    link: "",
                                    click: "logout()"
                                }
                            ]
                        }
                    ];
                    this.dropdowns = x;
                } else {
                    this.isUserTypeTeen = 1;
                    let x = [
                        {
                            name: "My Account",
                            status: false,
                            menu: [
                                {
                                    name: "Dashboard",
                                    link: "/dashboard/home",
                                    click: ""
                                },
                                {
                                    name: "My Gift Cards",
                                    link: "/dashboard/mycards",
                                    click: ""
                                },
                                {
                                    name: "History",
                                    link: "/dashboard/history",
                                    click: ""
                                },
                                {
                                    name: "Profile Settings",
                                    link: "/dashboard/settings",
                                    click: "hideSidebar()"
                                },
                                {
                                    name: "How it works",
                                    link: "/how-it-works",
                                    click: ""
                                },
                                {
                                    name: "Logout",
                                    link: "",
                                    click: "logout()"
                                }
                            ]
                        }
                    ];
                    this.dropdowns = x;
                }
            }
        });
        this.authService.getuserInfo().subscribe(info => {
            if (info != undefined) {
                this.userTypeChildParent = info.user.type;
                if (this.userTypeChildParent == "child") {
                    this.isUserTypeTeen = 0;
                    let x = [
                        {
                            name: "My Account",
                            status: false,
                            menu: [
                                {
                                    name: "Dashboard",
                                    link: "/dashboard/home",
                                    click: ""
                                },
                                {
                                    name: "Teen Profile Settings",
                                    link: "/dashboard/teen-settings",
                                    click: ""
                                },
                                {
                                    name: "Logout",
                                    link: "",
                                    click: "logout()"
                                }
                            ]
                        }
                    ];
                    this.dropdowns = x;
                } else {
                    this.isUserTypeTeen = 1;

                    let x = [
                        {
                            name: "My Account",
                            status: false,
                            menu: [
                                {
                                    name: "Dashboard",
                                    link: "/dashboard/home",
                                    click: ""
                                },
                                {
                                    name: "My Gift Cards",
                                    link: "/dashboard/mycards",
                                    click: ""
                                },
                                {
                                    name: "History",
                                    link: "/dashboard/history",
                                    click: ""
                                },
                                {
                                    name: "Profile Settings",
                                    link: '/dashboard/settings',
                                    click: ""
                                },
                                {
                                    name: "How it works",
                                    link: "/how-it-works",
                                    click: ""
                                },
                                {
                                    name: "Logout",
                                    link: "",
                                    click: "logout()"
                                }
                            ]
                        }
                    ];
                    this.dropdowns = x;
                }

                this.dropdowns.forEach(item => {
                    item.status = false;
                });

                // console.log("info", info);
            }
        });

        AuthguardService.isLoggedIn().subscribe(data => {
            this.isloggedIn = data;
        });

        AuthguardService.userNotifications.subscribe(data => {
            if (data && data.user_notifications) {
                let userData = data.user_notifications;
                if (userData) {
                    let user = JSON.parse(sessionStorage.getItem("userInfo"));
                    user.show_notifications = userData;
                    sessionStorage.setItem("userInfo", JSON.stringify(user));
                    let userD = JSON.parse(sessionStorage.getItem("userInfo"));
                    if (userD) {
                        this.hideShowNotifications =
                            userD.show_notifications == 1 ? true : false;
                    }
                }
            }
        });
        if (sessionStorage.length > 1) {
            let userD = JSON.parse(sessionStorage.getItem("userInfo"));

            if (userD) {
                this.hideShowNotifications =
                    userD.show_notifications == 1 ? true : false;
            }
        }

        // AuthguardService.isLoggedIn().subscribe(status => {
        //     this.isloggedIn = status;
        // });

        if (sessionStorage.length > 0 && sessionStorage.userInfo) {
            this.userInfo = JSON.parse(sessionStorage.userInfo);
            if (sessionStorage.isLoggedIn) {
                this.authService.loggedInSet();
            }
        }

        this.toggle.subscribe(data => {
            if (this.toggleMyAccount == true) {
                this.toggleMyAccount = false;
            }
            if (this.showNotifications == true) {
                this.showNotifications = false;
            }
            this.dropdowns.forEach(item => {
                item.status = false;
            });
        });

        this.getNotifications();
        /*console.log(
            "dropdown",
            this.dropdowns,
            "isuserType",
            this.isUserTypeTeen
        );*/
    }
    onChange(countryValue) {
        let country_id = countryValue;
        sessionStorage.setItem("countryName", country_id);
        this.authService.getcurrency(country_id).subscribe( res =>{
                let body = (<any>res);
                this.currencyValue = body.respData;
                if (this.currencyValue) {
                    //console.log(this.currencyValue);
                    sessionStorage.setItem("currencyValue", this.currencyValue.currency_value);  
                    sessionStorage.setItem("currencyName", this.currencyValue.currency_name );
                    // window.location.reload();
                }
                // this.countries.forEach(element => {
                //     console.log(element.id);
                //     if (element.iso3 === country_id){
                //         console.log(element.id);
                //      sessionStorage.setItem("countryId", element.id)
                //     }
                // });
            });

    }
    getNotifications() {
        if (sessionStorage.length > 1) {
            const user = JSON.parse(sessionStorage.getItem("userInfo"));
            //abhay@keyutech.com:03/03/22 - Added check for null as user is null
            if(user == null) return false;
            var body = {
                user_id: this.cardService.encrypt(user.id.toString())
            };
            let loginType = JSON.parse(sessionStorage.getItem('isLoggedIn'));
            if(loginType == true){
             this.authService.fetchWallet(body).subscribe(response => {
                if ((<any>response).status == 200) {
                    let userInfo = <any>response;
                    if (userInfo.success == true) {
                        this.notifications = userInfo.lastTrans;
                    }
                }
              });
           }
       }
    }

    deleteAllNotifications() {
        const user = JSON.parse(sessionStorage.userInfo);
        let body = {
            user_id: user.id
        };
        this.authService.clearAllNotifications(body).subscribe(response => {
            if ((<any>response).status == 200) {
                let notifyRes = (<any>response);
                if (notifyRes.success == true) {
                    console.log(notifyRes);
                }
            }
            this.showNotifications = !this.showNotifications;
            this.dropdowns.forEach(item => {
                item.status = false;
            });
        });
    }

    showMyAccountNav() {
        this.toggleMyAccount = true;
    }
    hideMyAccountNav() {
        this.toggleMyAccount = false;
    }
    openDashaboard(){
        if (this.isloggedIn) {
            this.router.navigate(['/dashboard/home']);
        } else {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    fromPage: 'dashboard',
                },
            };
            this.router.navigate(['/login-type'], navigationExtras);
        }
    }
    openStoreGiftCardsWallet(){
        if (this.isloggedIn) {
            this.router.navigate(['/dashboard/store-cards-wallet']);
        } else {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    fromPage: 'store-cards-wallet',
                },
            };
            this.router.navigate(['/login-type'], navigationExtras);
        }
    }

    toggleMyAccountNav() {
        this.toggleMyAccount = !this.toggleMyAccount;
        this.showNotifications = false;
        this.dropdowns.forEach(item => {
            item.status = false;
        });
    }

    logout() {
        // logout current user
        this.dropdowns.forEach(item => {
            item.status = false;
        });
        sessionStorage.clear();
        this.toggleMyAccount == false;
        this.showNotifications == false;
        this.authService.loggedOutSet(null);
        this.router.navigate(["/home"]);
    }

    toggleDropdown(menu) {
        
        let currentMenu = this.dropdowns.find(item => item.name == menu.name);
        if (currentMenu) {
            currentMenu.status = !currentMenu.status;
        }
        this.showNotifications = false;
    }
    hideDropdown() {
        this.showNotifications = !this.showNotifications;
        this.dropdowns.forEach(item => {
            item.status = false;
        });
    }
    hideSidebar() {
        // hide side bar
        if (window.innerWidth < 1200) {
            this.toggle = !this.toggle;
        }
    }

    //Method to call on down arrow to open the menu
    downArrow() {
        setTimeout(function(){
            let navbarDropdown1 = document.getElementById('navbarDropdown1');
            if(navbarDropdown1) (navbarDropdown1 as HTMLAnchorElement).click();
        }, 30);
    }

    //Method to call on up arrow to close the menu
    upArrow(menu){
        let currentMenu = this.dropdowns.find(item => item.name == menu.name);
        if (currentMenu) {
            currentMenu.status = !currentMenu.status;
        }
    }

}
