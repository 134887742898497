/***************************************************************
 * Gift card create and redeem service
 ****************************************************************/
import { Injectable } from "@angular/core";
// import {
//     Http,
//     Request,
//     Response,
//     Headers,
//     RequestOptions,
//     RequestMethod
// } from "@angular/http";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
import { ApiencryptService } from './apiencrypt.service';
import { ApidecryptService } from './apidecrypt.service';
import { Observable, pipe, Subject, of, BehaviorSubject} from "rxjs";
import { map, tap } from "rxjs/operators";
import CryptoJS from "./aes.js";
import { environment } from "../../environments/environment";
import { DataService } from "./data.service";
import { MethodService } from './method.service';

@Injectable({
    providedIn: "root"
})
export class GiftcardService {
    // application server url
    SERVER_HOST: string = "";
    // token
    token: string = "";
    private static httpRequestProcessingStatus = new BehaviorSubject<boolean>(
        false
    );
    private cryptoData = new BehaviorSubject<any>(undefined);
    fromRedeemPage = new BehaviorSubject<any>(false);
    public totalcryptovalue = new BehaviorSubject<number>(0);
    public offerData = new BehaviorSubject<any>(undefined);
    public profilestatus = new BehaviorSubject<any>(undefined);
    cryptoCertificates: any[] = [
        {
            id: 1,
            color: "white",
            label: "Bitcoin",
            background: "assets/certificates/bitcoin/bitYellow.jpg",
            font: "black"
        },
        {
            id: 2,
            color: "white",
            label: "Ethereum",
            background: "assets/certificates/etherium/ethYellow.jpg",
            font: "black"
        },
        {
            id: 3,
            color: "white",
            label: "Ripple",
            background: "assets/certificates/ripple/ripYellow.jpg",
            font: "black"
        }
    ];

    cryptoCertificatesBitcoin: any[] = [
        {
            id: 1,
            checkColor: "#ffb365",
            type: "bitcoin",
            background: "assets/certificates/bitcoin/bitYellow.jpg",
            name: "bitYellow.jpg",
            font: "black",
            color: "white",
            label: "Bitcoin",
            keyword: "BTC",
            currency: "BTC",
        },
        {
            id: 2,
            checkColor: "#fe0000",
            type: "bitcoin",
            background: "assets/certificates/bitcoin/bitRed.jpg",
            name: "bitRed.jpg",
            font: "black",
            color: "white",
            label: "Bitcoin",
            keyword: "BTC",
            currency: "BTC",
        },
        {
            id: 3,
            checkColor: "#9eb1fe",
            type: "bitcoin",
            background: "assets/certificates/bitcoin/bitMalibu.jpg",
            name: "bitMalibu.jpg",
            font: "black",
            color: "white",
            label: "Bitcoin",
            keyword: "BTC",
            currency: "BTC",
        },
        {
            id: 4,
            checkColor: "#57D1ff",
            type: "bitcoin",
            background: "assets/certificates/bitcoin/bitMelrose.jpg",
            name: "bitMelrose.jpg",
            font: "black",
            color: "white",
            label: "Bitcoin",
            keyword: "BTC",
            currency: "BTC",
        },
        {
            id: 5,
            checkColor: "#00d487",
            type: "bitcoin",
            background: "assets/certificates/bitcoin/bitGreen.jpg",
            name: "bitGreen.jpg",
            font: "black",
            color: "white",
            label: "Bitcoin",
            keyword: "BTC",
            currency: "BTC",
        }
    ];
    cryptoCertificatesEtherium: any[] = [
        {
            id: 4,
            checkColor: "#57D1ff",
            type: "etherium",
            background: "assets/certificates/etherium/ethMalibu.jpg",
            name: "ethMalibu.jpg",
            font: "black",
            color: "white",
            label: "Etherium",
            currency : "ETH",
            keyword: "ETH",
        },
        {
            id: 1,
            checkColor: "#ffb365",
            type: "etherium",
            background: "assets/certificates/etherium/ethYellow.jpg",
            name: "ethYellow.jpg",
            font: "black",
            color: "white",
            label: "Etherium",
            currency : "ETH",
            keyword: "ETH",
        },
        {
            id: 2,
            checkColor: "#fe0000",
            type: "etherium",
            background: "assets/certificates/etherium/ethRed.jpg",
            name: "ethRed.jpg",
            font: "black",
            color: "white",
            label: "Etherium",
            currency : "ETH",
            keyword: "ETH",
        },
        {
            id: 3,
            checkColor: "#9eb1fe",
            type: "etherium",
            background: "assets/certificates/etherium/ethMelrose.jpg",
            name: "ethMelrose.jpg",
            font: "black",
            color: "white",
            label: "Etherium",
            currency : "ETH",
            keyword: "ETH",
        },
        {
            id: 5,
            checkColor: "#00d487",
            type: "etherium",
            background: "assets/certificates/etherium/ethGreen.jpg",
            name: "ethGreen.jpg",
            font: "black",
            color: "white",
            label: "Etherium",
            currency : "ETH",
            keyword: "ETH",
        }
    ];
    cryptoCertificatesRipple: any[] = [
        {
            id: 3,
            checkColor: "#9eb1fe",
            type: "ripple",
            background: "assets/certificates/ripple/ripMelrose.jpg",
            name: "ripMelrose.jpg",
            font: "black",
            color: "white",
            label: "Ripple",
            currency : "XRP",
            keyword: "XRP",
        },
        {
            id: 1,
            checkColor: "#ffb365",
            type: "ripple",
            background: "assets/certificates/ripple/ripYellow.jpg",
            name: "ripYellow.jpg",
            font: "black",
            color: "white",
            label: "Ripple",
            currency : "XRP",
            keyword: "XRP",
        },
        {
            id: 2,
            checkColor: "#fe0000",
            type: "ripple",
            background: "assets/certificates/ripple/ripRed.jpg",
            name: "ripRed.jpg",
            font: "black",
            color: "white",
            label: "Ripple",
            currency : "XRP",
            keyword: "XRP",
        },
        {
            id: 4,
            checkColor: "#57D1ff",
            type: "ripple",
            background: "assets/certificates/ripple/ripMalibu.jpg",
            name: "ripMalibu.jpg",
            font: "black",
            color: "white",
            label: "Ripple",
            currency : "XRP",
            keyword: "XRP",
        },
        {
            id: 5,
            checkColor: "#00d487",
            type: "ripple",
            background: "assets/certificates/ripple/ripGreen.jpg",
            name: "ripGreen.jpg",
            font: "black",
            color: "white",
            label: "Ripple",
            currency : "XRP",
            keyword: "XRP",
        }
    ];
    cryptoCertificatesBitcoincash: any[] = [
        {
            id: 5,
            checkColor: "#00d487",
            type: "bitcoincash",
            background: "assets/certificates/bitcoincash/bitGreen.png",
            name: "bitGreen.jpg",
            font: "black",
            color: "white",
            label: "bitcoincash",
            keyword: "BCH",
            currency: "BCH",
        },
        {
            id: 1,
            checkColor: "#ffb365",
            type: "bitcoincash",
            background: "assets/certificates/bitcoincash/bitYellow.png",
            name: "bitYellow.jpg",
            font: "black",
            color: "white",
            label: "bitcoincash",
            keyword: "BCH",
            currency: "BCH",
        },
        {
            id: 2,
            checkColor: "#fe0000",
            type: "bitcoincash",
            background: "assets/certificates/bitcoincash/bitRed.png",
            name: "bitRed.jpg",
            font: "black",
            color: "white",
            label: "bitcoincash",
            keyword: "BCH",
            currency: "BCH",
        },
        {
            id: 3,
            checkColor: "#9eb1fe",
            type: "bitcoincash",
            background: "assets/certificates/bitcoincash/bitMalibu.png",
            name: "bitMalibu.jpg",
            font: "black",
            color: "white",
            label: "bitcoincash",
            keyword: "BCH",
            currency: "BCH",
        },
        {
            id: 4,
            checkColor: "#57D1ff",
            type: "bitcoincash",
            background: "assets/certificates/bitcoincash/bitMelrose.png",
            name: "bitMelrose.jpg",
            font: "black",
            color: "white",
            label: "bitcoincash",
            keyword: "BCH",
            currency: "BCH",
        }
    ];
    cryptoCertificatesLitecoin: any[] = [
        {
            id: 4,
            checkColor: "#57D1ff",
            type: "litecoin",
            background: "assets/certificates/litecoin/bitMelrose.png",
            name: "bitMelrose.jpg",
            font: "black",
            color: "white",
            label: "litecoin",
            keyword: "LTC",
            currency: "LTC",
        },
        {
            id: 1,
            checkColor: "#ffb365",
            type: "litecoin",
            background: "assets/certificates/litecoin/bitYellow.png",
            name: "bitYellow.jpg",
            font: "black",
            color: "white",
            label: "litecoin",
            keyword: "LTC",
            currency: "LTC",
        },
        {
            id: 2,
            checkColor: "#fe0000",
            type: "litecoin",
            background: "assets/certificates/litecoin/bitRed.png",
            name: "bitRed.jpg",
            font: "black",
            color: "white",
            label: "litecoin",
            keyword: "LTC",
            currency: "LTC",
        },
        {
            id: 3,
            checkColor: "#9eb1fe",
            type: "litecoin",
            background: "assets/certificates/litecoin/bitMalibu.png",
            name: "bitMalibu.jpg",
            font: "black",
            color: "white",
            label: "litecoin",
            keyword: "LTC",
            currency: "LTC",
        },
        {
            id: 5,
            checkColor: "#00d487",
            type: "litecoin",
            background: "assets/certificates/litecoin/bitGreen.png",
            name: "bitGreen.jpg",
            font: "black",
            color: "white",
            label: "litecoin",
            keyword: "LTC",
            currency: "LTC",
        }
    ];
    cryptoCertificatesStellar: any[] = [
        {
            id: 3,
            checkColor: "#9eb1fe",
            type: "stellar",
            background: "assets/certificates/stellar/bitMalibu.png",
            name: "bitMalibu.jpg",
            font: "black",
            color: "white",
            label: "stellar",
            keyword: "XLM",
            currency: "XLM",
        },
        {
            id: 1,
            checkColor: "#ffb365",
            type: "stellar",
            background: "assets/certificates/stellar/bitYellow.png",
            name: "bitYellow.jpg",
            font: "black",
            color: "white",
            label: "stellar",
            keyword: "XLM",
            currency: "XLM",
        },
        {
            id: 2,
            checkColor: "#fe0000",
            type: "stellar",
            background: "assets/certificates/stellar/bitRed.png",
            name: "bitRed.jpg",
            font: "black",
            color: "white",
            label: "stellar",
            keyword: "XLM",
            currency: "XLM",
        },
        {
            id: 4,
            checkColor: "#57D1ff",
            type: "stellar",
            background: "assets/certificates/stellar/bitMelrose.png",
            name: "bitMelrose.jpg",
            font: "black",
            color: "white",
            label: "stellar",
            keyword: "XLM",
            currency: "XLM",
        },
        {
            id: 5,
            checkColor: "#00d487",
            type: "stellar",
            background: "assets/certificates/stellar/bitGreen.png",
            name: "bitGreen.jpg",
            font: "black",
            color: "white",
            label: "stellar",
            keyword: "XLM",
            currency: "XLM",
        }
    ];
    cryptoCertificatesCardano: any[] = [
        {
            id: 3,
            checkColor: "#9eb1fe",
            type: "cardano",
            background: "assets/certificates/stellar/bitMalibu.png",
            name: "bitMalibu.jpg",
            font: "black",
            color: "white",
            label: "cardano",
            keyword: "ADA",
            currency: "ADA",
        },
        {
            id: 1,
            checkColor: "#ffb365",
            type: "cardano",
            background: "assets/certificates/stellar/bitYellow.png",
            name: "bitYellow.jpg",
            font: "black",
            color: "white",
            label: "cardano",
            keyword: "ADA",
            currency: "ADA",
        },
        {
            id: 2,
            checkColor: "#fe0000",
            type: "cardano",
            background: "assets/certificates/stellar/bitRed.png",
            name: "bitRed.jpg",
            font: "black",
            color: "white",
            label: "cardano",
            keyword: "ADA",
            currency: "ADA",
        },
        {
            id: 4,
            checkColor: "#57D1ff",
            type: "cardano",
            background: "assets/certificates/stellar/bitMelrose.png",
            name: "bitMelrose.jpg",
            font: "black",
            color: "white",
            label: "cardano",
            keyword: "ADA",
            currency: "ADA",
        },
        {
            id: 5,
            checkColor: "#00d487",
            type: "cardano",
            background: "assets/certificates/stellar/bitGreen.png",
            name: "bitGreen.jpg",
            font: "black",
            color: "white",
            label: "cardano",
            keyword: "ADA",
            currency: "ADA",
        }
    ];
    cryptoCertificatesDogecoin: any[] = [
        {
            id: 3,
            checkColor: "#9eb1fe",
            type: "dogecoin",
            background: "assets/certificates/stellar/bitMalibu.png",
            name: "bitMalibu.jpg",
            font: "black",
            color: "white",
            label: "dogecoin",
            keyword: "DOGE",
            currency: "DOGE",
        },
        {
            id: 1,
            checkColor: "#ffb365",
            type: "dogecoin",
            background: "assets/certificates/stellar/bitYellow.png",
            name: "bitYellow.jpg",
            font: "black",
            color: "white",
            label: "dogecoin",
            keyword: "DOGE",
            currency: "DOGE",
        },
        {
            id: 2,
            checkColor: "#fe0000",
            type: "dogecoin",
            background: "assets/certificates/stellar/bitRed.png",
            name: "bitRed.jpg",
            font: "black",
            color: "white",
            label: "dogecoin",
            keyword: "DOGE",
            currency: "DOGE",
        },
        {
            id: 4,
            checkColor: "#57D1ff",
            type: "dogecoin",
            background: "assets/certificates/stellar/bitMelrose.png",
            name: "bitMelrose.jpg",
            font: "black",
            color: "white",
            label: "dogecoin",
            keyword: "DOGE",
            currency: "DOGE",
        },
        {
            id: 5,
            checkColor: "#00d487",
            type: "dogecoin",
            background: "assets/certificates/stellar/bitGreen.png",
            name: "bitGreen.jpg",
            font: "black",
            color: "white",
            label: "dogecoin",
            keyword: "DOGE",
            currency: "DOGE",
        }
    ];
    cryptoCertificatesPolkadot: any[] = [
        {
            id: 3,
            checkColor: "#9eb1fe",
            type: "polkadot",
            background: "assets/certificates/stellar/bitMalibu.png",
            name: "bitMalibu.jpg",
            font: "black",
            color: "white",
            label: "polkadot",
            keyword: "DOT",
            currency: "DOT",
        },
        {
            id: 1,
            checkColor: "#ffb365",
            type: "polkadot",
            background: "assets/certificates/stellar/bitYellow.png",
            name: "bitYellow.jpg",
            font: "black",
            color: "white",
            label: "polkadot",
            keyword: "DOT",
            currency: "DOT",
        },
        {
            id: 2,
            checkColor: "#fe0000",
            type: "polkadot",
            background: "assets/certificates/stellar/bitRed.png",
            name: "bitRed.jpg",
            font: "black",
            color: "white",
            label: "polkadot",
            keyword: "DOT",
            currency: "DOT",
        },
        {
            id: 4,
            checkColor: "#57D1ff",
            type: "polkadot",
            background: "assets/certificates/stellar/bitMelrose.png",
            name: "bitMelrose.jpg",
            font: "black",
            color: "white",
            label: "polkadot",
            keyword: "DOT",
            currency: "DOT",
        },
        {
            id: 5,
            checkColor: "#00d487",
            type: "polkadot",
            background: "assets/certificates/stellar/bitGreen.png",
            name: "bitGreen.jpg",
            font: "black",
            color: "white",
            label: "polkadot",
            keyword: "DOT",
            currency: "DOT",
        }
    ];
    // card templates for gift cards
    giftCardTempplates: any[] = [
        {
            id: 1,
            color: "#053a6a",
            type: "gift",
            image: "assets/templates/teal.png",
            font: "black"
        },
        {
            id: 2,
            color: "#ff2a00",
            type: "gift",
            image: "assets/templates/red.png",
            font: "black"
        },
        {
            id: 3,
            color: "#000000",
            type: "gift",
            image: "assets/templates/black.png",
            font: "white"
        },
        {
            id: 4,
            color: "#f5e102",
            type: "gift",
            image: "assets/templates/yellow.png",
            font: "black"
        },
        {
            id: 5,
            color: "#13be22",
            type: "gift",
            image: "assets/templates/green.png",
            font: "black"
        }
    ];

    cryptoCertificateTemplates: any[] = [
        {
            id: 1,
            color: "#053a6a",
            type: "gift",
            image: "assets/templates/certificate/certificate1.jpg",
            font: "black",
            label: 'Home'
        },
        {
            id: 2,
            color: "#053a6a",
            type: "gift",
            image: "assets/templates/certificate/certificate2.jpg",
            font: "black",
            label: 'Home'
        },
        {
            id: 3,
            color: "#053a6a",
            type: "gift",
            image: "assets/templates/certificate/certificate3.jpg",
            font: "black",
            label: 'Home'
        },
        {
            id: 4,
            color: "#f5e102",
            type: "gift",
            image: "assets/templates/certificate/certificate4.jpg",
            font: "yellow",
            label: 'Home'
        },
        {
            id: 5,
            color: "#13be22",
            type: "gift",
            image: "assets/templates/certificate/certificate5.jpg",
            font: "yellow",
            label: 'Home'
        }
    ];

    giftCardTempplatesMarketFlyer: any[] = [
        {
            id: 1,
            color: "#053a6a",
            type: "gift",
            image: "assets/templates/marketFlyer/home_teal.jpg",
            font: "black",
            label: 'Home'
        },
        {
            id: 2,
            color: "#ff2a00",
            type: "gift",
            image: "assets/templates/marketFlyer/home_red.jpg",
            font: "black",
            label: 'Home'
        },
        {
            id: 3,
            color: "#000000",
            type: "gift",
            image: "assets/templates/marketFlyer/home_black.jpg",
            font: "red",
            label: 'Home'
        },
        {
            id: 4,
            color: "#f5e102",
            type: "gift",
            image: "assets/templates/marketFlyer/home_yellow.jpg",
            font: "yellow",
            label: 'Home'
        },
        {
            id: 5,
            color: "#13be22",
            type: "gift",
            image: "assets/templates/marketFlyer/home_green.jpg",
            font: "yellow",
            label: 'Home'
        }
    ];

    giftCardTempplatesMarketFlyerAdvocate: any[] = [
        {
            id: 1,
            color: "#053a6a",
            type: "gift",
            image: "assets/templates/marketFlyer/advocate/advocate_teal.jpg",
            font: "black",
            label: 'Advocate'
        },
        {
            id: 2,
            color: "#ff2a00",
            type: "gift",
            image: "assets/templates/marketFlyer/advocate/advocate_red.jpg",
            font: "black",
            label: 'Advocate'
        },
        {
            id: 3,
            color: "#000000",
            type: "gift",
            image: "assets/templates/marketFlyer/advocate/advocate_black.jpg",
            font: "red",
            label: 'Advocate'
        },
        {
            id: 4,
            color: "#f5e102",
            type: "gift",
            image: "assets/templates/marketFlyer/advocate/advocate_yellow.jpg",
            font: "yellow",
            label: 'Advocate'
        },
        {
            id: 5,
            color: "#13be22",
            type: "gift",
            image: "assets/templates/marketFlyer/advocate/advocate_green.jpg",
            font: "yellow",
            label: 'Advocate'
        }
    ];
    giftCardTempplatesMarketFlyerDentist: any[] = [
        {
            id: 1,
            color: "#053a6a",
            type: "gift",
            image: "assets/templates/marketFlyer/dentist/dentist_teal.jpg",
            font: "black",
            label: 'Dentist'
        },
        {
            id: 2,
            color: "#ff2a00",
            type: "gift",
            image: "assets/templates/marketFlyer/dentist/dentist_red.jpg",
            font: "black",
            label: 'Dentist'
        },
        {
            id: 3,
            color: "#000000",
            type: "gift",
            image: "assets/templates/marketFlyer/dentist/dentist_black.jpg",
            font: "red",
            label: 'Dentist'
        },
        {
            id: 4,
            color: "#f5e102",
            type: "gift",
            image: "assets/templates/marketFlyer/dentist/dentist_yellow.jpg",
            font: "yellow",
            label: 'Dentist'
        },
        {
            id: 5,
            color: "#13be22",
            type: "gift",
            image: "assets/templates/marketFlyer/dentist/dentist_green.jpg",
            font: "yellow",
            label: 'Dentist'
        }
    ];
    giftCardTempplatesMarketFlyerCuttingEdge: any[] = [
        {
            id: 1,
            color: "#053a6a",
            type: "gift",
            image: "assets/templates/marketFlyer/cutting_edge/cutting_edge_teal.jpg",
            font: "black",
            label: 'CuttingEdge'
        },
        {
            id: 2,
            color: "#ff2a00",
            type: "gift",
            image: "assets/templates/marketFlyer/cutting_edge/cutting_edge_red.jpg",
            font: "black",
            label: 'CuttingEdge'
        },
        {
            id: 3,
            color: "#000000",
            type: "gift",
            image: "assets/templates/marketFlyer/cutting_edge/cutting_edge_black.jpg",
            font: "red",
            label: 'CuttingEdge'
        },
        {
            id: 4,
            color: "#f5e102",
            type: "gift",
            image: "assets/templates/marketFlyer/cutting_edge/cutting_edge_yellow.jpg",
            font: "yellow",
            label: 'CuttingEdge'
        },
        {
            id: 5,
            color: "#13be22",
            type: "gift",
            image: "assets/templates/marketFlyer/cutting_edge/cutting_edge_green.jpg",
            font: "yellow",
            label: 'CuttingEdge'
        }
    ];
    giftCardTempplatesMarketFlyerMike: any[] = [
        {
            id: 1,
            color: "#053a6a",
            type: "gift",
            image: "assets/templates/marketFlyer/mike/mike_teal.jpg",
            font: "black",
            label: 'Mike'
        },
        {
            id: 2,
            color: "#ff2a00",
            type: "gift",
            image: "assets/templates/marketFlyer/mike/mike_red.jpg",
            font: "black",
            label: 'Mike'
        },
        {
            id: 3,
            color: "#000000",
            type: "gift",
            image: "assets/templates/marketFlyer/mike/mike_black.jpg",
            font: "red",
            label: 'Mike'
        },
        {
            id: 4,
            color: "#f5e102",
            type: "gift",
            image: "assets/templates/marketFlyer/mike/mike_yellow.jpg",
            font: "yellow",
            label: 'Mike'
        },
        {
            id: 5,
            color: "#13be22",
            type: "gift",
            image: "assets/templates/marketFlyer/mike/mike_green.jpg",
            font: "yellow",
            label: 'Mike'
        }
    ];
    giftCardMarketingTempIcon: any[] = [
        {
            id: 1,
            color: "#053a6a",
            type: "flyer-icon",
            image: "assets/templates/marketFlyer/thumbnails/1.png",
            font: "blue",
            label: 'Home'
        },
        {
            id: 2,
            color: "#053a6a",
            type: "flyer-icon",
            image: "assets/templates/marketFlyer/thumbnails/2.png",
            font: "blue",
            label: 'Mike'
        },
        {
            id: 3,
            color: "#053a6a",
            type: "flyer-icon",
            image: "assets/templates/marketFlyer/thumbnails/3.png",
            font: "blue",
            label: 'Advocate'
        },
        {
            id: 4,
            color: "#053a6a",
            type: "flyer-icon",
            image: "assets/templates/marketFlyer/thumbnails/4.png",
            font: "blue",
            label: 'CuttingEdge'
        },
        {
            id: 5,
            color: "#13be22",
            type: "flyer-icon",
            image: "assets/templates/marketFlyer/thumbnails/5.png",
            font: "blue",
            label: 'Dentist'
        },
        {
            id: 6,
            color: "#13be22",
            type: "flyer-icon",
            image: "assets/templates/marketFlyer/thumbnails/6.png",
            font: "blue",
            label: 'Custom'
        }
    ];
    // card templates for crypto cards
    cryptoCardTempplates: any[] = [
        {
            id: 1,
            color: "#f89f36",
            type: "crypto",
            image: "assets/templates/bitcoin.png",
            icon: "assets/icons/bitcoin.png",
            currency: "BTC",
            font: "white"
        },
        {
            id: 2,
            color: "#627eea",
            type: "crypto",
            image: "assets/templates/ethereum.png",
            icon: "assets/icons/erridium.png",
            currency: "ETH",
            font: "white"
        },
        {
            id: 3,
            color: "#03a1dc",
            type: "crypto",
            image: "assets/templates/ripple.png",
            icon: "assets/icons/ripple.png",
            currency: "XRP",
            font: "white"
        }
    ];

    constructor(
    // private http: Http,
    private httpClient: HttpClient,
    private apienc: ApiencryptService,
    private apidec: ApidecryptService,
    private global: GlobalPipe,
    private methodtype :MethodService
    )
    {
        this.SERVER_HOST = environment.serverHost;
    }
    // constructor(private http: Http, private httpClient: HttpClient) {
    //     this.SERVER_HOST = environment.serverHost;
    // }

    static getHttpRequestProcessing() {
        // getter for login status
        return GiftcardService.httpRequestProcessingStatus.asObservable();
    }
    setHttpRequestProcessing(data) {
        // getter for login status
        return GiftcardService.httpRequestProcessingStatus.next(data);
    }

    loggedOutSet(error) {
        // set user logout
        // sessionStorage.clear();
        // this.router.navigateByUrl("/login");
        this.setHttpRequestProcessing(false);
        // console.log(error);
        // return error;
    }

    setToken(token) {
        this.token = token;
    }
    getHTTPHeaders() {
        let headers = new Headers();
        // let options = new RequestOptions({
        //     headers: headers
        // });

        headers.append("Authorization", "Bearer " + sessionStorage.token);
        headers.set("content-type", "application/json");
        headers.append("accept", "application/json");
        headers.append("language", "enLang");
        headers.append("deviceInfo", "deviceinfo");
        headers.append("IPAddress", "");
        headers.append("requestType", "W");
        headers.append("userString", "");
        headers.append("session-token", "");
        headers.append("requestToken", "");
        headers.append("requestEnv", "");

        return headers;
    }

    getHTTPHeaders1(){
        let headers = new Headers();
        // let options = new RequestOptions({
        //     headers: headers
        // });

        headers.append("Authorization", "Bearer " + sessionStorage.token);
        headers.set("content-type", "application/json");
        headers.append("accept", "application/json");
        headers.append("language", "enLang");
        headers.append("deviceInfo", "deviceinfo");
        headers.append("IPAddress", "");
        headers.append("requestType", "");
        headers.append("userString", "");
        headers.append("session-token", "");
        headers.append("requestToken", "");
        headers.append("requestEnv", "dev");

        return headers;
    }

    getToken(token) {
        this.token = token;
    }

    //get crypto gift cards Image
    getCryptoGiftImg() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `crypto`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    this.cryptoData.next(response);
                    return response;

                })
            );
    }


    getCryptoCountData(){
        return this.cryptoData.asObservable();
    }

    createCard(data) {
        this.setHttpRequestProcessing(true);
        // create new card
        return this.httpClient.post(this.SERVER_HOST + "createGiftCards",data,this.methodtype.getheadersmethod()).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }

    estockPaymentGateway(data) {
        this.setHttpRequestProcessing(true);
        // create new card
        return this.httpClient.post(this.SERVER_HOST + "estockPaymentGateway", data).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }

    getRedeemCardInfo(data) {
        this.setHttpRequestProcessing(true);
        //fetch card details for redeem screen
        return this.httpClient.post(this.SERVER_HOST + "readGiftCard", data,this.methodtype.getheadersmethod()).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }

    //   getGiftCardTemplates() {
    //     // gift card templates getter
    //     return this.giftCardTempplates;
    //   }
    //   getCryptoCardTemplates() {
    //     // crypto card templates getter
    //     return this.cryptoCardTempplates;
    //   }
    getCryptoCertificatesBitcoin() {
        // crypto certificates template getter
        return this.cryptoCertificatesBitcoin;
    }
    getCryptoCertificatesEtherium() {
        // crypto certificates template getter
        return this.cryptoCertificatesEtherium;
    }
    getCryptoCertificatesRipple() {
        // crypto certificates template getter
        return this.cryptoCertificatesRipple;
    }
    getCryptoCertificates() {
        // crypto certificates template getter
        return this.cryptoCertificates;
    }
    getCryptoCertificatesLitecoin(){
        return this.cryptoCertificatesLitecoin;
    }
    getCryptoCertificatesBitcoincash(){
        return this.cryptoCertificatesBitcoincash;
    }
    getCryptoCertificatesStellar(){
        return this.cryptoCertificatesStellar;
    }
    getCryptoCertificatesCardano(){
        return this.cryptoCertificatesCardano;
    }
    getCryptoCertificatesDogecoin(){
        return this.cryptoCertificatesDogecoin;
    }
    getCryptoCertificatesPolkadot(){
        return this.cryptoCertificatesPolkadot;
    }
    redeemCard(data) {
        this.setHttpRequestProcessing(true);
        //fetch card details for redeem screen
        return this.httpClient
            .post(
                this.SERVER_HOST + "redeemCodeAuth",
                data,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(true);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    getGiftCardTemplates() {
        // gift card templates getter
        return this.giftCardTempplates;
    }


    getCryptoCertificateTemplates() {
        // gift card templates getter
        return this.cryptoCertificateTemplates;
    }
    getGiftCardTemplatesFlyer() {
        // gift card templates getter
        return this.giftCardTempplatesMarketFlyer;
    }

    getCryptoCardTemplates() {
        // crypto card templates getter
        return this.cryptoCardTempplates;
    }
    geMultiplMarketThemeIcon() {
        // crypto card templates getter
        return this.giftCardMarketingTempIcon;
    }

    encrypt(data) {
        let formatter = this.formatter();
        let cipher = CryptoJS.AES.encrypt(data, environment.passkey, {
            format: formatter
        }).toString();
        return cipher;
    }
    decrypt(data) {
        let formatter = this.formatter();
        let decipher = CryptoJS.AES.decrypt(data, environment.passkey, {
            format: formatter
        }).toString(CryptoJS.enc.Utf8);
        return decipher;
    }

    formatter() {
        return {
            stringify: function(cipherParams) {
                var j = {
                    ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
                    iv: "",
                    s: ""
                };
                if (cipherParams.iv) j.iv = cipherParams.iv.toString();
                if (cipherParams.salt) j.s = cipherParams.salt.toString();
                return JSON.stringify(j).replace(/\s/g, "");
            },
            parse: function(jsonStr) {
                var j = JSON.parse(jsonStr);
                var cipherParams = CryptoJS.lib.CipherParams.create({
                    ciphertext: CryptoJS.enc.Base64.parse(j.ct)
                });
                if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
                if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
                return cipherParams;
            }
        };
    }

    encoderDecoder(string) {
        var enc = "";
        var str = "";
        var key = "256";
        // make sure that input is string
        str = string.toString();
        for (var i = 0; i < string.length; i++) {
            // create block
            var a = string.charCodeAt(i);
            // bitwise XOR
            var b = a ^ (<any>key);
            enc = enc + String.fromCharCode(b);
        }
        return enc;
    }

    // How it works
    howItWorksFaqs() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(this.SERVER_HOST + `faq`, {}, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    }
                    // error => {
                    //   if ((<any>error).status == 500)
                    //     Swal({
                    //       title: "Error",
                    //       text: error.message,
                    //       type: "error",
                    //       conyfirmButtonColor: "#053a6a",
                    //       confirmButtonText: "OK",
                    //     });
                    //   this.setHttpRequestProcessing(false);
                    //   this.loggedOutSet(error);
                    // }
                )
            );
    }

    // Home Page get Images
    getHomePageBanners() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `banner`, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    }
                    // error => {
                    //   if ((<any>error).status == 500)
                    //     Swal({
                    //       title: "Error",
                    //       text: error.message,
                    //       type: "error",
                    //       conyfirmButtonColor: "#053a6a",
                    //       confirmButtonText: "OK",
                    //     });
                    //   this.setHttpRequestProcessing(false);
                    //   this.loggedOutSet(error);
                    // }
                )
            );
    }

    // accept and block features
    acceptBlockFeatures() {
        
        const url = sessionStorage.getItem("countryName") === "IND" ? `features?type=IND`:`features`;
        return this.methodtype.getcurl(url,this.methodtype.getheadersmethod());
    }

    // about page get data
    getAboutPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `aboutUs`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    // contact page get data
    getContactPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `contact`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    // terms page get data
    getTermsPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `terms`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    // policy page get data
    getPolicyPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `policy`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }


    // AML policy page get data
    getAMLPolicyPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `amlpolicy`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    // CCPa policy page get data
    getCCPaPolicyPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `ccpa`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }
    // disclosures page get data
    getDisclosuresPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `disclosures`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    // press page get data
    getPressPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `press`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }
    getDisclaimersPageData(){
        return this.httpClient
            .get(this.SERVER_HOST + `disclaimer`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }
    // careers page get data
    getCareersPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `careers`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    // blog page get data
    getBlogPageData() {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(this.SERVER_HOST + `blog`, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    // processing fee get data
    getProcessingFeeData() {
        // this.setHttpRequestProcessing(true);
        const url = `payment`;
        return this.methodtype.getcurl(url, this.methodtype.getheadersmethod());
    }

    getStockData(){
        // this.setHttpRequestProcessing(true);
        const url = `stock`;
        return this.methodtype.getcurl(url,this.methodtype.getheadersmethod());
    }
    getDefaultCard(){
        // this.setHttpRequestProcessing(true);
        const url = `defaultCrypto`;
        return this.methodtype.getcurl(url,this.methodtype.getheadersmethod());
    }
    getStocksGiftImg(){
        const url = `stock`;
        return this.methodtype.getcurl(url,this.methodtype.getheadersmethod());
    }

    getMarketingFlyerTemplateAdvocate(){
       return this.giftCardTempplatesMarketFlyerAdvocate;
    }
    getMarketingFlyerTemplateDentist(){
       return this.giftCardTempplatesMarketFlyerDentist;
    }
    getMarketingFlyerTemplateMike(){
       return this.giftCardTempplatesMarketFlyerMike;
    }
    getCustomFlyerTemplate(){
        return {
                id: 6,
                color: "#13be22",
                type: "gift",
                image: "assets/templates/marketFlyer/custom_flyer.jpg",
                font: "black",
                label: 'Custom'
            };
    }
    getMarketingFlyerTemplateCuttingEdge(){
       return this.giftCardTempplatesMarketFlyerCuttingEdge;
    }

     //get crypto gift cards Image
     checkTeenProfile(body:any) {
        return this.httpClient
            .post(this.SERVER_HOST + `checkTeenProfile`, body, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    return response;
                })
            );
    }

    getUsers(){
        // this.setHttpRequestProcessing(true);
        return this.httpClient.get(this.SERVER_HOST + 'users', this.methodtype.getheadersmethod()).pipe(
            tap(response => {
                // this.setHttpRequestProcessing(false);
                return response;
            })
        )
    }
    getTransactionData(data : any){
        return this.httpClient
            .post(this.SERVER_HOST + `getTransactionData`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(response => {
                    return response;

                })
            );
    }

    sendMassEmails(data) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}sendMassEmails`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    getCardsCategoryCount(data) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}getCardsCategoryCount`,data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    getUserCategoryCards(data) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}getUserCategoryCards`,data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }



    getStoreCards(data) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}getStoreCards`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    updateStoreCard(data) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}updateStoreCard`, data,this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    saveStoreCard(data) {
        // let strdata = JSON.stringify(data);
        // let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}saveStoreCard`,data,this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }


    getUsedStoreCards(data) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}getUsedStoreCards`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    moveCardtoWallet(data) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}moveCardtoWallet`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    searchStoreNames(data) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}searchStoreNames`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                         this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                         this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    deleteStoreCard(data) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}deleteStoreCard`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }
    gettemplates(){
        // this.setHttpRequestProcessing(true);
        const url = `giftTemplates`;
        let params = {bannerType: 'W'};
        return this.methodtype.getcurl(url,this.methodtype.getheadersmethod(), params);
    }
    buyCryptoCurrencyfreebie(data){
        this.setHttpRequestProcessing(true);
    return this.httpClient
        .post(
            this.SERVER_HOST + "buyCryptoCurrency",
            data,
            this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    //get wallet status
    fetchWalletLinkingStatus(userId){
        this.setHttpRequestProcessing(true);
    return this.httpClient
        .post(
            this.SERVER_HOST + "check-wallet",
            userId,
            this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    // check handle create-entity
    generatewallet(userId){
        this.setHttpRequestProcessing(true);
    return this.httpClient
        .post(
            this.SERVER_HOST + "create-entity",
            userId,
            this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    registerwallet(userInfo){
        this.setHttpRequestProcessing(true);
    return this.httpClient
        .post(
            this.SERVER_HOST + "register-wallet",
            userInfo,
            this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    //linkbankaccount
    linkbankaccount(data){
        this.setHttpRequestProcessing(true);
    return this.httpClient
        .post(
            this.SERVER_HOST + "link-bank",
            data,
            this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    // get-accounts
    fetchMyAccounts(userId){
        this.setHttpRequestProcessing(true);
    return this.httpClient
        .post(
            this.SERVER_HOST + "get-accounts",
            userId,
            this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    // issue sila load money to wallet 
    issueSila(data){
        this.setHttpRequestProcessing(true);
        return this.httpClient
        .post(
            this.SERVER_HOST + 'issue-sila',
            data,
            this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    console.log(error);
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    // kyc-status
    getkycstatus(userId){
        this.setHttpRequestProcessing(true);
    return this.httpClient
        .post(
            this.SERVER_HOST + "kyc-status",
            userId,
            this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(true);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    // get sila balance
    // getsilawallet(userId){
    //     this.setHttpRequestProcessing(true);
    // return this.httpClient
    //     .post(
    //         this.SERVER_HOST + "get-wallet",
    //         userId,
    //         this.methodtype.getheadersmethod()
    //     )
    //     .pipe(
    //         tap(
    //             response => {
    //                 this.setHttpRequestProcessing(false);
    //                 return response;
    //             },
    //             error => {
    //                 this.setHttpRequestProcessing(true);
    //                 this.loggedOutSet(error);
    //             }
    //         )
    //     );
    // }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {'enc_data' : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
        return JSON.stringify(inputdata);
    }
}

