import { Component, OnInit } from "@angular/core";
import { GiftcardService } from "../services/giftcard.service";
@Component({
    selector: "app-careers",
    templateUrl: "./careers.component.html",
    styleUrls: ["./careers.component.scss"]
})
export class CareersComponent implements OnInit {
    careerData: any;
    constructor(private giftService: GiftcardService) {}

    ngOnInit() {
        this.getCareersDataDetails();
    }

    getCareersDataDetails() {
        this.giftService.getCareersPageData().subscribe(response => {
            // let body = JSON.parse((<any>response)._body);

            if ((<any>response).success == true) {
                this.careerData = response;
                //console.log(this.careerData);
            }
        });
    }
}
