import { Component, OnInit } from "@angular/core";
import { AuthguardService } from "src/app/services/authguard.service";
import { Router } from "@angular/router";
import { CryptoService } from "src/app/services/crypto.service";
import { GiftcardService } from "src/app/services/giftcard.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-sell-stocks",
    templateUrl: "./sell-stocks.component.html",
    styleUrls: ["./sell-stocks.component.scss"]
})
export class SellStocksComponent implements OnInit {
    selectedStocksObj: any;
    transactionDone: boolean = false;
    selectedStock = "";
    selectedData: string;
    selectedDataInfo: any = undefined;
    walletAmount: number;
    date = new Date();
    insufficientBalanceError: boolean = false;
    showSummary: boolean = true;
    amount: string = "";
    cryptoWallet: any;
    stockOptions: any = undefined;
    processingFeeData: any;
    // transactionFee: number;
    processingCharges: number;
    amountEntered: number = 0;
    shares: number = 0;
    total: number = 0;
    currentPrice: number = 0;
    totalShares: number = 0;
    userInfo: any = null;
    userWalletInfo: any = null;
    percentage: any;
    selectedStockFromAllAvailable: any;
    transactionDetails: any = {
        orderId: "",
        message: "",
        stock: "",
        amount: "",
        total: "",
        shares: "",
        fee: ""
    };
    currencyName: any;
    currencyValue: any;

    constructor(
        private authServices: AuthguardService,
        private router: Router,
        private cryptoService: CryptoService,
        private cardService: GiftcardService
    ) {
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
    }

    ngOnInit() {
        // fetch processing Fee
        this.getProcessingFee();
        // fetch wallet Amount
        this.authServices.getuserInfo().subscribe(info => {
            if (info != undefined) {
                console.log("info", info);
                this.userInfo = info.user;
                this.walletAmount = info.giftCardsValue;
                // this.userWalletInfo = info.userCryptoCurrency;
                // this.getUserShares();
            }
        });
        // fetch all stocks data
        this.getAllStocksData();
    } // ngOnint end here

    // on cancel order
    cancel() {
        Swal({
            title: "",
            text: "Do you really want to cancel?",
            type: "error",
            showCancelButton: true,
            confirmButtonColor: "#053a6a",
            cancelButtonColor: "#eb6363",
            confirmButtonText: "YES",
            cancelButtonText: "No",
            allowOutsideClick: false,
        }).then(result => {
            if (result && result.value) {
                this.amount = "";
                this.amountEntered = 0;
                this.selectedStock = "";
                this.shares = 0;
                this.processingCharges = parseFloat(
                    this.processingFeeData.cryptocurrency_fee
                );
                this.total = 0;
                this.selectedData = this.stockOptions[0].stock_name;
                this.selectedDataInfo = undefined;
                this.selectedStocksObj = undefined;
                this.percentage = 0;
            }
        });
    }

    selectStocks(selected) {
        this.getStockFee(selected);
        this.selectedData = selected;

        this.selectedStockFromAllAvailable = this.stockOptions.find(
            data => data.stock_name == selected
        );
        //this.percentage = 0;
        // this.showSummary = false;
        if (this.userWalletInfo && Array.isArray(this.userWalletInfo)) {
            let message = "";
            this.selectedStocksObj = this.userWalletInfo.find(
                stock => stock.stock == selected
            );

            if (this.selectedStocksObj) {
            } else {
                message = "You don't have " + selected + " shares.";
            }
            if ((message! = "")) {
                Swal({
                    title: "Error",
                    text: message,
                    type: "error",
                    confirmButtonColor: "#053a6a",
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                });
            }
        }
    }

    calculateSharePrice() {
        if (this.selectedStocksObj && this.selectedStocksObj.shares) {
            this.showSummary = false;
            // this.transactionFee = 1.99;
            this.currentPrice = this.cryptoWallet.Close;

            let totalShares = parseFloat(this.selectedStocksObj.shares);
            let percentage = this.percentage;
            let transactionFee = this.processingCharges;
            let shares = (percentage / 100) * totalShares;
            console.log('shares', shares);
            let currentPrice = this.cryptoWallet.Close;
            let total = shares * parseFloat(currentPrice) + transactionFee;
            this.shares = shares;
            this.currentPrice = currentPrice;
            this.total = total;
        }
    }

    getAllStocksData() {
        this.cardService.getStockData().subscribe(response => {
            if ((<any>response).status == 200) {
                let stocksResponse = (<any>response)
                this.stockOptions = stocksResponse.stock;
                this.selectedStockFromAllAvailable = this.stockOptions[0];
                this.selectedData = this.stockOptions[0].stock_name;
                // fetch stocks Price
                this.getStockFee(this.selectedData);
                console.log(" this.stockOptions", this.stockOptions);
            }
        });
    }

    moveToAddMoneyWallet() {
        let session = JSON.stringify({
            buy: {
                amount: this.amount,
                selectedStock: this.selectedStock
            }
        });
        sessionStorage.setItem("Session", session);
        this.router.navigateByUrl("/dashboard/addmoney");
    }

    getProcessingFee() {
        this.cardService.getProcessingFeeData().subscribe(response => {
            let body = (<any>response)
            if ((<any>body).success == true) {
                this.processingFeeData = body.payment;
                //  console.log("this.processingFeeData", this.processingFeeData);
                this.processingCharges = parseFloat(
                    this.processingFeeData.transfer_fee
                );
            }
        });
    }
    // get stock price
    getStockFee(selected) {
        let body ={
            stock_name: selected
        }
        this.authServices.getNewStocksPriceApi(body).subscribe(response => {
            let body =(<any>response)
            let dateObjectArray = Object.keys(body.chart[0]);
                this.cryptoWallet = body.quote
                this.cryptoWallet.Open = this.cryptoWallet.open
                this.cryptoWallet.High = this.cryptoWallet.high
                this.cryptoWallet.Low = this.cryptoWallet.low
                this.cryptoWallet.Close = this.cryptoWallet.close
                this.cryptoWallet.Supply = this.cryptoWallet.latestVolume

                this.currentPrice = this.cryptoWallet.Close;
        })
       
    }

    placeOrder() {
        if (this.percentage == 0 || this.currentPrice <= 0) {
            alert("Plese select enough shares to sell.");
        } else {
            let token = {
                user_id: this.cardService.encrypt(this.userInfo.id.toString()),
                stock: this.selectedData,
                amount: this.cardService.encrypt(
                    (
                        ((this.selectedStocksObj.shares * this.percentage) /
                            100) *
                            this.cryptoWallet.Close +
                        this.processingCharges
                    ).toString()
                ), //this.total.toString()
                transaction_fee: this.cardService.encrypt(
                    this.processingCharges.toString()
                ),
                shares_quantity: this.cardService.encrypt(
                    (
                        (this.selectedStocksObj.shares * this.percentage) /
                        100
                    ).toString()
                ), //  this.shares.toString()
                currentPrice: this.cardService.encrypt(
                    this.currentPrice.toString()
                ) //this.currentPrice.toString()
            };

            this.authServices.sellStockShares(token).subscribe(response => {
                let data = (<any>response)
               
                let stock = this.stockOptions.find(
                    stock => stock.id == this.selectedStock
                );
                let stockName = this.stockOptions[0].stock_name;
                if (stock) {
                    stockName = stock.stock_name;
                }
                if (data.success) {
                    let total = this.processingCharges + this.amountEntered;
                    this.transactionDetails = {
                        orderId: data.transaction_id,
                        stock: stockName,
                        message: data.msg,
                        amount: this.amount,
                        total: total.toString(),
                        shares: this.shares.toString(),
                        fee: this.processingCharges.toString()
                    };
                 
                    this.transactionDone = true;
                    // reset wallet
                    window.scroll(0,0);
                    this.authServices
                        .fetchWallet({
                            user_id: this.cardService.encrypt(
                                this.userInfo.id.toString()
                            )
                        })
                        .subscribe(response => {});
                } else if (data.verified == false) {
                    Swal({
                        title: "",
                        text: data.msg,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#053a6a",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                    }).then(result => {
                        if (result.value == true) {
                            this.router.navigateByUrl("/dashboard/settings");
                        }
                    });
                } else {
                    Swal({
                        title: "",
                        text: data.msg,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#053a6a",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                    }).then(result => {});
                }
            });
        }
    }

    reset() {
        this.percentage = "";
        this.transactionDone = false;
        this.showSummary = true;
        this.amount = "";
        this.amountEntered = 0;
        this.total = 0;
        this.selectedStock = "";
        this.selectedData = this.stockOptions[0].stock_name;
        this.processingCharges = parseFloat(
            this.processingFeeData.cryptocurrency_fee
        );
        this.shares = 0;
    }

    // fetch user shares of stocks
    getUserShares() {
        let body = {
            user_id: this.cardService.encrypt(this.userInfo.id.toString())
        };
        this.authServices.getUserStockShares(body).subscribe(response => {
            let body = (<any>response)
            if ((<any>body).success == true) {
               
              this.userWalletInfo = body.stocks;
             
            }
        });
    }
}
