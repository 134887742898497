import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators,FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthguardService } from "../../../services/authguard.service";
import Swal from "sweetalert2";
import { GiftcardService } from "../../../services/giftcard.service";
import { AbstractControl } from "@angular/forms";
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-link-account',
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.scss']
})
export class LinkAccountComponent implements OnInit {
  linkaccountform: FormGroup;

  constructor(
        private router: Router,
        private authService: AuthguardService,
        private fb: FormBuilder,
        private cardService: GiftcardService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe
  ) {
    this.linkaccountform = this.fb.group({
      routingnumber: ["", Validators.compose([Validators.required,Validators.minLength(9)])],
      accountnumber: ["", Validators.compose([Validators.required,Validators.minLength(12)])],
      accountname: ["", Validators.compose([Validators.required])],
  });
   }

  ngOnInit() {

  }
  linkaccount(){
    if(this.linkaccountform.valid){
      let data = {
            accountName: this.linkaccountform.value.accountname,
            accountNumber: this.linkaccountform.value.accountnumber,
            routingNumber: this.linkaccountform.value.routingnumber,
            user_id: sessionStorage.userId
      };
      this.cardService.linkbankaccount(data).subscribe( resp => {
        debugger;
        console.log(resp);
        let dataobtained = (<any>resp)
        if(dataobtained.success == true){
          Swal({
            type: "success",
            title: "Account Linked successfully",
            text: 'Account Linked successfully',
            allowOutsideClick: false,
            customClass: "swal-custom",
            confirmButtonColor: "#053a6a"
        });
        this.router.navigateByUrl("/dashboard/link-accounts");
        }else if(dataobtained.success == false){
          Swal({
            type : "error",
            title: "${dataobtained.msg}",
            allowOutsideClick: false,
          })
        }
      });
    }
  }
  //encrypt method
  encryptmethod(inputdata, key, schema_version) {
    if(environment.requestEnv == ''){
        let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
        let encobj = {"enc_data" : encnoobj};
        return encobj;
    }
    return JSON.parse(inputdata);
}

//decrypt method
 decryptmethod(inputdata, key) {
    if(environment.requestEnv == ''){
        return this.apidec.decrypt(inputdata.enc_data, key);
    }
     return JSON.stringify(inputdata);
}
}
