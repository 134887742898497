import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { AuthguardService } from '../../../services/authguard.service';
import { EncryptionService } from '../../../services/encryption.service';
import { CryptoService } from '../../../services/crypto.service';
import { GiftcardService } from '../../../services/giftcard.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
@Component({
    selector: 'app-transfer',
    templateUrl: './transfer.component.html',
    styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {
    transferForm: FormGroup;
    transferConfirmForm: FormGroup;
    transferOptions: any = undefined;
    userInfo: any = undefined;
    transactionFee: number;
    transactionCompleted: boolean = false;
    trasnsactionSuccess: boolean = false;
    transactionID: string = '';
    walletAmount: string = '';
    totalCrypoAmount: any;
    token: string = '';
    amount: number = 0;
    isPhoneStartWithZero: boolean = false;
    phone: string = '';
    invalidPhoneNumber: string = '';
    total: number = 0;
    successResponse: any = {
        id: '',
        name: '',
        email: '',
        phone: '',
        card: '',
        date: '',
        amount: '0',
        fee: '0',
        total: '0'
    };
    min: number = 5;
    max: number = 0;
    userWallet: any = undefined;
    userFeatures: any; // get features api data
    processingFeeData: any; // get processing fee api data
    currencyName: any;
    currencyValue: any;

    constructor(
        private authService: AuthguardService,
        private encryption: EncryptionService,
        private cryptoService: CryptoService,
        private fb: FormBuilder,
        private router: Router,
        private cardService: GiftcardService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe
    ) {
        this.transferForm = this.fb.group({
            selectedCard: ['', Validators.compose([Validators.required])],
            firstname: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(16)
                ])
            ],
            lastname: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(16)
                ])
            ],
            amount: ['', Validators.compose([Validators.required])],
            email: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                    )
                ])
            ],
            phone: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                    )
                ])
            ]
        });
        this.transferConfirmForm = this.fb.group({
            selectedCard: ['', Validators.compose([Validators.required])],
            currency: [''],
            firstname: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(16)
                ])
            ],
            lastname: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(16)
                ])
            ],
            email: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                    )
                ])
            ],
            phone: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                    )
                ])
            ]
        });
        console.log(this.cardService.totalcryptovalue.value);
        this.cardService.totalcryptovalue.subscribe(data => {
            this.totalCrypoAmount = data;
        });
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
    }

    ngOnInit() {
        // call get feature APi
        this.getFeatures();
        // call processing fee Api
        this.getProcessingFee();
        // fetch user info from session
        if (sessionStorage.length > 1) {
            let userInfo = JSON.parse(sessionStorage.userInfo);
            this.userInfo = userInfo;
        }
        // fetch token
        this.authService.getToken().subscribe(token => {
            this.token = token;
        });
        // fetch user walletr info from service
        this.getUserData();
        // fetch transferabel options
        this.cryptoService.getSharesWallet().subscribe(sharesMarketValue => {
            let options = [];

            if (sharesMarketValue && this.userWallet) {
                // push gift value
                let giftvalue = this.walletAmount;
                // options.push({
                //     name: "Gift",
                //     fullname: "Gift",
                //     value: giftvalue,
                //     shares: giftvalue,
                //     bid: "1"
                // });

                this.userWallet.forEach(currency => {
                    if (currency.currency) {
                        let shares = currency.shares
                            ? parseFloat(currency.shares).toFixed(6)
                            : '0';
                        if (shares != '0') {
                            switch (currency.currency) {
                                case 'Gift':
                                    break;
                                case 'BTC':
                                    if (sharesMarketValue.BTC) {
                                        let value =
                                            parseFloat(shares) *
                                            parseFloat(
                                                sharesMarketValue.BTC.bid
                                            );
                                        options.push({
                                            name: 'BTC',
                                            fullname: 'Bitcoin',
                                            value: value,
                                            shares: shares,
                                            bid: sharesMarketValue.BTC.bid
                                        });
                                    }
                                    break;
                                case 'ETH':
                                    if (sharesMarketValue.ETH) {
                                        let value =
                                            parseFloat(shares) *
                                            parseFloat(
                                                sharesMarketValue.ETH.bid
                                            );
                                        options.push({
                                            name: 'ETH',
                                            fullname: 'Ethereum',
                                            value: value,
                                            shares: shares,
                                            bid: sharesMarketValue.ETH.bid
                                        });
                                    }
                                    break;
                                case 'XRP':
                                    if (sharesMarketValue.XRP) {
                                        let value =
                                            parseFloat(shares) *
                                            parseFloat(
                                                sharesMarketValue.XRP.bid
                                            );
                                        options.push({
                                            name: 'XRP',
                                            fullname: 'Ripple',
                                            value: value,
                                            shares: shares,
                                            bid: sharesMarketValue.XRP.bid
                                        });
                                    }
                                    break;
                                case 'XLM':
                                    if (sharesMarketValue.XLM) {
                                        let value =
                                            parseFloat(shares) *
                                            parseFloat(
                                                sharesMarketValue.XLM.bid
                                            );
                                        options.push({
                                            name: 'XLM',
                                            fullname: 'Stellar',
                                            value: value,
                                            shares: shares,
                                            bid: sharesMarketValue.XLM.bid
                                        });
                                    }
                                    break;
                                case 'BCH':
                                    if (sharesMarketValue.BCH) {
                                        let value =
                                            parseFloat(shares) *
                                            parseFloat(
                                                sharesMarketValue.BCH.bid
                                            );
                                        options.push({
                                            name: 'BCH',
                                            fullname: 'BitcoinCash',
                                            value: value,
                                            shares: shares,
                                            bid: sharesMarketValue.BCH.bid
                                        });
                                    }
                                    break;
                                case 'LTC':
                                        if (sharesMarketValue.LTC) {
                                            let value =
                                                parseFloat(shares) *
                                                parseFloat(
                                                    sharesMarketValue.LTC.bid
                                                );
                                            options.push({
                                                name: 'LTC',
                                                fullname: 'Litecoin',
                                                value: value,
                                                shares: shares,
                                                bid: sharesMarketValue.LTC.bid
                                            });
                                        }
                                        break;
                                case 'ADA':
                                    if (sharesMarketValue.ADA) {
                                        let value =
                                            parseFloat(shares) *
                                            parseFloat(
                                                sharesMarketValue.ADA.bid
                                            );
                                        options.push({
                                            name: 'ADA',
                                            fullname: 'Cardano',
                                            value: value,
                                            shares: shares,
                                            bid: sharesMarketValue.ADA.bid
                                        });
                                    }
                                    break;
                                case 'DOGE':
                                    if (sharesMarketValue.DOGE) {
                                        let value =
                                            parseFloat(shares) *
                                            parseFloat(
                                                sharesMarketValue.DOGE.bid
                                            );
                                        options.push({
                                            name: 'DOGE',
                                            fullname: 'Dogecoin',
                                            value: value,
                                            shares: shares,
                                            bid: sharesMarketValue.DOGE.bid
                                        });
                                    }
                                    break;
                                case 'DOT':
                                    if (sharesMarketValue.DOT) {
                                        let value =
                                            parseFloat(shares) *
                                            parseFloat(
                                                sharesMarketValue.DOT.bid
                                            );
                                        options.push({
                                            name: 'DOT',
                                            fullname: 'Polkadot',
                                            value: value,
                                            shares: shares,
                                            bid: sharesMarketValue.DOT.bid
                                        });
                                    }
                                    break;
                            }
                        }
                    }
                });
                this.transferOptions = options;
                if (this.transferForm.controls['selectedCard'].value != '') {
                    this.cardSelected();
                }
            }
        });
    }

    getUserData() {
        this.authService.getuserInfo().subscribe(info => {
            if (info != undefined) {
                this.walletAmount = info.giftCardsValue;
                this.userWallet = info.userCryptoCurrency;
                let options = [];
                let giftvalue = this.walletAmount;
                // options.push({
                //     name: "Gift",
                //     fullname: "Gift",
                //     value: giftvalue,
                //     shares: giftvalue,
                //     bid: "1"
                // });
                this.transferOptions = options;
            }
        });
    }

    preventSemicolon(e) {
        if (e.keyCode == 222 || e.keyCode == 186) {
            let parsedValue = e.target.value.replace(/[;'"]/g, '');
            e.target.value = parsedValue;
        }
    }

    reviewOrder($event) {
        $event.preventDefault();
        let formData = this.transferForm.value;
        let name = formData.firstname + ' ' + formData.lastname;
        let option = this.transferOptions.find(
            currency => currency.name == formData.selectedCard
        );
        if (option) {
            let currency = 'Gift';
            switch (option.name) {
                case 'Gift':
                    currency = 'Gift';
                    break;
                case 'BTC':
                    currency = 'Bitcoin';
                    break;
                case 'ETH':
                    currency = 'Ethereum';
                    break;
                case 'XRP':
                    currency = 'Ripple';
                    break;
                case 'XLM':
                    currency = 'Stellar';
                    break;
                case 'LTC':
                    currency = 'Litecoin';
                    break;
                case 'BCH':
                    currency = 'Bitcoincash';
                    break;
            }
            this.transferConfirmForm.controls['currency'].setValue(currency);
            this.transferConfirmForm.controls['selectedCard'].setValue(
                option.name
            );
        } else {
        }
        this.transferConfirmForm.controls['firstname'].setValue(
            formData.firstname
        );
        this.transferConfirmForm.controls['lastname'].setValue(
            formData.lastname
        );
        this.transferConfirmForm.controls['phone'].setValue(formData.phone);
        this.transferConfirmForm.controls['email'].setValue(formData.email);
        this.transactionCompleted = true;
        this.walletAmount = '';
        this.amount = formData.amount;
        this.total = parseFloat(formData.amount) + this.transactionFee;
        window.scroll(0,0);
    }
    back() {
        this.getUserData();
        this.transactionCompleted = false;
        window.scroll(0,0);
    }
    cancel() {
        Swal({
            type: 'warning',
            title: '',
            text: 'Are You Sure? ',
            allowOutsideClick: false,
            customClass: 'swal-custom',
            confirmButtonColor: '#053a6a',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonColor: '#053a6a',
            cancelButtonText: 'No'
        }).then(result => {
            if (result.value == true) {
                //component.addMoneyForm.reset();

                this.getUserData();
                this.transactionCompleted = false;
                this.transferForm.reset();
                this.transferConfirmForm.reset();
                window.scroll(0,0);
            }else {
                window.scroll(0,0);
            }
        });
    }

    transferAmounttoUser() {
        this.walletAmount = '';
        let formData = this.transferForm.value;
        let formConfirmData = this.transferConfirmForm.value;
        let bidprice = 1;
        var calculatesShares;
        let option = this.transferOptions.find(
            currency => currency.name == formData.selectedCard
        );
        if (option) {
            bidprice = option.bid;
            if (this.amount) {
                calculatesShares = (this.amount / bidprice).toFixed(4);
            }
        }
        let phoneNumberValidate = this.phone;
        let token = {
            user_id: this.userInfo.id,
            type: formConfirmData.selectedCard,
            first_name: formConfirmData.firstname,
            last_name: formConfirmData.lastname,
            email: formConfirmData.email,
            phone: formConfirmData.phone,
            transfer_amount: this.amount,
            transaction_fee: this.transactionFee,
            shares: calculatesShares,
            url: location.origin
        };
            //data encrypt
        let strdata = JSON.stringify(token);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.transferGiftCryptoShares(dataenc).subscribe(response => {
            //response decrypt
        let dataobtained = (<any>response)
        let decdata = this.decryptmethod(dataobtained, this.global.publicKey);
        let resp = JSON.parse(decdata);
            this.walletAmount = '';
            if (<any>resp) {
                let data = resp;
                if (data.success == false) {
                    this.invalidPhoneNumber = data.msg;
                }
                if (data.success == true) {
                    this.invalidPhoneNumber = '';
                    this.trasnsactionSuccess = true;
                    this.walletAmount = '';
                    this.successResponse = {
                        id: data.transaction_id,
                        message: data.msg,
                        name: formConfirmData.name,
                        email: formConfirmData.email,
                        phone: formConfirmData.phone,
                        card: formConfirmData.currency,
                        date: new Date(),
                        amount: formData.amount,
                        fee: this.transactionFee,
                        total: this.total
                    };
                    this.fetchWallet();
                    window.scroll(0,0);
                }
            }
        });
    }

    formaPhoneInput($event) {
        /// auto format phone number inputs
        let keycode = $event.keyCode;
        if ((keycode > 47 && keycode < 58) || keycode == 45) {
            let value = $event.target.value.replace(/[^\d\-]/g, '');
            if ($event.inputType == 'deleteContentBackward') {
            } else {
                if (value.length == 3 || value.length == 7) {
                    value += '-';
                }
            }
        } else {
            $event.preventDefault();
        }
    }

    handleCopyPaste($event) {
        let value = '';
        if ($event.inputType == 'insertFromPaste') {
            let input = $event.target.value.replace(/[^\d\-]/g, '');
            input = input.replace(/\-/g, '');
            value =
                input.slice(0, 3) +
                '-' +
                input.slice(3, 6) +
                '-' +
                input.slice(6, 10);
            if ($event.target.name == 'phone') {
            }
        }
    }

    fetchWallet() {
        let userInfo = JSON.parse(sessionStorage.userInfo);
        this.authService
            .fetchWallet({
                user_id: this.encryption.encrypt(userInfo.id.toString())
            })
            .subscribe(response => {
                // wallet info fetched
                this.walletAmount = '';
            });
    }

    resetScreen() {
        this.getUserData();
        this.trasnsactionSuccess = false;
        this.transactionCompleted = false;
        this.transferForm.reset();
        this.transferConfirmForm.reset();
        this.transferForm.controls['selectedCard'].setValue('');
        window.scroll(0,0);
        this.router.navigateByUrl('/dashboard/home');
    }

    cardSelected() {
        let formData = this.transferForm.value;

        if (formData.selectedCard != '') {
            let option = this.transferOptions.find(
                currency => currency.name == formData.selectedCard
            );
            if (option) {
                switch (option.name) {
                    case 'Gift':
                        this.min = 25;
                        if(option.value > this.transactionFee) {
                            this.max = option.value - this.transactionFee;
                        } else{
                            this.max = 0;
                        }
                        break;
                    case 'BTC':
                        this.min = 50;
                        this.max = option.value - this.transactionFee;
                        break;
                    case 'ETH':
                        this.min = 25;
                        this.max = option.value - this.transactionFee;
                        break;
                    case 'XRP':
                        this.min = 25;
                        this.max = option.value - this.transactionFee;
                    case 'XLM':
                            this.min = 25;
                            this.max = option.value - this.transactionFee;
                    case 'LTC':
                        this.min = 25;
                        this.max = option.value - this.transactionFee;
                    case 'BCH':
                        this.min = 25;
                        this.max = option.value - this.transactionFee;
                }
                // this.max = parseFloat(option.value);
                this.max = this.max;
            }
        }
    }

    allowOnlyCharactersName(control) {
        let input = this.transferForm.controls[control];
        let parsedValue = input.value.replace(
            /[!#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ''
        );
        this.transferForm.controls[control].setValue(parsedValue);
    }

    allowOnlyCharactersNameConfirm(control) {
        let input = this.transferConfirmForm.controls[control];
        let parsedValue = input.value.replace(
            /[!#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ''
        );
        this.transferConfirmForm.controls[control].setValue(parsedValue);
    }

    //get  features active or block
    
    allowDigitsInput(event) {
        let parsedValue = event.target.value.replace(/\D/g, ''); // Remove all non-digit characters
        event.target.value = parsedValue;
    }

    getFeatures() {
        this.cardService.acceptBlockFeatures().subscribe(response => {
            let body = (<any>response)

            if ((<any>body).success == true) {
                this.userFeatures = body;
                if (this.userFeatures.features.transfer_crypto != '1') {
                    this.router.navigateByUrl('dashboard/home');
                }
            }
        });
    }

    getProcessingFee() {
        this.cardService.getProcessingFeeData().subscribe(response => {
            let data = (<any>response)
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let body = JSON.parse(decdata);
            if ((<any>body).success == true) {
                this.processingFeeData = body.payment;
                this.transactionFee = parseFloat(
                    this.processingFeeData.transfer_fee
                );
                // this.min = parseFloat(this.processingFeeData.transfer_min);
                this.max = parseFloat(this.processingFeeData.transfer_max);
            }
        });
    }

    phoneStartWithZeroValidation($event) {
        let x;
        if ($event.target.value.length == 1) {
            let phoneRegex = /^[1-9][0-9]*$/g;
            if (phoneRegex.test($event.target.value)) {
                this.isPhoneStartWithZero = false;
            } else {
                this.isPhoneStartWithZero = true;
            }
        }
    }

    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {'enc_data' : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
            return JSON.stringify(inputdata);
    }
}
