import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError, tap} from 'rxjs/operators';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  showerrors: any = [];
  constructor(
    private router: Router,
  ) {
    /*
    fetch('https://static-global-s-msn-com.akamaized.net/hp-neu/sc/2b/a5ea21.ico?d=' + Date.now())
      .then(response => {
        // Check if the response is successful
        if (!response.ok)
          throw new Error('Network response was not ok');
        // At this point we can safely say the user has connection to the internet
        console.log("Internet available");
      })
      .catch(error => {
        // The resource could not be reached
        console.log("No Internet connection", error);
        Swal({
          type: 'warning',
          title: 'Oops...',
          text: 'Please check your Internet connection.',
          allowOutsideClick: false,
          customClass: 'swal-custom',
          confirmButtonColor: '#053a6a'
        });
        return false;

      });
      */

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
            }
          }, err => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                // handling error
                Swal({
                  type: 'error',
                  title: 'Your Session has been expired',
                  text: 'Please Login',
                  allowOutsideClick: false,
                  customClass: 'swal-custom',
                  confirmButtonColor: '#053a6a'
                });
                setTimeout(() => {
                  sessionStorage.clear();
                  this.router.navigate(['/home']);
                }, 2000);
              } else {
                console.log(
                  `error status : ${err.status} ${JSON.stringify(err.error)}`
                );
                switch (err.status) {
                  case 403:
                    break;
                  case 404:
                  case 400:
                  case 405:
                  case 406:
                  case 409:
                  case 500:
                    this.handleAuthError(err);
                    break;
                }
                if(err.status !== 401){
                  Swal({
                    type: 'error',
                    title: this.showerrors.status,
                    text: "Failed to load Response data",
                    allowOutsideClick: false,
                    customClass: 'swal-custom',
                    confirmButtonColor: '#053a6a'
                  });
                  // alert(this.showerrors.statusText);
                }
              }
            }
          }
        ),
      )
  }
  public handleAuthError(error) {
    console.log("error ", error);
    this.showerrors = error;
    Swal({
      type: 'error',
      title: this.showerrors.status,
      text: this.showerrors.statusText,
      allowOutsideClick: false,
      customClass: 'swal-custom',
      confirmButtonColor: '#053a6a'
    });
    //alert(this.showerrors.statusText);
    let msg = "";
    if (error !== undefined && typeof error === "string") {
      msg = error;
    } else if (error.error !== undefined && typeof error.error === "string") {
      msg = error.error;
    } else if (
      error.error.error !== undefined &&
      typeof error.error.error === "string"
    ) {
      msg = error.error.error;
    } else {
      msg = error.error.error.errors
        ? error.error.error.errors[0].errorMessage
        : "Something went wrong";
    }
  }
}
