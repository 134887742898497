import { Component, OnInit } from '@angular/core';
declare const TradingView: any;

@Component({
  selector: 'app-btc',
  templateUrl: './btc.component.html',
  styleUrls: ['./btc.component.scss']
})
export class BtcComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    // tslint:disable-next-line: no-unused-expression
    let graphData = {
            'symbol': 'COINBASE:BTCUSD',
            'timezone': 'America/Los_Angeles',
            'theme': 'Light',
            'style': '9',
            'locale': 'en',
            'toolbar_bg': '#f1f3f6',
            'enable_publishing': false,
            'withdateranges': true,
            'range': '1d',
            'hide_side_toolbar': false,
            'save_image': false,
            'container_id': 'tradingview_e41a9'
        };
    
    if(sessionStorage.getItem("countryName") === "IND" ){
      graphData['symbol'] = "BITFINEX:BTCINR";
      graphData['timezone'] = "India/Asia_Kolkata";
    }

    new TradingView.widget(graphData);
  }


}
