import { Injectable, OnInit } from '@angular/core';
import * as forge from 'node-forge';
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import * as sha1 from 'sha1';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class ApiencryptService {
  public DEFAULT_SCHEMA_VERSION = 3;
  public components = {
    "headers": {
      "version": '',
      "options": '',
      "encSalt": '',
      "hmacSalt": '',
      "iv": ''
    },
    "ciphertext": ''
  }
  public config: any = {
    hmac: {},
    pbkdf2: {}
  };

  public key = '';
  constructor(
    private global: GlobalPipe, 
    private http: HttpClient) { }

  getSha1Key(){
    var ENCRYPT_SALT = 'LDUH!Zpq^yFnrhkPMy4Yevybx%WB*3428009wLgERlH';
    // this.http.get('http://localhost:3000/auth', {responseType: 'text'})
    //   .subscribe(res => this.key = res);
      this.key = sha1(ENCRYPT_SALT);
      return this.key;
  }

  getSha384Key(){
    var ENCRYPTED_KEY_TWO = '';
    var md = forge.md.sha384.create();
    md.update(this.global.ENC_KEY_TWO);
    ENCRYPTED_KEY_TWO = md.digest().toHex();
    return ENCRYPTED_KEY_TWO;
  }

  encrypt(plaintext, password, DEFAULT_SCHEMA_VERSION) {
    this.configure(DEFAULT_SCHEMA_VERSION);
    this.components = this.makeComponents(DEFAULT_SCHEMA_VERSION);
    this.components.headers.encSalt = this.makeSalt();
    this.components.headers.hmacSalt = this.makeSalt();
    this.components.headers.iv = this.makeIv(this.config.ivLength);
    var encKey = this.makeKey(this.components.headers.encSalt, password);
    var hmacKey = this.makeKey(this.components.headers.hmacSalt, password);


    return this.encryptFromComponents(plaintext, this.components, encKey, hmacKey);
  }

  makeKey(salt, password) {
    return this.hash_pbkdf2(this.global.algo, password, salt, this.global.iterations, this.global.length, true);
  }

  hash_pbkdf2(algo, password, salt, iterations, length, raw_output) {
    return forge.pkcs5.pbkdf2(password, salt, iterations, length, algo)
  }


  makeComponents(version) {
    this.components.headers.version = this.indexToChar(version);
    this.components.headers.options = this.indexToChar(this.config.options);
    return this.components;
  }

  encryptFromComponents(plaintext, components, encKey, hmacKey) {
    var iv = components.headers.iv;
    // encrypt
    var data64 = forge.util.encode64(plaintext);
    var cipher = forge.cipher.createCipher('AES-CBC', encKey);
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(plaintext));
    cipher.finish();

    var encrypted = cipher.output.getBytes();
    this.components.ciphertext = encrypted;
    var base64_encode = '';
    base64_encode += this.components.headers.version;
    base64_encode += this.components.headers.options;
    base64_encode += this.isSet(this.components.headers.encSalt);
    base64_encode += this.isSet(this.components.headers.hmacSalt);
    base64_encode += this.components.headers.iv;
    base64_encode += this.components.ciphertext;
    base64_encode += this.makeHmac(this.components, hmacKey);
    var encrypted64 = forge.util.encode64(base64_encode);
    encrypted64 = encrypted64.replace(/\s+/, "");
    return encrypted64;
  }

  makeSalt() {
    return this.makeIv(this.global.saltLength);
  }

  makeIv(blockSize) {
    return forge.random.getBytesSync(blockSize);
  }

  makeHmac(components, hmacKey) {
    var hmacMessage = '';
    if (this.config.hmac.includesHeader) {
      hmacMessage += this.components.headers.version;
      hmacMessage += this.components.headers.options;
      hmacMessage += this.isSet(this.components.headers.encSalt);
      hmacMessage += this.isSet(this.components.headers.hmacSalt);
      hmacMessage += this.components.headers.iv;
    }

    hmacMessage += this.components.ciphertext;
    var hmac = forge.hmac.create();
    hmac.start(this.config.hmac.algorithm, hmacKey);
    hmac.update(hmacMessage);
    hmac = hmac.digest().toHex();
    if (this.config.hmac.includesPadding) {
      hmac = hmac.padStart(this.config.hmac.length, this.ToChar(0));
    }
    return this.hex2bin(hmac);
  }

  hex2bin(hex) {
    var bytes = [], str;
    for (var i = 0; i < hex.length - 1; i += 2)
      bytes.push(parseInt(hex.substr(i, 2), 16));
    return String.fromCharCode.apply(String, bytes);
  }

  indexToChar(i) {
    return String.fromCharCode(i); //97 in ASCII is 'a', so i=0 returns 'a', 
  }

  ToChar(i) {
    return String.fromCharCode(i + 97); //97 in ASCII is 'a', so i=0 returns 'a', 
  }

  isSet(variable) {
    if (typeof (variable) != "undefined" && variable !== null) {
      return variable;
    }
    return '';
  }

  configure(version) {
    // $config = new stdClass;

    this.config.algorithm = 'aes-256-';
    this.config.saltLength = 8;
    this.config.ivLength = 16;
    //this.config.pbkdf2 = {};


    this.config.pbkdf2.prf = 'sha1';
    this.config.pbkdf2.iterations = 10000;
    this.config.pbkdf2.keyLength = 32;

    //this.config.hmac = {};
    this.config.hmac.length = 32;

    if (!version) {
      this.configureVersionZero(this.config);
    } else if (version <= 3) {
      this.config.mode = 'cbc';
      this.config.options = 1;
      this.config.hmac.algorithm = 'sha256';
      this.config.hmac.includesPadding = false;

      switch (version) {
        case 1:
          this.config.hmac.includesHeader = false;
          this.config.truncatesMultibytePasswords = true;
          break;

        case 2:
          this.config.hmac.includesHeader = true;
          this.config.truncatesMultibytePasswords = true;
          break;

        case 3:
          this.config.hmac.includesHeader = true;
          this.config.truncatesMultibytePasswords = false;
          break;
      }
    } else {
      // throw new \RuntimeException('Unsupported schema version ' . $version);
      return 'Unsupported schema version ' + version;
    }
    return this.config;
  }

  configureVersionZero(config) {
    this.config.mode = 'ctr';
    this.config.options = 0;
    this.config.hmac.includesHeader = false;
    this.config.hmac.algorithm = 'sha1';
    this.config.hmac.includesPadding = true;
    this.config.truncatesMultibytePasswords = true;
  }

}
