import { Component, OnInit } from '@angular/core';
declare const TradingView: any;
@Component({
  selector: 'app-ltc',
  templateUrl: './ltc.component.html',
  styleUrls: ['./ltc.component.css']
})
export class LtcComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    // tslint:disable-next-line: no-unused-expression
    let graphData =  {
        "autosize": true,
        'symbol': 'COINBASE:LTCUSD',
        'timezone': 'America/Los_Angeles',
        'theme': 'Light',
        'style': '9',
        'locale': 'en',
        'toolbar_bg': '#f1f3f6',
        'enable_publishing': false,
        'withdateranges': true,
        'range': '1d',
        'hide_side_toolbar': false,
        'save_image': false,
        'container_id': 'tradingview_4e532'
      }
      
      if(sessionStorage.getItem("countryName") === "IND" ){
        graphData['symbol'] = "BITFINEX:LTCINR";
        graphData['timezone'] = "India/Asia_Kolkata";
      }
  
      new TradingView.widget(graphData);
  }


}
