import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthguardService } from '../../../services/authguard.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { GiftcardService } from '../../../services/giftcard.service';
import { Router } from '@angular/router';
import Swal from "sweetalert2";
@Component({
  selector: 'app-stockhistory',
  templateUrl: './stockhistory.component.html',
  styleUrls: ['./stockhistory.component.scss']
})
export class StockhistoryComponent implements OnInit {
  message: string = "";
  historytmp: any = [];
  history: any;
  userId: string = '';
  token: string = '';
  filters: any = {
    createdAt: "",
    currency: "",
    type: "",
    transactionMessage: "",
    noOfCards: "",
    amount: "",
  };
  currencyName: any;
  currencyValue: any;
  @ViewChild("cardHistory") cardHistory;

  constructor(
    private authService: AuthguardService,
    private giftCardService: GiftcardService,
    private router: Router
  ) { 
    this.currencyName = sessionStorage.getItem("currencyName");
    this.currencyValue = sessionStorage.getItem("currencyValue");
  }

  ngOnInit() {
    this.authService.getToken().subscribe(token => {
      this.token = token;
    });
    // this.fetchHistory();
  }
  fetchHistory(){
    this.userId = sessionStorage.userId;
    let body = {
      user_id: this.giftCardService.encrypt(this.userId)
    };
    this.authService.stocksSellBuyHistory(body).subscribe(response => {
      if ((<any>response).status == 200) {
        let responseData = (<any>response)
        let cryArr = responseData.stocksResponse.concat(responseData.stocksSellPurch);
        this.historytmp = cryArr;
        this.history = cryArr;
      }
    });
  }
  filterHistory() {
    let tmpHistoryData = this.history;
    let filteredHistoryData = [];
    // filter from created at
    if (this.filters.createdAt != '') {
      let searchRegex = new RegExp(this.filters.createdAt, "i");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.createdAt));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from currency
    if (this.filters.currency != '') {
      let searchRegex = new RegExp(this.filters.currency, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.currency));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from event type
    if (this.filters.type != '') {
      let searchRegex = new RegExp(this.filters.type, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.type));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from transactionMessage
    if (this.filters.transactionMessage != '') {
      let searchRegex = new RegExp(this.filters.transactionMessage, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.transactionMessage));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from noOfCards
    if (this.filters.noOfCards != '') {
      let searchRegex = new RegExp(this.filters.noOfCards, "g");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.noOfCards.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from amount
    if (this.filters.amount != '') {
      let searchRegex = new RegExp(this.filters.amount, "g");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.amount.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    this.cardHistory.inputData = filteredHistoryData;
  }

  cardHistoryActionResend(cardId) {
    let body = {
      id: cardId,
      user_id: this.giftCardService.encrypt(this.userId)
    }
    this.authService.sendResendCard(body).subscribe(response => {
      Swal({
        title: 'Mail has been sent',
        type: 'success',
        showCancelButton: false,
        confirmButtonText: 'ok',
        allowOutsideClick: false,
      })
    })
  }

  cardHistoryAction(action, cardId) {
    let actionClass = "";
    let user_id = sessionStorage.getItem('userId');
    let token = {
      action: action,
      id: cardId,
      user_id: this.giftCardService.encrypt(user_id)
    };
    switch (action) {
      case "Resend":
        actionClass = "resend";
        break;
      case "Sent":
        actionClass = "send";
        break;
      case "Recieve":
        actionClass = "recieve";
        break;
      case "Revoke":
        actionClass = "revoke";
        Swal({
          title: 'Are you sure you want to revoke this card',
          text: 'You will not be able to recover this order!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Revoke',
          cancelButtonText: 'No, Cancel it',
          allowOutsideClick: false,
        }).then((result) => {
          this.userId = sessionStorage.userId;
          let body = {
            user_id: this.giftCardService.encrypt(this.userId)
          };
          if (result.value) {
            this.authService.revokeGiftCard(token).subscribe(response => {
              Swal({
                type: 'success',
                title: 'You have successfully revoked this card',
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 2000
              })
              if ((<any>response).status == 200) {
                let responseData = (<any>response)
                this.message = responseData.msg;
                //this.fetchWalletDetails();
                this.fetchHistory();
              }
            },
              err => {
                Swal({
                  type: 'error',
                  title: 'Failed to revoked the gift card:)',
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  timer: 2000
                })
              }
            );
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal({
              type: 'error',
              title: 'Your card is safe :)',
              showConfirmButton: false,
              allowOutsideClick: false,
              timer: 2000
            })
          }
        });
        break;
      case "Accepted":
        actionClass = "accepted";
        break;
    }
    //this.showOrderInfo = true;
    return actionClass;
  }
  
}
