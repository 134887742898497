import { Component, OnInit } from '@angular/core';
declare const TradingView: any;

@Component({
  selector: 'app-doge',
  templateUrl: './doge.component.html',
  styleUrls: ['./doge.component.css']
})
export class DogeComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    // tslint:disable-next-line: no-unused-expression
    let graphData = {
            'symbol': 'COINBASE:DOGEUSD',
            'timezone': 'America/Los_Angeles',
            'theme': 'Light',
            'style': '9',
            'locale': 'en',
            'toolbar_bg': '#f1f3f6',
            'enable_publishing': false,
            'withdateranges': true,
            'range': '1d',
            'hide_side_toolbar': false,
            'save_image': false,
            'container_id': 'tradingview_e41a6'
        };
    
    if(sessionStorage.getItem("countryName") === "IND" ){
      graphData['symbol'] = "BITFINEX:DOGEINR";
      graphData['timezone'] = "India/Asia_Kolkata";
    }

    new TradingView.widget(graphData);
  }

}
