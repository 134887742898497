import { Component, OnInit } from "@angular/core";
import { GiftcardService } from "../services/giftcard.service";
@Component({
    selector: "app-disclosures",
    templateUrl: "./disclosures.component.html",
    styleUrls: ["./disclosures.component.scss"]
})
export class DisclosuresComponent implements OnInit {
    disclosureData: any;
    constructor(private giftService: GiftcardService) {}

    ngOnInit() {
        this.getDisclosureDataDetails();
    }

    getDisclosureDataDetails() {
        this.giftService.getDisclosuresPageData().subscribe(response => {
            let body = (<any>response)

            if ((<any>body).success == true) {
                this.disclosureData = body;
            }
        });
    }
}
