import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { tap } from 'rxjs/operators';
import {environment} from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class StatesService {
  SERVER_HOST: string = "";
  constructor(
    private httpClient: HttpClient,
  ) {  
    this.SERVER_HOST = environment.serverHost;
  }

  getstattes(id) {
    return this.httpClient.get(`${this.SERVER_HOST}state-list?country_id=${id}`).pipe(
        tap(
            response => {
                return response;
            },
            error => {
                console.log('Error fetching states');
            }
        )
    );
 }  
 getcities(body) {
   return this.httpClient.get(`${this.SERVER_HOST}city-list?state_id=${body.state_id} &country_id=${body.country_id}`).pipe(
     tap(response =>
      {
        return response;
      },
      error => {
        console.log('Error fetching cities');
      }
      )
   )
 }
 getcountry(){
  return this.httpClient.get(`${this.SERVER_HOST}country-list`).pipe(
    tap(
        response => {
            return response;
        },
        error => {
            console.log('Error fetching country');
        }
    )
);
 }
}
