import { Component, OnInit, OnDestroy, AfterContentInit } from "@angular/core";
import { GiftcardService } from "../services/giftcard.service";
import { Router } from "@angular/router";
import { AuthguardService } from "../services/authguard.service";
import { CryptoService } from "../services/crypto.service";
import { Observable, pipe, Subject, of, BehaviorSubject } from "rxjs";
import Swal from 'sweetalert2';

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit ,AfterContentInit{
    wallet: string = "";
    token: string = "";
    jwtToken: string = "";
    refreshInterval: any = undefined;
    hideSidebar = new BehaviorSubject<boolean>(true);
    constructor(
        private cardService: GiftcardService,
        private router: Router,
        private authService: AuthguardService,
        private cryptoService: CryptoService
    ) {}

    ngOnInit() {
        if (sessionStorage.length > 0) {
            let userInfo = JSON.parse(sessionStorage.userInfo);
            // this.isloggedIn = sessionStorage.isLoggedIn;
            let token = {
                user_id: this.cardService.encrypt(userInfo.id.toString())
            };
            AuthguardService.user.next(userInfo);

            this.authService
                .fetchWallet({
                    user_id: this.cardService.encrypt(userInfo.id.toString())
                })
                .subscribe(response => {
                    // wallet info fetched
                });

            // refresh token
            // this.refreshInterval = setInterval(_ => {
            //   this.authService.refreshToken().subscribe(response => {
            //     this.authService.setToken(response);
            //   });
            // }, 10000); // refresh token

            if (sessionStorage.token) {
                let token = "";
                if (sessionStorage.token.indexOf('"') == 0)
                    token = JSON.parse(sessionStorage.token);
                else token = sessionStorage.token;
                this.authService.setToken(token);
            }

            this.authService.getToken().subscribe(token => {
                this.token = token;
                let userData = sessionStorage.getItem("userInfo");
                let user = JSON.parse(userData);
                if (this.token == "") {
                    this.router.navigate(["/login", { email: user.email }]);
                    this.authService.loggedOutSet(null);
                }
            });

            /*********************************************
             * get Crypto latest data from sockets
             ********************************************/



        } else {
            this.router.navigateByUrl("/login");
            this.authService.loggedOutSet(null);
        }
      
    }
    ngAfterContentInit(){
        
        this.cryptoService.getSharesWallet().subscribe(data => {
        });
    }
    ngOnDestroy() {
        // clearInterval(this.refreshInterval);
    }

    handleGlobalClicks() {
        this.hideSidebar.next(true);
    }

    onResize(event) {
        // console.log("event", event.target.width);
        this.hideSidebar.next(true);
    }

    onActivate(event){
        window.scroll(0,0);
    }
}
