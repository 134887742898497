import { Component, OnInit } from "@angular/core";
import { GiftcardService } from "../services/giftcard.service";
@Component({
    selector: "app-press",
    templateUrl: "./press.component.html",
    styleUrls: ["./press.component.scss"]
})
export class PressComponent implements OnInit {
    pressData: any;
    constructor(private giftService: GiftcardService) {}

    ngOnInit() {
        this.getPressDataDetails();
    }

    getPressDataDetails() {
        this.giftService.getPressPageData().subscribe(response => {
            let body = (<any>response);

            if ((<any>body).success == true) {
                this.pressData = body;
                console.log(this.pressData);
            }
        });
    }
}
