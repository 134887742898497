import { Component, OnInit } from "@angular/core";
import { GiftcardService } from "../services/giftcard.service";
@Component({
    selector: "app-blog",
    templateUrl: "./blog.component.html",
    styleUrls: ["./blog.component.scss"]
})
export class BlogComponent implements OnInit {
    blogData: any;
    constructor(private giftService: GiftcardService) {}

    ngOnInit() {
        this.getBlogDataDetails();
    }

    getBlogDataDetails() {
        this.giftService.getBlogPageData().subscribe(response => {
            let body = (<any>response);
            if ((<any>response).success == true) {
                this.blogData = body;
                console.log(this.blogData);
            }
        });
    }
}
