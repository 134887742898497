import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import Swal from "sweetalert2";
import { AuthguardService } from "../../../services/authguard.service";
import { CryptoService } from "../../../services/crypto.service";
import { GiftcardService } from "../../../services/giftcard.service";
import { DataService } from "../../../services/data.service";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import * as moment from "moment";

import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from "@angular/forms";
import { Router, ActivatedRoute, Params } from "@angular/router";
import * as paypal from "paypal-checkout";
import { environment } from "../../../../environments/environment";

@Component({
    selector: "app-create-certificate",
    templateUrl: "./create-certificate.component.html",
    styleUrls: ["./create-certificate.component.scss"]
})
export class CreateCertificateComponent implements OnInit {
    @ViewChild("nameFocus") nameFocus: ElementRef;
    @ViewChild("purposeFocus") purposeFocus: ElementRef;
    @ViewChild("disclaimerFocus") disclaimerFocus: ElementRef;
    @ViewChild("amountFocus") amountFocus: ElementRef;

    @ViewChild("prevName")
    prevName: any;

    minDate: any;
    maxDate: any;
    expiryDates: any[] = [];
    // monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    monthNames: string[] = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];
    allTemplates: any = [];
    // [{
    //   id: 1,
    //   background: "assets/certificates/bitcoin.jpg",
    //   label: 'Bitcoin',
    //   color: "white"
    // },
    // {
    //   id: 2,
    //   background: "assets/certificates/ethereum.jpg",
    //   label: 'Ethereum',
    //   color: "white"
    // },
    // {
    //   id: 3,
    //   background: "assets/certificates/ripple.jpg",
    //   label: 'Ripple',
    //   color: "white"
    // },
    // {
    //   id: 4,
    //   background: "assets/certificates/gift-card-1.png",
    //   label: 'Gift Card 1',
    //   color: "white"
    // },
    // {
    //   id: 5,
    //   background: "assets/certificates/gift-card-2.png",
    //   label: 'Gift Card 2',
    //   color: "black"
    // },
    // {
    //   id: 6,
    //   background: "assets/certificates/gift-card-3.png",
    //   label: 'Gift Card 3',
    //   color: "black"
    // }
    //];
    // feature accept block
    cryptoData: any;
    socketSubscription: any;
    expiryDate: any;
    userFeatures: any;
    isFeatureMsg: boolean = false;
    transaction: any;
    creditValue: any;
    paypalValue: any;
    isCredit: boolean = false;
    isPaypal: boolean = false;
    id: any;
    cryptoShares: number = 0;
    addedCards: any[] = [];
    cryptoSelectedTemplates: any[] = [];
    bitcoinTemplates: any[] = [];
    etheriumTemplates: any[] = [];
    rippleTemplates: any[] = [];
    cryptoTemplates: any[] = [];
    selectedTemplate: number = 1;
    selectedTemplateIndex: number = 0;
    selectedTemplateName: any;
    selectedCard = undefined;
    maxCardQuantity = "";
    amountRange = "";
    selectedCryptoCertificateIndex: number = 0;
    imageFromRespone: any;
    newCertificate: any = {
        name: "",
        purpose: "",
        disclaimer: "",
        date: "",
        // dateToSend: "",
        // timeToSend: "",
        certificateId: "",
        redeemCode: "xxx-xxx-xxx",
        // logo: {
        //     name: "",
        //     image: ""
        // },
        signature: {
            name: "",
            image: ""
        },
        quantity: "1",
        amount: "",
        email: "",
        currency: "BTC",
        template_id: "1",
        totalWithFee: ""
    };
    certificateFee: any;
    processingFeeData: any;
    paymentPage: boolean = false;
    reviewFormDisable: boolean = true;
    showGiftColorTemplates: boolean = false;
    imageChangedEvent: any = null;
    showImageCropper: boolean = false;
    imageBeingCropped: string = "";
    currentVisibleCard: number = 0; // Added by ravi
    invalidForm: boolean = false;
    printInProgress: boolean = false;
    errors: any = null;
    allowCardEdit: boolean = false;
    allowCardPrint: boolean = false;
    allowCardPreview: boolean = false;
    certificateSubmitted: boolean = false;

    // payment functionality

    walletAmount: number = 0;
    addMoneyForm: FormGroup;
    responseData: any = {
        amount: "",
        transaction_id: ""
    };
    reviewCertificate: boolean = false;
    transactionDone: boolean = false;
    paypalSelected: boolean = false;
    token: string = "";
    paypalEnv: any;
    paypalKeys: any;
    cryptoWallet: any;
    cryptoWalletInfo: any;
    currencyName: any;
    currencyValue: any;
    constructor(
        private authguardService: AuthguardService,
        private giftcardService: GiftcardService,
        private dataService: DataService,
        private cryptoService: CryptoService,
        // payment functionality

        private router: Router,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute // private authService: AuthguardService, // private cardService: GiftcardService,
    ) {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() + 30);
        // console.log("newDate", newDate);
        this.minDate = {
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            day: newDate.getFullYear()
        };
        this.maxDate = {
            year: newDate.getFullYear() + 10,
            month: 12,
            day: 31
        };
        this.newCertificate.date = {
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            day: newDate.getDate()
        };
        //console.log(" this.newCertificate.date", this.newCertificate.date);
        this.newCertificate.dateToSend = {
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            day: newDate.getDate()
        };
        this.newCertificate.certificateId = Date.parse(
            newDate.toLocaleString()
        ).toString();
        this.newCertificate.timeToSend = { hour: 12, minute: 0, second: 0 };
        
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
    }
    checkDate(data) {
        //    console.log("date", data);
    }

    ngOnInit() {
        /// get bitfinix data start

        // fetch crypto shares wallet
        this.socketSubscription = this.cryptoService
            .getSharesWallet()
            .subscribe(data => {
                if (data != undefined) {
                    this.cryptoData = data;
                    //   console.log("bitfinixdata certificate", data);
                    // this.cryptoData.btc = data.BTC;
                    // this.cryptoData.eth = data.ETH;
                    // this.cryptoData.xrp = data.XRP;
                }
            });

        /// bitfinix data end

        // fetch  get processing fee
        this.getProcessingFee();
        //  initialize date array for gift cards expiry
        for (let i = 1; i <= 3; i++) {
            let d = moment(new Date());
            // let newDate = d.add(i * 30, 'days').toDate();
            let newDate = d.add(i * 30, "days").format("MM/DD/YYYY");
            this.expiryDates.push({
                date: newDate,
                days: i * 30 + " days",
                saveDate: newDate
            });
        }
        let infinite = moment(new Date());
        let infiniteDate = infinite.add(100, "years").format("MM/DD/YYYY");
        this.expiryDates.push({
            date: "Forever",
            days: "Never Expire",
            saveDate: infiniteDate
        });

        this.newCertificate.date = this.expiryDates[2].date;

        this.getFeatures();

        this.id = setInterval(() => {
            this.getFeatures();
        }, 180000);

        this.allTemplates = this.giftcardService.getCryptoCertificates();
        // this.giftTemplates = this.giftcardService.getGiftCardTemplates();
        this.bitcoinTemplates = this.giftcardService.getCryptoCertificatesBitcoin();
        this.etheriumTemplates = this.giftcardService.getCryptoCertificatesEtherium();
        this.rippleTemplates = this.giftcardService.getCryptoCertificatesRipple();
        this.cryptoTemplates = this.giftcardService.getCryptoCardTemplates();

        this.cryptoSelectedTemplates = this.bitcoinTemplates;

        // payment functionality

        this.addMoneyForm = this.fb.group({
            paymentType: ["credit"],
            // amount: ["", Validators.compose([Validators.required])],
            cardName: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3)
                ])
            ],
            cardNumber: ["", Validators.compose([Validators.required])],
            expiry: ["", Validators.compose([Validators.required])],
            cvv: ["", Validators.compose([Validators.required])]
        });

        // if (this.transactionDone == false) {
        //   this.initPaypalCheckout();
        // }

        if (this.certificateSubmitted) {
            this.initPaypalCheckout(0);
        }
        this.authguardService.getToken().subscribe(token => {
            this.token = token;
        });
        this.authguardService.getuserInfo().subscribe(info => {
            if (info != undefined) {
                this.walletAmount = info.giftCardsValue;
                this.cryptoWalletInfo = info.userCryptoCurrency;
            }
        });

        this.cryptoService.getSharesWallet().subscribe(data => {
            if (data != undefined) {
                this.cryptoWallet = data;
                this.calculateSharePrice();
            }
        });
    }
    calculateSharePrice() {
        if (
            this.cryptoWallet &&
            this.cryptoWalletInfo &&
            Array.isArray(this.cryptoWalletInfo) && sessionStorage.length > 0
        ) {
            this.cryptoShares = 0;
            let btc = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "BTC"
            );
            if (btc && this.cryptoWallet.BTC && btc.shares != "0") {
                let totalBTC =
                    parseFloat(btc.shares) *
                    parseFloat(this.cryptoWallet.BTC.bid);
                if (!isNaN(totalBTC)) {
                    this.cryptoShares += totalBTC;
                }
            }
            let xrp = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "XRP"
            );
            if (xrp && this.cryptoWallet.XRP && xrp.shares != "0") {
                let totalXRP =
                    parseFloat(xrp.shares) *
                    parseFloat(this.cryptoWallet.XRP.bid);
                if (!isNaN(totalXRP)) {
                    this.cryptoShares += totalXRP;
                }
            }
            let eth = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "ETH"
            );
            if (eth && this.cryptoWallet.ETH && eth.shares != "0") {
                let totalETH =
                    parseFloat(eth.shares) *
                    parseFloat(this.cryptoWallet.ETH.bid);
                if (!isNaN(totalETH)) {
                    this.cryptoShares += totalETH;
                }
                this.cryptoShares = parseFloat(this.cryptoShares.toFixed(2));
            }
        } else {
            this.cryptoShares = 0;
        }

    }
    filterSpecialCharacterInSenderEmail() { }

    slideLeft() { }

    editCard() {
        setTimeout(() => {
            this.nameFocus.nativeElement.focus();
        }, 100);

        // edit crypto card
        this.allowCardEdit = !this.allowCardEdit;
    }
    previewCardToggle(event) {
        this.allowCardPreview = true;
        event.stopPropagation();
    }

    selectTemplate(id, label) {
        //this.newCertificate.currency = label;
        // this.newCertificate.template_id = id;
        this.selectedTemplate = id;
        // console.log(id);
        switch (id) {
            case 1:
                this.cryptoSelectedTemplates = this.bitcoinTemplates;
                this.newCertificate.currency = "BTC";
                break;
            case 2:
                this.cryptoSelectedTemplates = this.etheriumTemplates;
                this.newCertificate.currency = "ETH";
                break;
            case 3:
                this.cryptoSelectedTemplates = this.rippleTemplates;
                this.newCertificate.currency = "XRP";
                break;
        }
        this.selectedTemplateIndex = this.allTemplates.findIndex(
            template => template.id == id
        );
        this.selectedCryptoCertificateIndex = this.selectedCryptoCertificateIndex;
        this.newCertificate.template_id = 1;
    }
    changeTemplate() {
        this.selectedTemplateIndex = this.allTemplates.findIndex(
            template => template.id == this.selectedTemplate
        );

        switch (this.selectedTemplateIndex) {
            case 0:
                this.cryptoSelectedTemplates = this.bitcoinTemplates;
                this.newCertificate.currency = "BTC";
                break;
            case 1:
                this.cryptoSelectedTemplates = this.etheriumTemplates;
                this.newCertificate.currency = "ETH";
                break;
            case 2:
                this.cryptoSelectedTemplates = this.rippleTemplates;
                this.newCertificate.currency = "XRP";
                break;
        }
        this.selectedCryptoCertificateIndex = this.selectedCryptoCertificateIndex;
        this.newCertificate.template_id = 1;
    }
    leftRightCertificateMove(certificateMove) {
        if (certificateMove == "left") {
            if (this.selectedCryptoCertificateIndex > 0) {
                this.selectedCryptoCertificateIndex--;
            } else {
                this.selectedCryptoCertificateIndex;
            }

            if (this.newCertificate.template_id > 1) {
                this.newCertificate.template_id--;
                //    console.log(
                //         "left if this.newCertificate.template_id",
                //         this.newCertificate.template_id
                //     );
            } else {
                this.newCertificate.template_id;
                // console.log(
                //     "left else this.newCertificate.template_id",
                //     this.newCertificate.template_id
                // );
            }
        } else if (certificateMove == "right") {
            if (
                this.selectedCryptoCertificateIndex <
                this.cryptoSelectedTemplates.length - 1
            ) {
                this.selectedCryptoCertificateIndex++;
            } else {
                this.selectedCryptoCertificateIndex;
            }

            if (
                this.newCertificate.template_id <
                this.cryptoSelectedTemplates.length
            ) {
                this.newCertificate.template_id++;
                // console.log(
                //     "right if this.newCertificate.template_id",
                //     this.newCertificate.template_id
                // );
            } else {
                this.newCertificate.template_id;
                // console.log(
                //     "right else this.newCertificate.template_id",
                //     this.newCertificate.template_id
                // );
            }
        }
    }

    updateTemplate(selectedArrayIndex, template, type) {
        this.selectedCryptoCertificateIndex = selectedArrayIndex;
        this.newCertificate.template_id = template.id;
        //console.log(template);
        // console.log(
        //     "this.newCertificate.template_id",
        //     this.newCertificate.template_id
        // );
        // update card template
        // let selectedCard, allCards = [];
        // if (this.selectedCard && this.selectedCard.producttype == type) {
        //   selectedCard = this.addedCards.find(card => card.id == this.selectedCard.id)
        //   selectedCard.template = template;
        //   if (type == 'crypto') {
        //     selectedCard.currency = template.currency;
        //   }
        // }
    }
    openCropper(event: any, imageType: string) {
        let files = event.target.files;
        if (
            files[0] &&
            (files[0].name.indexOf(".jpg") > -1 ||
                files[0].name.indexOf(".jpeg") > -1 ||
                files[0].name.indexOf(".png") > -1 ||
                files[0].name.indexOf(".gif") > -1)
        ) {
            this.showImageCropper = true;
            this.imageChangedEvent = event;
            if (event.target.files.length > -1) {
                if (imageType == "logo") {
                    this.newCertificate.logo.name = event.target.files[0].name;
                    this.imageBeingCropped = "logo";
                } else if (imageType == "sign") {
                    this.newCertificate.signature.name =
                        event.target.files[0].name;
                    this.imageBeingCropped = "sign";
                }
            }
        } else {
            Swal({
                title: "Please upload only image (JPEG, PNG).",
                type: "warning",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: "OK"
            });
        }
    }
    cropImage(image: string) {
        if (this.imageBeingCropped == "logo") {
            this.newCertificate.logo.image = image;
        } else if (this.imageBeingCropped == "sign") {
            this.newCertificate.signature.image = image;
        }
    }
    openCropperInAlreadyAddedImage(imageType: string): void {
        let self = this;
        if (imageType == "logo") {
            setTimeout(_ => {
                if (
                    !self.showImageCropper &&
                    self.newCertificate.logo.name != "" &&
                    self.newCertificate.logo.image != ""
                ) {
                    self.showImageCropper = true;
                    self.imageBeingCropped = "logo";
                }
            }, 600);
        } else if (imageType == "sign") {
            setTimeout(_ => {
                if (
                    !self.showImageCropper &&
                    self.newCertificate.signature.name != "" &&
                    self.newCertificate.signature.image != ""
                ) {
                    self.showImageCropper = true;
                    self.imageBeingCropped = "sign";
                }
            }, 600);
        }
    }
    preventText(event) {
        // console.log(event.keyCode);
        if (
            (event.keyCode >= 65 && event.keyCode <= 90) ||
            event.keyCode == 32 ||
            event.keyCode == 9 ||
            event.keyCode == 190 ||
            event.keyCode == 13 ||
            (event.keyCode == 8 ||
                event.keyCode == 46 ||
                (event.keyCode >= 37 && event.keyCode <= 40))
        ) {
        } else {
            event.preventDefault();
        }
    }
    preventTextInput(event) {
        let parsedValue = event.target.value.replace(
            /[`~#$^@&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ""
        );
        event.target.value = parsedValue;
    }

    preventTextForNumberonly($event) {
        // prevent alphabets in preview amount text field
        let value = $event.target.value.replace(
            /[a-zA-Z%@*^$!)(_+=.#&\s]/g,
            ""
        );
        $event.target.value = value;
        // if ($event.target.className.indexOf("priceinput") > -1) {
        //     if ($event.target.className.indexOf("giftAmount") > -1) {
        //         this.giftAmount = value;
        //         this.updateCardAmount(value, "gift");
        //     }
        //     if ($event.target.className.indexOf("cryptoAmount") > -1) {
        //         this.cryptoAmount = value;
        //         this.updateCardAmount(value, "crypto");
        //     }
        // }
    }

    allowAlphabetsEmailOnlyInput(event) {
        let parsedValue = event.target.value.replace(
            /[`~#$^&%*\(\)+=\-\[\]\/\{\}|:<>?,\s]/g,
            ""
        );
        event.target.value = parsedValue;
    }
    allowAlphabetsEmailOnlyKeyup(event) {
        // console.log(event.keyCode);
        if (
            (event.keyCode >= 65 && event.keyCode <= 90) ||
            event.keyCode == 50 ||
            event.keyCode == 9 ||
            event.keyCode == 190 ||
            event.keyCode == 13 ||
            event.keyCode == 8 ||
            event.keyCode == 46 ||
            (event.keyCode >= 37 && event.keyCode <= 40) ||
            (event.keyCode >= 48 && event.keyCode <= 57)
        ) {
        } else {
            event.preventDefault();
        }
    }
    x = ["2", "3"];

    print() {
        this.responseData.certificate.forEach(element => {
            //   console.log(element);
            this.newCertificate.redeemCode = element.redeem_code;
            this.newCertificate.certificateId = element.redeem_code;
            this.imageFromRespone = element.template_image;
            setTimeout(_ => {
                try {
                    html2canvas(document.querySelector("#contentToConvert"), {
                        logging: false,
                        useCORS: true,
                        letterRendering: true,
                        allowTaint: false
                    }).then(canvas => {
                        var imgData = canvas.toDataURL("image/jpeg");

                        var tWindow = window.open("");
                        $(tWindow.document.body)
                            .html(
                                "<div style='width:600px;margin:auto'>" +
                                "<img id='Image' src=" +
                                imgData +
                                " style='width:100%;'></img> </div>"
                            )
                            .ready(function () {
                                tWindow.focus();
                                tWindow.print();
                            });

                        // let doc = new jsPDF({
                        //     orientation: "landscape",
                        //     unit: "in",
                        //     format: [3.5, 2]
                        // });
                        // console.log("imgData", imgData); // var canvas = doc.canvas;
                        // canvas.height = 1050;
                        // canvas.width = 600;
                        // doc.addImage(imgData, "JPEG", 0, 0, 3.5, 2); // change parameters to change size of print pdf
                        // doc.save("certificate.pdf");
                        this.printInProgress = false;
                    });
                } catch (e) {
                    this.printInProgress = false;
                    alert("Unable to print. Please try again");
                }
            }, 1000);
        });
        this.allowCardPreview = !this.allowCardPreview;
    }
    // printCertificate() {
    //     this.printInProgress = true;
    //     this.allowCardPreview = !this.allowCardPreview;

    //     document.getElementById("printId").click();
    // }
    submitCertificate() {
        // if (this.newCertificate.quantity > 500) {
        //     this.maxCardQuantity =
        //         "Please enter quantity below 500 or equal to 500";
        //     setTimeout(() => {
        //         this.maxCardQuantity = "";
        //     }, 5000);
        //     return;
        // }

        // this.certificateSubmitted = true;
        // if (this.certificateSubmitted) {
        //     this.initPaypalCheckout(parseFloat(this.newCertificate.amount));
        // }
        // console.log("submit", this.newCertificate.amount);
        // console.log(
        //     "this.newCertificate.currency",
        //     this.newCertificate.currency
        // );
        // console.log(
        //     "this.newCertificate.template_id",
        //     this.newCertificate.template_id
        // );

        switch (this.newCertificate.currency) {
            case "BTC":
                this.selectedTemplateName = this.bitcoinTemplates.find(
                    template => template.id == this.newCertificate.template_id
                );
                break;
            case "ETH":
                this.selectedTemplateName = this.etheriumTemplates.find(
                    template => template.id == this.newCertificate.template_id
                );
                break;
            case "XRP":
                this.selectedTemplateName = this.rippleTemplates.find(
                    template => template.id == this.newCertificate.template_id
                );
                break;
        }
        console.log("dxg" + this.newCertificate.template_id)
        let selectedTemplateName = this.selectedTemplateName.background.split(
            "/"
        );
        this.selectedTemplateName =
            selectedTemplateName[selectedTemplateName.length - 1];
        //   console.log("this.selectedTemplateName", this.selectedTemplateName);

        let emailReg = new RegExp(
            "^[a-zA-Z0-9.!#$%&*+/=?^_{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
        );

        this.transactionDone = false;
        this.invalidForm = false;
        this.errors = {};
        if (
            (this.newCertificate.name &&
                this.newCertificate.name.trim() == "") ||
            this.newCertificate.name.trim().length < 3
        ) {
            this.errors.name = "Please Enter a valid name.";
            this.invalidForm = true;
        } else {
            this.errors.name = null;
        }
        if (
            (this.newCertificate.email &&
                this.newCertificate.email.trim() == "") ||
            this.newCertificate.email.trim().length < 3 ||
            !emailReg.test(this.newCertificate.email)
        ) {
            this.errors.email = "Please Enter a valid email.";
            this.invalidForm = true;
        } else {
            this.errors.name = null;
        }
        if (
            (this.newCertificate.purpose &&
                this.newCertificate.purpose.trim() == "") ||
            this.newCertificate.purpose.trim().length < 3
        ) {
            this.errors.purpose = "Please enter purpose of certificate.";
            this.invalidForm = true;
        } else {
            this.errors.purpose = null;
        }
        // if (
        //     (this.newCertificate.disclaimer &&
        //         this.newCertificate.disclaimer.trim() == "") ||
        //     this.newCertificate.disclaimer.trim().length < 3
        // ) {
        //     this.errors.disclaimer = "Please enter disclaimer text.";
        //     this.invalidForm = true;
        // } else {
        //     this.errors.disclaimer = null;
        // }
        // if (
        //     (this.newCertificate.quantity &&
        //         this.newCertificate.quantity.trim() == "") ||
        //     this.newCertificate.quantity.trim().length == 0
        // ) {
        //     this.errors.quantity = "Please enter quantity.";
        //     this.invalidForm = true;
        // } else {
        //     this.errors.quantity = null;
        // }
        if (
            (this.newCertificate.amount &&
                this.newCertificate.amount.trim() == "") ||
            this.newCertificate.amount.trim().length == 0 ||
            this.newCertificate.amount < 5 ||
            this.newCertificate.amount >
            parseFloat(this.processingFeeData.crypto_certificate_max)
        ) {
            this.errors.amount = "Please enter amount.";
            this.invalidForm = true;
        } else {
            this.errors.amount = null;
        }

        if (
            this.newCertificate.amount >
            parseFloat(this.processingFeeData.crypto_certificate_max)
        ) {
            this.errors.amount =
                "Please enter amount less than" +
                parseFloat(this.processingFeeData.crypto_certificate_max);
            this.invalidForm = true;
        }
        // if (this.newCertificate.logo.image == "") {
        //     this.errors.logo = "Please upload your logo.";
        //     this.invalidForm = true;
        // } else {
        //     this.errors.logo = null;
        // }
        // if (this.newCertificate.signature.image == "") {
        //     this.errors.signature = "Please upload your signature.";
        //     this.invalidForm = true;
        // } else {
        //     this.errors.signature = null;
        // }
        // let newDate = this.newCertificate.date;
        // let dateSelected = new Date(
        //     this.newCertificate.date.month.toString() +
        //         "/" +
        //         this.newCertificate.date.day.toString() +
        //         "/" +
        //         this.newCertificate.date.year.toString()
        // );
        // let sendDateSelected = new Date(
        //     this.newCertificate.dateToSend.month.toString() +
        //         "/" +
        //         this.newCertificate.dateToSend.day.toString() +
        //         "/" +
        //         this.newCertificate.dateToSend.year.toString()
        // );

        // if (dateSelected < newDate) {
        //     this.errors.date = "Please select a valid date.";
        //     this.invalidForm = true;
        // }
        // if (sendDateSelected < newDate) {
        //     this.errors.dateToSend = "Please select a valid date.";
        //     this.invalidForm = true;
        // }
        // if (sendDateSelected < dateSelected) {
        //     this.errors.dateToSendLess = "Send Date cannot be less than date.";
        //     this.invalidForm = true;
        // }
        if (this.invalidForm == false) {
            //   console.log("Submit form");
            // this.newCertificate.amount = parseFloat( this.newCertificate.amount) * parseFloat( this.newCertificate.amount)
            //   console.log(this.newCertificate);
            this.certificateSubmitted = true;
            this.newCertificate.totalWithFee =
                parseFloat(this.newCertificate.amount) *
                parseFloat(this.newCertificate.quantity) +
                this.certificateFee * parseFloat(this.newCertificate.quantity);
            // console.log("totalWithFee", this.newCertificate.totalWithFee);

            this.reviewCertificate = true;
            this.transactionDone = false;
            this.paymentPage = false;
            window.scrollTo(0, 0);
        } else {
            //   console.log("resolve errors");
        }
    }

    reviewCertificateForm() {
        if (this.certificateSubmitted) {
            this.initPaypalCheckout(
                parseFloat(this.newCertificate.totalWithFee)
            );
        }
        this.paymentPage = true;
        this.reviewCertificate = false;
        this.transactionDone = false;
        window.scrollTo(0, 0);
    }

    //  payment functionality

    preventTextCardNumber($event) {
        let value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, "");
        $event.target.value = value;
    }
    preventTextCardExpiry($event) {
        let value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, "");
        if ($event.type == "keyup" && $event.code == "Backspace") {
        } else {
            if (value.length == 2) {
                value += "/";
            }
        }
        $event.target.value = value;
    }
    preventTextCvv($event) {
        let value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, "");
        $event.target.value = value;
    }

    initPaypalCheckout(amountdata) {
        let component = this;
        setTimeout(_ => {
            paypal.Button.render(
                {
                    env: environment.paypalEnv,
                    client: environment.paypalKeys,
                    commit: true,
                    style: {
                        color: "gold",
                        size: "small"
                    },
                    payment: function (data, actions) {
                        component.paypalSelected = true;
                        component.addMoneyForm.controls["paymentType"].setValue(
                            "paypal"
                        );
                        // disable payent through paypal if no transaction is needed
                        let amount = parseFloat(amountdata);
                        //  console.log(amount);
                        // if (amount == 0 || isNaN(amount)) {
                        //     Swal({
                        //         type: "warning",
                        //         title: "Oops...",
                        //         text: "Please enter amount to be added.",
                        //         customClass: "swal-custom",
                        //         confirmButtonColor: "#053a6a"
                        //     });
                        //     return false;
                        // }

                        return actions.payment.create({
                            payment: {
                                transactions: [
                                    {
                                        amount: {
                                            total: amount,
                                            currency: "USD"
                                        }
                                    }
                                ]
                            }
                        });
                    },
                    onAuthorize: function (data, actions) {
                        // Make a call to the REST api to execute the payment
                        return actions.payment
                            .execute()
                            .then(function (payment) {
                                component.addMoneyForm.controls[
                                    "paymentType"
                                ].setValue("paypal");
                                component.addmoneyViaPaypal(payment);
                            });
                    },
                    onCancel: function (data, actions) { },
                    onError: function (err) { }
                },
                "#paypal-button"
            );
        }, 100);
    }

    addmoneyViaCard() {
        // let dateSelected = new Date(
        //     this.newCertificate.date.month.toString() +
        //         "/" +
        //         this.newCertificate.date.day.toString() +
        //         "/" +
        //         this.newCertificate.date.year.toString()
        // );

        //   console.log("dateSelected", dateSelected);

        let user_id = sessionStorage.getItem("userId");
        let current_market_value = parseFloat(
            this.cryptoData[this.newCertificate.currency].ask
        );
        let crypto_amount =
            (parseFloat(this.newCertificate.amount) *
                parseFloat(this.newCertificate.quantity)) /
            current_market_value;

        // console.log(
        //     "crypto_amount",
        //     crypto_amount,
        //     "current_market_value",
        //     current_market_value
        // );
        let body = {
            // user_id: this.giftcardService.encrypt(user_id),
            user_id: user_id,

            method: "Stripe",
            crypto_amount: crypto_amount,
            current_market_value: current_market_value,
            name: this.addMoneyForm.value.cardName,
            // amount: this.addMoneyForm.value.amount,
            card_number: this.addMoneyForm.value.cardNumber,
            exp_month: this.addMoneyForm.value.expiry.slice(0, 2).toString(),
            exp_year: this.addMoneyForm.value.expiry.slice(2).toString(),
            cvc: this.addMoneyForm.value.cvv,
            description: "Credit card purchase",
            currency: this.newCertificate.currency,
            template_id: this.newCertificate.template_id,
            template_image: this.selectedTemplateName,
            amount: this.newCertificate.amount,
            certificateId: "1540813095000",

            certificate_disclaimer: this.newCertificate.disclaimer,
            email: this.newCertificate.email,
            name_on_certificate: this.newCertificate.name,
            first_name: this.newCertificate.name.split(" ")[0],
            last_name: this.newCertificate.name.split(" ")[1],
            gift_type: "Certificate",
            redeem_limit: this.newCertificate.quantity,
            certificate_purpose: this.newCertificate.purpose,
            certificate_signature: this.newCertificate.signature.image,
            expiry_days: this.newCertificate.date,
            totalamount:
                parseFloat(this.newCertificate.amount) *
                parseFloat(this.newCertificate.quantity),
            processingFee:
                this.certificateFee * parseFloat(this.newCertificate.quantity),
            redeemurl: location.href.replace(
                "dashboard/createcertificates",
                "redeem"
            )

            // redeemCode: "xxx-xxx-xxx",
            // signature: {
            //     image: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD",
            //     name: "Anonymous-Whatsapp-profile-picture.jpg"
            // }
        };

        this.transaction = body;
        this.transaction.total =
            parseFloat(this.newCertificate.amount) *
            parseFloat(this.newCertificate.quantity);
        this.transaction.totalWithFee = this.newCertificate.totalWithFee;
        //  console.log("card", this.transaction);

        this.authguardService.buyCertificate(body).subscribe(response => {
            if ((<any>response).status == 200) {
                let resData = (<any>response)
                if (resData.success && resData.success != "0") {
                    this.responseData = resData;
                    this.transaction.createdAt = this.responseData.transaction.created_at.date;
                    this.transaction.transactionId =
                        this.responseData.transaction.transaction_id
                    console.log('transactionId', this.transaction.transactionId);
                    this.transaction.transactionId = this.transaction.transactionId.replace(
                        /"/g,
                        ""
                    );
                    this.reviewCertificate = false;
                    this.paymentPage = false;
                    this.transactionDone = true;
                    this.fetchWallet();
                    this.imageFromRespone = this.responseData.certificate[0].template_image;
                    console.log('transaction', this.transaction);
                } else {
                    Swal({
                        type: "error",
                        title: "Sorry...",
                        text: resData.msg,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                }
            }
        });
        //call add money api
    }
    addmoneyViaPaypal(data) {
        console.log("xg" + this.newCertificate.template_id)
        // let dateSelected = new Date(
        //     this.newCertificate.date.month.toString() +
        //         "/" +
        //         this.newCertificate.date.day.toString() +
        //         "/" +
        //         this.newCertificate.date.year.toString()
        // );
        // this.transactionDone = true;
        //call add money api
        let user_id = sessionStorage.getItem("userId");
        let current_market_value = parseFloat(
            this.cryptoData[this.newCertificate.currency].ask
        );
        let crypto_amount =
            (parseFloat(this.newCertificate.amount) *
                parseFloat(this.newCertificate.quantity)) /
            current_market_value;

        // console.log(
        //     "crypto_amount",
        //     crypto_amount,
        //     "current_market_value",
        //     current_market_value
        // );
        let body = {
            // user_id: this.giftcardService.encrypt(user_id),
            user_id: user_id,
            // transaction_amount: this.addMoneyForm.controls["amount"].value,
            transaction_id: data.id,
            details: data.id,
            crypto_amount: crypto_amount,
            current_market_value: current_market_value,
            name: "",
            exp_month: "",
            exp_year: "",
            card_name: "",
            cvc: "",
            method: "paypal",
            transaction_status: "approved",

            description: "Paypal purchase",
            currency: this.newCertificate.currency,
            template_id: this.newCertificate.template_id,
            template_image: this.selectedTemplateName,
            amount: this.newCertificate.amount,
            certificate_disclaimer: this.newCertificate.disclaimer,
            email: this.newCertificate.email,
            name_on_certificate: this.newCertificate.name,
            first_name: this.newCertificate.name.split(" ")[0],
            last_name: this.newCertificate.name.split(" ")[1],
            gift_type: "Certificate",
            redeem_limit: this.newCertificate.quantity,
            certificate_purpose: this.newCertificate.purpose,
            certificate_signature: this.newCertificate.signature.image,
            expiry_days: this.newCertificate.date,
            totalamount:
                parseFloat(this.newCertificate.amount) *
                parseFloat(this.newCertificate.quantity),
            processingFee:
                this.certificateFee * parseFloat(this.newCertificate.quantity)
        };
        this.transaction = body;
        this.transaction.total =
            parseFloat(this.newCertificate.amount) *
            parseFloat(this.newCertificate.quantity);
        this.transaction.totalWithFee = this.newCertificate.totalWithFee;

        //    console.log("paypal", this.transaction);
        this.authguardService.buyCertificate(body).subscribe(response => {
            let resData = (<any>response)
            if ((<any>response).status == 200) {
                this.responseData = resData;
                //   this.responseData.transaction_id = data.id;
                this.transaction.transactionId =
                    this.responseData.transaction.transaction_id
                this.transaction.transactionId = this.transaction.transactionId.replace(
                    /"/g,
                    ""
                );
                this.transaction.createdAt = this.responseData.transaction.created_at.date;
                this.reviewCertificate = false;
                this.paymentPage = false;
                this.transactionDone = true;
                this.fetchWallet();
            } else {
                Swal({
                    type: "error",
                    title: "Sorry....",
                    text: resData.msg,
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#053a6a"
                });
                return false;
            }
        });
    }

    cancel() {
        // let component = this;
        this.transactionDone = false;
        this.certificateSubmitted = false;
        // Swal({
        //     type: "warning",
        //     title: "",
        //     text: "Are You Sure ?",
        //     customClass: "swal-custom",
        //     confirmButtonColor: "#053a6a",
        //     confirmButtonText: "Yes",
        //     showCancelButton: true,
        //     cancelButtonColor: "#053a6a",
        //     cancelButtonText: "No"
        // }).then(result => {
        //     if (result.value == true) {
        //         //component.addMoneyForm.reset();
        //         this.transactionDone = false;
        //         this.certificateSubmitted = false;
        //         // this.newCertificate.name = "";
        //         // this.newCertificate.purpose = "";
        //         // this.newCertificate.disclaimer = "";
        //         // this.newCertificate.amount = "";
        //         // this.newCertificate.signature.name = "";
        //         // this.newCertificate.signature.image = "";
        //         // this.newCertificate.certificateId = "";
        //         // this.newCertificate.email = "";
        //         // this.newCertificate.quantity = "";
        //         // let newDate = new Date();
        //         // newDate.setDate(newDate.getDate() + 30);
        //         // this.newCertificate.date = {
        //         //     year: newDate.getFullYear(),
        //         //     month: newDate.getMonth() + 1,
        //         //     day: newDate.getDate()
        //         // };
        //         // this.cryptoTemplates = this.giftcardService.getCryptoCardTemplates();
        //         // this.allTemplates = this.giftcardService.getCryptoCertificates();
        //     }
        // });
    }
    backToPreview() {
        //  let component = this;
        this.transactionDone = false;
        this.certificateSubmitted = true;
        this.reviewCertificate = true;
        this.paymentPage = false;
        // Swal({
        //     type: "warning",
        //     title: "",
        //     text: "Are You Sure ?",
        //     customClass: "swal-custom",
        //     confirmButtonColor: "#053a6a",
        //     confirmButtonText: "Yes",
        //     showCancelButton: true,
        //     cancelButtonColor: "#053a6a",
        //     cancelButtonText: "No"
        // }).then(result => {
        //     if (result.value == true) {
        //         //component.addMoneyForm.reset();
        //         this.transactionDone = false;
        //         this.certificateSubmitted = true;
        //         this.reviewCertificate = true;
        //         this.paymentPage = false;
        //     }
        // });
        window.scroll(0, 0);
    }

    reset() {
        Swal({
            type: "warning",
            title: "",
            text: "Are You Sure? ",
            allowOutsideClick: false,
            customClass: "swal-custom",
            confirmButtonColor: "#053a6a",
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonColor: "#053a6a",
            cancelButtonText: "No"
        }).then(result => {
            if (result.value == true) {
                //component.addMoneyForm.reset();
                this.selectedTemplate = 1;
                this.cryptoSelectedTemplates = this.bitcoinTemplates;
                this.selectedCryptoCertificateIndex = 0;

                this.newCertificate.name = "";
                this.newCertificate.purpose = "";
                this.newCertificate.disclaimer = "";
                this.newCertificate.amount = "";
                this.newCertificate.signature.name = "";
                this.newCertificate.signature.image = "";
                this.newCertificate.certificateId = "";
                this.newCertificate.email = "";
                this.newCertificate.quantity = "1";
                let newDate = new Date();

                this.newCertificate.date = this.expiryDates[2].date;
                this.cryptoTemplates = this.giftcardService.getCryptoCardTemplates();
                this.allTemplates = this.giftcardService.getCryptoCertificates();
                this.transaction = {};
                this.addMoneyForm.reset();
                this.transactionDone = false;
                this.certificateSubmitted = false;
                // this.router.navigate(["/dashboard/createcertificates", {}]);
                this.bitcoinTemplates = this.giftcardService.getCryptoCertificatesBitcoin();
                this.etheriumTemplates = this.giftcardService.getCryptoCertificatesEtherium();
                this.rippleTemplates = this.giftcardService.getCryptoCertificatesRipple();
                this.cryptoTemplates = this.giftcardService.getCryptoCardTemplates();

                this.cryptoSelectedTemplates = this.bitcoinTemplates;
                this.newCertificate.certificateId = Date.parse(
                    newDate.toLocaleString()
                ).toString();
                this.newCertificate.redeemCode = "xxx-xxx-xxx";
            }
        });
        window.scroll(0, 0);
        // window.location.reload();
    }

    resetContinue() {
        //component.addMoneyForm.reset();
        this.selectedTemplate = 1;
        this.cryptoSelectedTemplates = this.bitcoinTemplates;
        this.selectedCryptoCertificateIndex = 0;
        this.newCertificate.name = "";
        this.newCertificate.purpose = "";
        this.newCertificate.disclaimer = "";
        this.newCertificate.amount = "";
        this.newCertificate.signature.name = "";
        this.newCertificate.signature.image = "";
        this.newCertificate.certificateId = "";
        this.newCertificate.email = "";
        this.newCertificate.quantity = "1";
        let newDate = new Date();

        this.newCertificate.date = this.expiryDates[2].date;
        this.cryptoTemplates = this.giftcardService.getCryptoCardTemplates();
        this.allTemplates = this.giftcardService.getCryptoCertificates();
        this.transaction = {};
        this.addMoneyForm.reset();
        this.transactionDone = false;
        this.certificateSubmitted = false;
        // this.router.navigate(["/dashboard/createcertificates", {}]);
        this.bitcoinTemplates = this.giftcardService.getCryptoCertificatesBitcoin();
        this.etheriumTemplates = this.giftcardService.getCryptoCertificatesEtherium();
        this.rippleTemplates = this.giftcardService.getCryptoCertificatesRipple();
        this.cryptoTemplates = this.giftcardService.getCryptoCardTemplates();

        this.cryptoSelectedTemplates = this.bitcoinTemplates;
        this.newCertificate.certificateId = Date.parse(
            newDate.toLocaleString()
        ).toString();
        this.newCertificate.redeemCode = "xxx-xxx-xxx";

        // window.location.reload();
    }

    fetchWallet() {
        let userInfo = JSON.parse(sessionStorage.userInfo);
        this.authguardService
            .fetchWallet({
                user_id: this.giftcardService.encrypt(userInfo.id.toString())
            })
            .subscribe(response => {
                // wallet info fetched
            });
    }

    allowOnlyCharacters() {
        let parsedValue = this.addMoneyForm.controls["cardName"].value.replace(
            /[`~#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ""
        );
        this.addMoneyForm.controls["cardName"].setValue(parsedValue);
    }

    validateAmount() {
        if (parseInt(this.addMoneyForm.controls["amount"].value) > 100000) {
            Swal({
                type: "warning",
                title: "",
                text: "You cannot enter more than $100,000?",
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            }).then(_ => {
                this.addMoneyForm.controls["amount"].setValue("100000");
            });
        }
    }

    // validation function for alphabets only
    allowAlphabetsOnlyInput(event) {
        let parsedValue = event.target.value.replace(
            /[`~#$^@&%*\(\)+=!\-\[\]\/\{\}|:<>?."",0-9]/g,
            ""
        );
        event.target.value = parsedValue;
    }
    omit_special_char(event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return (
            (k > 64 && k < 91) ||
            (k > 96 && k < 123) ||
            k == 8 ||
            k == 32 ||
            (k >= 48 && k <= 57)
        );
    }

    // function for feature msg
    featureMsg() {
        this.creditValue = this.userFeatures.features.credit_card;
        this.paypalValue = this.userFeatures.features.paypal;

        if (
            this.userFeatures.features.credit_card != "1" &&
            this.isCredit == false
        ) {
            Swal({
                type: "warning",
                title: "Credit Card",
                text: this.userFeatures.features.credit_card_message,
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            }).then(result => {
                if (result.value == true) {
                    this.isCredit = true;
                    this.featureMsg();
                }
            });
        }
        if (
            this.userFeatures.features.paypal != "1" &&
            this.isPaypal == false
        ) {
            Swal({
                type: "warning",
                title: "Paypal",
                text: this.userFeatures.features.paypal_message,
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            }).then(result => {
                if (result.value == true) {
                    this.isPaypal = true;
                    this.featureMsg();
                }
            });
        }
        this.isFeatureMsg = true;
    }

    //get  features active or block

    getFeatures() {
        this.giftcardService.acceptBlockFeatures().subscribe(response => {
            let body = (<any>response)

            if ((<any>body).success == true) {
                
                // update user feature
                sessionStorage.setItem("features", JSON.stringify(body));

                this.userFeatures = body;
                //   console.log("feature_addMoney", this.userFeatures);
                if (this.isFeatureMsg == false) {
                    this.featureMsg();
                }

                if (
                    this.creditValue !=
                    this.userFeatures.features.credit_card ||
                    this.paypalValue != this.userFeatures.features.paypal
                ) {
                    this.isFeatureMsg = false;

                    this.isPaypal = false;
                    this.isCredit = false;
                    this.featureMsg();
                }
            }
        });
    }

    /// select expiry date
    updateCardExpiryDate() {
        //   console.log("expiryDate", this.expiryDate);
    }

    // get procesing fee

    getProcessingFee() {
        this.giftcardService.getProcessingFeeData().subscribe(response => {
            let body = (<any>response)
            if ((<any>body).success == true) {
                this.processingFeeData = body.payment;
                //   console.log(" this.processingFeeData", this.processingFeeData);

                this.certificateFee = parseFloat(
                    this.processingFeeData.cryptocurrency_fee
                );

                // this.processingFeePerCard = parseFloat(
                //     this.processingFeeData.giftCard_fee
                // );
                // this.shippingCharges = parseFloat(
                //     this.processingFeeData.shipping_fee
                // );
            }
        });
    }

    //select amount function
    updateCardAmount(amount) {
        this.newCertificate.amount = amount;
    }

    close() {
        this.allowCardPreview = false;
    }
}
