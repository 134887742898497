import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
    historytype; //abhay@keyutech.com: 03/09/22 - removed the initial value 'all' and written code into ngOnInit to fix the issue of radio button selection
    
    constructor(private router: Router) { }

    ngOnInit() {

        //Now check the route url and updat the history type value into the radio button
        this.historyTypeValue();
        
    }

    //Method to check and updat the history type
    historyTypeValue(){
        //getting the route url        
        let arr, type, url = this.router.url;

        //Then spliting with the / to get the last index
        if(url) {
            arr = url.split('/');
            if(arr[3]) type = arr[3];
        }
        
        //Then we are checking and updating the radio button and loading the history details
        if(type == 'gifthistory') this.historytype = 'gift';
        else if(type == 'cryptohistory') this.historytype = 'crypto';
        else this.historytype = 'all';
    }
    

    historytypechanged() {
        
        if (this.historytype == 'all')
        {
            let value = '/dashboard/history/alltransactionhistory';
            this.router.navigate([value]);
        }
        //abhay@keyutech.com: 03/25/22: commented the gift option as not needed now
        /*else if(this.historytype == 'gift'){
            let value = '/dashboard/history/gifthistory';
            this.router.navigate([value]);
        }*/
        else if(this.historytype == 'crypto'){
            let value = '/dashboard/history/cryptohistory';
            this.router.navigate([value]);
        }
        else{
            let value = '/dashboard/history/certificatehistory';
            this.router.navigate([value]);
        }

    }


}