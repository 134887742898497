import { CommonApiService } from './../services/common-api.service';
/****************************************
 * User Sign In component
 ****************************************/
import { Component, OnInit } from "@angular/core";
import { AuthguardService } from "../services/authguard.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from "@angular/forms";
import { GiftcardService } from "../services/giftcard.service";
import { environment } from "../../environments/environment";
import { ApiencryptService } from '../services/apiencrypt.service';
import { ApidecryptService } from '../services/apidecrypt.service';
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
@Component({
    selector: "app-signin",
    templateUrl: "./signin.component.html",
    styleUrls: ["./signin.component.scss"]
})
export class SigninComponent implements OnInit {
    // sing in form declare
    public signinForm: FormGroup;
    // boolean flags set
    displayVersion: string = "";
    invalidInput: boolean = false;
    otpRecieved: boolean = false;
    invalidOTP: boolean = false;
    signinToken: any = undefined;

    invalidInputError: string = "";
    invalidOTPMessage: string = "";
    fromPage: string = '';
    makeEmailAddressDisabled: boolean = false;
    constructor(
        private router: Router,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private authService: AuthguardService,
        private cardService: GiftcardService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe,
        private _common: CommonApiService
    ) {
        this.displayVersion = environment.version;
        this.activatedRoute.queryParams.subscribe(params => {
            this.fromPage = params['fromPage'];
        });
    }

    ngOnInit() {
        // initialize login form
        this.signinForm = this.fb.group({
            input: ["", Validators.compose([Validators.required])],
            otp: ["", Validators.compose([])]
        });
        // sign in token
        this.signinToken = {
            email: "",
            phone: "",
            otp: "",
            password: ""
        };
        if (sessionStorage.token) {
            this.router.navigateByUrl("/dashboard");
        }

        this.activatedRoute.params.subscribe(params => {
            if (params.email) {
                this.signinForm.controls["input"].setValue(params.email);
            } else if (params.phone) {
                this.signinForm.controls["input"].setValue(params.phone);
            } else {
            }
        });
    }

    sendOtpSubmit() {
        // validate input as email or phone
        let valid = true;
        let otpToken = {
            email: "",
            phone: ""
        };
        let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        let input = this.signinForm.value.input;
        if (emailReg.test(input)) {
            // email entered
            otpToken = {
                ...otpToken,
                email: input
            };
            this.signinToken = {
                ...this.signinToken,
                email: input
            };
        } else if (phoneReg.test(input)) {
            otpToken = {
                ...otpToken,
                phone: input
            };
            this.signinToken = {
                ...this.signinToken,
                phone: input
            };
        } else {
            this.invalidInput = true;
            this.invalidInputError = "Please enter a email id or phone number";
            valid = false;
        }

        if (valid == true) {
            let strotptoken = JSON.stringify(otpToken);
            //otp token encrypting here
            let otpTokenenc = this.encryptmethod(strotptoken, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.signInOTP(otpTokenenc).subscribe(data => {
                // let data = JSON.parse((<any>response)._body);
                let decdata = this.decryptmethod(data, this.global.publicKey);
                let resp = JSON.parse(decdata);
                if (resp.success == true) {
                    this.signinToken = {
                        ...this.signinToken,
                        otp: "",
                        password: ""
                    };
                    this.otpRecieved = true;
                    this.signinForm.get('input').disable();
                    (<HTMLInputElement>(
                        document.querySelector('[name="otp"]')
                    )).focus();
                } else if (resp.success == false) {
                    this.invalidInput = true;
                    this.invalidInputError = resp.msg;
                }
            });
        }
    }

    navigateToSignUp() {
        // /register
        this.activatedRoute.params.subscribe(params => {
            if (params.code) {
                this.router.navigate(["/register", { code: params.code }]);
            } else {
                this.router.navigate(["/register"]);
            }
        });
    }

    validateInput() {
        // validate user input to check if valid email or password is entered
        let input = this.signinForm.value.input.trim();
        let emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if (emailReg.test(input) || phoneReg.test(input)) {
            this.invalidInput = false;
        } else {
            this.invalidInput = true;
            this.invalidInputError =
                "Please enter your registered phone number/email";
        }
    }

    loginFormSubmit() {
        // submint login form to verify user
        let otp = this.signinForm.value.otp;
        if (otp.length == 6) {
            this.signinToken.otp = this.cardService.encrypt(otp);
            this.signinToken.password = this.cardService.encrypt(otp);
            // let dec_signinToken = async callback => {
            //     await waits(1000);
            //     callback(this.signinToken);
            //   };

            let strdec_signinToken = { ...this.signinToken };
            let dec_signinToken = JSON.stringify(strdec_signinToken);
            let otpTokenenc = this.encryptmethod(dec_signinToken, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.signin(otpTokenenc).subscribe(data => {
                // let obtaineddata = JSON.parse((<any>data)._body);
                let decdata = this.decryptmethod(data, this.global.publicKey);
                var logindata = JSON.parse(decdata);
                if (logindata.success == false || logindata.success == "0") {
                    this.invalidOTPMessage = logindata.msg;
                } else {
                    let component = this;

                    sessionStorage.setItem(
                        "userInfo",
                        JSON.stringify(logindata.user)
                    );
                    sessionStorage.setItem("token", logindata.access_token);
                    sessionStorage.setItem(
                        "tokenType",
                        JSON.stringify(logindata.token_type)
                    );
                    sessionStorage.setItem("isLoggedIn", "true");

                    sessionStorage.setItem("userId", logindata.user.id);

                    // Store user feature
                    sessionStorage.setItem("features", JSON.stringify(logindata.features));
                    // show free offer
                    sessionStorage.setItem("showOffer", "true");
                    localStorage.setItem("offers",JSON.stringify(logindata.offers));
                    localStorage.setItem("showpendingstatus","true");
                    sessionStorage.setItem("currencyName",logindata.currency_name);
                    //abhay@keyutech.com:03/06/22- Added a key to identify this is a new registerd user
                    localStorage.setItem("isNewUser", 'N');      
                    component.authService.loggedInSet();
                    component.authService.setuserInfo(logindata);
                    let userId = sessionStorage.userId;
                    let token1 = {
                        user_id: this.cardService.encrypt(userId)
                    };
                    this.authService.userProfileDetails(token1).subscribe(res => {
                        let response = <any>res;
                        if (response.success) {
                            let user = response.user;
                            this.cardService.profilestatus.next(response.user.status);
                        }
                    });
                    component.activatedRoute.params.subscribe(params => {
                        this._common.checkCookieAndCallCampaignApi('Login-Success');
                        if (params.code) {
                            // redirect if user
                            component.router.navigate([
                                "/redeem",
                                { code: params.code }
                            ]);
                        } else if (this.fromPage === 'store-cards-wallet') {
                            component.router.navigateByUrl('/dashboard/store-cards-wallet');
                        } else {
                            component.router.navigateByUrl("/dashboard");
                        }
                    });
                    if (logindata.offers.length == 1) {
                        this.cardService.offerData.next(logindata.offers[0]);
                    } else if (logindata.offers.length == 0) {
                        this.cardService.offerData.next(undefined);
                    }
                    // Refresh token
                    let sessionInt = setInterval(_ => {
                        if (sessionStorage.length > 1) {
                            this.authService.refreshToken().subscribe(response => {
                                this.authService.setToken(response);
                            });
                        }
                        else {
                            clearInterval(sessionInt);
                        }
                    }, 700000);

                }
            });
        } else {
            // invalid otp entered show error message
            this.invalidOTP = true;
            this.invalidOTPMessage = "Invalid OTP";
        }

    }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if (environment.requestEnv == '') {
            let encnoobj = this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = { "enc_data": encnoobj };
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
    decryptmethod(inputdata, key) {
        if (environment.requestEnv == '') {
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
        return JSON.stringify(inputdata);
    }
}
