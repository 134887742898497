import { Injectable } from '@angular/core';
import CryptoJS from "./aes.js"
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  constructor(
  ) { }

  encrypt(data) {
    let formatter = this.formatter();
    let cipher = CryptoJS.AES.encrypt(data, environment.passkey, { format: formatter }).toString();
    return cipher;
  }
  decrypt(data) {
    let formatter = this.formatter();
    let decipher = CryptoJS.AES.decrypt(data, environment.passkey, { format: formatter }).toString(CryptoJS.enc.Utf8);
    return decipher;
  }

  formatter() {
    return {
      stringify: function (cipherParams) {
        var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64), iv: '', s: '' };
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j).replace(/\s/g, '');
      },
      parse: function (jsonStr) {
        var j = JSON.parse(jsonStr);
        var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
        return cipherParams;
      }
    }
  }

  encoderDecoder(string) {
    var enc = "";
		var str = "";
    var key = '256';
		// make sure that input is string
		str = string.toString();
		for (var i = 0; i < string.length; i++) {
			// create block
			var a = string.charCodeAt(i);
			// bitwise XOR
			var b = a ^ <any>key;
			enc = enc + String.fromCharCode(b);
		}
		return enc;
  }

}
