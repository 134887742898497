import { Component, OnInit } from "@angular/core";
import { AuthguardService } from "src/app/services/authguard.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EncryptionService } from "src/app/services/encryption.service";
import { Router } from "@angular/router";
import { GiftcardService } from "src/app/services/giftcard.service";
import { BehaviorSubject } from "rxjs";
import Swal from "sweetalert2";

interface Mystocksdata{
    name: String;
    amount:number;
    shares: number;
}

@Component({
    selector: "app-transfer-stocks",
    templateUrl: "./transfer-stocks.component.html",
    styleUrls: ["./transfer-stocks.component.scss"]
})
export class TransferStocksComponent implements OnInit {
    transferForm: FormGroup;
    transferConfirmForm: FormGroup;
    transactionCompleted: boolean = false;
    trasnsactionSuccess: boolean = false;
    totalWalletAmount: number = 0;
    userInfo: any = undefined;
    invalidPhoneNumber: string = "";
    walletAmount: string = "";
    isPhoneStartWithZero: boolean = false;
    transactionFee: number;
    min: number = 25;
    max: number = 0;
    total: number = 0;
    totalStockSharesArray : Mystocksdata[] = [];
    amount: number = 0;
    phone: string = "";
    currentPrice: number = 0;
    cryptoWallet: any;
    stocksApiData: any = '';
    stocksApiAllData: any[] = [];
    successResponse: any = {
        id: "",
        name: "",
        email: "",
        phone: "",
        card: "",
        date: "",
        amount: "0",
        fee: "0",
        total: "0"
    };
    currencyName: any;
    currencyValue: any;  
    transferOptions: any;
    processingFeeData: any; // get processing fee api data

    constructor(
        private authServices: AuthguardService,
        private fb: FormBuilder,
        private encryption: EncryptionService,
        private router: Router,
        private cardService: GiftcardService,
        private authService: AuthguardService,
    ) {
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
      
    }

    ngOnInit() {
        this.getProcessingFee();

        if (sessionStorage.length > 1) {
            let userInfo = JSON.parse(sessionStorage.userInfo);
            this.userInfo = userInfo;
        }

        this.transferForm = this.fb.group({
            selectedCard: ["", Validators.compose([Validators.required])],
            firstname: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(16)
                ])
            ],
            lastname: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(16)
                ])
            ],
            amount: ["", Validators.compose([Validators.required])],
            email: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                    )
                ])
            ],
            phone: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                    )
                ])
            ]
        });


        this.transferConfirmForm = this.fb.group({
            selectedCard: ["", Validators.compose([Validators.required])],
            currency: [""],
            firstname: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(16)
                ])
            ],
            lastname: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(16)
                ])
            ],
            email: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
                    )
                ])
            ],
            phone: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                    )
                ])
            ]
        });

      this.userStocksData();
    }

    // get stock price
    getStockFee(selected, shares) {
        let body = {
           stock_name: selected
        }
        this.authService.getNewStocksPriceApi(body).subscribe(response => {
            let body = (<any>response)
            console.log('body', body);
            let dateObjectArray = body.quote;
            //console.log("dateObjectArray", dateObjectArray[0]);

            this.cryptoWallet = dateObjectArray;

            this.cryptoWallet.Open = this.cryptoWallet.open;
            this.cryptoWallet.High = this.cryptoWallet.high
            this.cryptoWallet.Low = this.cryptoWallet.low
            this.cryptoWallet.Close = this.cryptoWallet.close;
            this.cryptoWallet.Supply = this.cryptoWallet.latestVolume
 
            if (isNaN(this.cryptoWallet.Close) && isNaN(shares)) {
            } else {
                let amount =  (this.cryptoWallet.Close * shares).toFixed(3);
                this.totalStockSharesArray.push({name:selected , amount: parseFloat(amount), shares: this.cryptoWallet.Close })
                console.log('totalStockSharesArray',this.totalStockSharesArray);
                this.totalWalletAmount = parseFloat(amount);
            }
        });

    }

        //getUserStockFee
        userStocksData() {
            this.authServices.getuserInfo().subscribe(data => {
                if (data && data.user) {
                  //  this.totalWalletAmount = data.giftCardsValue
                    let options = [];
                    this.stocksApiAllData = data.userStocksCurrency;
                    let userStocks = [];
                    for (let i = 0; i < this.stocksApiAllData.length; i++) {
                        userStocks.push({
                            name: this.stocksApiAllData[i].stock,
                            shares: this.stocksApiAllData[i].shares
                        });
                    }

                    for (let j = 0; j < userStocks.length; j++) {
                        this.getStockFee(
                            userStocks[j].name,
                            parseFloat(userStocks[j].shares)
                        );
                    }
                }
            });
        }

    transferAmounttoUser() {

        let formData = this.transferForm.value;
        let formConfirmData = this.transferConfirmForm.value;
        let bidprice = 1;
        var calculatesShares;
        let option = this.totalStockSharesArray.find(
            currency => currency.name == formData.selectedCard
        );
        if (option) {
            let bidprice = option.shares;
            if (this.amount) {
                calculatesShares = (this.amount / bidprice).toFixed(4);
            }
        }

        let phoneNumberValidate = this.phone;
        console.log('calculateShares', calculatesShares);
        let token = {
            user_id: this.encryption.encrypt(this.userInfo.id.toString()),
            type: formConfirmData.selectedCard,
            first_name: formConfirmData.firstname,
            last_name: formConfirmData.lastname,
            email: formConfirmData.email,
            phone: formConfirmData.phone,
            transfer_amount: this.encryption.encrypt(this.amount.toString()),
            transaction_fee: this.encryption.encrypt(
                this.transactionFee.toString()
            ),
            shares: this.encryption.encrypt(calculatesShares)
        };

        this.authService.transferGiftStocksShares(token).subscribe(response => {
            if ((<any>response).status == 200) {
                let data = (<any>response)
                if (data.success == false) {
                    this.invalidPhoneNumber = data.msg;
                }
                if (data.success == true) {
                    this.invalidPhoneNumber = "";
                    this.trasnsactionSuccess = true;
                    this.successResponse = {
                        id: data.transaction_id,
                        message: data.msg,
                        name: formConfirmData.name,
                        email: formConfirmData.email,
                        phone: formConfirmData.phone,
                        card: formConfirmData.currency,
                        date: new Date(),
                        amount: formData.amount,
                        fee: this.transactionFee,
                        total: this.total
                    };
                    this.fetchWallet();
                    window.scroll(0,0);
                }
            }
        });
        
    }

    resetScreen() {
        this.totalStockSharesArray = [];
        this.userStocksData();
        this.transactionCompleted = false;
        this.trasnsactionSuccess = false;
        this.transferForm.reset();
        this.transferConfirmForm.reset();
        this.transferForm.controls["selectedCard"].setValue("");
    }

    allowOnlyCharactersNameConfirm(control) {
        let input = this.transferConfirmForm.controls[control];
        let parsedValue = input.value.replace(
            /[!#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ""
        );
        this.transferConfirmForm.controls[control].setValue(parsedValue);
    }

    allowOnlyCharactersName(control){
        let input = this.transferForm.controls[control];
        let parsedValue = input.value.replace(
            /[!#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9""]/g,
            ""
        );
        this.transferForm.controls[control].setValue(parsedValue);
    }

    reviewOrder($event) {
        
        $event.preventDefault();
        let formData = this.transferForm.value;
        console.log('formData', formData);
        let name = formData.firstname + " " + formData.lastname;
        let option = this.totalStockSharesArray.find(
            currency => currency.name == formData.selectedCard
        );
        if (option) {
            let currency = "Gift";
            switch (option.name) {
                case "GOOGL":
                    currency = "GOOGL";
                    break;
                case "MSFT":
                    currency = "MSFT";
                    break;
                case "AAPL":
                    currency = "AAPL";
                    break;
            }
            this.transferConfirmForm.controls["currency"].setValue(currency);
            this.transferConfirmForm.controls["selectedCard"].setValue(
                option.name
            );
        } else {
        }
        this.transferConfirmForm.controls["firstname"].setValue(
            formData.firstname
        );
        this.transferConfirmForm.controls["lastname"].setValue(
            formData.lastname
        );
        this.transferConfirmForm.controls["phone"].setValue(formData.phone);
        this.transferConfirmForm.controls["email"].setValue(formData.email);
        this.transactionCompleted = true;
        this.amount = formData.amount;
        this.total = parseFloat(formData.amount) + this.transactionFee;
    }

    getProcessingFee() {
        this.cardService.getProcessingFeeData().subscribe(response => {
            console.log("response", response);
            let body = (<any>response)
            if ((<any>body).success == true) {
                this.processingFeeData = body.payment;
                //  console.log("this.processingFeeData", this.processingFeeData);
                this.transactionFee = parseFloat(
                    this.processingFeeData.transfer_fee
                );
                this.max = parseFloat(this.processingFeeData.transfer_max);
            }
        });
    }
    handleCopyPaste($event) {
        let value = "";
        if ($event.inputType == "insertFromPaste") {
            let input = $event.target.value.replace(/[^\d\-]/g, "");
            input = input.replace(/\-/g, "");
            value =
                input.slice(0, 3) +
                "-" +
                input.slice(3, 6) +
                "-" +
                input.slice(6, 10);
            if ($event.target.name == "phone") {
            }
        }
    }

    fetchWallet() {
        let userInfo = JSON.parse(sessionStorage.userInfo);
        this.authService
            .fetchWallet({
                user_id: this.encryption.encrypt(userInfo.id.toString())
            })
            .subscribe(response => {
                // wallet info fetched
            });
    }

    phoneStartWithZeroValidation($event) {
        // let input = this.transferForm.controls[$event.target.value];
        // let parsedValue = input.value.replace(/^[1-9][0-9]*$/g, "");
        // this.transferForm.controls[$event.target.value].setValue(parsedValue);
        let x;
        if ($event.target.value.length == 1) {
            let phoneRegex = /^[1-9][0-9]*$/g;
            if (phoneRegex.test($event.target.value)) {
                this.isPhoneStartWithZero = false;
            } else {
                this.isPhoneStartWithZero = true;
                this.transferForm.controls["phone"].setErrors({
                    incorrect: true
                });
            }
        }
        console.log(x);
    }

    back(){
        this.userStocksData();
        this.transactionCompleted = false;
    }

    cancel() {
        Swal({
            type: "warning",
            title: "",
            text: "Are You Sure? ",
            allowOutsideClick: false,
            customClass: "swal-custom",
            confirmButtonColor: "#053a6a",
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonColor: "#053a6a",
            cancelButtonText: "No"
        }).then(result => {
            if (result.value == true) {
                //component.addMoneyForm.reset();
                this.userStocksData();
                this.transactionCompleted = false;
                this.transferForm.reset();
                this.transferConfirmForm.reset();
            }
        });
    }

    cardSelected(){
        let formData = this.transferForm.value;
        if (formData.selectedCard != "") {
            let option = this.totalStockSharesArray.find(
                currency => currency.name == formData.selectedCard
            );
            console.log('option',option);
            if (option) {
                switch (option.name) {
                    case "Gift":
                        this.min = this.min;
                        this.max = option.amount - this.transactionFee; 
                        break;
                    case "GOOGL":
                    case "MSFT":
                    case "AAPL":
                        this.min = this.min;
                        this.max = option.amount - this.transactionFee;
                }
                // this.max = parseFloat(option.value);
                this.max = this.max;
            }
        }
    }
}
