import {environment} from './../../../../environments/environment';
import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from "@angular/core";
import {NgForm} from "@angular/forms";
import {AuthguardService} from "../../../services/authguard.service";
import Swal from "sweetalert2";
import {GiftcardService} from "../../../services/giftcard.service";
import {Router} from "@angular/router";
import {isArray} from "util";
import * as bootstrap from "bootstrap";
import {ApiencryptService} from '../../../services/apiencrypt.service';
import {ApidecryptService} from '../../../services/apidecrypt.service';
import {GlobalPipe} from '../../../shared/globalpipes/global.pipe';
import {StatesService} from 'src/app/services/states.service';
import {CompileShallowModuleMetadata} from '@angular/compiler';
declare var $: any;

@Component({
    selector: "app-profile-settings",
    templateUrl: "./profile-settings.component.html",
    styleUrls: ["./profile-settings.component.scss"]
})
export class ProfileSettingsComponent implements OnInit, AfterViewInit {
//aa code start
    @ViewChild('firstNameInput') firstNameInput: ElementRef;

      ngAfterViewInit(): void {
        this.firstNameInput.nativeElement.focus();
      }
    //aa code end
    password;
    showpassword = false;
    selectedChildImageIndex = 0;
    userId: string = "";
    token: string = "";
    emailNotExist: string = '';
    phoneNotExist: string = '';
    modalheading: string = 'email address';
    childdob: boolean = false;
    childEmailReg: boolean = false;
    userDetails: any = {
        firstname: "",
        lastname: "",
        address: "",
        email: "",
        phone: "",
        city: "",
        gender: "",
        country:"",
        state: "",
        zipcode: "",
        id_number: "",
        dob: ""
    };

    showChildImageCropper: boolean = false;
    errors: any = null;
    emailerrors: any = null;
    invalidPhoneNumber: string = "";
    notifications: boolean = false;
    emailVerified: boolean = true;
    phoneVerified: boolean = true;
    childemailVerified: boolean = false;
    childphoneVerified: boolean = false;
    otpRecieved: boolean = false;
    showChildShowHide: boolean = false;
    otpEntered: string = "";
    verificationRequestType: string = "";
    states;
    public emailverifybtn: boolean = true;
    status: string = "0";
    showImageCropper: boolean = false;
    imageChangedEvent = "";
    childImageChangedEvent = "";
    profileImageName = "";
    document = null;
    documentName = "";
    documentType = "";
    profileImage = "";
    oldImage: string = "";
    dob: any = "";
    oldDocument: string = "";
    oldDocumentName: string = "";
    startdate: any = {year: 1900, month: 1, day: 1};
    // childstartdate: any = {year: new Date().getFullYear() - 18, month: new Date().getMonth() + 1, day: 1};
    childstartdate: any = {year: 1990, month: new Date().getMonth() + 1, day: 1};
    enddate = {year: (new Date()).getFullYear() - 18, month: (new Date()).getMonth() + 1, day: (new Date()).getDate() - 1};
    profileImageUploaded: boolean = false;
    addChild: any[] = [];
    childCount = 0;
    exceedChildLength: boolean = false;
    docerrormsg: any[] = [];
    profilpicerrmsg: any[] = [];
    cities: any;
    ssndisable;
    aadhaardisable;
    encryptedssn: any;
    accountcount: number;
    userData: any = [];
    countries;
    idType:string;
    countryId:any;
    statesId: any;
    statename:any;
    IsdocUploaded: Boolean = false;
    constructor(
        private authService: AuthguardService,
        private cardService: GiftcardService,
        private router: Router,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe,
        private stateservice: StatesService,
    ) {
        this.countryId = sessionStorage.getItem("countryId");
        if (this.countryId == 'undefined')
        {
            this.countryId = 226;
        }
        this.getstateslist(this.countryId)
    }

    ngOnInit() {
        this.password = 'password';
        this.fetchUserInfo();
        this.authService.getToken().subscribe(token => {
            this.token = token;
        });
        this.fetchWallet();
        this.fetchMyAccounts();
        let userdata = JSON.parse(sessionStorage.getItem('userInfo'))
        this.statename = userdata.state;
        //console.log(this.statename);
        this.getCitiesdata(this.statesId);
    }
    allowAlphabetsEmailOnlyInput(event) {

        if (event.target.value != "") {
            let parsedValue = event.target.value.replace(
                /[`~#$^&%*\(\)+=\-\[\]\/\{\}|:<>?,\s]/g,
                ""
            );
            event.target.value = parsedValue;
            this.childEmailReg = false;
            let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!emailReg.test(event.target.value)) {
                this.childEmailReg = true;
            }
        } else {
            this.childEmailReg = false;
        }
    }

    preventSemicolon(e) {
        if (e.keyCode == 222 || e.keyCode == 186) {
            let parsedValue = e.target.value.replace(/[;'"]/g, "");
            e.target.value = parsedValue;
        }
    }

    disableUnableNotifications(e) {
        var body;
        if (e.target.checked == true) {
            body = "1";
        } else if (e.target.checked == false) {
            body = "0";
        }

        if (sessionStorage.userId) {
            let user_id = sessionStorage.userId;
            let token = {notification: body, user_id: user_id};
            let strdata = JSON.stringify(token);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService
                .disableOrUnableNotification(dataenc)
                .subscribe(response => {
                    let data = (<any>response)
                    let decdata = this.decryptmethod(data, this.global.publicKey);
                    let resp = JSON.parse(decdata);
                    if (<any>resp) {
                        let userNotifyData = resp;
                        if (userNotifyData.success == true) {
                            Swal({
                                type: "success",
                                title: "Success",
                                text: userNotifyData.msg,
                                allowOutsideClick: false,
                                customClass: "swal-custom",
                                confirmButtonColor: "#48bec4"
                            });
                        } else if (userNotifyData.error) {
                            Swal({
                                type: "error",
                                title: "Error",
                                text: userNotifyData.error,
                                allowOutsideClick: false,
                                customClass: "swal-custom",
                                confirmButtonColor: "#48bec4"
                            });
                        }
                    }
                });
        }
    }

    fetchUserInfo() {
        if (sessionStorage.length > 1) {
            this.userId = sessionStorage.userId;
            let token = {
                user_id: this.cardService.encrypt(this.userId)
            };
            let strdata = JSON.stringify(token);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.userProfileDetails(dataenc).subscribe(res => {
                let responsedata = (<any>res);
                let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                let resp = JSON.parse(decdata);
                let response = resp;
                let component = this;
                AuthguardService.user.next(resp.user);
                if (response.success) {
                    //console.log(response.user);
                    let user = response.user;
                    this.userData = user;
                    this.status = response.user.status;
                    if (this.status == '0') {
                        this.ssndisable = false;
                    } else if (this.userData.id_number == '' && this.status == '1') {
                        this.ssndisable = false;
                    } else if (this.status == '1') {
                        this.ssndisable = true;
                    } else if (this.status == '2') {
                        this.ssndisable = false;
                    }
                    if (this.status == '0') {
                        this.aadhaardisable = false;
                    } else if (this.userData.aadhaardisable == '' && this.status == '1') {
                        this.aadhaardisable = false;
                    } else if (this.status == '1') {
                        this.aadhaardisable = true;
                    } else if (this.status == '2') {
                        this.aadhaardisable = false;
                    }
                    
                    // sessionStorage.setItem("userInfo", JSON.stringify(response.user));
                    component.authService.setuserInfo(resp);
                    let userData = JSON.parse(
                        sessionStorage.getItem("userInfo")
                    );
                    if (userData) {
                        userData.show_notifications = user.show_notifications;
                        sessionStorage.setItem(
                            "userInfo",
                            JSON.stringify(userData)
                        );
                    }
                    let dobDate = {};
                    if (user.dob) {
                        let dob = user.dob.split("-");
                        dobDate = {
                            year: parseInt(dob[0]),
                            month: parseInt(dob[1]),
                            day: parseInt(dob[2])
                        };
                    }
                    this.dob = dobDate;
                    this.userDetails = {
                        firstname:
                            user.first_name != "0" ? user.first_name : "",
                        lastname: user.last_name != "0" ? user.last_name : "",
                        address: user.address != "0" ? user.address : "",
                        email: user.email != "0" ? user.email : "",
                        oldemail: user.email != "0" ? user.email : "",
                        phone: user.phone != "0" ? user.phone.slice(-10) : "",
                        oldphone: user.phone != "0" ? user.phone.slice(-10) : "",
                        gender: user.gender != "0" ? user.gender : "",
                        city: user.city != "0" ? user.city : "",
                        state: user.state != "0" ? user.state : "",
                        country : user.country != "0" ? user.country : "",
                        zipcode: user.zipcode != "0" ? user.zipcode : "",
                        verifyotp: user.verifyotp != "0" ? user.verifyotp : "",
                        dob: {year: parseInt(user.dob.split("-")[0]), month: parseInt(user.dob.split("-")[1]), day: parseInt(user.dob.split("-")[2])},
                        documents: user.document != "0" ? user.document.substr(user.document.lastIndexOf("/") + 1) : "",
                        account_number:
                            user.account_number != "0"
                                ? user.account_number
                                : "",
                       id_number: user.id_number != "0" ? user.id_number : "",    
                    };
                    //console.log(this.cardService.decrypt(user.id_number));
                    this.userDetails.id_number = JSON.parse(this.cardService.decrypt(user.id_number));
                    this.userDetails.aadhaarnumber = JSON.parse(this.cardService.decrypt(user.id_number));
                    // if(this.userDetails.ssnnumber){
                    //    this.userDetails.ssnnumber =  ('*****'.concat(this.userDetails.ssnnumber.substr(this.userDetails.ssnnumber.length - 4)));
                    // }
                    this.addChild = user.teen;
                    if (isArray(this.addChild)) {
                        for (let i = 0; i < this.addChild.length; i++) {
                            this.addChild[i].profile_name = this.addChild[
                                i
                            ].profile_pic;
                            this.addChild[i].user_id = this.addChild[i].id;
                            this.addChild[i].password = "";
                            this.addChild[i].phone = this.addChild[i].phone != "0" ? this.addChild[i].phone : "",
                                this.addChild[i].email = this.addChild[i].email != "0" ? this.addChild[i].email : "",
                                this.addChild[i].profile_name = this.addChild[i].profile_name != "0" ? this.addChild[i].profile_name : "",
                                this.addChild[i].confirmPassword = "";
                            this.addChild[i].profile_pic = "";
                            this.addChild[i].dobBackup = this.addChild[i].dob;
                            this.addChild[i].passwordMandatory = 0;
                            this.addChild[i].dob = {
                                year: parseInt(
                                    this.addChild[i].dob.split("-")[0]
                                ),
                                month: parseInt(
                                    this.addChild[i].dob.split("-")[1]
                                ),
                                day: parseInt(
                                    this.addChild[i].dob.split("-")[2]
                                )
                            };
                        }
                    }

                    this.notifications =
                        user.show_notifications == "1" ? true : false;
                    this.emailVerified =
                        user.email_verified != "0" ? true : false;
                    this.phoneVerified =
                        user.phone_verified != "0" ? true : false;
                    this.oldImage =
                        user.profile_pic != "0" && user.profile_pic != ""
                            ? user.profile_pic
                            : "";
                    this.profileImageUploaded =
                        user.profile_pic != "0" && user.profile_pic != ""
                            ? true
                            : false;
                    this.oldDocument =
                        user.document != "0" ? user.document : "";
                    this.oldDocumentName =
                        user.document != "0"
                            ? user.document.slice(
                                user.document.lastIndexOf("/") + 1
                            )
                            : "";
                    this.profileImageName = "";
                    setTimeout(() => {
                        this.changeState();
                    }, 2000);
                }
            });
        }
    }
    isExistEmail(email, userid) {
        let getemail;
        if (!userid) {
            userid = "";
            getemail = {email: email};
        }
        else {
            getemail = {email: email, user_id: userid};
        };
        let strgetemail = JSON.stringify(getemail);
        let strgetemailenc = this.encryptmethod(strgetemail, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.checkAlreadyExist(strgetemailenc).subscribe(response => {
            //    console.log("email "+email +"userid "+userid)
            let dataobtained = (<any>response)
            let decdata = this.decryptmethod(dataobtained, this.global.publicKey);
            let resp = JSON.parse(decdata);
            //console.log(resp);
            if ((<any>resp)._body) {
                let data = JSON.parse((<any>resp)._body);
                //  console.log(data)
                // error while processing
                if (data.email_exist == 1) {
                    this.emailNotExist = "true";
                    this.childemailVerified = true;
                } else {
                    this.emailNotExist = '';
                    this.childemailVerified = false;
                }
            } else {
                this.emailNotExist = '';
                this.childemailVerified = false;
            }

        });
    }
    isExistphone(phone, userid) {
        let getphone;
        if (!userid) {
            userid = "";
            getphone = {phone: phone};
        }
        else {
            getphone = {phone: phone, user_id: userid};
        }
        let strgetPhone = JSON.stringify(getphone);
        let strgetPhoneenc = this.encryptmethod(strgetPhone, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.checkAlreadyExist(strgetPhoneenc).subscribe(response => {
            let dataobtained = (<any>response)
            let decdata = this.decryptmethod(dataobtained, this.global.publicKey);
            let resp = JSON.parse(decdata);
            // console.log("phone "+phone +"userid "+userid)
            if ((<any>resp)._body) {
                let data = JSON.parse((<any>resp)._body);
                // console.log(data)
                // error while processing
                if (data.phone_exist == 1) {
                    this.phoneNotExist = "true";
                    this.childphoneVerified = true;
                } else {
                    this.phoneNotExist = '';
                    this.childphoneVerified = false;
                }
            } else {
                this.phoneNotExist = '';
                this.childphoneVerified = false;
            }

        });
    }
    verifyEmail(email) {
        this.verificationRequestType = "email";
        let body = {
            user_id: this.cardService.encrypt(this.userId),
            email: email
        };
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);

        this.authService.verifyEmail(dataenc, this.userId).subscribe(response => {
            let data = (<any>response)
            if (data.success == true) {
                this.otpRecieved = true;
            }
        });
    }

    verifyOTP(otp) {
        let body = {
            user_id: this.cardService.encrypt(this.userId),
            otp: this.cardService.encrypt(otp),
            type: this.verificationRequestType
        };
        let user_id = sessionStorage.getItem("userId");
        this.authService.verifyOTP(body).subscribe(response => {
            let data = (<any>response)
            if (data.success == true) {
                switch (this.verificationRequestType) {
                    case "email":
                        this.emailVerified = true;
                        this.otpRecieved = false;
                        break;
                    case "phone":
                        this.phoneVerified = true;
                        this.otpRecieved = false;
                        break;
                    default:
                }
            }
        });
    }

    verifyPhone(phone) {
        this.verificationRequestType = "phone";
        let body = {
            user_id: this.cardService.encrypt(this.userId),
            phone: phone
        };
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.sentOTP(dataenc, this.userId).subscribe(response => {
            let data = (<any>response)
            if (data.success == false) {
                this.invalidPhoneNumber = data.msg;
            }
            if (data.success == true) {
                this.invalidPhoneNumber = "";
                this.otpRecieved = true;
            }
        });
    }

    validateForm(form) {
        if(!this.IsdocUploaded) {
            if(form && !form.touched) return false;
        }
        let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let formData = form.value;
        let valid = true;
        this.errors = null;

        let error = {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            country:"",
            state: "",
            zipcode: "",
            profileImage: "",
            document: "",
            dob: "",
            gender: "",
            verifyotp: "",
            id_number: "",
            aadhaarnumber: ""
        };
        if (formData.firstname.length < 1) {
            error.firstname = "Please enter your firstname.";
            valid = false;
        }
        if (formData.lastname.length < 1) {
            error.lastname = "Please enter your lastname.";
            valid = false;
        }
        if (formData.address.length < 1) {
            error.address = "Please enter your address.";
            valid = false;
        }
        if (formData.city.length < 1) {
            error.city = "Please enter your city.";
            valid = false;
        }
        if (formData.state.length < 1) {
            error.state = "Please select your Country.";
            valid = false;
        }
        if (formData.country.length < 1) {
            error.country = "Please select your state.";
            valid = false;
        }
        if (formData.zipcode.length < 5) {
            error.zipcode = "Please enter your zipcode.";
            valid = false;
        }
            //console.log(formData);
//let data = Object.keys(res).length;
        if(formData.id_number != undefined && formData.id_number.length < 9){
            error.id_number = "Please enter valid ssn number";
            valid = false;
        }
        if (formData.email.length < 3 || formData.email.length > 0) {
            if (!emailReg.test(formData.email)) {
                error.email = "Please enter your valid email id.";
                valid = false;
            }
        }
        if (formData.email.length < 1) {
            error.email = "Please enter your email id.";
            valid = false;
        }
        if (formData.phone.length < 10) {
            error.phone = "Please enter your phone number.";
            valid = false;
        }
        // if (this.document == null && this.oldDocumentName == "") {
        //     error.document = "Please upload your document.";
        //     valid = false;
        // }
        if (!this.dob.year || this.dob == null || this.dob == "") {
            error.dob = "Please enter your date of birth.";
            valid = false;


        }

        if (this.addChild.length > 0) {
            for (let i = 0; i < this.addChild.length; i++) {
                let date = "";
                if (this.addChild[i].dob != "") {

                    let month =
                        this.addChild[i].dob.month < 10
                            ? "0" + this.addChild[i].dob.month
                            : this.addChild[i].dob.month;
                    let day =
                        this.addChild[i].dob.day < 10
                            ? "0" + this.addChild[i].dob.day
                            : this.addChild[i].dob.day;
                    this.addChild[i].dob =
                        this.addChild[i].dob.year + "-" + month + "-" + day;



                }
                if (this.addChild[i].first_name.length < 3) {
                    // error.firstname = "Please enter your firstname.";
                    // this.addChild[i].error.first_name =true
                    valid = false;
                }
                if (this.addChild[i].last_name.length < 3) {
                    // error.lastname = "Please enter your lastname.";
                    valid = false;
                }
                if (this.addChild[i].username.length < 3) {
                    // error.lastname = "Please enter your lastname.";
                    valid = false;
                } //profile_pic
                if (this.addChild[i].email) {
                    if (this.addChild[i].email.length < 3 && this.addChild[i].email.length > 0 && !emailReg.test(this.addChild[i].email)) {
                        valid = false;
                    } else {
                        this.isExistEmail(this.addChild[i].email, this.addChild[i].id);
                        if (this.childemailVerified == true) {
                            valid = false;
                        }
                        else {valid = true;}
                    }
                } else {
                    valid = true;
                }

                if (!this.addChild[i].dob.year ||
                    this.addChild[i].dob == null ||
                    this.addChild[i].dob == "" || (new Date().getFullYear() - this.addChild[i].dob.year > 18)

                ) {
                    this.childdob = true;
                    valid = false;
                } else {
                    this.childdob = false;
                    valid = true;
                }

                if (this.addChild[i].passwordMandatory == 1) {
                    if (this.addChild[i].password.length < 3) {
                        // error.firstname = "Please enter your firstname.";
                        valid = false;
                    }
                    if (this.addChild[i].confirmPassword.length < 3) {
                        //  error.lastname = "Please enter your firstname.";
                        valid = false;
                    }
                    if (
                        this.addChild[i].confirmPassword !=
                        this.addChild[i].password
                    ) {
                        //  error.lastname = "Please enter your firstname.";
                        valid = false;
                    } else {
                        this.addChild[i].password = this.addChild[i].password;

                    }
                }
                if (this.addChild[i].phone) {
                    if (this.addChild[i].phone != "" && this.addChild[i].phone.length < 10) {
                        valid = false;
                    }
                    else {
                        this.isExistphone(this.addChild[i].phone, this.addChild[i].id);
                        if (this.childphoneVerified == true) {
                            valid = false;
                        }
                        else {valid = true;}
                    }
                } else {
                    valid = true;
                }
            }
        }

        if (formData.email != formData.oldemail || formData.phone != formData.oldphone) {
            if (!formData.verifyotp || formData.verifyotp.length != 6) {
                $("#otpVerity").modal();
                error.verifyotp = "Please enter OTP.";
                valid = false;
                this.modalheading = 'phone number';

                // Verify email otp
                if (formData.email != formData.oldemail) {
                    this.modalheading = 'email address';
                    this.verifyProfileEmail(formData.email);
                    return;
                }

                // Verify phone otp
                this.verifyProfilePhone(formData.phone);
                return;
            }
        }

        if (valid) {
            this.updateProfileSettings();
        } else {
            this.errors = error;
            Swal({
                type: "error",
                title: "Error",
                text: "Please fill all * required fields",
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#48bec4"
            });
        }
    }

    updateProfileSettings() {
        if (this.oldDocument != "" && this.document == null) {
            this.document = "";
        }
        // if (this.oldDocument == "" && this.document == null) {
        //     alert("Please attach documents.");
        // }
        let date = "";

        if (this.userDetails.dob && this.userDetails.dob != "") {
            let month =
                this.userDetails.dob.month < 10 ? "0" + this.userDetails.dob.month : this.userDetails.dob.month;
            let day = this.userDetails.dob.day < 10 ? "0" + this.userDetails.dob.day : this.userDetails.dob.day;
            date = this.userDetails.dob.year + "-" + month + "-" + day;
        }

        for (let i = 0; i < this.addChild.length; i++) {
            if (
                this.addChild[i].dob == "" ||
                this.addChild[i].dob == undefined ||
                this.addChild[i].dob == "NaN-NaN-NaN"
            ) {
                this.addChild[i].dob = this.addChild[i].dobBackup;
            }
        }
        if(this.userDetails.id_number != null && this.userDetails.id_number !='' && this.userDetails.id_number != undefined ){    
            const randomnum = (Math.floor(Math.random() * 9) + 1).toString();
            this.encryptedssn = this.cardService.encrypt((randomnum.concat(this.userDetails.id_number)));
            // this.encryptedssn = this.userDetails.id_number
            this.idType == "SSN"
        } 
        // else if(this.userDetails.aadhaarnumber != null && this.userDetails.aadhaarnumber !='' && this.userDetails.aadhaarnumber != undefined){
        //   this.idType =  "Aadhaar"
        //   const randomnum = (Math.floor(Math.random() * 12) + 1).toString();
        //   this.encryptedssn = this.cardService.encrypt((randomnum.concat(this.userDetails.aadhaarnumber)));
        //   }
          else{
            this.idType = "";
          }
         

        let token = {
            user_id: this.userId,
            first_name: this.userDetails.firstname,
            last_name: this.userDetails.lastname,
            email: this.userDetails.email,
            phone: this.userDetails.phone,
            address: this.userDetails.address,
            gender: this.userDetails.gender,
            dob: date,
            city: this.userDetails.city,
            country: this.userDetails.country,
            state: this.userDetails.state,
            zipcode: this.userDetails.zipcode,
            id_number: this.encryptedssn,
            verifyotp: this.userDetails.verifyotp,
            profile_pic: this.profileImage,
            document: this.document,
            documentName: this.documentName,
            documentType: this.documentType,
            notifications: this.notifications ? "1" : "0",
            teens: this.addChild,
            idType: "SSN",
            ssnnumber: this.encryptedssn,
            sardineSessionKey: localStorage.getItem('sardineSessionKey')
        };
        console.log('token', token);
        //data encrypt
        let strdata = JSON.stringify(token);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService
            .updateSettingsdata(dataenc, this.userId)
            .subscribe(response => {
                //response decrypt
                console.log(response);
                let dataobtained = (<any>response)
                let decdata = this.decryptmethod(dataobtained, this.global.publicKey);
                let resp = JSON.parse(decdata);
                let data = resp;
                if (data.success == true && data.msg) {
                    this.fetchUserInfo();
                    $("#otpVerity .close").click();
                    this.invalidPhoneNumber = "";
                    Swal({
                        type: "success",
                        title: "Success",
                        text: data.msg,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#48bec4"
                    });
                } else if (data.success == false && data.msg) {
                    $("#otpVerity .close").click();
                    Swal({
                        type: "error",
                        title: "Error",
                        text: data.msg,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#48bec4"
                    });
                } else if (data.success == false && data.error.document) {
                    this.docerrormsg = data.error.document;
                } else if (data.success == false && data.error.profile_pic) {
                    this.profilpicerrmsg = data.error.profile_pic;
                } else if (data.error) {
                    Swal({
                        type: "error",
                        title: "Error",
                        text: data.error,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#48bec4"
                    });
                } else {
                    Swal({
                        type: "error",
                        title: "Error",
                        text: "Some error occured.",
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#48bec4"
                    });
                }
                this.fetchWallet();
            });
    }

    openChildCropper(event: any, index) {
        //  this.showChildImageCropper = true;
        this.selectedChildImageIndex = index;
        let files = event.target.files;
        if (
            files[0] &&
            (files[0].name.indexOf(".jpg") > -1 ||
                files[0].name.indexOf(".jpeg") > -1 ||
                files[0].name.indexOf(".png") > -1 || files[0].name.indexOf(".JPG") > -1 ||
                files[0].name.indexOf(".JPEG") > -1 ||
                files[0].name.indexOf(".PNG") > -1)
        ) {
            this.showChildImageCropper = true;
            this.childImageChangedEvent = event;
            if (event.target.files.length > -1) {
                this.addChild[index].profile_name = event.target.files[0].name;

            }
        } else {
            Swal({
                title: "Please upload only image (JPEG or PNG).",
                type: "warning",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: "OK"
            });
        }
    }
    childCropImage(image: string) {
        this.addChild[this.selectedChildImageIndex].profile_pic = image;
        console.log(
            " this.addChild[this.selectedChildImageIndex]",
            this.addChild[this.selectedChildImageIndex].profile_pic
        );
        // this.oldImage = image;
    }
    openCropper(event: any) {
        // this.showImageCropper = true;
        this.profilpicerrmsg = [];
        let files = event.target.files;
        if (
            files[0] &&
            (files[0].name.indexOf(".jpg") > -1 ||
                files[0].name.indexOf(".jpeg") > -1 ||
                files[0].name.indexOf(".png") > -1 || files[0].name.indexOf(".JPG") > -1 ||
                files[0].name.indexOf(".JPEG") > -1 ||
                files[0].name.indexOf(".PNG") > -1)
        ) {
            this.showImageCropper = true;
            this.imageChangedEvent = event;
            if (event.target.files.length > -1) {
                this.profileImageName = event.target.files[0].name;
            }
        } else {
            Swal({
                title: "Please upload only image (JPEG or PNG).",
                type: "warning",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: "OK"
            });
        }
    }
    cropImage(image: string) {
        this.profileImage = image;
        this.oldImage = image;
    }

    uploadDocument($event) {
        this.docerrormsg = [];
        let files = $event.target.files;
        if (files.length > 0) {
            this.IsdocUploaded = true;
            if (
                files[0] &&
                (
                    files[0].name.indexOf(".jpg") > -1 ||
                    files[0].name.indexOf(".jpeg") > -1 ||
                    files[0].name.indexOf(".JPG") > -1 ||
                    files[0].name.indexOf(".JPEG") > -1)
            ) {
                let names = [];
                Array.prototype.forEach.call(files, function (file) {
                    names.push(file.name);
                });
                this.documentName = names.join(", ");
                this.documentType = files[0].type.split("/")[1];
                if (this.errors && this.errors["document"]) {
                    this.errors["document"] = null;
                }
                var reader = new FileReader();
                reader.readAsDataURL(files[0]);
                let self = this;
                reader.onload = function () {
                    self.document = reader.result;
                };
            } else {
                Swal({
                    title:
                        "Please upload only image(JPEG) File",
                    type: "warning",
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonText: "OK"
                });
            }
        }
    }

    deleteDocument() {
        let body = {
            user_id: this.cardService.encrypt(this.userId)
        };
        Swal({
            title: "Are you sure you want to delete this document",
            type: "warning",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: "Yes, Delete it",
            cancelButtonText: "No, Cancel it"
        }).then(result => {
            this.userId = this.userId;
            let body = {
                user_id: this.cardService.encrypt(this.userId)
            };
            if (result.value) {
                this.authService.deleteSettingsDocument(body).subscribe(
                    response => {
                        if ((<any>response).success == true) {
                            this.oldDocumentName = "";
                            this.documentName = "";
                            this.fetchUserInfo();
                            Swal({
                                type: "success",
                                title: "You have successfully delete this document",
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                timer: 2000
                            });
                        } else if ((<any>response).success == false) {
                            Swal({
                                type: "error",
                                title: "Failed to delete the document:)",
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                timer: 2000
                            });
                        }
                    },
                    err => {
                        Swal({
                            type: "error",
                            title: "Failed to delete the document:)",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            timer: 2000
                        });
                    }
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal({
                    type: "error",
                    title: "Your document is safe :)",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    timer: 2000
                });
            }
        });
    }
    validateEmail(model, error, email) {

        let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        this.emailerrors = null;
        this.errors = null;
        if (model.value && model.value.length > 1) {
            if (this.errors && this.errors[error]) {
                this.errors[error] = null;
            }
            else if (!emailReg.test(email)) {

                this.emailerrors = "Please enter your valid email id.";
            }
            else {
                this.emailerrors = null;
            }
        }
        else if (email.length < 1) {
            this.emailerrors = "Please enter your email id.";

        }
        else {
            this.emailerrors = null;
        }

    }
    hideErrorMessage(model, error) {

        this.errors = null;

        if (model.value && model.value.length > 1) {
            if (this.errors && this.errors[error]) {
                this.errors[error] = null;
            }

        }
    }

    deleteProfileImageTeen(id) {
        Swal({
            title: "Are you sure you want to delete your profile image.",
            type: "warning",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: "Yes, Delete it",
            cancelButtonText: "No, Cancel it"
        }).then(result => {
            this.userId = id;
            let body = {
                user_id: this.userId
            };
            let strdata = JSON.stringify(body);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            if (result.value) {
                this.authService.deleteTeenProfileImage(dataenc).subscribe(
                    response => {
                        //response decrypt
                        let data = (<any>response)
                        let decdata = this.decryptmethod(data, this.global.publicKey);
                        let resp = JSON.parse(decdata);
                        Swal({
                            type: "success",
                            title:
                                "You have successfully deleted your profile image.",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            timer: 2000
                        });
                        if (<any>resp) {
                            // let responseData = JSON.parse(
                            //     (<any>response)._body
                            // );
                            if (resp.success == true) {
                                this.oldDocumentName = "";
                                this.fetchUserInfo();
                            }
                        }
                    },
                    err => {
                        Swal({
                            type: "error",
                            title: "Failed to delete the document:)",
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal({
                    type: "error",
                    title: "Your document is safe :)",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    timer: 2000
                });
            }
        });
    }

    deleteProfileImage() {
        if (this.profileImageUploaded) {
            let body = {
                user_id: this.cardService.encrypt(this.userId)
            };
            Swal({
                title: "Are you sure you want to delete your profile image.",
                type: "warning",
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonText: "Yes, Delete it",
                cancelButtonText: "No, Cancel it"
            }).then(result => {
                this.userId = this.userId;
                let body = {
                    user_id: this.cardService.encrypt(this.userId)
                };
                let strdata = JSON.stringify(body);
                let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
                if (result.value) {
                    this.authService.deleteProfileImage(dataenc).subscribe(
                        response => {
                            let data = (<any>response)
                            let decdata = this.decryptmethod(data, this.global.publicKey);
                            let resp = JSON.parse(decdata);
                            if (<any>resp) {
                                if (resp.success == true) {
                                    Swal({
                                        type: "success",
                                        title:
                                            "You have successfully deleted your profile image.",
                                        allowOutsideClick: false,
                                        showConfirmButton: false,
                                        timer: 2000
                                    });
                                    this.oldDocumentName = "";
                                    this.fetchUserInfo();
                                }
                            }
                        },
                        err => {
                            Swal({
                                type: "error",
                                title: "Failed to delete the document:)",
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                timer: 2000
                            });
                        }
                    );
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal({
                        type: "error",
                        title: "Your document is safe :)",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        timer: 2000
                    });
                }
            });
        } else {
            this.oldImage = "";
            this.profileImageName = "";
        }
    }

    imageLoaded() {
        // console.log("image loaded ")
    }
    loadImageFailed() {
        // console.log("image load failed ")
    }

    allowOnlyCharacters(input) {
        //console.log("input", input);
        let parsedValue = this.userDetails[input].replace(
            /[`~#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ""
        );
        this.userDetails[input] = parsedValue;
    }
    allowOnlyCharactersForAddress(input) {
        let parsedValue = this.userDetails[input].replace(
            /[`~_!#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,]/g,
            ""
        );
        this.userDetails[input] = parsedValue;
    }
    allowAlphabetsOnlyInput(event) {
        let parsedValue = event.target.value.replace(
            /[`~#$^@&%*\(\)+=!\-\[\]\/\{\}|:<>?.,0-9]/g,
            ""
        );
        event.target.value = parsedValue;
    }

    // prevent keys except alphabets
    allowAlphabetsOnlyKeyup(event) {
        if (
            (event.keyCode >= 65 && event.keyCode <= 90) ||
            event.keyCode == 32 ||
            event.keyCode == 9 ||
            event.keyCode == 190 ||
            event.keyCode == 13 ||
            (event.keyCode == 8 ||
                event.keyCode == 46 ||
                (event.keyCode >= 37 && event.keyCode <= 40))
        ) {
        } else {
            event.preventDefault();
        }
    }

    showCardMessageOne() {

        if (this.addChild.length >= 3) {
            this.exceedChildLength = true;
            Swal({
                type: 'warning',
                title: 'Error...',
                text: 'Can"t add more than 3 childs.',
                allowOutsideClick: false,
                customClass: 'swal-custom',
                confirmButtonColor: '#48bec4'
            });
        } else {
            this.childCount++;
            //let x = this["addChild" + this.childCount];
            let x = {
                user_id: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                dob: "",
                username: "",
                password: "",
                confirmPassword: "",
                passwordMandatory: 1,
                profile_pic: ""
            };
            this.addChild.push(x);
            this.exceedChildLength = false;
        }

    }

    removeChild(index) {
        //console.log(index, this.userData);
        this.addChild.splice(index, 1);
        //console.log(this.userData.teen);
        if (this.addChild['index'] == '0' || this.addChild['index'] !== null) {
            //console.log(this.addChild);
            //console.log(this.userData.teen);

            Swal({
                title: 'Are you sure you want to remove this Teen',
                text: 'You will not be able to recover this Teen!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, Remove teen',
                cancelButtonText: 'No, Cancel it',
                allowOutsideClick: false,
            }).then((result) => {
                //console.log(result.value, this.userData.teen);
                this.userId = sessionStorage.userId;
                if (result.value) {
                    let teenid = this.userData.teen
                    let body = {
                        user_id: this.userId,
                        teen_id: JSON.stringify(teenid[0].user_id)
                    }
                    //console.log("teen body", body);
                    this.authService.removeteen(body).subscribe(response => {
                        let data = (<any>response)
                        //console.log("Remove teen", data);
                        let resp = JSON.parse(data);
                        if (<any>resp) {
                            Swal({
                                type: 'success',
                                title: 'You have successfully remove this teen',
                                showConfirmButton: false,
                                timer: 2000,
                                allowOutsideClick: false,
                            })
                        }
                    },
                        err => {
                            Swal({
                                type: 'error',
                                title: 'Failed to remove the teen',
                                showConfirmButton: false,
                                timer: 2000,
                                allowOutsideClick: false,
                            })
                        }
                    );
                }
                else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal({
                        type: 'error',
                        title: 'teen is safe :)',
                        showConfirmButton: false,
                        timer: 2000,
                        allowOutsideClick: false,
                    })
                }
            });

        } else {
            Swal({
                type: 'error',
                title: 'No teen ',
                showConfirmButton: false,
                timer: 2000,
                allowOutsideClick: false,
            })
        }

    }

    // Verify phone number for profile update
    verifyProfilePhone(phone) {
        this.verificationRequestType = "phone";
        let body = {
            user_id: this.cardService.encrypt(this.userId),
            phone: phone
        };
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.sentOTP(dataenc, this.userId).subscribe(response => {
            let resp = (<any>response)
            let decdata = this.decryptmethod(resp, this.global.publicKey);
            let data = JSON.parse((<any>decdata)._body);
            if (data.success == false) {
                this.invalidPhoneNumber = data.msg;
            }
            if (data.success == true) {
                this.invalidPhoneNumber = "";
            }
            else {
                $("#otpVerity .close").click();
                Swal({
                    type: "error",
                    title: "Error",
                    text: data.error,
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#48bec4"
                });
            }
        });
    }

    // Verify email for profile update
    verifyProfileEmail(email) {
        this.verificationRequestType = "email";
        let body = {
            user_id: this.cardService.encrypt(this.userId),
            email: email
        };
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        // let encstr = JSON.stringify(this.userId);
        // let encuserid = this.encryptmethod(encstr, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.verifyEmail(dataenc, this.userId).subscribe(response => {
            let resp = (<any>response)
            let decdata = this.decryptmethod(resp, this.global.publicKey);
            let data = JSON.parse(decdata);
            if (data.success != true) {
                $("#otpVerity .close").click();
                Swal({
                    type: "error",
                    title: "Error",
                    text: data.error,
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#48bec4"
                });
            }
        });
    }
    
    getstateslist(countryId) {
        let country_id = countryId;
        this.stateservice.getstattes(country_id).subscribe(response => {
            let body = (<any>response);
            if (body) {
                this.states = body.respData;
            }
            this.states.forEach(element => {
                if (this.statename == element.state_name){
                    this.statesId = element.id;
                    }
            });
            this.getCitiesdata(this.statesId);    
       });  
    }

    getCitiesdata(statesId){
        let body = { 
            state_id : statesId, 
            country_id : this.countryId
          }
          this.stateservice.getcities(body).subscribe(response => {
            let body = (<any>response);
            //console.log(body);
            if(body) {
              this.cities = body.respData;
            }
          });
    }
    changeState() {
        let form = this.userDetails;
        if (form.state != "") {
            let option = this.states.find(
              state => state.state_name == form.state
          );
          if(option != undefined && option.id){
           let body = { 
              state_id : option.id, 
              country_id : this.countryId
            }
            //console.log(body);
            this.stateservice.getcities(body).subscribe(response => {
              let body = (<any>response);
              if(body) {
                this.cities = body.respData;
              }
            });
         }
        }
    }
    fetchWallet() {
        let userInfo = JSON.parse(sessionStorage.userInfo);
        this.authService
            .fetchWallet({
                user_id: this.cardService.encrypt(userInfo.id.toString())
            })
            .subscribe(response => {
                // wallet info fetched
            });
    }
    onClick() {
        if (this.password === 'password') {
            this.password = 'text';
            this.showpassword = true;
        } else {
            this.password = 'password';
            this.showpassword = false;
        }
    }
    fetchMyAccounts() {
        if (sessionStorage.getItem('userId')) {
            this.userId = sessionStorage.getItem("userId");
            let token = {
                user_id: this.userId
            };
            this.cardService.fetchMyAccounts(token).subscribe(response => {
                const dataobtained = <any>response;
                //console.log(dataobtained);
                if ((dataobtained.success === true)) {
                    this.accountcount++;
                } else {
                    this.accountcount = 0;
                }


            });
        }
    }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if (environment.requestEnv == '') {
            let encnoobj = this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data": encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
    decryptmethod(inputdata, key) {
        if (environment.requestEnv == '') {
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
        return JSON.stringify(inputdata);
    }
}
