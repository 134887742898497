import { Component, OnInit } from "@angular/core";
import { GiftcardService } from "../services/giftcard.service";
@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit {
    contactData: any;
    constructor(private giftService: GiftcardService) {}

    ngOnInit() {
        this.getContactDataDetails();
    }

    getContactDataDetails() {
        this.giftService.getContactPageData().subscribe(body => {
            // let body = JSON.parse((<any>response)._body);

            if ((<any>body).success == true) {
                this.contactData = body;
                //console.log(this.contactData);
            }
        });
    }
}
