import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from "@angular/forms";
import { AuthguardService } from "../../../services/authguard.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import * as paypal from "paypal-checkout";
import Swal from "sweetalert2";
import { GiftcardService } from "../../../services/giftcard.service";
import { DataService } from "../../../services/data.service";
import { environment } from "../../../../environments/environment";
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
@Component({
    selector: "app-add-money",
    templateUrl: "./add-money.component.html",
    styleUrls: ["./add-money.component.scss"]
})
export class AddMoneyComponent implements OnInit {
    usewallet : any;
    walletAmount: number = 0;
    addMoneyForm: FormGroup;
    responseData: any = {
        amount: "",
        transaction_id: ""
    };
    transactionDone: boolean = false;
    paypalSelected: boolean = false;
    token: string = "";
    paypalEnv: any;
    paypalKeys: any;

    // feature accept block
    userFeatures: any;
    silaACH:any;
    id: any;
    isCredit: boolean = false;
    isPaypal: boolean = false;
    isFeatureMsg: boolean = false;
    creditValue: any;
    paypalValue: any;
    userId: string;
    accountList: any;
    cardsfieldshidden: boolean = true;
    isPaypalSelected: boolean = true;
    isLoadMoneySelected: boolean = false;
    accountcount: number = 0;
    accountsdata: { user_id: any; accountName: any; publicToken: any; accountId: any; };
    currencyName:any;
    currencyValue: any;
    countryName: any;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private authService: AuthguardService,
        private cardService: GiftcardService,
        private dataService: DataService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe
    ) {
        
        this.currencyName = sessionStorage.getItem("currencyName"); 
        this.currencyValue = sessionStorage.getItem("currencyValue");
        this.countryName= sessionStorage.getItem("countryName");
        console.log(this.countryName);
    }

    ngOnDestroy() {
        if (this.id) {
            clearInterval(this.id);
        }
    }

    ngOnInit() {
        // call accept block user features
        this.getFeatures();
        // this.fetchMyAccounts();

        this.id = setInterval(() => {
            this.getFeatures();
        }, 180000);

        this.addMoneyForm = this.fb.group({
            paymentType: ["paypal",Validators.compose([Validators.required])],
            amount: ["", Validators.compose([Validators.required, Validators.max(1000)])],
            cardName: [''],
            cardNumber: [''],
            expiry: [''],
            cvv: [''],
            selectedAccount: ['']
        }); 
        if (this.transactionDone == false) {
            this.initPaypalCheckout();
        }
        this.authService.getToken().subscribe(token => {
            this.token = token;
        });
        this.authService.getuserInfo().subscribe(info => {
            if (info != undefined) {
                this.walletAmount = info.giftCardsValue;
            }
        });
        
    }

    preventTextCardNumber($event) {
        let value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, "");
        $event.target.value = value;
    }
    preventTextCardExpiry($event) {
        let value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, "");
        if ($event.type == "keyup" && $event.code == "Backspace") {
        } else {
            if (value.length == 2) {
                value += "/";
            }
        }
        $event.target.value = value;
    }
    preventTextCvv($event) {
        let value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, "");
        $event.target.value = value;
    }

    initPaypalCheckout() {
        let component = this;
        setTimeout(_ => {
            paypal.Button.render(
                {
                    env: environment.paypalEnv,
                    client: environment.paypalKeys,
                    commit: true,
                    style: {
                        layout:'horizontal',
                        color: 'gold',
                        size: 'medium',
                        shape: 'rect'
                    },
                    payment: function(data, actions) {
                        component.paypalSelected = true;
                        component.addMoneyForm.controls["paymentType"].setValue(
                            "paypal"
                        );
                        // disable payent through paypal if no transaction is needed
                        let amount = parseFloat(
                            component.addMoneyForm.controls["amount"].value
                        );
                        if (amount == 0 || isNaN(amount)) {
                            Swal({
                                type: "warning",
                                title: "Oops...",
                                text: "Please enter amount to be added.",
                                allowOutsideClick: false,
                                customClass: "swal-custom",
                                confirmButtonColor: "#053a6a"
                            });
                            return false;
                        }

                        return actions.payment.create({
                            payment: {
                                transactions: [
                                    {
                                        amount: {
                                            total: amount,
                                            currency: "USD"
                                        }
                                    }
                                ]
                            }
                        });
                    },
                    onAuthorize: function(data, actions) {
                        // Make a call to the REST api to execute the payment
                        return actions.payment
                            .execute()
                            .then(function(payment) {
                                component.addMoneyForm.controls[
                                    "paymentType"
                                ].setValue("paypal");
                                component.addmoneyViaPaypal(payment);
                            });
                    },
                    onCancel: function(data, actions) {},
                    onError: function(err) {}
                },
                "#paypal-btn"
            );
        }, 1000);
    }

    addmoneyViaCard() {
        let user_id = sessionStorage.getItem("userId");
        let body = {
            user_id: this.cardService.encrypt(user_id),
            type: "Stripe",
            card_name: this.addMoneyForm.value.cardName,
            amount: this.addMoneyForm.value.amount,
            card_number: this.addMoneyForm.value.cardNumber,
            exp_month: this.addMoneyForm.value.expiry.slice(0, 2).toString(),
            exp_year: this.addMoneyForm.value.expiry.slice(2).toString(),
            cvv: this.addMoneyForm.value.cvv
        };
           //data encrypt
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.addMoneyWalletUser(dataenc).subscribe(res => {
            //response decrypt
            let data = (<any>res);
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let response = JSON.parse(decdata);
                let resData = response;
                if (resData.success !== false  && resData.success != "0") {
                    this.responseData = resData;
                    this.transactionDone = true;
                    this.fetchWallet();
                    window.scroll(0,0);
                } else {
                    Swal({
                        type: "error",
                        title: "Sorry...",
                        text: resData.msg,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                }
        });
        //call add money api
    }
    addmoneyViaPaypal(data) {
        //call add money api
        let user_id = sessionStorage.getItem("userId");
        let body = {
            user_id: this.cardService.encrypt(user_id),
            amount: this.addMoneyForm.controls["amount"].value,
            transaction_id: data.id,
            card_number: "",
            exp_month: "",
            exp_year: "",
            card_name: "",
            cvv: "",
            type: "paypal"
        };
        //data encrypt
     let strdata = JSON.stringify(body);
     let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.addMoneyWalletUser(dataenc).subscribe(res => {
            //response decrypt
            let data = (<any>res);
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let response = JSON.parse(decdata);
            if (response.success === true ) {
                let resData = response;
                // let resData = JSON.parse((<any>response)._body);
                this.responseData = resData;
                this.responseData.transaction_id = resData.transaction_id;
                this.transactionDone = true;
                this.fetchWallet();
                window.scroll(0,0);
            }else {
                Swal({
                    type: "error",
                    title: "Sorry...",
                    text: response.msg,
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#053a6a"
                });
            }
        });
    }
    addmoneyViawallet(){
        var presentdate  = new Date();
        var reqdate = presentdate.getFullYear()+'-'+(presentdate.getMonth()+1)+'-'+presentdate.getDate();
        var time = presentdate.getHours() + ":" + presentdate.getMinutes() + ":" + presentdate.getSeconds();
        var dateTime = reqdate+' '+time;
        // user_id, amount, accountName
        let user_id = sessionStorage.getItem("userId");
        let form = this.addMoneyForm.value;
        let body = {
            user_id: this.cardService.encrypt(user_id),
            amount: this.addMoneyForm.value.amount,
            type:"wallet",
            accountName: this.addMoneyForm.value.selectedAccount,
            transDateTime:dateTime
        };
           //data encrypt
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.addMoneyWalletUser(body).subscribe(res => {
            //response decrypt
            let data = (<any>res);
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let response = JSON.parse(decdata);
            if ((response.success === true )){
                Swal({
                    type:"success",
                    title:response.msg,
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#053a6a"
                });
                this.fetchWallet();
                // window.scroll(0,0);
                this.router.navigateByUrl("/dashboard/home");
            } else {
                Swal({
                    type: "error",
                    title: "Sorry...",
                    text: response.msg,
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#053a6a"
                });
            }
        });
    }
    cancel() {
        let component = this;
        Swal({
            type: "warning",
            title: "",
            text: "Are You Sure?",
            allowOutsideClick: false,
            customClass: "swal-custom",
            confirmButtonColor: "#053a6a"
        }).then(result => {
            if (result.value == true) {
                component.addMoneyForm.reset();
            }
        });
    }

    reset() {
        this.router.navigate(["../", {}]);
    }

    fetchWallet() {
        let userInfo = JSON.parse(sessionStorage.userInfo);
        this.authService
            .fetchWallet({
                user_id: this.cardService.encrypt(userInfo.id.toString())
            })
            .subscribe(response => {
                // wallet info fetched
            });
    }
    omit_special_char(event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return (
            (k > 64 && k < 91) ||
            (k > 96 && k < 123) ||
            k == 8 ||
            k == 32 ||
            (k >= 48 && k <= 57)
        );
    }

    allowOnlyCharacters() {
        let parsedValue = this.addMoneyForm.controls["cardName"].value.replace(
            /[`~#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ""
        );
        this.addMoneyForm.controls["cardName"].setValue(parsedValue);
    }

    validateAmount() {
        if (parseInt(this.addMoneyForm.controls["amount"].value) > 100000) {
            Swal({
                type: "warning",
                title: "",
                text: "You cannot enter more than $100,000?",
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            }).then(_ => {
                this.addMoneyForm.controls["amount"].setValue("100000");
            });
        }
    }

    // function for feature msg
    featureMsg() {
        this.creditValue = this.userFeatures.features.credit_card;
        this.paypalValue = this.userFeatures.features.paypal;

        if (this.userFeatures.features.credit_card == "1")
        {
            this.isCredit = true;
        }
        // if (
        //    this.userFeatures.features.credit_card != "1" &&
        //    this.isCredit == false
        // ) {
        //     Swal({
        //        type: "warning",
        //        title: "Credit Card",
        //      text: this.userFeatures.features.credit_card_message,
        //      allowOutsideClick: false,
        //      customClass: "swal-custom",
        //      confirmButtonColor: "#053a6a"
        //  }).then(result => {
        //      if (result.value == true) {
        //          this.isCredit = true;
        //          this.featureMsg();
        //      }
        //  });
        // }

        if (
            this.userFeatures.features.paypal != "1" &&
            this.isPaypal == false
        ) {
            Swal({
                type: "warning",
                title: "Paypal",
                text: this.userFeatures.features.paypal_message,
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            }).then(result => {
                if (result.value == true) {
                    this.isPaypal = true;
                    this.featureMsg();
                }
            });
        }
        this.isFeatureMsg = true;
    }

    //get  features active or block

    getFeatures() {
        this.cardService.acceptBlockFeatures().subscribe(response => {
            let body = (<any>response)

            if ((<any>body).success == true) {

                // update user feature
                sessionStorage.setItem("features", JSON.stringify(body));

                this.userFeatures = body;
                console.log("feature_addMoney", this.userFeatures);
                this.silaACH = JSON.parse(body.features.other_features);
                if (this.isFeatureMsg == false) {
                    this.featureMsg();
                }

                if (
                    this.creditValue !=
                        this.userFeatures.features.credit_card ||
                    this.paypalValue != this.userFeatures.features.paypal
                ) {
                    this.isFeatureMsg = false;

                    this.isPaypal = false;
                    this.isCredit = false;
                    this.featureMsg();
                }
            }
        });
    }
    allowAlphabetsOnlyInput(event) {
        let parsedValue = event.target.value.replace(
            /[`~#$^@&%*\(\)+=!\-\[\]\/\{\}|:<>?."",0-9]/g,
            ""
        );
        event.target.value = parsedValue;
    }

    addMoneyOneThousand(event){
      var max =  1000;
      if(event.target.value > max){
        this.addMoneyForm.controls["amount"].setValue("1000");
      }
    }
    fetchMyAccounts() {
        if (sessionStorage.getItem('userId')) {
            this.userId = sessionStorage.getItem("userId");
            let token = {
                user_id: this.userId
            };
            this.cardService.fetchMyAccounts(token).subscribe(response  => {
                const dataobtained = <any>response;
                console.log(dataobtained);
                if ((dataobtained.success === true )){
                    this.accountList = dataobtained.data;
                     this.accountcount++;
                } else {
                    this.accountList = [];
                    this.accountcount = 0;
                }
    
    
            });
        }
    }
    radioButtonChanged(event){
        let radioValue = event.target['value'];
         if(radioValue == 'wallet'){
           this.cardsfieldshidden = true;
           this.isLoadMoneySelected = true;
           this.isPaypalSelected = false;
           console.log(this.addMoneyForm);
         } else if (radioValue == 'paypal'){
            this.cardsfieldshidden = true;
            this.isPaypalSelected = true;
            this.isLoadMoneySelected = false;
            this.initPaypalCheckout();
         } else if (radioValue == 'credit'){
            this.cardsfieldshidden = false;
            this.isPaypalSelected = false;
            this.isLoadMoneySelected = false;
         } else {
           this.cardsfieldshidden = true;
           this.isPaypalSelected = false;
         }
      }
      onPlaidSuccess(event) {
        console.log(event);
        // Send the public token to your server so you can do the token exchange.
        if (event.token && event.metadata) {
            this.accountsdata = {
                user_id :sessionStorage.userId,
                accountName: event.metadata.account.name,
                publicToken: event.token,
                accountId:event.metadata.account.id
                // institution: event.metadata.institution
            }
            this.cardService.linkbankaccount(this.accountsdata).subscribe(resp => {
                let token1 = {
                    user_id: this.userId
                };
                console.log(resp);
                let dataobtained = (<any>resp)
                if (dataobtained.success == true) {
                    Swal({
                        type: "success",
                        title: "Account Linked successfully now you can use wallet amount",
                        text: 'Account Linked successfully',
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                    this.fetchMyAccounts();
                    this.fetchWallet();
                    window.scroll(0,0);
                    this.router.navigateByUrl("/dashboard/home");
                } else if (dataobtained.success == false) {
                    Swal({
                        type: "error",
                        title: "${dataobtained.msg}",
                        allowOutsideClick: false
                    })
                }
            });

        }
    }

    onPlaidExit(event) {
        // Get errors or exit reason.
        console.log(event);
    }

    onPlaidEvent(event) {
        // Log events so you can have insight into how your users are using plaid link.
    }

    onPlaidLoad(event) {
        // Do something when the iframe loads.
    }

    onPlaidClick(event) {
        // Do something when the button is clicked.
    }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data" : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.encobj, key);
        }
            return JSON.stringify(inputdata);
    }
}
