import { Component, OnInit } from '@angular/core';
import { GiftcardService } from "../services/giftcard.service";
@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
    disclosureData: any;
    constructor(private giftService: GiftcardService) {}

  ngOnInit() {
    this.getDisclaimerDetails();
}
getDisclaimerDetails() {
    this.giftService.getDisclaimersPageData().subscribe(response => {
        let body = (<any>response)
        if ((<any>body).success == true) {
            this.disclosureData = body;
            console.log(this.disclosureData);
        }
    });
}
}
