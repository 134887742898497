import { ActivatedRoute } from '@angular/router';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AuthguardService } from './authguard.service';
import { MethodService } from './method.service';
@Injectable({
    providedIn: 'root'
})
export class CommonApiService {

    constructor(
        private httpClient: HttpClient,
        private methodtype: MethodService,
        private _authGuard: AuthguardService,
        private _activated: ActivatedRoute

    ) { }
    updateCampData(campaignId: string, userId: string = null, element: string = 'Landing-Page') {
        const navigator = window.navigator;
        const ip = this.getCookie('Ip') ? this.getCookie('Ip') : '1.1.1.1';
        var uagent = {
                userAgent: navigator.userAgent,
                vendor: navigator.vendor,
                platform: navigator.platform,
                language: navigator.language,
                cookieEnabled: navigator.cookieEnabled,
                appName: navigator.appName,
                appCodeName: navigator.appCodeName
            };
        const data = {
            cid: campaignId,
            user_agent: JSON.stringify(uagent),
            element: element,
            ip: ip
        };
        // window.setCookie('ppkcookie','testcookie',7);
        document.cookie = `cId=${campaignId}`;
        if (userId) {
            data['user_id'] = userId;
            document.cookie = `user_id=${userId}`;

        }
        this.httpClient
            .post(`${environment.serverHost}update-camp-data`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this._authGuard.setHttpRequestProcessing(false);
                        return response;
                    }
                )
            ).subscribe(res => {
                console.log(res);
            });
    }
    checkQueryParam() {
        this._activated.queryParams.subscribe(res => {
            if (res['cid'] && !this.getCookie('Landing-Page')) {
                this.updateCampData(res['cid'], res['userId']);
                this.setCookie('Landing-Page', 'false');
            }
        });
    }
    checkCookieAndCallCampaignApi(element: 'Landing-Page' | 'Signup-Page' | 'Login-Page' | 'Signup-Success' | 'Login-Success' = 'Landing-Page') {
        try {
            if (this.getCookie('cId')) {
                this.updateCampData(this.getCookie('cId'), this.getCookie('user_id'), element);
            }
        } catch (error) {

        }
    }
    checkFlagAndCallCampaign(page: 'Landing-Page' | 'Signup-Page' | 'Login-Page') {
        const cookieValue = this.getCookie(page);
        if (!cookieValue && this.getCookie('cId')) {
            this.checkCookieAndCallCampaignApi(page);
            this.setCookie(page, 'false');
        }
    }
    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }
    setCookie(name: string, value: string) {
        document.cookie = `${name}=${value}`;
    }
    getIpAddress() {
        if (!this.getCookie('Ip')) {
            // tslint:disable-next-line:max-line-length
            this.httpClient.get('https://api.ipdata.co?api-key=fede2bd1cb8be2140472b39d1efc7b2a18b989db49fee51383ab5fd6').subscribe((res: any) => {
                this.setCookie('Ip', res.ip);
            })
        }
    }
}
