import { CommonApiService } from './../services/common-api.service';
import { environment } from './../../environments/environment';
/******************************************************
 *  Sign up new user component
 *******************************************************/
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthguardService } from '../services/authguard.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GiftcardService } from '../services/giftcard.service';
import { AbstractControl } from '@angular/forms';
import { ApiencryptService } from '../services/apiencrypt.service';
import { ApidecryptService } from '../services/apidecrypt.service';
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
import Swal from "sweetalert2";
import { StatesService } from '../services/states.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {
  password;
  password1;
  showpassword = false;
  showssnpassword = false;
  // declare forrm group
  public signupForm: FormGroup;
  // set flags for form validation
  dateError: boolean = false;
  registrationSuccessFul: boolean = false;
  otpRequestGenerated: boolean = false;
  otpRecieved: boolean = false;
  invalidOtp: string = '';
  emailNotExist: string = '';
  phoneNotExist: string = '';
  // set models
  otp: string = '';
  invalidTop: string = '';
  userId: string = '';
  startdate: any = { year: 1900, month: 1,day: 1};
  enddate: any;
  licenseenddate : any;
  licensestartdate : any;
  invalidInputError: string = '';
  invaliddlexp: string = '';
  invaliddob: boolean = false;
  invalidexp : boolean = false;
  sslcheckshow : boolean = false;
  continuedisabled : boolean = false;
  showDrivinglicence : boolean = false;
  showSSN : boolean = false;
  showAadhaar : boolean = false;
  imageChangedEvent = "";
  drivingImageName = "";
    states;
    cities;
    countries = [{ iso3: 'US', name: 'United States', id: 226 }]; // Assuming there's only one country
  countryId: number;
    docerrormsg: any[];
    documentType: any;
    errors: any;
    document: any;
    documentName;
  encryptedssn: any;
  encryptedaadhaar: any;
  setconfirmssndisable:boolean = true;
  setconfirmaadhaarnumber: boolean = true;
  currencyName: any;
  currencyValue: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthguardService,
    private cardService: GiftcardService,
    private apienc: ApiencryptService,
    private apidec: ApidecryptService,
    private stateservice: StatesService,
        private global: GlobalPipe,
        private _commonApi: CommonApiService
  ) {
    this.enddate = { year: (new Date()).getFullYear()-18, month: (new Date()).getMonth() + 1, day: (new Date()).getDate()-1 };
    this.licensestartdate = { year: (new Date()).getFullYear(), month: (new Date()).getMonth() + 1, day: (new Date()).getDate() };
    this.licenseenddate = { year: (new Date()).getFullYear() +20, month: (new Date()).getMonth() + 1, day: (new Date()).getDate() };
    // this.getstateslist();
    this.getcountrylist();
    this.currencyName = sessionStorage.getItem("currencyName");
    this.currencyValue = sessionStorage.getItem("currencyValue");
  }

  ngOnInit() {
        this._commonApi.checkFlagAndCallCampaign('Signup-Page');

    this.password = 'password';
    this.password1 ='password';
    // initialize signup form
    this.signupForm = this.fb.group({
      first_name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(32)])],
      last_name: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(32)])],
      streetAddress1 : ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)])],
      confirmEmail: ['', Validators.compose([Validators.required, Validators.pattern(/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)])],
      confirmphone: ['', Validators.compose([Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)])],
      gender: ['', Validators.compose([Validators.required])],
      idType: ['', Validators.compose([Validators.required])],
      drivinglicensenumber: ['',Validators.compose([])],
      drivinglicensexp : ['', Validators.compose([])],
      document:['', Validators.compose([])],
      ssnnumber: [null],
      confirmssnnumber: [null],
      aadhaarnumber: [null],
      confirmaadhaarnumber:[null],
      country: [this.countries[0].iso3, Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required])],
      postalcode :['',Validators.compose([Validators.required,Validators.minLength(5)])],
      dobPicker: ['', Validators.compose([])],
      otp: ['', Validators.compose([Validators.minLength(6), Validators.maxLength(6)])],
    }, {
      validator: Validators.compose([ this.confirmphone,this.confirmEmail,this.userIsMoreThan18 ])
    });
    if ((<any>sessionStorage).token) {
      this.router.navigateByUrl('/dashboard');
    }
    // Execute the same logic on page load as you would on a country change
    this.handleCountrySelectionOnLoad();
  }
  sslcheckshowupdate(){
    this.sslcheckshow = true;
    this.getContryIdnumber();
    window.scroll(0,0);
  }
  getContryIdnumber(){
      let country = JSON.parse(sessionStorage.getItem("countryId")); 
      if(country == '226'){  
        this.showSSN = true;
      }
       if(country == '99'){
         this.showAadhaar = true;
      }
      window.scroll(0,0);
    }

  onSignUpFormSubmit() {
    let form = this.signupForm.value;
    //console.log("onSignUpFormSubmit", form)
    let dob = '';
    let dldob = ''
    if (form.dobPicker != '' && form.dobPicker != null) {
      this.invaliddob = false;
      let month = form.dobPicker.month < 10 ? '0' + form.dobPicker.month : form.dobPicker.month;
      let day = form.dobPicker.day < 10 ? '0' + form.dobPicker.day : form.dobPicker.day;
      dob = form.dobPicker != '' ? form.dobPicker.year + '-' + month + '-' + day : '';
    } else {
      this.invaliddob = true;
      this.invalidInputError = "Please enter date of birth";
      return false;
    }
    if(form.idType == 'Drivinglicence'){
        if(form.drivinglicensexp != '' && form.drivinglicensexp != null){
            this.invalidexp = false;
            let month = form.drivinglicensexp.month < 10 ? '0' + form.drivinglicensexp.month : form.drivinglicensexp.month;
            let day = form.drivinglicensexp.day < 10 ? '0' + form.drivinglicensexp.day : form.drivinglicensexp.day;
            dldob = form.drivinglicensexp != '' ? form.drivinglicensexp.year + '-' + month + '-' + day : '';
        } else{
            this.invalidexp = true;
            this.invaliddlexp = 'Please select expiry';
            return false;
        }
    }
    //console.log(form.aadhaarnumber);
    // create token
    const randomnum = (Math.floor(Math.random() * 9) +1).toString();
    const randomnumber = (Math.floor(Math.random() * 12) +1).toString();
    if(form.aadhaarnumber != null && form.aadhaarnumber !='' && form.aadhaarnumber != undefined){
     this.encryptedssn = this.cardService.encrypt((randomnumber.concat(form.aadhaarnumber)));
    }else if(form.ssnnumber != null && form.ssnnumber !='' && form.ssnnumber != undefined){
      this.encryptedssn = this.cardService.encrypt((randomnum.concat(form.ssnnumber)));
    }
     else{
      this.encryptedssn = "";
    }
    let otpToken = {
      email: form.email,
      phone: form.phone,
      dob: dob,
      first_name: form.first_name,
      confirmphone: form.confirmphone,
      last_name: form.last_name,
      gender: form.gender,
      country: form.country,
      state: form.state,
      city:form.city,
      user_id: this.cardService.encrypt(this.userId),
      password: form.otp,
      drivinglicensenumber : form.drivinglicensenumber,
      dlexpiry : dldob,
      document : sessionStorage.getItem('imgdata'),
      number :this.encryptedssn,
      address : form.streetAddress1,
      zipcode :form.postalcode,
      sardineSessionKey:localStorage.getItem('sardineSessionKey')
    };
      otpToken['idType'] = "SSN"
    if(form.aadhaarnumber != null && form.aadhaarnumber !='' && form.aadhaarnumber != undefined){
      otpToken['idType'] = "AADHAAR"
    }

    // register and fetch otp
    //data encrypt
    //console.log("otpToken", otpToken);
    let strdata = JSON.stringify(otpToken);
    let dataenc = this.encryptmethod(strdata, 
    this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
    this.authService.signUpOTP(dataenc).subscribe(response => {
      // let resp = JSON.parse((<any>response)._body);
      let decdata = this.decryptmethod(response, this.global.publicKey);
      let data = JSON.parse(decdata);
      this.sslcheckshow = false;
      this.showSSN = false;
        this.showAadhaar = false; 
      if (data.success == true) {
        this.otpRequestGenerated = true;
        // this.otp = data.otp;
        this.otpRecieved = true;
        window.scroll(0, 0);
        this.userId = this.cardService.decrypt(data.user_id);
      } else {
        // error while processing
        if (data.success == false) {
          this.emailNotExist = data.error.email;
            this.phoneNotExist = data.error.phone;
        //   setInterval(() => {
        //     this.emailNotExist = '';
        //     this.phoneNotExist = '';
        //   }, 3000);
        }
      }
    });
  }

  registerUser() {
    // register user after user enters password
    let dldob = '';
    let component = this;
    let form = this.signupForm.value;
    //console.log("registerUser", form)
    let month = form.drivinglicensexp.month < 10 ? '0' + form.drivinglicensexp.month : form.drivinglicensexp.month;
    let day = form.drivinglicensexp.day < 10 ? '0' + form.drivinglicensexp.day : form.drivinglicensexp.day;
    dldob = form.drivinglicensexp != '' ? form.drivinglicensexp.year + '-' + month + '-' + day : '';
    let signupToken = {
      ...form,
      phone: form.phone,
      user_id: this.cardService.encrypt(this.userId),
      password: form.otp,
      dlexpiry : dldob,
      document : sessionStorage.getItem('imgdata'),
      number : this.encryptedssn,
      sardineSessionKey:localStorage.getItem('sardineSessionKey')
    };
    //console.log("form.idType",form.idType);
    if(form.ssnnumber != null && form.ssnnumber != ''){
      signupToken['idType'] = "SSN";
    }else if(form.aadhaarnumber != null && form.aadhaarnumber !=''){
      signupToken['idType'] = "Aadhaar"
    } else{
      delete signupToken.idType;
      delete signupToken.idType;
    }
    //console.log("signupToken", signupToken)
    let strdata = JSON.stringify(signupToken);
    let signupTokenenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
    // validate if otp is valid
    // if (this.otp == this.signupForm.value.otp) {
    this.authService.signUp(signupTokenenc).subscribe(signupResponse => {
      let data = (<any>signupResponse)
      //console.log(data);
      let decdata = this.decryptmethod(data, this.global.publicKey);
      let body = JSON.parse(decdata);
      if (body.success == false) {
        this.invalidOtp = body.error;
        return false;
      }
      if (body.success == true)
        this.authService.updateReceivedGiftValue(signupTokenenc).subscribe(data => {
        });

      this.registrationSuccessFul = true;
      sessionStorage.setItem("userInfo", JSON.stringify(body.user));
      sessionStorage.setItem("token", body.access_token);
      sessionStorage.setItem("tokenType", JSON.stringify(body.token_type));
      sessionStorage.setItem("isLoggedIn", 'true');
      sessionStorage.setItem("userId", body.user.id);
      localStorage.setItem("offers",JSON.stringify(body.offers));
      //abhay@keyutech.com:03/06/22- Added a key to identify this is a new registerd user
      localStorage.setItem("isNewUser", 'Y');
      this.authService.loggedInSet();
      let userId = sessionStorage.userId;
      let token1 = {
          user_id: this.cardService.encrypt(userId)
      };
      this.authService.userProfileDetails(token1).subscribe(res => {
          let response = <any>res;
          if (response.success) {
              let user = response.user;
              this.cardService.profilestatus.next(response.user.status);
          }
      });
      if(body.offers.length == 1){
        this.cardService.offerData.next(body.offers[0]);
        // show free offer
        sessionStorage.setItem("showOffer", "true");
        localStorage.setItem("showpendingstatus","true");
        } else if(body.offers.length == 0){
            this.cardService.offerData.next(undefined);
        }
      setTimeout(_ => {
        component.activatedRoute.params.subscribe(params => {
          if (params.code) {
            component.router.navigate(["/redeem", { code: params.code }]);
          } else {
            component.router.navigate(["/dashboard"]);
          }
        });
      }, 600)
    });
  }


  omit_special_char(event) {
    if (event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 37 || event.keyCode === 39) {
      return true
    } else {
      var k;
      k = event.charCode;  //         k = event.keyCode;  (Both can be used)
      return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }
  }

  formaPhoneInput($event) {
    // auto format phone input
    let value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, "");
    if ($event.inputType == "deleteContentBackward") {
    } else {
      if (value.length == 3 || value.length == 7) {
        value += '-';
      }
    }
    this.signupForm.controls["phone"].setValue(value);
  }
  formaconfirmPhoneInput($event) {
    // auto format phone input
    let value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, "");
    if ($event.inputType == "deleteContentBackward") {
    } else {
      if (value.length == 3 || value.length == 7) {
        value += '-';
      }
    }
    this.signupForm.controls["confirmphone"].setValue(value);
  }
  filterSpecialCharacterInEmail() {
    let input = this.signupForm.controls['email'].value;
    if (input.indexOf('@') == 0) {
      input = '';
    }
    let parsedValue = input.replace(/[!#$^&%*\(\)+=\-\[\]\/\{\}|:<>?,]/g, '');
    this.signupForm.controls['email'].setValue(parsedValue);
  }
  filterSpecialCharacterInFirstname() {
    let input = this.signupForm.controls['first_name'].value;
    if (input.indexOf('@') == 0) {
      input = '';
    }
    let parsedValue = input.replace(/[!#$@.^&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g, '');
    this.signupForm.controls['first_name'].setValue(parsedValue);
  }
  filterSpecialCharacterInLastname() {
    let input = this.signupForm.controls['last_name'].value;
    if (input.indexOf('@') == 0) {
      input = '';
    }
    let parsedValue = input.replace(/[!#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g, '');
    this.signupForm.controls['last_name'].setValue(parsedValue);
  }
  allowAlphabetsNumbersHyphenDotInput(event) {
    let parsedValue = event.target.value.replace(
        /[`~$^@%*\(\)+=\\[\]\\{\}|<>?]/g,
        ''
    );
    event.target.value = parsedValue;
  }
  confirmssnnumber(form: AbstractControl) {
    let ssnnumber = form.get('ssnnumber').value; // to get value in input tag
    let confirmssnn = form.get('confirmssnnumber').value; // to get value in input tag
    if (confirmssnn != ssnnumber) {
      form.get('confirmssnnumber').setErrors({matchssn: true });
    } else {
      form.get('confirmssnnumber').setErrors(null);
      return null;
    }
  }
  confirmaadhaarnumber(form: AbstractControl) {
    let aadhaarnumber = form.get('aadhaarnumber').value; // to get value in input tag aadhaar
    let confirmaadhaarnumber = form.get('confirmaadhaarnumber').value; // to get value in input tag aadhaar
    if (confirmaadhaarnumber != aadhaarnumber) {
      form.get('confirmaadhaarnumber').setErrors({matchssn: true });
    } else {
      form.get('confirmaadhaarnumber').setErrors(null);
      return null;
    }
  }
  confirmEmail(form: AbstractControl) {
    let email = form.get('email').value; // to get value in input tag
    let confirmEmail = form.get('confirmEmail').value; // to get value in input tag
    if (email != confirmEmail) {
      form.get('confirmEmail').setErrors({ matchEmail: true })
    } else {
      form.get('confirmEmail').setErrors(null);
      return null
    }
  }
  confirmphone(form: AbstractControl) {
      let phone = form.get('phone').value;
      let confirmphone = form.get('confirmphone').value;
      if(phone == confirmphone){
        form.get('confirmphone').setErrors(null);
        return null;
      } else{
        form.get('confirmphone').setErrors({matchPhone : true})
      }
  }
  userIsMoreThan18(form: AbstractControl) {
    let dateEntered = form.get('dobPicker').value;
    if (dateEntered != '' && dateEntered != null) {
      let newDate = new Date();
      let validDate = {
        year: newDate.getFullYear() - 18,
        month: newDate.getMonth() + 1,
        day: newDate.getDate()-1
      };
      if (dateEntered.year && (typeof dateEntered == "string" && dateEntered.length < 10) || (typeof dateEntered == "object" && (dateEntered.year < 1900 || dateEntered.year > newDate.getFullYear()))) {

        form.get('dobPicker').setErrors({ invalidDate: "Enter a valid date." });
        return null;
      } else {
        form.get('dobPicker').setErrors({ invalidDate: null });
      }


      if (typeof dateEntered == "object" && dateEntered.year && dateEntered.month && dateEntered.day) {
        if (dateEntered.year > validDate.year ) {
          // console.log("User is less than 18 years old;");
          form.get('dobPicker').setErrors({ userIsTeen: "User is less than 18 years old." });
          if (dateEntered.month > validDate.month) {
            form.get('dobPicker').setErrors({ userIsTeen: "User is less than 18 years old." });
            if (dateEntered.day > validDate.day) {
              form.get('dobPicker').setErrors({ userIsTeen: "User is less than 18 years old." });
            }
          }

        } else {
          // console.log("User is more than 18 years old;");
          form.get('dobPicker').setErrors({ userIsTeen: null });
          form.get('dobPicker').updateValueAndValidity();
          return null;
        }
      }
    }
  }

  handleEmailCopyCutPaste($event) {
    $event.preventDefault();
  }
  updateidtype(event){
      //console.log(event.target.value);
      const val = event.target.value;
  }
  uploadDocument($event) {
    this.docerrormsg = [];
    let files = $event.target.files;
    if (files.length > 0) {
        if (
            files[0] &&
            (
                files[0].name.indexOf(".jpg") > -1 ||
                files[0].name.indexOf(".jpeg") > -1 ||
                files[0].name.indexOf(".JPG") > -1 ||
                files[0].name.indexOf(".JPEG") > -1 )
        ) {
            let names = [];
            Array.prototype.forEach.call(files, function(file) {
                names.push(file.name);
            });
            this.documentName = names.join(", ");
            this.documentType = files[0].type.split("/")[1];
            if (this.errors && this.errors["document"]) {
                this.errors["document"] = null;
            }
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);
            let self = this;
            reader.onload = function() {
                self.document = reader.result;
                sessionStorage.setItem('imgdata' ,self.document);
            };
            // this.continuedisable();
        } else {
            Swal({
                title:
                    "Please upload only image(JPEG) File",
                type: "warning",
                showCancelButton: false,
                confirmButtonText: "OK",
                allowOutsideClick: false,
            });
        }
    }
}
continuedisable(){
    let form = this.signupForm.value;
    if(form.idType == 'drivinglicence' && form.document == ''){
        this.continuedisabled  = true;
    }else if(form.idType == 'SSN' && form.ssnnumber! == ''){
        this.continuedisabled = false;
    }else if(form.idType == 'ADR' && form.aadhaarnumber! == ''){
      this.continuedisabled = false;
    }else{
        this.continuedisabled = true;
    }
  }
  allowAlphabetsNumbersInput(event) {
    const code = (event.which) ? event.which : event.keyCode;
    if (!(code == 32) && // space
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) ){
        event.preventDefault();
    }else{
        let parsedValue = event.target.value.replace(
            /[`~!#$^@&%*\(\)+=\-\[\]\/\{\}|:<>?.,]/g,
            ''
        );
        event.target.value = parsedValue;
    }
}
numberOnly(event){
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getcountrylist() {
    this.stateservice.getcountry().subscribe(response => {
      let body = (<any>response);
      if(body) {
        this.countries = body.respData;
      }
    });
  }
handleCountrySelectionOnLoad() {
    const form = this.signupForm.value;

    if (form.country !== "") {
      this.signupForm.patchValue({ state: '' }); // Reset the state field if needed
      //this.signupForm.controls['state'].disable(); // Disable the state field if required
      
      const selectedCountry = this.countries.find(country => country.iso3 === form.country);

      if (selectedCountry?.id) {
        sessionStorage.setItem("countryId", selectedCountry.id.toString());
        this.countryId = selectedCountry.id;
        this.getstateslist(this.countryId); // Fetch states list for the selected country
      }
    }
  }
  getstateslist(countryid) {
    let country_id = countryid;
    this.stateservice.getstattes(country_id).subscribe(response => {
      let body = (<any>response);
      if(body) {
        this.states = body.respData;
      }
    });
  }
  changeState(){
    let form = this.signupForm.value;
    if (form.state != "") {
      this.signupForm.patchValue({
                city : ''
             });
      // this.signupForm.controls['city'].disable();
      this.signupForm.value['city'] = "";
      let option = this.states.find(
        state => state.state_name == form.state
    );

    if(option.id){
     let body = {
        country_id : this.countryId,   
         state_id : option.id
      }
      this.stateservice.getcities(body).subscribe(response => {
        let body = (<any>response);
        if(body) {
          this.cities = body.respData;
          this.signupForm.controls['city'].enable();
        }
      });
    }
    
    }
  }
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.showpassword = true;
    } else {
      this.password = 'password';
      this.showpassword = false;
    }
  }
  onClickconfirm() {
    if (this.password1 === 'password') {
      this.password1 = 'text';
      this.showssnpassword = true;
      
    } else {
      this.password1 = 'password';
      this.showssnpassword = false;
    }
  }
  //encrypt method
  encryptmethod(inputdata, key, schema_version) {
    if(environment.requestEnv == ''){
        let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
        let encobj = {"enc_data" : encnoobj};
        return encobj;
    }
    return JSON.parse(inputdata);
}

//decrypt method
 decryptmethod(inputdata, key) {
    if(environment.requestEnv == ''){
        return this.apidec.decrypt(inputdata.enc_data, key);
    }
        return JSON.stringify(inputdata);
}
ssnOptionalValidation(){
    const ssnnumber = this.signupForm.get('ssnnumber');
    const cssnnumber = this.signupForm.get('confirmssnnumber');
    if ( this.signupForm.get('ssnnumber').value.length > 0 ){
      this.signupForm.get('ssnnumber').setValidators([Validators.pattern(/^\d{9}$/)]);
      this.signupForm.setValidators([this.confirmssnnumber]);
      this.signupForm.get('ssnnumber').updateValueAndValidity();
      this.setconfirmssndisable = false;
    } else {
      this.signupForm.get('ssnnumber').clearValidators();        
      this.signupForm.patchValue({
        confirmssnnumber  : null
      });
      this.signupForm.clearValidators();
      this.signupForm.get('confirmssnnumber').setErrors(null);        
      this.signupForm.get('confirmssnnuber').updateValueAndValidity();
      this.setconfirmssndisable = true;
    }
  }
  ssnOptionalValidationcnfirm(){
    if ( this.signupForm.get('confirmssnnumber').value.length > 0 ){
      this.signupForm.get('confirmssnnumber').setValidators([Validators.pattern(/^\d{9}$/)]);
      this.signupForm.setValidators([this.confirmssnnumber]);
    }
  }
  aadhaarOptionalValidation(){
    const aadhaarnumber = this.signupForm.get('aadhaarnumber');
    const confirmaadhaarnumber = this.signupForm.get('confirmaadhaarnumber');
    if ( this.signupForm.get('aadhaarnumber').value.length > 0 ){
      this.signupForm.get('aadhaarnumber').setValidators([Validators.pattern(/^\d{12}$/)]);
      this.signupForm.setValidators([this.confirmaadhaarnumber]);
      this.signupForm.get('aadhaarnumber').updateValueAndValidity();
      this.setconfirmaadhaarnumber = false;
    } else {
      this.signupForm.get('aadhaarnumber').clearValidators();        
      this.signupForm.patchValue({
        confirmaadhaarnumber : null
      });
      this.signupForm.clearValidators();
      this.signupForm.get('confirmssnnumber').setErrors(null);        
      this.signupForm.get('confirmssnnuber').updateValueAndValidity();
      this.setconfirmaadhaarnumber = true;
    }
  }
  aadhaarOptionalValidationcnfirm(){
    if ( this.signupForm.get('confirmssnnumber').value.length > 0 ){
      this.signupForm.get('confirmssnnumber').setValidators([Validators.pattern(/^\d{12}$/)]);
      this.signupForm.setValidators([this.confirmaadhaarnumber]);
    }
  }
}
