import { Component, OnInit, } from '@angular/core';
declare const TradingView: any;

@Component({
  selector: 'app-eth',
  templateUrl: './eth.component.html',
  styleUrls: ['./eth.component.scss']
})
export class EthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let graphData = {
      "autosize": true,
      "symbol": "BITFINEX:ETHUSD",
      "timezone": "America/Los_Angeles",
      "theme": "Light",
      "style": "9",
      "locale": "en",
      "toolbar_bg": "#f1f3f6",
      "enable_publishing": false,
      "withdateranges": true,
      "range": "1d",
      "hide_side_toolbar": false,
      "save_image": false,
      "container_id": "tradingview_e423a9"
    } 

    if(sessionStorage.getItem("countryName") === "IND" ){
      graphData['symbol'] = "BITFINEX:ETHINR";
      graphData['timezone'] = "India/Asia_Kolkata";
    }

    new TradingView.widget(graphData);
  }

 
}
