import { Injectable } from '@angular/core';
// import { Http, Request, Response, Headers, RequestOptions, RequestMethod } from "@angular/http";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Observable, pipe, Subject, of, BehaviorSubject, from } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
import { ApiencryptService } from './apiencrypt.service';
import { ApidecryptService } from './apidecrypt.service';

@Injectable({
  providedIn: 'root'
})
export class MethodService {
  envkey: string = "";
  SERVER_HOST: string = "";
  constructor(
    private httpClient: HttpClient,
    private apienc: ApiencryptService,
    private apidec: ApidecryptService,
    private global: GlobalPipe,
  ) {
    this.envkey = environment.requestEnv;
    this.SERVER_HOST = environment.serverHost;
  }

  get_query_params(queryParams) {
    let params = new HttpParams();
    for (const key of Object.keys(queryParams)) {
      params = params.set(key, queryParams[key]);
    }
    return params;

  }

  getcurl(url,headers,params?) {
    // do encryption
    let get_string = 'users';
    let strdata = JSON.stringify(get_string);
    let data = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
    //const queryParams = {enc_data : data.enc_data};
    // let params = params);
    let resData = this.getRequest(url, headers, params)
    return resData;
  }

  postcurl(url, data, headers) {
    let response = this.postRequest(this.SERVER_HOST + url, data, headers);
    if (this.envkey === 'dev') {
      return response;
    }
    return this.apienc.encrypt(response, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
  }

  getRequest(url, headers, params?) {
    return this.httpClient.get(this.SERVER_HOST + url, {headers: headers,params: params})
      .pipe(tap(response => {
        return response;
      }
      ));
  }

  postRequest(url, data, headers) {
    return this.httpClient.post(url, data, headers)
      .pipe(tap(response => {
        return response;
      })
      );
  }


  encryptmethod(inputdata, key, schema_version) {
    let encnoobj = this.apienc.encrypt(inputdata, key, schema_version);
    let encobj = { "enc_data": encnoobj };
    return encobj;
  }
  getheadersmethod() {
    if (environment.requestEnv == '') {

      const headers = {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          'Authorization': "Bearer " + sessionStorage.token,
          'accept': 'application/json',
          'language': 'enLang',
          'deviceInfo': 'deviceinfo',
          'IPAddress': "",
          'requestType': "W",
          "userString": "",
          "session-token": "",
          "requestToken": "",
          "requestEnv": ""
        })
      };
     return  headers ;
    }
    else {
      const headers = {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          'Authorization': "Bearer " + sessionStorage.token,
          'accept': 'application/json',
          'language': 'enLang',
          'deviceInfo': 'deviceinfo',
          'IPAddress': "",
          'requestType': "W",
          "userString": "",
          "session-token": "",
          "requestToken": "",
          "requestEnv": "dev"
        })
      };
     return  headers ;
    }
  }
}


