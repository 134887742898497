// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    passkey: "365eGifts",
    paypalEnv: "sandbox",
    paypalKeys: {
        sandbox:
            "AYcJ5QLBDuon8ChZZId3ELqrVD_rLIxFNB3_JfzKBdjTyOa2NDV0FeGRnFcxhyIUtImnmWzARFuORp9_",
        production:
            "AYcJ5QLBDuon8ChZZId3ELqrVD_rLIxFNB3_JfzKBdjTyOa2NDV0FeGRnFcxhyIUtImnmWzARFuORp9_"
    },
    bitfinexSocketUrl: "wss://api.bitfinex.com/ws",
    serverHost: `${location.protocol}//${location.host}/api/`, // cloudways server
    serverImgHost: `${location.protocol}//${location.host}/fe/`,
    version: 'M1.1 03/30/2022',
    requestEnv : 'dev',
    sockettype:'bitfinex'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
