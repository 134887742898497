import { Directive,ElementRef,Output,EventEmitter } from '@angular/core';

@Directive({
  selector: '[appOutside]',
   host: {
   '(document:click)': 'onClick($event)',
  }
})
export class OutsideDirective {
  @Output()
  clickedOutside = new EventEmitter<void>();
  constructor(private _elementRef: ElementRef) { }

  onClick(event) {
    const clickedOutside = this._elementRef.nativeElement.contains(event.target);
    if (!clickedOutside) {
      // this.clickedOutside.emit(null);
     }
    }

}