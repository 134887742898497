import { Component, OnInit } from '@angular/core';
import { GiftcardService } from '../services/giftcard.service';

@Component({
  selector: 'app-ccpa',
  templateUrl: './ccpa.component.html',
  styleUrls: ['./ccpa.component.scss']
})
export class CcpaComponent implements OnInit {

  privacyPolicyData: any;
    constructor(private giftService: GiftcardService) {}

    ngOnInit() {
        this.getPrivacyPolicyDataDetails();
    }

    getPrivacyPolicyDataDetails() {
        this.giftService.getCCPaPolicyPageData().subscribe(response => {
            let body = (<any>response);

            if ((<any>body).success == true) {
                this.privacyPolicyData = body;
            }
        });
    }


}
