import { Component, OnInit } from "@angular/core";
import { GiftcardService } from "../services/giftcard.service";

@Component({
    selector: "app-privacypolicy",
    templateUrl: "./privacypolicy.component.html",
    styleUrls: ["./privacypolicy.component.scss"]
})
export class PrivacypolicyComponent implements OnInit {
    privacyPolicyData: any;
    constructor(private giftService: GiftcardService) {}

    ngOnInit() {
        this.getPrivacyPolicyDataDetails();
    }

    getPrivacyPolicyDataDetails() {
        this.giftService.getPolicyPageData().subscribe(response => {
            let body = (<any>response);

            if ((<any>body).success == true) {
                this.privacyPolicyData = body;
                //console.log(this.privacyPolicyData);
            }
        });
    }
}
