import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from "@angular/forms";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthguardService } from "../../../services/authguard.service";
import * as paypal from "paypal-checkout";
import Swal from "sweetalert2";
import { GiftcardService } from "../../../services/giftcard.service";
import { AbstractControl } from "@angular/forms";
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
import { environment } from "../../../../environments/environment";

@Component({
    selector: "app-withdraw",
    templateUrl: "./withdraw.component.html",
    styleUrls: ["./withdraw.component.scss"]
})
export class WithdrawComponent implements OnInit {
    states: string[] = [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District Of Columbia",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
    ];
    processingFeeData: any; // processing fee api data
    paymentOption: string = "cheque"; // 'cheque', 'paypal'
    userInfo = undefined;
    token: string = "";
    transactionFee: number;
    withdrawlAmount: number;
    amountMsg: string = "";
    walletAmount: number = 0;
    invalidPhoneNumber: string = "";
    confirmPayment: boolean = false;
    transactionDone: boolean = false;
    transactionResponse: any = {};
    withdrawform: FormGroup;
    paypalForm: FormGroup;
    amountTotal: number = 0;
    amountIsGreaterToWithdr: string = "";
    amount: number = 0;
    name: string = "";
    address: string = "";
    city: string = "";
    email: string = "";
    phone: string = "";
    total: number = 0;
    state: string = "";
    zipcode: string = "";
    min: number = 5;
    max: number = 0;
    completeprofileInfo = false;
    respinfo ;
    accountcount: number = 0;
    silaACH:any;
    currencyName: any;
    currencyValue: any;
    
    constructor(
        private router: Router,
        private authService: AuthguardService,
        private fb: FormBuilder,
        private cardService: GiftcardService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe
    ) {
        this.withdrawform = this.fb.group({
            amount: ["", Validators.compose([Validators.required])],
            name: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
            address: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
            city: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
            state: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
            zipcode: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.min(5)])
            ]
        });
        this.paypalForm = this.fb.group(
            {
                amount: ["", Validators.compose([Validators.required])],
                email: [
                    "",
                    Validators.compose([Validators.required, Validators.email])
                ],
                emailConfirm: [
                    "",
                    Validators.compose([Validators.required, Validators.email])
                ],
                phone: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(
                            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                        ),
                        Validators.minLength(10)
                    ])
                ],
                phoneConfirm: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(
                            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                        ),
                        Validators.minLength(10)
                    ])
                ]
            },
            {
                validator: Validators.compose([
                    this.confirmEmail,
                    this.confirmPhone
                ])
            }
        );

        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
    }

    ngOnInit() {
        this.fetchWithdrawInfo() ;
        this.authService.getToken().subscribe(token => {
            this.token = token;
        });
        var user_id = sessionStorage.getItem("userId");
        let userid = {
            user_id: user_id
        };
        this.cardService.fetchMyAccounts(userid).subscribe(response  => {
            const dataobtained = <any>response;
            if ((dataobtained.success === true )){
                    this.accountcount++;
            } else {
                this.accountcount = 0;
            }
        });
        if (sessionStorage.length > 1) {
            let userInfo = JSON.parse(sessionStorage.userInfo);
            this.userInfo = userInfo;

        }
        this.getFeatures();
        this.fetchWallet();
    }

    fetchWithdrawInfo(){
        this.getProcessingFee();

        this.authService.getuserInfo().subscribe(info => {
            this.respinfo = info;
            console.log("info", info);
            if (info != undefined) {
                let amount = parseFloat(info.giftCardsValue);
                if (!isNaN(amount)) {
                    this.walletAmount = amount;
                    if( info.user.address =="0" || info.user.city  == "0" || info.user.city  == "0" || info.user.state  == "0" || info.user.zipcode  == "0"){
                        this.completeprofileInfo = true;
                    }else{
                        this.completeprofileInfo = false;
                    }
                }
            }
        });
    }
    preventSemicolon(e) {
        if (e.keyCode == 222 || e.keyCode == 186) {
            let parsedValue = e.target.value.replace(/[;'"]/g, "");
            e.target.value = parsedValue;
        }
    }

    paymentTypeChanged() {
        switch (this.paymentOption) {
            case "cheque":
                this.paymentOption = "cheque";
                break;

            case "paypal":
                this.paymentOption = "paypal";
                break;
            case "wallet":
                this.paymentOption = "wallet";
                if(this.accountcount == 0){
                    this.withdrawform.invalid;
                }
                break;

            default:
                this.paymentOption = "cheque";
                break;
        }
    }

    validateAmount(event) {
        let value = event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, '');
        value = parseFloat(value).toFixed(2);
        value = value.trim();
        let amount = value;
        console.log(amount)
        if(amount == ''){
            amount = 0
        }else {
            amount = amount;
        }
        // validate amount for cheque withdraw
        let amountToWithdraw = parseFloat(amount);
        let walletAmountAvailable = this.walletAmount;
        if (isNaN(amountToWithdraw)) {
            this.amountMsg = "Amount is required";
        } else {
            this.withdrawform.controls["amount"].setValue(amountToWithdraw);
            if (amountToWithdraw <= walletAmountAvailable) {
                this.amountMsg = "";
                this.amount = amountToWithdraw;
            }
            else if(walletAmountAvailable < this.min) {
                    this.amountMsg = "You don't have enough money to withdraw";
            }else {
                this.amount = walletAmountAvailable;
                let totalTransferAmount =
                    this.walletAmount - this.transactionFee;
                // this.amountMsg = `Amount $${totalTransferAmount} is greater than gift card value $${
                //     this.walletAmount
                // } please add $${this.transactionFee} to withdraw`;

                this.withdrawform.controls["amount"].setValue(
                    totalTransferAmount
                );
            }
        }
        console.log(this.amount)
    }

    paymentViaCheck() {

        let amount = this.withdrawform.value.amount;
        this.amountTotal = amount;

        let transFee = this.transactionFee;

        this.amount = amount - transFee;
        if (this.withdrawform.valid) {
            this.confirmPayment = true;
        }
        window.scroll(0,0);
    }
    paymentViaPaypal($event) {
        $event.preventDefault();
        let amount = parseInt(this.paypalForm.value.amount);
        this.amountTotal = amount;
        let transFee = this.transactionFee;
        this.amount = amount - transFee;
        if (this.paypalForm.valid) {
            this.confirmPayment = true;
        }
        window.scroll(0,0);
    }
    getEmailPhone() {
        let email = this.paypalForm.value.email;
        let phone = this.paypalForm.value.phone;
        this.email = email;
        this.phone = phone;
    }

    back() {
        this.confirmPayment = false;
        window.scroll(0,0);
    }

    cancel() {
        Swal({
            type: "warning",
            title: "",
            text: "Are You Sure? ",
            allowOutsideClick: false,
            customClass: "swal-custom",
            confirmButtonColor: "#053a6a",
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonColor: "#053a6a",
            cancelButtonText: "No"
        }).then(result => {
            if (result.value == true) {
                //component.addMoneyForm.reset();
                this.confirmPayment = false;
                this.withdrawform.reset();
                this.paypalForm.reset();
                this.paymentOption = "cheque";
                window.scroll(0,0);
                this.router.navigateByUrl("/dashboard/home");
            }
        });
    }

    withdrawAmount() {
        var user_id = sessionStorage.getItem("userId");
        let paymentmethod = this.paymentOption;
        let cheque = this.withdrawform.controls;
        let paypal = this.paypalForm.value;
        let newPhoneNumber = this.phone;
        var presentdate  = new Date();
        var reqdate = presentdate.getFullYear()+'-'+(presentdate.getMonth()+1)+'-'+presentdate.getDate();
        var time = presentdate.getHours() + ":" + presentdate.getMinutes() + ":" + presentdate.getSeconds();
        var dateTime = reqdate+' '+time;
        let token = {
            user_id: user_id,
            type: this.paymentOption,
            paypal_email: paypal.email,
            phone: newPhoneNumber,
            name: cheque.name.value,
            address: cheque.address.value,
            city: cheque.city.value,
            state: cheque.state.value,
            zipcode: cheque.zipcode.value,
            withdrawl_amount: this.amount,
            transaction_fee: this.transactionFee,
            transaction_status:'approved',
            transDateTime:dateTime
        };
        // Check Wallet balance
        let totalTransactionAmount =
            token.withdrawl_amount + token.transaction_fee;

        if (this.walletAmount >= totalTransactionAmount) {
            let strdata = JSON.stringify(token);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.withdraw(dataenc).subscribe(response => {
            let dataobtained = (<any>response)
            let decdata = this.decryptmethod(dataobtained, this.global.publicKey);
            let resp = JSON.parse(decdata);
                if (<any>resp) {
                    let data = resp;
                    if (data.success == false) {
                        Swal({
                            type: "warning",
                            title: "Oops",
                            text: data.msg,
                            allowOutsideClick: false,
                            customClass: "swal-custom",
                            confirmButtonColor: "#053a6a"
                        });
                        return false;
                        // this.invalidPhoneNumber = data.msg;

                    }
                    this.transactionResponse = data;
                    if (data.success == true) {
                        this.invalidPhoneNumber = "";
                        this.total =
                            this.transactionResponse.withdrawlAmount +
                            this.transactionResponse.transactionFee;
                        this.transactionDone = true;
                        this.confirmPayment = false;
                        this.fetchWallet();
                    }
                    window.scroll(0,0);
                }
            });
        }
    }

    confirmEmail(form: AbstractControl) {
        let email = form.get("email").value; // to get value in input tag
        let confirmEmail = form.get("emailConfirm").value; // to get value in input tag
        if (email != confirmEmail) {
            form.get("emailConfirm").setErrors({ matchEmail: true });
        } else {
            form.get("emailConfirm").setErrors(null);
            return null;
        }
    }

    confirmPhone(form: AbstractControl) {
        let email = form.get("phone").value; // to get value in input tag
        let confirmEmail = form.get("phoneConfirm").value; // to get value in input tag
        if (email != confirmEmail) {
            form.get("phoneConfirm").setErrors({ matchPhone: true });
        } else {
            form.get("phoneConfirm").setErrors(null);
            return null;
        }
    }

    reset() {
        this.paymentOption = "cheque";
        this.transactionFee = parseFloat(this.processingFeeData.withdraw_fee);
        this.amount = 0;
        this.total = 0;
        this.amountMsg = "";
        this.confirmPayment = false;
        this.transactionDone = false;
        this.transactionResponse = {};
        this.withdrawform.reset();
        this.paypalForm.reset();
        window.scroll(0,0);
    }

    fetchWallet() {
        let userInfo = JSON.parse(sessionStorage.userInfo);
        this.authService
            .fetchWallet({
                user_id: this.cardService.encrypt(userInfo.id.toString())
            })
            .subscribe(response => {
                // wallet info fetched
                this.respinfo = response;
                console.log("this.respinfo", this.respinfo);
                this.withdrawform.patchValue({
                    // amount: parseFloat(info.giftCardsValue),
                    name: (this.respinfo.user.first_name) + ' ' + (this.respinfo.user.last_name),
                    address: this.respinfo.user.address != "0" ? this.respinfo.user.address : "",
                    city: this.respinfo.user.city != "0" ? this.respinfo.user.city : "",
                    state: this.respinfo.user.state != "0" ? this.respinfo.user.state : "",
                    zipcode: this.respinfo.user.zipcode != "0" ? this.respinfo.user.zipcode : ""
                });
            });
    }

    formaPhoneInput($event) {
        /// auto format phone number inputs
        let keycode = $event.keyCode;
        if ((keycode > 47 && keycode < 58) || keycode == 45) {
            let value = $event.target.value.replace(/[^\d\-]/g, "");
            if ($event.inputType == "deleteContentBackward") {
            } else {
                if (value.length == 3 || value.length == 7) {
                    value += "-";
                }
            }
        } else {
            $event.preventDefault();
        }
    }

    handleCopyPaste($event) {
        let value = "";
        if ($event.inputType == "insertFromPaste") {
            let input = $event.target.value.replace(/[^\d\-]/g, "");
            input = input.replace(/\-/g, "");
            value =
                input.slice(0, 3) +
                "-" +
                input.slice(3, 6) +
                "-" +
                input.slice(6, 10);
            if ($event.target.name == "phone") {
            }
        }
    }

    handleEmailCopyCutPaste($event) {
        $event.preventDefault();
    }

    allowOnlyCharactersName() {
        let parsedValue = this.name.replace(
            /[!#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ""
        );
        this.withdrawform.controls["name"].setValue(parsedValue);
        this.name = parsedValue;
    }
    allowOnlyCharactersCity() {
        let parsedValue = this.city.replace(
            /[!#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ""
        );
        this.withdrawform.controls["city"].setValue(parsedValue);
        this.city = parsedValue;
    }

    resetScreen() {
        this.confirmPayment = false;
        this.transactionDone = false;
        this.withdrawform.reset();
        this.paypalForm.reset();
        this.reset();
        this.paymentOption = "cheque";
        //this.cancel();
        this.name = "";
        this.address = "";
        this.city = "";
        this.state = "state";
        this.zipcode = "";
        window.scroll(0,0);
        this.router.navigateByUrl("/dashboard/home");
    }

    // procesing fee

    getProcessingFee() {
        this.cardService.getProcessingFeeData().subscribe(response => {
            //response decrypt
            let data = (<any>response)
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let resp = JSON.parse(decdata);
            let body = resp;
            console.log("body in withdraw", body);
            if ((<any>body).success == true) {
                this.processingFeeData = body.payment;
                  console.log("this.processingFeeData", this.processingFeeData);
                this.transactionFee = parseFloat(
                    this.processingFeeData.withdraw_fee
                );

                // this.min = this.processingFeeData.withdrawl_min;
                this.max = parseFloat(this.processingFeeData.withdrawl_max);
                this.newMethod();
            }
        });
    }

    private newMethod() {
        this.withdrawform.patchValue({
            // amount: parseFloat(info.giftCardsValue),
            name: (this.respinfo.user.first_name) + ' ' + (this.respinfo.user.last_name),
            address: this.respinfo.user.address != "0" ? this.respinfo.user.address : "",
            city: this.respinfo.user.city != "0" ? this.respinfo.user.city : "",
            state: this.respinfo.user.state != "0" ? this.respinfo.user.state : "",
            zipcode: this.respinfo.user.zipcode != "0" ? this.respinfo.user.zipcode : ""
        });
    }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data" : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
         return JSON.stringify(inputdata);
    }

    getFeatures(){
        this.cardService.acceptBlockFeatures().subscribe(response => {
            let body = (<any>response)
            if ((<any>body).success == true) {
                this.silaACH = JSON.parse(body.features.other_features);
            }
        });
    }
}

