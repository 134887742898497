import { Component, OnInit } from '@angular/core';
declare const TradingView: any;
@Component({
  selector: 'app-ada',
  templateUrl: './ada.component.html',
  styleUrls: ['./ada.component.css']
})
export class AdaComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    // tslint:disable-next-line: no-unused-expression
  let  graphData = {
        'autosize': true,
        'symbol': 'COINBASE:ADAUSD',
        'timezone': 'America/Los_Angeles',
        'theme': 'Light',
        'style': '9',
        'locale': 'en',
        'toolbar_bg': '#f1f3f6',
        'enable_publishing': false,
        'withdateranges': true,
        'range': '1d',
        'hide_side_toolbar': false,
        'save_image': false,
        'container_id': 'tradingview_f258a'
      }
      if(sessionStorage.getItem("countryName") === "IND" ){
        graphData['symbol'] = "BITFINEX:ADAINR";
        graphData['timezone'] = "India/Asia_Kolkata";
      }
  
      new TradingView.widget(graphData);
  }

}
