//abhay@keyutech.com:04/03/22 - commented the console only

import { environment } from './../../../../environments/environment';
import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    AfterViewInit,
    AfterContentInit
} from "@angular/core";
import * as shape from "d3-shape";
import * as d3 from "d3";
import { AuthguardService } from "../../../services/authguard.service";
import { GiftcardService } from "../../../services/giftcard.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { CryptoService } from "../../../services/crypto.service";
import * as Highstock from "highcharts/highstock";
import Swal from "sweetalert2";
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { NgForm } from "@angular/forms";
import { StatesService } from 'src/app/services/states.service';

// import { SilaService } from '../../../services/sila.service';
// import { RxStompService, InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
// import { RxStompState } from '@stomp/rx-stomp';
declare const TradingView: any;


interface StockArrayInterfaceForGraph {
    name: "";
    series: any[];
}

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
    showofferDialog: boolean = true;
    documentName;
    submitted = false;
    @ViewChild("redeemHistoryTable", { static: false }) redeemHistoryTable;
    userId: string = "";
    stocksGraphpData: any[] = [];
    seriesOptions = [];
    seriesCounter = 0;
    names = ['GOOGL', 'MSFT', 'AAPL'];
    stockArrayForGraph: StockArrayInterfaceForGraph[] = [];
    userWalletInfo: any;
    userInfo: any = null;
    token: string = "";
    stocksStatus: any;
    transactionHistory: any;
    redeemHistory: any;
    cryptoData: any = {
        btc: undefined,
        eth: undefined,
        xrp: undefined,
       // xlm: undefined,
        ltc: undefined,
        bch: undefined,
        ada: undefined,
        doge: undefined,
        dot: undefined
      };
    selected: string = "btc";
    selectedStock: string;
    cryptoChart: any;
    socketSubscription;
    userEstocks: any = undefined;
    userFeatures: any;

    id: any;
    isTransfer: boolean = false;
    isSell: boolean = false;
    isBuy: boolean = false;
    isFeatureMsg: boolean = false;
    transferValue: any;
    lastLoginUser = "";
    sellValue: any;
    buyValue: any;
    isUserTypeTeen: any;
    stockOptions: any = undefined;
    userTypeChildParent: any;
    // stocksNameShowingInGraph = ["GOOGL", "MSFT", "AAPL"];
    stocksNameShowingInGraph = [];
    isShowMarquee = false;
    showAddCardForm: boolean = false;
    showConfirmForm: boolean = false;
    showAddAccountForm: boolean = false;
    docerrormsg: any[];
    documentType: any;
    errors: any;
    document: any;
    // freeDetails = {
    //     agreeTerms: this.agreeTerms
    // };
    offerdetails: any;
    form: FormGroup;
    cryptoWallet: any;
    selectedCryptoInfo: any;
    currentPrice: any;
    confirmForm: FormGroup;
    states;
    cities;
    startdate: any = { year: 1900, month: 1, day: 1 };
    enddate: any;
    linkaccountform: FormGroup;
    userDetails: any = {
        firstname: "",
        lastname: "",
        address: "",
        email: "",
        phone: "",
        city: "",
        gender: "",
        state: "",
        zipcode: "",
        dob: "",
        ssnnumber: ""
    };
    dob: any;
    emailerrors: any;
    userkyc: any;
    isWalletLinked: false;
    accountsdata;
    showAddAccountPlaidForm: boolean;
    userFirstName;
    previouscity;
    profilestat;
    encryptedssn: any;
    other_feature = new BehaviorSubject<any>(undefined);
    offeravailable:any =[];
    currencyName:any;
    currencyValue:any;
    constructor(
        private authService: AuthguardService,
        private router: Router,
        private cardService: GiftcardService,
        private cryptoService: CryptoService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe,
        private activeRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private stateservice: StatesService,
        // private SilaService : SilaService,
        // private rxStompService: RxStompService,
        private toastr: ToastrService
    ) {
        this.currencyValue = {"currency_value":1,"currency_name":"USD"};
        this.currencyName = this.currencyValue.currency_name;
        sessionStorage.setItem("currencyValue", this.currencyValue.currency_value);
        sessionStorage.setItem("currencyName", this.currencyValue.currency_name);
        this.cardService.fromRedeemPage.subscribe(data => {
            this.isShowMarquee = data;
        });
        this.cardService.profilestatus.subscribe(data => {
            this.profilestat = data;
        });
        this.enddate = { year: (new Date()).getFullYear() - 18, month: (new Date()).getMonth() + 1, day: (new Date()).getDate() - 1 };
        this.getstateslist();
        let userData = JSON.parse(sessionStorage.getItem("userInfo"));
        let country_id = (userData.country == 0) ? 'USA' : userData.country;
        this.authService.getcurrency(country_id).subscribe( res =>{
        sessionStorage.setItem("countryName", country_id);
            let body = (<any>res);
            if (body.success == true) {
                this.currencyValue = body.respData;
                sessionStorage.setItem("currencyValue", this.currencyValue.currency_value);
                this.currencyName = this.currencyValue.currency_name;
                sessionStorage.setItem("currencyName", this.currencyValue.currency_name);
            }
        });
        this.stateservice.getcountry().subscribe(response => {
            let body = (<any>response);   
            if(body) {
              let countries = body.respData; 
              countries.forEach(element => {
               if( element.iso3 == country_id){
                   sessionStorage.setItem("countryId", element.id )
               }
             });
            }
        });    
        
        // this.rxStompService.connectionState$.subscribe(state => {
        //     // state is an Enum (Integer), RxStompState[state] is the corresponding string
        //     // console.log(RxStompState[state]+'checking state');
        //     // if (RxStompState[state] === 'CONNECTING') {
        //     //     // alert(RxStompState[state]);
        //     //     this.toastr.info('Info', 'Please wait, we are fetching live market values. Meanwhile you can use non-crypto features.', {
        //     //         closeButton: true,
        //     //         timeOut: 5000,
        //     //         progressBar: true,
        //     //         progressAnimation: 'increasing',
        //     //         positionClass: "toast-top-right",
        //     //     }
        //     //     );

        //     // }
        // });
        // this.getfreeoffer();
    }

    ngOnInit() {
        this.cryptoService.connectToStomp();
        this.form = this.formBuilder.group({
            agreeTerms: ['', Validators.requiredTrue]
        });
        this.linkaccountform = this.formBuilder.group({
            routingnumber: ["", Validators.compose([Validators.required, Validators.minLength(9)])],
            accountnumber: ["", Validators.compose([Validators.required, Validators.minLength(12)])],
            accountname: ["", Validators.compose([Validators.required])],
        });
        if (sessionStorage.length > 0) {
            this.userFirstName = sessionStorage.userInfo.first_name;
            this.userTypeChildParent = JSON.parse(sessionStorage.userInfo).type;
            if (this.userTypeChildParent == "child") {
                this.isUserTypeTeen = 0;
            } else {
                this.isUserTypeTeen = 1;
            }
            if (sessionStorage.features) {
                this.userFeatures = JSON.parse(sessionStorage.features);
                if (this.isFeatureMsg == false) {
                    this.featureMsg();
                }

                if (
                    this.transferValue !=
                    this.userFeatures.features.transfer_crypto ||
                    this.sellValue != this.userFeatures.features.sell_crypto ||
                    this.buyValue != this.userFeatures.features.buy_crypto
                ) {
                    this.isFeatureMsg = false;
                    this.isTransfer = false;
                    this.isSell = false;
                    this.isBuy = false;
                    this.featureMsg();
                }
            }   
            this.offeravailable = JSON.parse(localStorage.getItem("offers"));
            //console.log("offers", this.offeravailable);
        }
        // this.getAllStocksData();
        // call accept block user features
        // this.id = setInterval(() => {
        //     this.getFeatures();
        // }, 180000);

        // fetch last 3 transactions history from user info
        if (sessionStorage.length > 0) {
            this.authService.getuserInfo().subscribe(response => {
                let data = (<any>response);
                if (data) {
                    //  this.userInfo = data;
                    this.transactionHistory = data.lastTrans;
                    if (data.last_login_at != undefined) {
                        this.lastLoginUser = data.last_login_at;
                    }
                    this.userTypeChildParent = data.user.type;
                    if (this.userTypeChildParent == "child") {
                        this.isUserTypeTeen = 0;
                    } else {
                        this.isUserTypeTeen = 1;
                    }
                }

            });
            this.fetchDashboardMyEStocks();
            this.fetchDashboardRedeemHistory();
            if (this.isUserTypeTeen == 1) {
                this.cardService.acceptBlockFeatures().subscribe(response => {
                    //console.log(response);
                    let body = (<any>response);
                    sessionStorage.setItem("features", JSON.stringify(body));
                    this.userFeatures = body;
                    if ((<any>body).success == true) {
                        let disablesila= JSON.parse(body.features.other_features);
                        // this.other_feature.next(JSON.parse(body.features.other_features));
                        if ( disablesila.other_features['0'].title =='ACH' && disablesila.other_features['0'].status == 1 ) {
                            this.walletCheck();
                        }else{
                             this.getfreeoffer();
                        }

                    }
                    if (this.isFeatureMsg == false) {
                        this.featureMsg();
                    }
    
                    if (
                        this.transferValue !=
                        this.userFeatures.features.transfer_crypto ||
                        this.sellValue != this.userFeatures.features.sell_crypto ||
                        this.buyValue != this.userFeatures.features.buy_crypto
                    ) {
                        this.isFeatureMsg = false;
                        this.isTransfer = false;
                        this.isSell = false;
                        this.isBuy = false;
                        this.featureMsg();
                    }
                    
                    
                })
                
                // let other_feature =  JSON.parse(this.userFeatures.features.other_features);
                // // let other_feature =  JSON.parse(sessionStorage.features.other_features);
                // if ( other_feature.other_features['0'].title =='ACH' && other_feature.other_features['0'].status == 1 ) {
                    // this.walletCheck();
                // } else {
                //     this.getfreeoffer();
                // }                
                this.fetchwallet();
            }
            //get usershares
            // this.getUserShares();
        } // end if

        // fetch token
        this.authService.getToken().subscribe(token => {
            this.token = token;
        });

        // this.fetchChartData();

        // fetch crypto shares wallet

        // this.socketSubscription = this.cryptoService
        //     .getSharesWallet()
        //     .subscribe(data => {
        //         if (data != undefined) {
        //             this.cryptoData.btc = data.BTC;
        //             this.cryptoData.eth = data.ETH;
        //             this.cryptoData.xrp = data.XRP;

        //             this.calculateMyEstocks(this.userEstocks);
        //         }
        //     });

        let userData = JSON.parse(sessionStorage.getItem("userInfo"));

        if (userData.type != "child") {
            if (typeof userData.last_login_at == "string") {
                this.lastLoginUser = userData.last_login_at;
            } else if (typeof userData.last_login_at == "object") {
                this.lastLoginUser = userData.last_login_at.date;
            }
        }
    }
    fetchwallet() {
        let userData = JSON.parse(sessionStorage.getItem("userInfo"));
        this.authService
            .fetchWallet({
                user_id: this.cardService.encrypt(userData.id.toString())
            })
            .subscribe(response => {
                // wallet info fetched
            });
    }

    ngOnDestroy() {
        // clearInterval(this.cryptoCurrencyFetchLatestRecrdInterval);
        this.socketSubscription.unsubscribe();
        this.cardService.fromRedeemPage.next(false);
        if (this.id) {
            clearInterval(this.id);
        }
    }

    ngAfterViewInit() {
        if (this.cryptoData.btc) {
            this.selected = "btc";
        }
    }
    ngAfterContentInit() {
        // fetch crypto shares wallet
        this.socketSubscription = this.cryptoService
            .getSharesWallet()
            .subscribe(data => {
                if (data != undefined) {
                    this.cryptoData.btc = data.BTC;
                    this.cryptoData.eth = data.ETH;
                    this.cryptoData.xrp = data.XRP;
                    this.cryptoData.xlm = data.XLM;
                    this.cryptoData.ltc = data.LTC;
                    this.cryptoData.bch = data.BCH;
                    this.cryptoData.ada = data.ADA;
                    this.cryptoData.doge = data.DOGE;
                    this.cryptoData.dot = data.DOT;
                    this.calculateMyEstocks(this.userEstocks);
                }
            });
        // this.cryptoService.connectionStatus = this.connectionStatus$;
        this.cryptoService.getSharesWallet().subscribe(data => {
            if (data != undefined) {
                this.cryptoWallet = data;
            }
        });
    }

    get f() { return this.form.controls; }
    closeDialog() {
        this.showAddCardForm = false;
        this.showofferDialog = false;
        // show free offer
        sessionStorage.setItem("showOffer", "false");
    }
    showDialog() {
        if (sessionStorage.getItem("showOffer") == "true") {
            this.showAddCardForm = true;
        } else {
            this.showAddCardForm = false;
        }
    }

    toggleButton() {
        $(".drop-down").toggle();
        $(".drop-down1").hide();
    }

    toggleButtonsell() {
        $(".drop-down1").toggle();
        $(".drop-down").hide();
    }

    initChart(data) {
        let $this = this;
        this.cryptoChart = Highstock.stockChart("container", <any>{

            rangeSelector: {
                selected: 2
            },
            backgroundColor: '#91d7d9',
            // style: {
            //     fill :'#91d7d9!important',
            // },
            colors: [
                "rgba(248,159,54,1)",
                "rgba(98,126,234,1)",
                "rgba(3,161,220,1)"
            ],

            title: {
                text: ""
            },

            legend: {
                enabled: true,
                verticalAlign: "top",
                itemMarginTop: 32,
                symbolHeight: 16,
                symbolWidth: 16,
                alignColumn: "right",
                itemStyle: {
                    fontSize: "14px"
                }
            },

            yAxis: {
                enabled: true,
                opposite: false,
                title: {
                    text: "<b>Market Price of Currency in US dollar</b>",
                    style: {
                        color: "#7eaeff",
                        fontSize: "14px"
                    },
                    align: "middle"
                }
            },

            credits: {
                enabled: false
            },

            series: [
                <any>{
                    allowPointSelect: true,
                    enabled: true,
                    name: data[0].name,
                    visible: true,
                    data: data[0].series,
                    type: 'area',
                    tooltip: {
                        valueDecimals: 2
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, "rgba(248,159,54,1)"],
                            [1, "rgba(248,159,54,0)"]
                        ]
                    },
                    events: {
                        legendItemClick: function () {
                            if (this.name == "Bitcoin") {
                                $this.selected = "btc";
                            }
                        }
                    }
                },
                <any>{
                    allowPointSelect: true,
                    name: data[1].name,
                    data: data[1].series,
                    enabled: true,
                    visible: true,
                    type: 'area',
                    tooltip: {
                        valueDecimals: 2
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, "rgba(98,126,234,1)"],
                            [1, "rgba(98,126,234,0)"]
                        ]
                    },
                    events: {
                        legendItemClick: function () {
                            if (this.name == "Ethereum") {
                                $this.selected = "eth";
                            }
                        }
                    }
                },
                <any>{
                    allowPointSelect: true,
                    name: data[2].name,
                    data: data[2].series,
                    type: 'area',
                    enabled: true,
                    visible: true,
                    tooltip: {
                        valueDecimals: 2
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, "rgba(3,161,220,1)"],
                            [1, "rgba(3,161,220,0)"]
                        ]
                    },
                    events: {
                        legendItemClick: function () {
                            if (this.name == "Ripple") {
                                $this.selected = "xrp";
                            }
                        }
                    }
                }
            ]
        });

        this.selected = "btc";
    }
    /// stock Graph

    initChartStock(data) {
        //console.log('data', data);
        let $this = this;
        this.cryptoChart = Highstock.stockChart("container1", <any>{
            rangeSelector: {
                selected: 3
            },

            colors: [
                "rgba(248,159,54,1)",
                "rgba(98,126,234,1)",
                "rgba(3,161,220,1)"
            ],

            title: {
                text: ""
            },

            legend: {
                enabled: true,
                verticalAlign: "top",
                itemMarginTop: 20,
                symbolHeight: 16,
                symbolWidth: 16,
                alignColumn: "",
                itemStyle: {
                    fontSize: "14px"
                }
            },

            yAxis: {
                enabled: true,
                opposite: false,
                title: {
                    text: "<b>Market Price of Currency in US dollar</b>",
                    style: {
                        color: "#7eaeff",
                        fontSize: "14px"
                    },
                    align: "middle"
                }
            },

            credits: {
                enabled: false
            },
            series: [
                <any>{
                    allowPointSelect: true,
                    name: data[0].stock_name,
                    data: data[0].stock,
                    enabled: true,
                    visible: true,
                    type: "area",
                    tooltip: {
                        valueDecimals: 2
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, "rgba(248,159,54,1)"],
                            [1, "rgba(248,159,54,0)"]
                        ]
                    },

                },
                <any>{
                    allowPointSelect: true,
                    name: data[1].stock_name,
                    data: data[1].stock,
                    enabled: true,
                    visible: true,
                    type: "area",
                    tooltip: {
                        valueDecimals: 2
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, "rgba(248,159,54,1)"],
                            [1, "rgba(248,159,54,0)"]
                        ]
                    },

                },
                <any>{
                    allowPointSelect: true,
                    name: data[2].stock_name,
                    data: data[2].stock,
                    enabled: true,
                    visible: true,
                    type: "area",
                    tooltip: {
                        valueDecimals: 2
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, "rgba(248,159,54,1)"],
                            [1, "rgba(248,159,54,0)"]
                        ]
                    },

                },
            ]
        });

        this.selectedStock = 'googl';
    }

    fetchDashboardMyEStocks() {
        this.userId = sessionStorage.userId;
        let token = {
            user_id: this.cardService.encrypt(this.userId)
        };
        let strtoken = JSON.stringify(token);
        // token encrypting here
        let Tokenenc = this.encryptmethod(strtoken, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.fetchDashboardMyEStocks(Tokenenc).subscribe(response => {
            let data = (<any>response)
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let resp = JSON.parse(decdata);
            if ((<any>resp).success == true) {
                let responseData = resp;
                this.userEstocks = responseData.data;
            } else {
                this.userEstocks = [];
            }
        });
    }
    fetchDashboardRedeemHistory() {
        this.userId = sessionStorage.userId;
        let token = {
            user_id: this.cardService.encrypt(this.userId)
        };
        let strtoken = JSON.stringify(token);
        // token encrypting here
        let Tokenenc = this.encryptmethod(strtoken, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.fetchDashboardRedeemHistory(Tokenenc).subscribe(response => {
            let data = (<any>response)
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let resp = JSON.parse(decdata);
            if ((<any>resp).success == true) {
                let responseData = resp;
                let data = responseData.list;
                let cardsdata = [];
                if (Array.isArray(data)) {
                    data.forEach((card, index) => {
                        let newCard = {};
                        let code =
                            card.redeem_code.length == 12
                                ? card.redeem_code.slice(3, 6) +
                                "-" +
                                card.redeem_code.slice(6, 9) +
                                "-" +
                                card.redeem_code.slice(9, 12)
                                : card.redeem_code.length == 12
                                    ? card.redeem_code.slice(0, 3) +
                                    "-" +
                                    card.redeem_code.slice(3, 6) +
                                    "-" +
                                    card.redeem_code.slice(6, 9)
                                    : "xxx-xxx-xxx";
                        newCard = {
                            ...card,
                            index: index,
                            code: code
                        };
                        cardsdata.push(newCard);
                    });
                    this.redeemHistory = cardsdata.sort(function (a, b) {
                        return a.index - b.index;
                    });
                }
            } else {
                this.redeemHistory = [];
            }
        });
    }
    calculateMyEstocks(stocks) {

        this.stocksStatus = [];
        if (stocks && Array.isArray(stocks)) {
            stocks.forEach(data => {
                let share = {};
                let amount = 0;
                let purchaseValue = 0;
                let btcValue = 0;
                let ethValue = 0;
                let xrpValue = 0;
                let xlmValue = 0;
                let bchValue = 0;
                let ltcValue = 0;
                let adaValue = 0;
                let dogeValue = 0;
                let dotValue = 0;
                let totalAmount = 0;
                let profitValue = 0;
                let lossValue = 0;
                let profit = 0;
                let loss = 0;
                let gain = 0;
                switch (data.cryptoCurrency) {
                    case "BTC":
                        if (this.cryptoData && this.cryptoData.btc) {
                            purchaseValue = data.purchaseMarketValue;
                            btcValue = this.cryptoData.btc.bid;
                            totalAmount = data.quantity;
                            amount = totalAmount * btcValue;
                            if (btcValue > purchaseValue) {
                                profitValue = btcValue - purchaseValue;
                                // profit = (profitValue * 100) / purchaseValue;
                                profit = profitValue * totalAmount;
                                gain = 1;
                            } else if (btcValue < purchaseValue) {
                                lossValue = purchaseValue - btcValue;
                                // loss = (lossValue * 100) / btcValue;
                                loss = lossValue * totalAmount;
                                gain = -1;
                            }
                        }
                        break;
                    case "ETH":
                        if (this.cryptoData && this.cryptoData.eth) {
                            purchaseValue = data.purchaseMarketValue;
                            ethValue = this.cryptoData.eth.bid;
                            totalAmount = data.quantity;
                            amount = totalAmount * ethValue;
                            if (ethValue > purchaseValue) {
                                profitValue = ethValue - purchaseValue;
                                // profit = (profitValue * 100) / purchaseValue;
                                profit = profitValue * totalAmount;
                                gain = 1;
                            } else if (ethValue < purchaseValue) {
                                lossValue = purchaseValue - ethValue;
                                // loss = (lossValue * 100) / ethValue;
                                loss = lossValue * totalAmount;
                                gain = -1;
                            }
                        }
                        break;
                    case "XRP":
                        if (this.cryptoData && this.cryptoData.xrp) {
                            purchaseValue = data.purchaseMarketValue;
                            xrpValue = this.cryptoData.xrp.bid;
                            totalAmount = data.quantity;
                            amount = totalAmount * xrpValue;
                            if (xrpValue > purchaseValue) {
                                profitValue = xrpValue - purchaseValue;
                                // profit = (profitValue * 100) / purchaseValue;
                                profit = profitValue * totalAmount;
                                gain = 1;
                            } else if (xrpValue < purchaseValue) {
                                lossValue = purchaseValue - xrpValue;
                                // loss = (lossValue * 100) / xrpValue;
                                loss = lossValue * totalAmount;
                                gain = -1;
                            }
                        }
                        break;
                        // case "XLM":
                        //     if (this.cryptoData && this.cryptoData.xlm) {
                        //         purchaseValue = data.purchaseMarketValue;
                        //         xlmValue = this.cryptoData.xlm.bid;
                        //         totalAmount = data.quantity;
                        //         amount = totalAmount * xlmValue;
                        //         if (xlmValue > purchaseValue) {
                        //             profitValue = xlmValue - purchaseValue;
                        //             // profit = (profitValue * 100) / purchaseValue;
                        //             profit = profitValue * totalAmount;
                        //             gain = 1;
                        //         } else if (xlmValue < purchaseValue) {
                        //             lossValue = purchaseValue - xlmValue;
                        //             // loss = (lossValue * 100) / xrpValue;
                        //             loss = lossValue * totalAmount;
                        //             gain = -1;
                        //         }
                        //     }
                        //     break;
                        case "LTC":
                            if (this.cryptoData && this.cryptoData.ltc) {
                                purchaseValue = data.purchaseMarketValue;
                                ltcValue = this.cryptoData.ltc.bid;
                                totalAmount = data.quantity;
                                amount = totalAmount * ltcValue;
                                if (ltcValue > purchaseValue) {
                                    profitValue = ltcValue - purchaseValue;
                                    // profit = (profitValue * 100) / purchaseValue;
                                    profit = profitValue * totalAmount;
                                    gain = 1;
                                } else if (ltcValue < purchaseValue) {
                                    lossValue = purchaseValue - ltcValue;
                                    // loss = (lossValue * 100) / xrpValue;
                                    loss = lossValue * totalAmount;
                                    gain = -1;
                                }
                            }
                            break;
                        case "BCH":
                            if (this.cryptoData && this.cryptoData.bch) {
                                purchaseValue = data.purchaseMarketValue;
                                bchValue = this.cryptoData.bch.bid;
                                totalAmount = data.quantity;
                                amount = totalAmount * bchValue;
                                if (bchValue > purchaseValue) {
                                    profitValue = bchValue - purchaseValue;
                                    // profit = (profitValue * 100) / purchaseValue;
                                    profit = profitValue * totalAmount;
                                    gain = 1;
                                } else if (bchValue < purchaseValue) {
                                    lossValue = purchaseValue - bchValue;
                                    // loss = (lossValue * 100) / xrpValue;
                                    loss = lossValue * totalAmount;
                                    gain = -1;
                                }
                            }
                            break;
                        case "ADA":
                            if (this.cryptoData && this.cryptoData.ada) {
                                purchaseValue = data.purchaseMarketValue;
                                adaValue = this.cryptoData.ada.bid;
                                totalAmount = data.quantity;
                                amount = totalAmount * adaValue;
                                if (adaValue > purchaseValue) {
                                    profitValue = adaValue - purchaseValue;
                                    // profit = (profitValue * 100) / purchaseValue;
                                    profit = profitValue * totalAmount;
                                    gain = 1;
                                } else if (adaValue < purchaseValue) {
                                    lossValue = purchaseValue - adaValue;
                                    // loss = (lossValue * 100) / xrpValue;
                                    loss = lossValue * totalAmount;
                                    gain = -1;
                                }
                            }
                            break;
                        case "DOGE":
                            if (this.cryptoData && this.cryptoData.doge) {
                                purchaseValue = data.purchaseMarketValue;
                                dogeValue = this.cryptoData.doge.bid;
                                totalAmount = data.quantity;
                                amount = totalAmount * dogeValue;
                                if (dogeValue > purchaseValue) {
                                    profitValue = dogeValue - purchaseValue;
                                    // profit = (profitValue * 100) / purchaseValue;
                                    profit = profitValue * totalAmount;
                                    gain = 1;
                                } else if (dogeValue < purchaseValue) {
                                    lossValue = purchaseValue - dogeValue;
                                    // loss = (lossValue * 100) / xrpValue;
                                    loss = lossValue * totalAmount;
                                    gain = -1;
                                }
                            }
                            break;
                        case "DOT":
                            if (this.cryptoData && this.cryptoData.dot) {
                                purchaseValue = data.purchaseMarketValue;
                                dotValue = this.cryptoData.dot.bid;
                                totalAmount = data.quantity;
                                amount = totalAmount * dotValue;
                                if (dotValue > purchaseValue) {
                                    profitValue = dotValue - purchaseValue;
                                    // profit = (profitValue * 100) / purchaseValue;
                                    profit = profitValue * totalAmount;
                                    gain = 1;
                                } else if (dotValue < purchaseValue) {
                                    lossValue = purchaseValue - dotValue;
                                    // loss = (lossValue * 100) / xrpValue;
                                    loss = lossValue * totalAmount;
                                    gain = -1;
                                }
                            }
                            break;
            
                }
                this.stocksStatus.push({
                    profit: profit,
                    loss: loss,
                    gain: gain,
                    amount: amount,
                    cryptoCurrency: data.cryptoCurrency,
                    quantity: data.quantity,
                    purchaseMarketValue: data.purchaseMarketValue,
                    createdAt: data.createdAt
                });
            });
        }
    }

    moveToBuy() {
        //console.log(this.profilestat);
        // if(this.profilestat!== 1){
        //     this.toastr.warning('', 'We are validating your SSN meanwhile please recheck the SSN provided to approve your account to be eligible for crypto trading', {
        //         closeButton: true,
        //         timeOut: 3000,
        //         progressBar: true,
        //         progressAnimation: 'increasing',
        //         positionClass: "toast-top-right",
        //     });
        //     return false;
        // } 
        // if (this.userFeatures.features.buy_crypto !== "1" && this.userFeatures.features.buy_crypto! == "0") {
        //     Swal({
        //         type: "warning",
        //         title: "Buy Crypto",
        //         text: this.userFeatures.features
        //             .buy_stock_crypto_feature_message,
        //             allowOutsideClick: false,
        //         customClass: "swal-custom",
        //         confirmButtonColor: "#053a6a"
        //     });
        //     return false;
        // } 
         if(this.offeravailable.length !== 0) {
            sessionStorage.setItem("requestType",  "redeembuy");
            this.router.navigate(["/dashboard/buy"]);
        }
         else {
            sessionStorage.setItem("requestType",  "");
            this.router.navigate(["/dashboard/buy"]);
        }
    }

    moveToBuyStocks() {
        if (this.userFeatures.features.buy_stock != "1") {
            Swal({
                type: "warning",
                title: "Buy Stock",
                text: this.userFeatures.features
                    .buy_stock_crypto_feature_message,
                    allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            });
            return false;
        }else {
            this.router.navigate(["/dashboard/buy-stocks"]);
        }
    }

    moveToSell() {
        //console.log(this.profilestat);
    //     if(this.profilestat!== 1){
    //     this.toastr.warning('', 'We are validating your SSN meanwhile please recheck the SSN provided to approve your account to be eligible for crypto trading', {
    //         closeButton: true,
    //         timeOut: 3000,
    //         progressBar: true,
    //         progressAnimation: 'increasing',
    //         positionClass: "toast-top-right",
    //     });
    //     return false;
    // }  
    //  if (this.userFeatures.features.sell_crypto != "1") {
    //     Swal({
    //         type: "warning",
    //         title: "Sell Crypto",
    //         text: this.userFeatures.features
    //             .buy_stock_crypto_feature_message,
    //             allowOutsideClick: false,
    //         customClass: "swal-custom",
    //         confirmButtonColor: "#053a6a"
    //     });
    //     return false;
    // } else {
            this.router.navigate(["/dashboard/sell"]);
        // }
    }

    moveToSellStocks() {
        if (this.userFeatures.features.sell_stock != "1") {
            Swal({
                type: "warning",
                title: "Sell Stock",
                text: this.userFeatures.features
                    .sell_stock_crypto_feature_message,
                    allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            });
            return false;
        } else {
            this.router.navigate(["/dashboard/sell-stocks"]);
        }
    }

    // get stock price
    getStockFee(selected, index) {

        this.authService.getStocksPrice(selected).subscribe(response => {
            let body = (<any>response)

            let dateObjectArray = Object.keys(body["Time Series (Daily)"]);
            let selectedStocksArray = [];
            for (let j = 0; j < dateObjectArray.length; j++) {
                selectedStocksArray.push([
                    new Date(
                        dateObjectArray[j].split("-")[1] +
                        "/" +
                        dateObjectArray[j].split("-")[2] +
                        "/" +
                        dateObjectArray[j].split("-")[0]
                    ).getTime(),
                    parseFloat(
                        body["Time Series (Daily)"][dateObjectArray[j]][
                        "4. close"
                        ]
                    )
                ]);
            }

            this.stockArrayForGraph.push({
                name: selected,
                series: selectedStocksArray
            });
            var stockChartArray = [];

            for (let i = 0; i < this.stockArrayForGraph.length; i++) {
                var stockChartObj = {
                    allowPointSelect: true,
                    enabled: true,
                    name: this.stockArrayForGraph[i].name,
                    visible: true,
                    data: this.stockArrayForGraph[i].series,
                    type: "area",
                    tooltip: {
                        valueDecimals: 2
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, "rgba(248,159,54,1)"],
                            [1, "rgba(248,159,54,0)"]
                        ]
                    },
                    events: {
                        legendItemClick: function () {
                            // if (this.name == this.stockArrayForGraph[i].name) {
                            //     this.selectedStock = this.stockArrayForGraph[
                            //         i
                            //     ].name;
                            // }
                            this.selectedStock = this.name;
                        }
                    }
                };
                stockChartArray.push(stockChartObj);
            }



            if (this.stocksNameShowingInGraph.length - 1 == index) {
                setTimeout(() => {

                    // this.initChartStock(stockChartArray);

                }, 1000);
            }
        });
    }

    // fetchChartData() {
    //     // fetch chartdata
    //     this.authService.fetchChartDataRange().subscribe(response => {
    //         this.initChart(response);
    //     });
    // }

    // get  features active or block
    // getFeatures() {
    //     this.cardService.acceptBlockFeatures().subscribe(response => {
    //         let body = (<any>response)

    //         if ((<any>body).success == true) {
    //             this.userFeatures = body;
    //             //  console.log(this.userFeatures);

    //             if (this.userFeatures.features.transfer_crypto != "1") {
    //                 Swal({
    //                     type: "warning",
    //                     title: "Transfer",
    //                     text: this.userFeatures.features
    //                         .transfer_stock_crypto_feature_message,
    //                     customClass: "swal-custom",
    //                     confirmButtonColor: "#053a6a"
    //                 });
    //                 return false;
    //             }

    //             if (this.userFeatures.features.buy_crypto != "1") {
    //                 Swal({
    //                     type: "warning",
    //                     title: "Credit Card",
    //                     text: this.userFeatures.features
    //                         .buy_stock_crypto_feature_message,
    //                     customClass: "swal-custom",
    //                     confirmButtonColor: "#053a6a"
    //                 });
    //                 return false;
    //             }

    //             if (this.userFeatures.features.sell_crypto != "1") {
    //                 Swal({
    //                     type: "warning",
    //                     title: "Paypal",
    //                     text: this.userFeatures.features
    //                         .sell_stock_crypto_feature_message,
    //                     customClass: "swal-custom",
    //                     confirmButtonColor: "#053a6a"
    //                 });
    //                 return false;
    //             }
    //         }
    //     });
    // }

    // function for feature msg
    featureMsg() {
        this.transferValue = this.userFeatures.features.transfer_crypto;
        this.buyValue = this.userFeatures.features.buy_crypto;
        this.sellValue = this.userFeatures.features.sell_crypto;
        // Umcomment the below section when stock feature is completed.
        /*if (
            this.userFeatures.features.transfer_crypto != "1" &&
            this.isTransfer == false
        ) {
            Swal({
                type: "warning",
                title: "Transfer",
                text: this.userFeatures.features
                    .transfer_stock_crypto_feature_message,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            }).then(result => {
                if (result.value == true) {
                    this.isTransfer = true;
                    this.featureMsg();
                }
            });
        }*/
        /*if (
            this.userFeatures.features.buy_crypto != "1" &&
            this.isBuy == false
        ) {
            Swal({
                type: "warning",
                title: "Buy",
                text: this.userFeatures.features
                    .buy_stock_crypto_feature_message,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            }).then(result => {
                if (result.value == true) {
                    this.isBuy = true;
                    this.featureMsg();
                }
            });
        }*/
        /*if (
            this.userFeatures.features.sell_crypto != "1" &&
            this.isSell == false
        ) {
            Swal({
                type: "warning",
                title: "Sell",
                text: this.userFeatures.features
                    .sell_stock_crypto_feature_message,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            }).then(result => {
                if (result.value == true) {
                    this.isSell = true;
                    this.featureMsg();
                }
            });
        }
        */
        this.isFeatureMsg = true;
    }

    // get  features active or block

    getFeatures() {
        this.cardService.acceptBlockFeatures().subscribe(response => {
            let body = (<any>response)

            if ((<any>body).success == true) {
                // update user feature
                sessionStorage.setItem("features", JSON.stringify(body));

                this.userFeatures = body;

                if (this.isFeatureMsg == false) {
                    this.featureMsg();
                }

                if (
                    this.transferValue !=
                    this.userFeatures.features.transfer_crypto ||
                    this.sellValue != this.userFeatures.features.sell_crypto ||
                    this.buyValue != this.userFeatures.features.buy_crypto
                ) {
                    this.isFeatureMsg = false;
                    this.isTransfer = false;
                    this.isSell = false;
                    this.isBuy = false;
                    this.featureMsg();
                }
            }
        });
    }
    uploadDocument($event) {
        this.docerrormsg = [];
        let files = $event.target.files;
        if (files.length > 0) {
            if (
                files[0] &&
                (
                    files[0].name.indexOf(".jpg") > -1 ||
                    files[0].name.indexOf(".jpeg") > -1 ||
                    files[0].name.indexOf(".JPG") > -1 ||
                    files[0].name.indexOf(".JPEG") > -1)
            ) {
                let names = [];
                Array.prototype.forEach.call(files, function (file) {
                    names.push(file.name);
                });
                this.documentName = names.join(", ");
                this.documentType = files[0].type.split("/")[1];
                if (this.errors && this.errors["document"]) {
                    this.errors["document"] = null;
                }
                var reader = new FileReader();
                reader.readAsDataURL(files[0]);
                let self = this;
                reader.onload = function () {
                    self.document = reader.result;
                };
            } else {
                Swal({
                    title:
                        "Please upload only image(JPEG) File",
                    type: "warning",
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonText: "OK"
                });
            }
        }
    }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if (environment.requestEnv == '') {
            let encnoobj = this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = { "enc_data": encnoobj };
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
    decryptmethod(inputdata, key) {
        if (environment.requestEnv == '') {
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
        return JSON.stringify(inputdata);
    }
    gotoRedeem(val) {
        if (val == 'true') {
            this.router.navigate(["/redeem?code=792-076-851"]);
        }
    }


    // }

    // fetch user shares of stocks
    // getUserShares() {

    //       this.names.forEach((card, index) => {

    //         var body = {};
    //           if(index == 0){
    //               body ={
    //                   stock_name: 'GOOGL'
    //               }
    //           }else if(index == 1){
    //              body ={
    //                 stock_name: 'MSFT'
    //             }
    //           }else if(index == 2){
    //              body ={
    //                 stock_name: 'AAPL'
    //             }
    //           }
    //           this.authService.getStockGraphData(body).subscribe(response => {
    //               let body = (<any>response)
    //               if(body.stock_name == 'GOOGL'){
    //               this.stocksGraphpData.push(body);
    //               }else if(body.stock_name == 'MSFT'){
    //               this.stocksGraphpData.push(body);
    //               }else if(body.stock_name == 'AAPL'){
    //               this.stocksGraphpData.push(body);
    //               }
    //               this.initChartStock(this.stocksGraphpData);
    //           })
    //       })



    //     // this.authService.getNewStocksPriceApi()

    //     let body = {
    //         user_id: this.cardService.encrypt(this.userId.toString())
    //     };
    //     this.authService.getUserStockShares(body).subscribe(response => {
    //         let body = (<any>response)
    //         if ((<any>body).success == true) {
    //             this.userWalletInfo = body.stocks;
    //             let userStocks = [];
    //             for (let i = 0; i < this.userWalletInfo.length; i++) {
    //                 userStocks.push(this.userWalletInfo[i].stock);
    //             }
    //             this.stocksNameShowingInGraph = userStocks;
    //             this.selectedStock = this.stocksNameShowingInGraph[0];


    //             for (let i = 0; i < this.stocksNameShowingInGraph.length; i++) {
    //                 this.getStockFee(this.stocksNameShowingInGraph[i], i);
    //             }

    //         }
    //     });
    // }

    // get all stocks data
    // getAllStocksData() {
    //     this.cardService.getStockData().subscribe(response => {
    //         if ((<any>response).status == 200) {
    //             let stocksResponse = (<any>response)
    //             this.stockOptions = stocksResponse.stock;
    //         }
    //     });
    // }
    redeem() {
        //console.log(this.form);
        if (this.cryptoWallet && this.cryptoWallet[this.offerdetails?.offer_currency]) {
            this.selectedCryptoInfo = this.cryptoWallet[this.offerdetails?.offer_currency];
            this.currentPrice = this.selectedCryptoInfo.ask;
            let shares = this.offerdetails?.value / this.currentPrice;
            if (shares > 0) {
                this.buyCryptoCurrencyfreebie(shares);
            }
        } else{
            Swal({
                type: "warning",
                title: "warning",
                text: "Your profile in pending",
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            });
        }
    }
    buyCryptoCurrencyfreebie(shares) {
        let userData = JSON.parse(sessionStorage.getItem("userInfo"));
        let data = {
            user_id: userData.id,
            type: "Purchased",
            crypto_currency: this.offerdetails?.offer_currency,
            amount: parseInt(this.offerdetails?.value),
            transaction_fee: 0,
            shares_quantity: Math.round(shares * 1000) / 1000,
            currentPrice: Math.round(this.currentPrice* 100) / 100,
            request_type: 'redeembuy',
            document: this.document
        };
        if (data) {
            this.cardService.buyCryptoCurrencyfreebie(data).subscribe(
                res => {
                    let resp = (<any>res)
                    if ((<any>resp).success == true) {
                        this.showAddCardForm = false;
                        this.showofferDialog = false;
                        sessionStorage.setItem("showOffer", "false");
                        Swal({
                            type: "success",
                            title: resp.msg,
                            allowOutsideClick: false,
                            customClass: "swal-custom",
                            confirmButtonColor: "#053a6a"
                        });
                        this.authService
                            .fetchWallet({
                                user_id: this.cardService.encrypt(userData.id.toString())
                            })
                            .subscribe(response => {
                                // wallet info fetched
                            });
                        this.authService.getuserInfo().subscribe(response => {
                            let data = (<any>response);
                            //console.log(data);
                            if (data) {
                                this.transactionHistory = data.lastTrans;
                                this.lastLoginUser = data.last_login_at;
                                //console.log("lastLoginUser", this.lastLoginUser);
                                this.userTypeChildParent = data.user.type;
                                if (this.userTypeChildParent == "child") {
                                    this.isUserTypeTeen = 0;
                                } else {
                                    this.isUserTypeTeen = 1;
                                }
                                this.fetchDashboardMyEStocks();
                                this.calculateMyEstocks(this.userEstocks);
                            }
                            this.calculateMyEstocks(this.userEstocks);
                        });
                    } else if ((<any>resp).success == false) {
                        Swal({
                            type: "error",
                            title: "",
                            text: resp.msg,
                            allowOutsideClick: false,
                        })
                    }
                    else {
                        Swal({
                            type: "error",
                            title: "Something went wrong",
                            allowOutsideClick: false,
                        })
                    }
                }
            )
        }
    }
    walletCheck() {
        this.userId = sessionStorage.userId;
        let token = {
            user_id: this.userId
        };
        let strtoken = JSON.stringify(token);
        // token encrypting here
        let Tokenenc = this.encryptmethod(strtoken, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.cardService.fetchWalletLinkingStatus(token).subscribe(response => {
            let data = (<any>response);
            //console.log(response);
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let resp = JSON.parse(decdata);
            if ((<any>resp).success == true && resp.data.status == "does_not_exist") {
                this.fetchUserInfo();
                let responseData = resp;
                Swal({
                    type: 'warning',
                    title: `Please validate user details to setup Crypto account and Wallet. `,
                    customClass: 'swal-custom',
                    confirmButtonColor: '#053a6a',
                    confirmButtonText: "Proceed",
                    allowOutsideClick: false,
                }).then(result => {
                    if (result.value == true) {
                        this.showConfirmForm = true;
                    }
                    else {
                        this.showConfirmForm = false;
                        this.cardService.offerData.subscribe(data => {
                            if (data?.value) {
                                this.offerdetails = data;
                                //console.log(this.offerdetails);
                                this.showDialog();
                            } else {
                                this.showAddCardForm = false;
                            }
                        });

                    }
                });
            } else if (resp.success = true && resp.data.kyc_status == 'P') {
                let token1 = {
                    user_id: this.userId
                };
                this.cardService.getkycstatus(token1).subscribe(res => {
                    let responsedata = (<any>res);
                    let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                    let resp = JSON.parse(decdata);
                    let response = resp;
                    // if (response.success == false && response.data.status == 'P' && response.data.msg) {
                    //     Swal({
                    //         type: 'warning',
                    //         title: response.data.msg,
                    //         customClass: 'swal-custom',
                    //         showConfirmButton: true,
                    //         allowOutsideClick:false,
                    //         confirmButtonColor: '#053a6a',
                    //     })
                    //     return false;
                    // }
                    if (response.success == false && response.data.status == 'P') {
                        this.userkyc = response.data.status;
                        Swal({
                            type: 'warning',
                            title: 'Your KYC verification under process please wait',
                            customClass: 'swal-custom',
                            showConfirmButton: true,
                            allowOutsideClick:false,
                            confirmButtonColor: '#053a6a',
                        }).then(willDelete => {
                            if (willDelete.value === true) {
                                this.getkyc();
                                if (this.userkyc == 'P') {
                                    setTimeout(function () {
                                        this.getkyc();
                                    }, 5000);
                                }
                                if (this.userkyc == 'S') {
                                    Swal({
                                        type: 'success',
                                        title: 'User Kyc is Successfly Completed',
                                        allowOutsideClick: false,
                                        customClass: 'swal-custom',
                                        showConfirmButton: false,
                                        confirmButtonColor: '#053a6a',
                                    });

                                    this.cardService.offerData.subscribe(data => {
                                        if (data?.value) {
                                            this.offerdetails = data;
                                            this.showDialog();
                                        } else {
                                            this.showAddCardForm = false;
                                        }
                                    });
                                }
                            }
                        });
                    }
                })
            } else {
                this.showConfirmForm = false;
                this.cardService.offerData.subscribe(data => {
                    if (data?.value) {
                        this.offerdetails = data;
                        this.showDialog();
                    } else {
                        this.showAddCardForm = false;
                    }
                });

            }
        });
    }
    linkaccount() {
        if (this.linkaccountform.valid) {
            let data = {
                accountName: this.linkaccountform.value.accountname,
                accountNumber: this.linkaccountform.value.accountnumber,
                routingNumber: this.linkaccountform.value.routingnumber,
                user_id: sessionStorage.userId
            };
            this.cardService.linkbankaccount(data).subscribe(resp => {
                let token1 = {
                    user_id: this.userId
                };
                //console.log(resp);
                let dataobtained = (<any>resp)
                if (dataobtained.success == true) {
                    Swal({
                        type: "success",
                        title: "Account Linked successfully",
                        text: 'Account Linked successfully',
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                    this.showAddAccountForm = false;
                    this.getkyc();
                    setTimeout(function () {
                        this.getkyc();
                    }, 5000);
                    this.cardService.offerData.subscribe(data => {
                        if (data?.value) {
                            this.offerdetails = data;
                            this.showDialog();
                        } else {
                            this.showAddCardForm = false;
                        }
                    });
                } else if (dataobtained.success == false) {
                    Swal({
                        type: "error",
                        title: "${dataobtained.msg}",
                        allowOutsideClick: false,
                    })
                }
                // check offer data 
            });
        }
    }
    numberOnly(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    getstateslist() {
       //  this.stateservice.getstattes().subscribe(response => {
       //     let body = (<any>response);
       //     if (body) {
       //         this.states = body.respData;
       //     }
       // });
    }
    changeState() {
        let form = this.userDetails;
        if (form.state != "") {
            this.userDetails.city = '';
            let option = this.states.find(
                state => state.state_code == form.state
            );

            if (option.id) {
                this.stateservice.getcities(option.id).subscribe(response => {
                    let body = (<any>response);
                    if (body) {
                        this.cities = body.respData;
                    }
                });
            }

        }
    }
    validateForm(form) {
        let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let formData = form.value;
        let valid = true;
        this.errors = null;

        let error = {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            state: "",
            zipcode: "",
            profileImage: "",
            document: "",
            dob: "",
            gender: "",
            ssnnumber: ""
        };
        if (formData.firstname.length < 1) {
            error.firstname = "Please enter your firstname.";
            valid = false;
        }
        if (formData.lastname.length < 1) {
            error.lastname = "Please enter your lastname.";
            valid = false;
        }
        if (formData.address.length < 1) {
            error.address = "Please enter your address.";
            valid = false;
        }
        if (formData.city.length < 1) {
            error.city = "Please enter your city.";
            valid = false;
        }
        if (formData.state.length < 1) {
            error.state = "Please select your state.";
            valid = false;
        }
        if (formData.zipcode.length < 1) {
            error.zipcode = "Please enter your zipcode.";
            valid = false;
        }
        if (formData.ssnnumber.length < 9) {
            error.ssnnumber = "Please enter valid ssn number";
            valid = false;
        }
        if (formData.email.length < 3 || formData.email.length > 0) {
            if (!emailReg.test(formData.email)) {
                error.email = "Please enter your valid email id.";
                valid = false;
            }
        }
        if (formData.email.length < 1) {
            error.email = "Please enter your email id.";
            valid = false;
        }
        if (formData.phone.length < 10) {
            error.phone = "Please enter your phone number.";
            valid = false;
        }
        if (!this.dob.year || this.dob == null || this.dob == "") {
            error.dob = "Please enter your date of birth.";
            valid = false;
        }
        if (valid) {
            this.updateandcreateentity();
        } else {
            this.errors = error;
            Swal({
                type: "error",
                title: "Error",
                text: "Please fill all * required fields",
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            });
        }
    }
    updateandcreateentity() {
        let date = "";

        if (this.userDetails.dob && this.userDetails.dob != "") {
            let month =
                this.userDetails.dob.month < 10 ? "0" + this.userDetails.dob.month : this.userDetails.dob.month;
            let day = this.userDetails.dob.day < 10 ? "0" + this.userDetails.dob.day : this.userDetails.dob.day;
            date = this.userDetails.dob.year + "-" + month + "-" + day;
        }
        const randomnum = (Math.floor(Math.random() * 9) +1).toString();
        this.encryptedssn = this.cardService.encrypt((randomnum.concat(this.userDetails.ssnnumber)));
        let token = {
            user_id: this.userId,
            first_name: this.userDetails.firstname,
            last_name: this.userDetails.lastname,
            email: this.userDetails.email,
            phone: this.userDetails.phone,
            address: this.userDetails.address,
            gender: this.userDetails.gender,
            dob: date,
            city: this.userDetails.city,
            state: this.userDetails.state,
            zipcode: this.userDetails.zipcode,
            ssnnumber: this.encryptedssn,
            idType: 'SSN',
            sardineSessionKey:localStorage.getItem('sardineSessionKey')
        };
        let token1 = {
            user_id: this.userId
        };
        //data encrypt
        let strdata = JSON.stringify(token);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService
            .updateSettingsdata(dataenc, this.userId)
            .subscribe(response => {
                //response decrypt
                let dataobtained = (<any>response)
                let decdata = this.decryptmethod(dataobtained, this.global.publicKey);
                let resp = JSON.parse(decdata);
                let data = resp;
                if (data.success == true && data.msg) {
                    if (data.success == true) {
                        this.cardService.generatewallet(token1).subscribe(res => {
                            let responsedata = (<any>res);
                            let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                            let resp = JSON.parse(decdata);
                            let response = resp;
                            if (response.success == true) {
                                //console.log(response);
                                this.cardService.getkycstatus(token1).subscribe(res => {
                                    let responsedata = (<any>res);
                                    let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                                    let resp = JSON.parse(decdata);
                                    let response = resp;
                                    if (response.success == false && response.data.status == 'P') {
                                        this.userkyc = response.data.status;
                                        this.showConfirmForm = false;
                                        Swal({
                                            type: 'warning',
                                            title: 'Submitted for review',
                                            text: 'While you wait for the review to process, go ahead and Link an account',
                                            allowOutsideClick: false,
                                            customClass: 'swal-custom',
                                            showConfirmButton: true,
                                            showCancelButton: true,
                                            confirmButtonText: 'Link Account',
                                            cancelButtonText: 'Fetch Status',
                                            confirmButtonColor: '#053a6a',
                                            cancelButtonColor: '#d33'
                                        }).then(willDelete => {
                                            if (willDelete.value === true) {
                                                this.showConfirmForm = false;
                                                this.showAddAccountPlaidForm = true;
                                            } else {
                                                this.showConfirmForm = false;
                                                this.cardService.getkycstatus(token1).subscribe(
                                                    res => {
                                                        let responsedata = (<any>res);
                                                        let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                                                        let resp = JSON.parse(decdata);
                                                        let response = resp;
                                                        this.userkyc = resp.data.status;
                                                        if (this.userkyc == 'P') {
                                                            this.getkyc();
                                                            setTimeout(function () {
                                                                this.getkyc();
                                                            }, 5000);
                                                        }
                                                        if (this.userkyc == 'S') {
                                                            Swal({
                                                                type: 'success',
                                                                title: 'User Kyc is Successfly Completed',
                                                                allowOutsideClick: false,
                                                                customClass: 'swal-custom',
                                                                showConfirmButton: false,
                                                                confirmButtonColor: '#053a6a',
                                                            });
                                                            this.cardService.offerData.subscribe(data => {
                                                                if (data?.value) {
                                                                    this.offerdetails = data;
                                                                    this.showDialog();
                                                                } else {
                                                                    this.showAddCardForm = false;
                                                                }
                                                            });
                                                        }

                                                    });
                                            };
                                        });
                                    }
                                })
                            } else if (response.success == false && response.data.status == 'FAILURE') {
                                if (response.data.validation_details.identity){
                                    Swal({
                                        type: 'error',
                                        title: response.data.validation_details.identity,
                                        customClass: 'swal-custom',
                                        showConfirmButton: true,
                                        allowOutsideClick:false,
                                        confirmButtonColor: '#053a6a',
                                        cancelButtonColor: '#d33'
                                    });
                                }
                                // Swal({
                                //     type: 'warning',
                                //     title: 'Your KYC verification under process please wait',
                                //     customClass: 'swal-custom',
                                //     showConfirmButton: true,
                                //     showCancelButton: true,
                                //     confirmButtonColor: '#053a6a',
                                //     cancelButtonColor: '#d33'
                                // });
                            } else if (response.data.status == 'S') {
                                Swal({
                                    type: 'warning',
                                    title: 'Your KYC verification under process please wait',
                                    text: 'In mean while we can link your bank A/C',
                                    allowOutsideClick: false,
                                    customClass: 'swal-custom',
                                    showConfirmButton: true,
                                    showCancelButton: true,
                                    confirmButtonColor: '#053a6a',
                                    cancelButtonColor: '#d33'
                                }).then(willDelete => {
                                    if (willDelete.value === true) {
                                        this.showConfirmForm = false;
                                        this.showAddAccountForm = true;
                                    } else {
                                        this.showConfirmForm = false;
                                        this.cardService.getkycstatus(token1).subscribe(
                                            res => {
                                                let responsedata = (<any>res);
                                                let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                                                let resp = JSON.parse(decdata);
                                                let response = resp;
                                                this.getkyc();
                                                if (this.userkyc == 'P') {
                                                    setTimeout(function () {
                                                        this.getkyc();
                                                    }, 5000);
                                                }
                                                if (this.userkyc == 'S') {
                                                    Swal({
                                                        type: 'success',
                                                        title: 'User Kyc is Successfly Completed',
                                                        allowOutsideClick: false,
                                                        customClass: 'swal-custom',
                                                        showConfirmButton: false,
                                                        confirmButtonColor: '#053a6a',
                                                    });
                                                    this.cardService.offerData.subscribe(data => {
                                                        if (data?.value) {
                                                            this.offerdetails = data;
                                                            this.showDialog();
                                                        } else {
                                                            this.showAddCardForm = false;
                                                        }
                                                    });
                                                }

                                            });
                                    };
                                });
                            }


                        });
                    }
                }
                if(data.sucess == false && data.error){
                    Swal({
                        type: "error",
                        showConfirmButton: true,
                        title: 'Error!!',
                        text:data.error,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                }
            });

    }
    fetchUserInfo() {
        if (sessionStorage.length > 1) {
            this.userId = sessionStorage.userId;
            let token = {
                user_id: this.cardService.encrypt(this.userId)
            };
            let strdata = JSON.stringify(token);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.userProfileDetails(dataenc).subscribe(res => {
                let responsedata = (<any>res);
                let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                let resp = JSON.parse(decdata);
                let response = <any>resp;
                //console.log(response);
                AuthguardService.user.next(resp.user);
                if (response.success) {
                    //console.log(response.user);
                    let user = response.user;
                    this.cardService.profilestatus.next(response.user.status);
                    let userData = JSON.parse(
                        sessionStorage.getItem("userInfo")
                    );
                    if (userData) {
                        userData.show_notifications = user.show_notifications;
                        sessionStorage.setItem(
                            "userInfo",
                            JSON.stringify(userData)
                        );
                    }
                    let dobDate = {};
                    if (user.dob) {
                        let dob = user.dob.split("-");
                        dobDate = {
                            year: parseInt(dob[0]),
                            month: parseInt(dob[1]),
                            day: parseInt(dob[2])
                        };
                    }
                    this.dob = dobDate;
                    this.userDetails = {
                        firstname:
                            user.first_name != "0" ? user.first_name : "",
                        lastname: user.last_name != "0" ? user.last_name : "",
                        address: user.address != "0" ? user.address : "",
                        email: user.email != "0" ? user.email : "",
                        phone: user.phone != "0" ? user.phone.slice(-10) : "",
                        gender: user.gender != "0" ? user.gender : "",
                        state: user.state != "0" ? user.state : "",
                        city: user.city != "0" ? user.city : "",
                        zipcode: user.zipcode != "0" ? user.zipcode : "",
                        dob: { year: parseInt(user.dob.split("-")[0]), month: parseInt(user.dob.split("-")[1]), day: parseInt(user.dob.split("-")[2]) },
                        idType: 'SSN'
                    };
                    this.userDetails.ssnnumber = JSON.parse(this.cardService.decrypt(user.id_number));
                }
            });
        }
    }
    validateEmail(model, error, email) {

        let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        this.emailerrors = null;
        this.errors = null;
        if (model.value && model.value.length > 1) {
            if (this.errors && this.errors[error]) {
                this.errors[error] = null;
            }
            else if (!emailReg.test(email)) {

                this.emailerrors = "Please enter your valid email id.";
            }
            else {
                this.emailerrors = null;
            }
        }
        else if (email.length < 1) {
            this.emailerrors = "Please enter your email id.";
        }
        else {
            this.emailerrors = null;
        }

    }
    allowOnlyCharactersForAddress(input) {
        let parsedValue = this.userDetails[input].replace(
            /[`~_!#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,]/g,
            ""
        );
        this.userDetails[input] = parsedValue;
    }
    allowOnlyCharacters(input) {
        let parsedValue = this.userDetails[input].replace(
            /[`~#$^@.&%*\(\)+=\-\[\]\/\{\}|:<>?,0-9]/g,
            ""
        );
        this.userDetails[input] = parsedValue;
    }
    hideErrorMessage(model, error) {
        this.errors = null;
        if (model.value && model.value.length > 1) {
            if (this.errors && this.errors[error]) {
                this.errors[error] = null;
            }

        }
    }
    preventSemicolon(e) {
        if (e.keyCode == 222 || e.keyCode == 186) {
            let parsedValue = e.target.value.replace(/[;'"]/g, "");
            e.target.value = parsedValue;
        }
    }
    getkyc() {
        let token1 = {
            user_id: this.userId
        };
        this.cardService.getkycstatus(token1).subscribe(res => {
            let responsedata = (<any>res);
            let decdata = this.decryptmethod(responsedata, this.global.publicKey);
            let resp = JSON.parse(decdata);
            let response = resp;
            if (response.data.status == 'S') {
                Swal({
                    type: "success",
                    showConfirmButton: true,
                    title: 'Congratulations!!',
                    text:'Your account setup completed.',
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#053a6a"
                });
            }
            if (response.success == false && response.data.status == 'P') {
                Swal({
                    type: 'warning',
                    title: 'Your KYC verification is pending please click on fetch status',
                    allowOutsideClick: false,
                    customClass: 'swal-custom',
                    showConfirmButton: true,
                    showCancelButton:true,
                    cancelButtonText:'Cancel',
                    confirmButtonColor: '#053a6a',
                    confirmButtonText: 'Fetch Status'
                }).then(result => {
                    if (result.value == true && this.userkyc !== 'S') {
                        this.getkyc();
                    }
                });
            }
        })
    }
    // closeBuySellDialog(){
    //     this.showBuyForm = false;
    // }
    onPlaidSuccess(event) {
        //console.log(event);
        // Send the public token to your server so you can do the token exchange.
        if (event.token && event.metadata) {
            this.accountsdata = {
                user_id :sessionStorage.userId,
                accountName: event.metadata.account.name,
                publicToken: event.token,
                accountId:event.metadata.account.id
                // institution: event.metadata.institution
            }
            this.cardService.linkbankaccount(this.accountsdata).subscribe(resp => {
                let token1 = {
                    user_id: this.userId
                };
                //console.log(resp);
                let dataobtained = (<any>resp)
                if (dataobtained.success == true) {
                    Swal({
                        type: "success",
                        title: "Account Linked successfully",
                        text: 'Account Linked successfully',
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                    this.showAddAccountPlaidForm = false;
                    this.getkyc();
                    setTimeout(function () {
                        this.getkyc();
                    }, 5000);
                    this.cardService.offerData.subscribe(data => {
                        if (data?.value) {
                            this.offerdetails = data;
                            this.showDialog();
                        } else {
                            this.showAddCardForm = false;
                        }
                    });
                } else if (dataobtained.success == false) {
                    Swal({
                        type: "error",
                        title: "${dataobtained.msg}",
                        allowOutsideClick: false,
                    })
                }
                // check offer data 
            });

        }
    }

    onPlaidExit(event) {
        // Get errors or exit reason.
        //console.log(event);
    }

    onPlaidEvent(event) {
        // Log events so you can have insight into how your users are using plaid link.
    }

    onPlaidLoad(event) {
        // Do something when the iframe loads.
    }

    onPlaidClick(event) {
        // Do something when the button is clicked.
    }
    getfreeoffer(){
        if (sessionStorage.length > 1) {
            this.userId = sessionStorage.userId;
            let token = {
                user_id: this.cardService.encrypt(this.userId)
            };
            let strdata = JSON.stringify(token);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.userProfileDetails(dataenc).subscribe(res => {
                let responsedata = (<any>res);
                let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                let resp = JSON.parse(decdata);
                let response = <any>resp;
                //console.log(response);
                AuthguardService.user.next(resp.user);
                if (response.success) {
                    //console.log(response.user);
                    let user = response.user;
                    let status = response.user.status;
                    // if(status!== 1){
                    //     localStorage.setItem("showpendingstatus","true");
                    // }
                    this.cardService.profilestatus.next(response.user.status);
                    let tempoffdata = this.cardService.offerData.subscribe(data => {
                        if (data?.value) {
                            tempoffdata = data;
                        } 
                    });
                    if(status!== 0 ){
                        this.cardService.offerData.subscribe(data => {
                            if (data?.value) {
                                this.offerdetails = data;
                                this.showDialog();
                            } else {
                                this.showAddCardForm = false;
                            }
                        });
                    }else{
                        if (JSON.parse(localStorage.getItem("showpendingstatus"))) {
                        Swal({
                            type: "warning",
                            title: "Your profile status is pending to validate Please update your profile",
                            text: "",
                            showConfirmButton: true,
                            customClass: "swal-custom",
                            confirmButtonColor: "#053a6a",
                            allowOutsideClick: false,
                        }).then(result => {
                            if (result.value == true) {
                                localStorage.setItem("showpendingstatus","false");
                            }
                        });
                        return false;
                     } 
                     return false;
                    }
                }
            });
        }
        
        
    }
    closePlaidForm(){
        this.showAddAccountPlaidForm = false;
     }
}