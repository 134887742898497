import { CommonApiService } from './../services/common-api.service';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-choose-login-account',
    templateUrl: './choose-login-account.component.html',
    styleUrls: ['./choose-login-account.component.scss']
})
export class ChooseLoginAccountComponent implements OnInit {
    fromPage: string = '';
    constructor(private router: Router, private activeRoute: ActivatedRoute, private _common: CommonApiService) {
        this.activeRoute.queryParams.subscribe(params => {
            this.fromPage = params['fromPage'];
        });
    }

    ngOnInit() {
        this._common.checkFlagAndCallCampaign('Login-Page');
    }

    openPage(page) {
        if (this.fromPage) {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    fromPage: this.fromPage,
                },
            };
            this.router.navigate([page], navigationExtras);
        } else {
            this.router.navigate([page]);
        }

    }

}
