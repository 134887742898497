import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DashboardComponent } from "./dashboard.component";

import { AuthguardService } from "../services/authguard.service";

import { ProfileSettingsComponent } from "./components/profile-settings/profile-settings.component";
import { WithdrawComponent } from "./components/withdraw/withdraw.component";
import { HomeComponent } from "./components/home/home.component";
import { CryptohistoryComponent } from "./components/cryptohistory/cryptohistory.component";
import { HistoryComponent } from "./components/history/history.component";
import { AllTransactionHistoryComponent } from "./components/alltransactionhistory/alltransactionhistory.component";
import { GifthistoryComponent } from "./components/gifthistory/gifthistory.component";
import { StockhistoryComponent } from "./components/stockhistory/stockhistory.component";
import { CertificatehistoryComponent } from "./components/certificatehistory/certificatehistory.component";
import { TransferComponent } from "./components/transfer/transfer.component";
import { BuyComponent } from "./components/buy/buy.component";
import { SellComponent } from "./components/sell/sell.component";
import { AddMoneyComponent } from "./components/add-money/add-money.component";
import { WithdrawTransferHistoryComponent } from "./components/withdraw-transfer-history/withdraw-transfer-history.component";
import { MycardsComponent } from "./components/mycards/mycards.component";
import { AddMoneyHistoryComponent } from "./components/add-money-history/add-money-history.component";
import { CreateCertificateComponent } from "./components/create-certificate/create-certificate.component";
import { SendCertificatesComponent } from "./components/send-certificates/send-certificates.component";
import { AllNotificationsComponent } from "./components/all-notifications/all-notifications.component";
import { TransferStocksComponent } from "./components/transfer-stocks/transfer-stocks.component";
import { BuyStocksComponent } from "./components/buy-stocks/buy-stocks.component";
import { SellStocksComponent } from "./components/sell-stocks/sell-stocks.component";
import { TeenProfileSettingsComponent } from "./components/teen-profile-settings/teen-profile-settings.component";
import { CustomerRelationshipComponent } from "./components/customer-relationship/customer-relationship.component";
import { CustomerDetailsComponent } from "./components/customer-details/customer-details.component";
import { StoreCardsWalletComponent } from "./components/store-cards-wallet/store-cards-wallet.component";
import { LinkAccountComponent } from "./components/link-account/link-account.component";
import { LinkAccountsComponent } from "./components/link-accounts/link-accounts.component";

const routes: Routes = [
    {
        path: "",
        component: DashboardComponent,
        canActivate: [AuthguardService],
        children: [
            {
                path: "",
                redirectTo: "home",
                pathMatch: "full"
            },
            {
                path: "home",
                component: HomeComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "teen-settings",
                component: TeenProfileSettingsComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "history",
                component: HistoryComponent,
                canActivate: [AuthguardService],
                children: [
                    {
                        path: "",
                        redirectTo: "alltransactionhistory",
                        pathMatch: "full"
                    },
                    {
                        path: "alltransactionhistory",
                        component: AllTransactionHistoryComponent,
                        canActivate: [AuthguardService]
                    },
                    {
                        path: "gifthistory",
                        component: GifthistoryComponent,
                        canActivate: [AuthguardService]
                    },
                    {
                        path: "cryptohistory",
                        component: CryptohistoryComponent,
                        canActivate: [AuthguardService]
                    },
                    {
                        path: "stockhistory",
                        component: StockhistoryComponent,
                        canActivate: [AuthguardService]
                    },
                    {
                        path: "certificatehistory",
                        component: CertificatehistoryComponent,
                        canActivate: [AuthguardService]
                    },
                ]
            },
            {
                path: "addmoneyhistory",
                component: AddMoneyHistoryComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "withdraw-transfer",
                component: WithdrawTransferHistoryComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "transfer",
                component: TransferComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "settings",
                component: ProfileSettingsComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "withdraw",
                component: WithdrawComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "buy",
                component: BuyComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "sell",
                component: SellComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "addmoney",
                component: AddMoneyComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "mycards",
                component: MycardsComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "createcertificates",
                component: CreateCertificateComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "send-certificates",
                component: SendCertificatesComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "all-notifications",
                component: AllNotificationsComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "transfer-stocks",
                component: TransferStocksComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "buy-stocks",
                component: BuyStocksComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "sell-stocks",
                component: SellStocksComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "customer-relationship",
                component: CustomerRelationshipComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "customer-details",
                component: CustomerDetailsComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "store-cards-wallet",
                component: StoreCardsWalletComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "link-account",
                component: LinkAccountComponent,
                canActivate: [AuthguardService]
            },
            {
                path: "link-accounts",
                component: LinkAccountsComponent,
                canActivate: [AuthguardService]
            },

        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {}
