//abhay@keyutech.com:04/03/22 - commented the console only at 74, 152, 156
import { environment } from './../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { AuthguardService } from "../../services/authguard.service";
import { CryptoService } from "../../services/crypto.service";
import { GiftcardService } from "../../services/giftcard.service";
import {
    trigger,
    state,
    style,
    animate,
    transition
} from "@angular/animations";
import { ApiencryptService } from '../../services/apiencrypt.service';
import { ApidecryptService } from '../../services/apidecrypt.service';
import { GlobalPipe } from '../../shared/globalpipes/global.pipe';
@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    animations: [
        trigger("slide", [
            state("in", style({ transform: "translateX(0)" })),
            transition("void => *", [
                animate(200, style({ transform: "translateX(0)" }))
            ]),
            transition("* => void", [
                animate(200, style({ transform: "translateX(-100%)" }))
            ])
        ])
    ]
})
export class NavbarComponent implements OnInit {
    @Input("toggle")
    toggle: boolean = true;
    @Input("hideSidebarToggle")
    hideSidebarToggle;
    openSubMenu: boolean = false;
    historyActive: boolean = false;
    userInfo: any = undefined;
    totalStockShares = 0;
    giftAmount: string = "0";
    cryptoShares: number;
    giftCardValue: number;
    firstName: string = "";
    lastName: string = "";
    cryptoWallet: any;
    cryptoWalletInfo: any;
    profileImg = "assets/dashboard/profile.png";
    userFeatures: any;
    userId: any;
    id: any;
    userWalletInfo: any;
    userTypeChildParent: any;
    isUserTypeTeen: any;
    @Output()
    toggleNav = new EventEmitter();
    isStaffUser: any;
    profilestat: any;
    transferdisable;
    currencyValue:any;
    currencyName:any;
    constructor(
        private router: Router,
        private authService: AuthguardService,
        private cryptoService: CryptoService,
        private cardService: GiftcardService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe
    ) {
        this.authService.getuserInfo().subscribe(data => {
            if (data && data.user) {
                this.userInfo = data.user;
                this.currencyName = data?.currency_name;
                this.currencyValue = sessionStorage.getItem("currencyValue");
                this.giftCardValue = data.giftCardsValue;
                this.cryptoWalletInfo = data.userCryptoCurrency;
            }
        });

        this.cardService.profilestatus.subscribe(data => {
            this.profilestat = data;
            if(this.profilestat !== 1){
                this.transferdisable = true;
            } else{
                this.transferdisable = false;
            }
        });
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
    }

    ngOnDestroy() {
        if (this.id) {
            clearInterval(this.id);
        }
    }

    ngOnInit() {

        if (sessionStorage.length > 0) {
            this.userTypeChildParent = JSON.parse(sessionStorage.userInfo).type;
            if (this.userTypeChildParent == "child") {
                this.isUserTypeTeen = 0;
            } else {
                this.isUserTypeTeen = 1;
            }
        }
        this.toggle = true;
        // call accept block user features
        this.getFeatures();

        this.id = setInterval(() => {
            this.getFeatures();
        }, 180000);

        // fetch session from sessionStorage
        if (sessionStorage.length > 0 && sessionStorage.isLoggedIn == true) {
            if (this.router.url.indexOf("history") > -1) {
                this.openSubMenu = true;
                this.historyActive = true;
            }
            // userinfo from session
            this.userInfo = JSON.parse(sessionStorage.userInfo);
            this.userId = sessionStorage.userId;

            if (
                this.userInfo &&
                this.userInfo.profile_pic &&
                this.userInfo.profile_pic != "0"
            ) {
                this.profileImg = this.userInfo.profile_pic;
                this.firstName = this.userInfo.first_name;
                this.lastName = this.userInfo.last_name;
            }
        }

        AuthguardService.user.subscribe(data => {
            if (data) {
                this.profileImg =
                    data.profile_pic != "" && data.profile_pic != "0"
                        ? data.profile_pic
                        : "assets/dashboard/profile.png";
                this.firstName = data.first_name;
                this.lastName = data.last_name;
                this.cardService.profilestatus.next(data.status);
            }
        });

        this.authService.getuserInfo().subscribe(data => {
             //console.log(data);
            if (data && data.user) {
                this.userInfo = data.user;
                this.giftCardValue = data.giftCardsValue;
                //console.log(this.giftCardValue)
                this.cryptoWalletInfo = data.userCryptoCurrency;
                // this.getUserShares();

                let groups = [];
                if(this.userInfo.user_groups)
                {
                    this.userInfo.user_groups.forEach(group => {
                        groups.push(group.group_name);
                    });
                    let staffData = groups.indexOf('staff');
                    if(staffData >= 0){
                        this.isStaffUser = true;
                    }
                }
                else { this.isStaffUser = false; }
            } else {
                this.giftCardValue = 0;
            }
            // calculate wallet  as per latest market prices of crypto shares
        });

        if (window.innerWidth < 1200) {
            // toggle sidebar in mobile;
            this.toggle = !this.toggle;
        }

        this.hideSidebarToggle.subscribe(data => {
            if (window.innerWidth < 1200) {
                this.toggle = false;
            } else {
                this.toggle = true;
            }

        });

        // fetch crypto shares wallet
        this.cryptoService.getSharesWallet().subscribe(data => {

            if (data != undefined && sessionStorage.length > 0) {
                this.cryptoWallet = data;
                this.calculateSharePrice();

            }
        });


        this.authService.getuserInfo().subscribe(info => {
            if (info != undefined) {
                this.userTypeChildParent = info.user.type;
                if (this.userTypeChildParent == "child") {
                    this.isUserTypeTeen = 0;
                } else {
                    this.isUserTypeTeen = 1;
                }

            }
        });

    }

    // fetch user shares of stocks
    getUserShares() {
            // userinfo from session
            this.userInfo = JSON.parse(sessionStorage.userInfo);
            this.userId = sessionStorage.userId;
        let body = {
            user_id: this.cardService.encrypt(this.userId.toString())
        };
        this.authService.getUserStockShares(body).subscribe(response => {
            // let body = JSON.parse((<any>response)._body);
            if ((<any>response).success == true) {
               this.userWalletInfo = response.stocks;

                let userStocks = [];
                for (let i = 0; i < this.userWalletInfo.length; i++) {
                    userStocks.push({
                        name: this.userWalletInfo[i].stock,
                        shares: this.userWalletInfo[i].shares
                    });
                }

                for (let j = 0; j < userStocks.length; j++) {
                    this.getStockFee(
                        userStocks[j].name,
                        parseFloat(userStocks[j].shares)
                    );
                }
            }
        });
    }
    calculateSharePrice() {
        if (
            this.cryptoWallet &&
            this.cryptoWalletInfo &&
            Array.isArray(this.cryptoWalletInfo) && sessionStorage.length > 0
        ) {
            this.cryptoShares = 0;
            let btc = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "BTC"
            );
            if (btc && this.cryptoWallet.BTC && btc.shares != "0") {
                let totalBTC =
                    parseFloat(btc.shares) *
                    parseFloat(this.cryptoWallet.BTC.bid);
                if (!isNaN(totalBTC)) {
                    this.cryptoShares += totalBTC;
                }
            }
            let xrp = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "XRP"
            );
            if (xrp && this.cryptoWallet.XRP && xrp.shares != "0") {
                let totalXRP =
                    parseFloat(xrp.shares) *
                    parseFloat(this.cryptoWallet.XRP.bid);
                if (!isNaN(totalXRP)) {
                    this.cryptoShares += totalXRP;
                }
            }
            let eth = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "ETH"
            );
            if (eth && this.cryptoWallet.ETH && eth.shares != "0") {
                let totalETH =
                    parseFloat(eth.shares) *
                    parseFloat(this.cryptoWallet.ETH.bid);
                if (!isNaN(totalETH)) {
                    this.cryptoShares += totalETH;
                }
                this.cryptoShares = parseFloat(this.cryptoShares.toFixed(2));
                this.cardService.totalcryptovalue.next(this.cryptoShares);
            }
            let xlm = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "XLM"
            );
            if (xlm && this.cryptoWallet.XLM && xlm.shares != "0") {
                let totalXLM =
                    parseFloat(xlm.shares) *
                    parseFloat(this.cryptoWallet.XLM.bid);
                if (!isNaN(totalXLM)) {
                    this.cryptoShares += totalXLM;
                }
                this.cryptoShares = parseFloat(this.cryptoShares.toFixed(2));
                this.cardService.totalcryptovalue.next(this.cryptoShares);
            }
            let bch = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "BCH"
            );
            if (bch && this.cryptoWallet.BCH && bch.shares != "0") {
                let totalBCH =
                    parseFloat(bch.shares) *
                    parseFloat(this.cryptoWallet.BCH.bid);
                if (!isNaN(totalBCH)) {
                    this.cryptoShares += totalBCH;
                }
                this.cryptoShares = parseFloat(this.cryptoShares.toFixed(2));
                this.cardService.totalcryptovalue.next(this.cryptoShares);
            }
            let ltc = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "LTC"
            );
            if (ltc && this.cryptoWallet.LTC && ltc.shares != "0") {
                let totalLTC =
                    parseFloat(ltc.shares) *
                    parseFloat(this.cryptoWallet.LTC.bid);
                if (!isNaN(totalLTC)) {
                    this.cryptoShares += totalLTC;
                }
                this.cryptoShares = parseFloat(this.cryptoShares.toFixed(2));
                this.cardService.totalcryptovalue.next(this.cryptoShares);
            }
            let ada = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "ADA"
            );
            if (ada && this.cryptoWallet.ADA && ada.shares != "0") {
                let totalADA =
                    parseFloat(ada.shares) *
                    parseFloat(this.cryptoWallet.ADA.bid);
                if (!isNaN(totalADA)) {
                    this.cryptoShares += totalADA;
                }
            }
            let doge = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "DOGE"
            );
            if (doge && this.cryptoWallet.DOGE && doge.shares != "0") {
                let totalDOGE =
                    parseFloat(doge.shares) *
                    parseFloat(this.cryptoWallet.DOGE.bid);
                if (!isNaN(totalDOGE)) {
                    this.cryptoShares += totalDOGE;
                }
            }
            let dot = this.cryptoWalletInfo.find(
                crypto => crypto.currency == "DOT"
            );
            if (dot && this.cryptoWallet.DOT && dot.shares != "0") {
                let totalDOT =
                    parseFloat(dot.shares) *
                    parseFloat(this.cryptoWallet.DOT.bid);
                if (!isNaN(totalDOT)) {
                    this.cryptoShares += totalDOT;
                }
            }
        } else{
        this.cryptoShares = 0;
            this.cardService.totalcryptovalue.next(this.cryptoShares);
        }

    }

    toggleSubMenu() {
        // toggle history sub menu in nav pannel
        this.openSubMenu = !this.openSubMenu;
    }

    hideSubMenu() {
        // hide history sub menu in nav pannel
        this.openSubMenu = false;
    }

    moveToBuy() {
        // navigate to buy page
        this.router.navigate(["/dashboard/buy"]);
    }

    toggleSidebar() {
        // toggle side bar
        this.toggle = !this.toggle;
    }

    hideSidebar() {
        // hide side bar
        if (window.innerWidth < 1200) {
            this.toggle = !this.toggle;
        }
    }

    // get Features
    getFeatures() {
        this.cardService.acceptBlockFeatures().subscribe(response => {
            // let data = JSON.parse((<any>response)._body);
            let decdata = this.decryptmethod(response, this.global.publicKey);
            let body = JSON.parse(decdata);
            if ((<any>body).success == true) {
                // update user feature
                sessionStorage.setItem("features", JSON.stringify(body));
                this.userFeatures = body;
            }
        });
    }

    // get stock price
    getStockFee(selected, shares) {

        this.authService.getStocksPrice(selected).subscribe(body => {
            // let body = JSON.parse((<any>response)._body);

            if (body["Time Series (Daily)"] && sessionStorage.length > 0) {
                let dateObjectArray;
                dateObjectArray = Object.keys(body["Time Series (Daily)"])[0];
                this.cryptoWallet =
                    body["Time Series (Daily)"][dateObjectArray];

                this.cryptoWallet.Open = this.cryptoWallet["1. open"];
                this.cryptoWallet.High = this.cryptoWallet["2. high"];
                this.cryptoWallet.Low = this.cryptoWallet["3. low"];
                this.cryptoWallet.Close = parseFloat(
                    this.cryptoWallet["4. close"]
                );
                this.cryptoWallet.Supply = this.cryptoWallet["5. volume"];

                if (isNaN(this.cryptoWallet.Close) && isNaN(shares)) {
                } else {

                    if(this.totalStockShares === 0 ) {
                        this.totalStockShares += this.cryptoWallet.Close * shares;
                    } else {
                        this.totalStockShares = this.cryptoWallet.Close * shares;
                    }
                }
            }
        });
    }
     //encrypt method
     encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data" : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
         return JSON.stringify(inputdata);
    }
} // end component navbar component
