import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rounddown'
})
export class RounddownPipe implements PipeTransform {

  transform(value: any): any {
    return (Math.floor(value * 10000) / 10000).toFixed(4);
  }

}
