import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { GiftcardService } from 'src/app/services/giftcard.service';
import { NavigationExtras, Router } from '@angular/router';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { IMyDpOptions, IMyInputFieldChanged } from 'mydatepicker';
import * as moment from 'moment';
import { ApiencryptService } from 'src/app/services/apiencrypt.service';
import { ApidecryptService } from 'src/app/services/apidecrypt.service';
import { GlobalPipe } from 'src/app/shared/globalpipes/global.pipe';
@Component({
  selector: 'app-store-cards-wallet',
  templateUrl: './store-cards-wallet.component.html',
  styleUrls: ['./store-cards-wallet.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StoreCardsWalletComponent implements OnInit {
  public myDatePickerOptions: IMyDpOptions = {
    disableUntil: {
      year: parseInt(moment().subtract(1, 'days').format('YYYY')),
      month: parseInt(moment().subtract(1, 'days').format('MM')),
      day: parseInt(moment().subtract(1, 'days').format('DD')),
    },
    dateFormat: 'mm/dd/yyyy',
    showClearDateBtn: true,
    editableDateField: true,
    openSelectorOnInputClick: true,
    indicateInvalidDate: true,
    // disableSince: {
    //     year: parseInt(moment().add(1, 'days').format('YYYY')) ,
    //     month: parseInt(moment().add(1, 'days').format('MM')),
    //     day: parseInt(moment().add(1, 'days').format('DD')),
    // }
    disableSince: { year: 0, month: 0, day: 0 }
  };

  public redeemDatePickerOptions: IMyDpOptions = {
    /*disableUntil : {
        year: parseInt(moment().subtract(1, 'days').format('YYYY')) ,
        month: parseInt(moment().subtract(1, 'days').format('MM')),
        day: parseInt(moment().subtract(1, 'days').format('DD')),
    },*/
    dateFormat: 'mm/dd/yyyy',
    showClearDateBtn: true,
    editableDateField: true,
    openSelectorOnInputClick: true,
    indicateInvalidDate: true,
    disableSince: {
      year: parseInt(moment().add(1, 'days').format('YYYY')),
      month: parseInt(moment().add(1, 'days').format('MM')),
      day: parseInt(moment().add(1, 'days').format('DD')),
    }
  };

  @ViewChild('usersTable',{static:false})usersTable;
  @ViewChild('closeModalOnClick') closeModalOnClick: ElementRef
  editMode: boolean;
  searchStoreNames: any;
  hideAutoComplete: boolean = true;
  usedcards: any;
  unUsedCards: any;
  unUsedCategoryCards: any;
  usedCategoryCards: any;
  showAddCardForm: boolean = false;
  categories: any;
  expiryDatehasError: boolean;
  redeemDatehasError: boolean;

  newCardForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', Validators.compose([
      Validators.required,
    ])),
    expiryDate: new FormControl(''),
    storeCategoryId: new FormControl(''),
    cardNumber: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(4)
    ])),
    amount: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(1)
    ])),
    description: new FormControl(''),
    pin: new FormControl(''),
  });

  showAnotherImage: boolean = false;
  cardFrontImageFileName: string;
  cardBackImageFileName: string;
  frontImage: File;
  backImage: File;
  public frontImageError: any;
  public backImageError: any;

  isLoading: boolean = true;
  showCardDialogFlag: boolean = false;
  cardData: any;
  hasCardExpiryDate: boolean = false;
  showCategriesSection: boolean = true;
  category: any;

  showRedeemSectionFlag: boolean = false;
  redeemCardForm = new FormGroup({
    redeemDate: new FormControl(''),
    redeemMessage: new FormControl(''),
  });

  oldFrontImage: any;
  oldBackImage: any;
  currencyName: any;
  currencyValue: any;
  
  constructor(
    private cardService: GiftcardService,
    private router: Router,
    private apienc: ApiencryptService,
    private apidec: ApidecryptService,
    private global: GlobalPipe
  ) { 
    this.currencyName = sessionStorage.getItem("currencyName");
    this.currencyValue = sessionStorage.getItem("currencyValue");
  }

  ngOnInit() {
    this.loadStoreCards();
  }

  loadStoreCards() {
    if (sessionStorage.getItem("userId")) {
      this.getUnUsedCategoryCards();
      this.getUsedStoreCards();
      this.getUserCategoryCards();
    }
  }

  showCategoriesSection() {
    this.showCategriesSection = true;
    this.getUnUsedCategoryCards();
  }

  preventText($event) {
    const value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, '');
    $event.target.value = value;
  }

  preventNumeric($event) {
    const value = $event.target.value.replace(/[0-9%@*^$!)(_+=#&]/g, '');
    $event.target.value = value;
  }

  hasCardExpiry(falg) {
    this.hasCardExpiryDate = falg;
  }

  getUnUsedCategoryCards() {
    this.isLoading = true;
    const user = JSON.parse(sessionStorage.userInfo);
    const data = {
      user_id: user.id,
      used: 0
    };
    let strdata = JSON.stringify(data);
    let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
    this.cardService.getCardsCategoryCount(dataenc).subscribe(response => {
      let responsedata = (<any>response)
      let decdata = this.decryptmethod(responsedata, this.global.publicKey);
      let resp = JSON.parse(decdata);
      // const body = JSON.parse((<any>decdata)._body);
      if (<any>resp) {
        this.unUsedCategoryCards = resp.cardsCategories;
        this.isLoading = false;
      }
    });
  }

  getUsedCategoryCards() {
    const user = JSON.parse(sessionStorage.userInfo);
    const data = {
      user_id: user.id,
      used: 1
    };
    let strdata = JSON.stringify(data);
    let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
    this.cardService.getCardsCategoryCount(dataenc).subscribe(response => {
      let responsedata = (<any>response)
      let decdata = this.decryptmethod(responsedata, this.global.publicKey);
      let resp = JSON.parse(decdata);
      //const body = JSON.parse((<any>decdata)._body);
      if (<any>resp) {
        this.usedCategoryCards = resp.cardsCategories;
      }
    });
  }

  getUsedStoreCards() {
    const user = JSON.parse(sessionStorage.userInfo);
    const data = {
      user_id: user.id,
      //used: 1
    };
    let strdata = JSON.stringify(data);
    let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
    this.cardService.getUsedStoreCards(dataenc).subscribe(response => {
      let responsedata = (<any>response)
      let decdata = this.decryptmethod(responsedata, this.global.publicKey);
      //const body = JSON.parse((<any>decdata)._body);
      let resp = JSON.parse(decdata);
      if (<any>resp) {
        this.usedcards = resp.cards;
      }
    });
  }

  openCategoryCards(category) {
    this.showCategriesSection = false;
    this.category = category;
    this.getUserCategoryCards();
  }

  getUserCategoryCards() {
    if (this.category) {
      const user_id = sessionStorage.getItem('userId');
      const data = { store_category_id: this.category.id, user_id: user_id, used: this.category.used };
      let strdata = JSON.stringify(data);
      let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
      this.cardService.getUserCategoryCards(dataenc).subscribe(response => {
        let responsedata = (<any>response)
        let decdata = this.decryptmethod(responsedata, this.global.publicKey);
        let resp = JSON.parse(decdata);
        const cards = resp;
        this.unUsedCards = cards;
      });
    }
  }

  showAddCardOverlay() {
    this.closeModalOnClick.nativeElement.style.display = 'block';
    this.clearCardForm();
    this.showAddCardForm = !this.showAddCardForm;
    this.editMode = false;
  }

  closeDialog() {
    this.closeModalOnClick.nativeElement.style.display = 'none';
    this.showAddCardForm = false;
    this.showAnotherImage = false;
  }

  clearCardForm() {
    this.newCardForm.reset();
    this.oldFrontImage = '';
    this.oldBackImage = '';
    this.cardFrontImageFileName = '';
    this.cardBackImageFileName = '';
    this.frontImage = null;
    this.backImage = null;
    this.frontImageError = '';
    this.backImageError = '';
  }

  selectFrontImage(event: any): void {
    if (event.target.files[0]) {
      //console.debug(event.target.files[0].size);
      const allowedExtesnions = ['image/jpeg', 'image/jpg','image/png'];
      if (allowedExtesnions.indexOf(event.target.files[0].type) == -1) {
        this.frontImageError = 'Only JPG or PNG files are allowed';
      } else if (event.target.files[0].size > 6000000) {
        this.frontImageError = 'Maximum image size is 6 MB';
      } else {
        this.frontImage = event.target.files[0];
        this.cardFrontImageFileName = this.frontImage.name;
        this.frontImageError = '';
      }
    }
  }

  selectBackImage(event: any): void {
    if (event.target.files[0]) {
      const allowedExtesnions = ['image/jpeg', 'image/jpg'];
      if (allowedExtesnions.indexOf(event.target.files[0].type) == -1) {
        this.backImageError = 'Only JPG or PNG files are allowed';;
      } else if (event.target.files[0].size > 6000000) {
        this.backImageError = 'Maximum image size is 6 MB';
      } else {
        this.backImage = event.target.files[0];
        this.cardBackImageFileName = this.frontImage.name;
        this.backImageError = '';
      }
    }
  }

  showSaveCard() {
    return this.newCardForm.valid && !this.expiryDatehasError && !this.redeemDatehasError && !this.frontImageError && !this.backImageError;
    /* && !!this.cardFrontImageFileName && !!this.cardBackImageFileName;*/
  }

  saveCard() {
    let user = JSON.parse(sessionStorage.userInfo);
    let storeCard = new FormData();
    let redeem_date;
    let redeem_message;
    let used ;
    if (this.showRedeemSectionFlag) {
      let redeemDate = '';
      if (this.redeemCardForm.value.redeemDate) {
        redeem_date = this.redeemCardForm.value.redeemDate.formatted;
      }
      redeem_date = redeemDate;
      redeem_message = this.redeemCardForm.value.redeemMessage;
      used = '1';
    } else {
      redeem_date = '';
      redeem_message = '';
      used = '0';
    }

    let front_image;
    if (this.cardFrontImageFileName) {
      console.log(this.frontImage);
      console.log(this.frontImage.name);
      front_image =  this.frontImage, this.frontImage.name;
      console.log(this.frontImage);
    } else {
      front_image = '';
    }
    let backImage;
    if (this.cardBackImageFileName) {
      backImage = this.backImage, this.backImage.name;
      console.log(backImage);
    } else {
      backImage = '';
    }
    let expiry_date;
    if (this.newCardForm.value.expiryDate && !!this.hasCardExpiryDate) {
      expiry_date =this.newCardForm.value.expiryDate.formatted;
    } else {
      expiry_date = '';
    }

      let newcarddata = {
        'redeem_message': redeem_message,
        'redeem_date' : redeem_date,
        'used' : used,
        'pin'   : (this.newCardForm.value.pin) ? this.newCardForm.value.pin : '', 
        'user_id' : user.id,
        'description' :(this.newCardForm.value.description) ? this.newCardForm.value.description : '',
        'storeName' : this.newCardForm.value.name,
        'storeCategoryId': (this.newCardForm.value.storeCategoryId) ? this.newCardForm.value.storeCategoryId : '',
        'card_number': this.newCardForm.value.cardNumber,
        'amount': this.newCardForm.value.amount,
        'oldFrontImage' : this.oldFrontImage,
        'oldBackImage' : this.oldBackImage,
        'id': (this.newCardForm.value.id) ? this.newCardForm.value.id : '',
        'frontImage': front_image,
        'backImage':backImage,
        'expiry_date':expiry_date
      };
    this.cardService.saveStoreCard(newcarddata).subscribe(response => {
        Swal({
          title: 'Success',
          text: (!!this.newCardForm.value.id) ? 'Store Card updated successfully' : 'Store Card added successfully',
          type: 'success',
          confirmButtonColor: '#053a6a',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then(_ => {
          this.loadStoreCards();
          this.closeDialog();
          window.scroll(0, 0);
        });
    });
  }

  closeCardDialog() {
    this.closeModalOnClick.nativeElement.style.display = 'none';
    this.showCardDialogFlag = false;
    this.cardData = '';
  }

  showCardDialog(card) {
    this.closeModalOnClick.nativeElement.style.display = 'block';
    this.showCardDialogFlag = true;
    this.cardData = card;
  }

  moveCardtoWallet(cardId: any) {
    let user = JSON.parse(sessionStorage.userInfo);
    let data = {
      id: cardId.toString(),
      user_id: user.id.toString(),
    };
    let strdata = JSON.stringify(data);
    let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
    this.cardService.moveCardtoWallet(dataenc).subscribe(response => {
      console.log(response);
      let responsedata = (<any>response)
      let decdata = this.decryptmethod(responsedata, this.global.publicKey);
      let body = JSON.parse(decdata);
      console.log("body",body);
      if ((<any>body).success === true) {
        Swal({
          title: 'Success',
          text:
            'Store card successfully added Store Gifts Cards Wallet',
          type: 'success',
          confirmButtonColor: '#053a6a',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then(_ => {
          this.loadStoreCards();
         });
      }
    });
  }

  deleteCard(cardId: any, event) {
    Swal({
      type: 'warning',
      title: 'Delete',
      text: 'Do you really you want delete?',
      allowOutsideClick: false,
      customClass: 'swal-custom',
      confirmButtonColor: '#053a6a',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonColor: '#053a6a',
      cancelButtonText: 'No',
    }).then(result => {
      if (result.value === true) {
        const user = JSON.parse(sessionStorage.userInfo);
        const data = {
          id: cardId.toString(),
          user_id: user.id.toString()
        };
        let strdata = JSON.stringify(data);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.cardService.deleteStoreCard(dataenc).subscribe(response => {
          let responsedata = (<any>response)
          let decdata = this.decryptmethod(responsedata, this.global.publicKey);
          let resp = JSON.parse(decdata);
          //const body = (<any>response)
          if ((<any>resp).success == true) {
            Swal({
              title: 'Success',
              text:
                'Store Card deleted Successfully',
                allowOutsideClick: false,
              type: 'success',
              confirmButtonColor: '#053a6a',
              confirmButtonText: 'OK',
            }).then(_ => { });
            this.loadStoreCards();
          }
        });
      }
    });
    event.stopPropagation();
  }

  getStoreNames(storeName: string) {
    if (!!storeName) {
      this.hideAutoComplete = false;
      // clear store Id selection
      this.newCardForm.patchValue({
        storeCategoryId: null
      });
      const data = { store: storeName }
      let strdata = JSON.stringify(data);
      let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
      this.cardService.searchStoreNames(dataenc).subscribe(response => {
        let responsedata = (<any>response)
        let decdata = this.decryptmethod(responsedata, this.global.publicKey);
        let resp = JSON.parse(decdata);
        // const body = (<any>response)
        if ((<any>resp).success == true) {
          this.searchStoreNames = resp.allCategories;
        }
      });
    }
  }

  selectStore(storeId, storeName) {
    this.newCardForm.patchValue({
      storeCategoryId: storeId,
      name: storeName
    });
    this.hideAutoComplete = true;
  }

  /* Edit Card Start */
  editCard(card, event) {
    this.cardData = card;
    this.clearCardForm();
    this.editMode = true;
    this.showRedeemSectionFlag = false;
    this.closeModalOnClick.nativeElement.style.display = 'block';
    this.showAddCardForm = !this.showAddCardForm;

    if (!!card) {
      const storeName = (card.store_category_id == 5) ? card.store_name : card.name;
      this.newCardForm.patchValue({
        id: card.id,
        name: storeName,
        storeCategoryId: card.store_category_id,
        cardNumber: card.card_number,
        pin: card.pin,
        amount: card.amount,
        description: card.description
      });

      if (card.expiry_date) {
        const date = new Date(card.expiry_date);
        this.newCardForm.patchValue({
          expiryDate: {
            formatted: moment(card.expiry_date).format('MM/DD/YYYY'),
            date: {
              year: date.getFullYear(),
              month: date.getMonth() + 1,
              day: date.getDate()
            }
          }
        });
        this.hasCardExpiryDate = true;
      } else {
        this.newCardForm.patchValue({ expiryDate: null });
        this.hasCardExpiryDate = false;
      }

      this.oldFrontImage = (card.front_image) ? card.front_image : '';
      this.oldBackImage = (card.back_image) ? card.back_image : '';
      if (card.back_image) {
        this.showAnotherImage = true;
      }
      this.getStoreNames(storeName);
      this.hideAutoComplete = true;

      setTimeout(() => {
        this.newCardForm.patchValue({
          storeCategoryId: card.store_category_id,
        });
      }, 1000);

    }

    event.stopPropagation();
  }

  deleteOldFrontImage() {
    this.oldFrontImage = '';
  }

  deleteOldBackImage() {
    this.oldBackImage = '';
  }
  /* Edit Card End  */
  /* Redeem Card Start */
  showRedeemSection(flag: boolean) {
    const date = new Date();
    this.redeemCardForm.patchValue({
      redeemDate: {
        formatted: moment().format('MM/DD/YYYY'),
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
    });
    this.showRedeemSectionFlag = flag;
  }
  /* Redeem Card End */

  onInputFieldChanged(event: IMyInputFieldChanged) {
    if (!!event.value) {
      if (event.valid) {
        this.expiryDatehasError = false;
      } else {
        this.expiryDatehasError = true;
      }
    } else {
      this.expiryDatehasError = false;
    }
    // console.debug('onInputFieldChanged(): Value: ', event.value, ' - dateFormat: ', event.dateFormat, ' - valid: ', event.valid);
  }

  onRedeemFieldChanged(event: IMyInputFieldChanged) {
    if (!!event.value) {
      if (event.valid) {
        this.redeemDatehasError = false;
      } else {
        this.redeemDatehasError = true;
      }
    } else {
      this.redeemDatehasError = false;
    }
    // console.debug('onInputFieldChanged(): Value: ', event.value, ' - dateFormat: ', event.dateFormat, ' - valid: ', event.valid);
  }

  close() {
    this.closeModalOnClick.nativeElement.style.display = 'none';
    this.showCardDialogFlag = false;
    this.showAddCardForm = false;
  }

  //encrypt method
  encryptmethod(inputdata, key, schema_version) {
    if (environment.requestEnv == '') {
      let encnoobj = this.apienc.encrypt(inputdata, key, schema_version);
      let encobj = { "enc_data": encnoobj };
      return encobj;
    }
    return JSON.parse(inputdata);
  }

  //decrypt method
  decryptmethod(inputdata, key) {
    if (environment.requestEnv == '') {
      return this.apidec.decrypt(inputdata.enc_data, key);
    }
    return JSON.stringify(inputdata);
  }
}
