import { environment } from './../../../../environments/environment';
 import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthguardService } from "../../../services/authguard.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { GiftcardService } from "../../../services/giftcard.service";
import { CryptoService } from "../../../services/crypto.service";
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
// import { RxStompService, InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { ToastrService } from 'ngx-toastr';
// import { RxStompState } from '@stomp/rx-stomp';

@Component({
    selector: "app-buy",
    templateUrl: "./buy.component.html",
    styleUrls: ["./buy.component.scss"]
})
export class BuyComponent implements OnInit {
    summary: any = undefined;
    walletAmount: number = 0;
    token: string = "";
    buy_crypto_max: number = 0;
    buy_crypto_min: number = 50;
    totalBtcValue: number = 0;
    totalXrpValue: number = 0;
    totalEthValue: number = 0;
    totalXlmValue: number = 0;
    totalBchValue: number = 0;
    totalLtcValue: number = 0;
    totalAdaValue: number = 0;
    totalDogeValue: number = 0;
    totalDotValue: number = 0;
    currencies = [
        { name: "Bitcoin", id: "BTC" },
        { name: "Ethereum", id: "ETH" },
        // { name: "Ripple", id: "XRP" },
        { name: "Litecoin", id: "LTC" },
        { name: "Bitcoincash", id: "BCH" },
        // { name: "Stellar", id: "XLM" },
        { name: "Cardano", id: "ADA" },
        { name: "Dogecoin", id: "DOGE" },
        { name: "Polkadot", id: "DOT" }
    ];
    selectedCrypto: string = "BTC";
    selectedCryptoInfo: any = undefined;
    selectedCurrency = "";
    amount: string = "";
    amountEntered: number = 0;
    processingCharges: number;
    currentPrice: number = 0;
    shares: number = 0;
    date = new Date();
    total: number = 0;
    totalShares: number = 0;
    transactionDone: boolean = false;
    showSummary: boolean = true;
    insufficientBalanceError: boolean = false;
    userInfo: any = null;
    userWalletInfo: any = null;
    userFeatures: any;
    transactionDetails: any = {
        orderId: "",
        message: "",
        currency: "",
        amount: "",
        total: "",
        shares: "",
        fee: ""
    };
    cryptoWallet: any;
    processingFeeData: any;
    processingCryptoData: any[] = [];
    leftBTCValue: number = 0;
    leftXRPValue: number = 0;
    leftETHValue: number = 0;
    leftADAValue: number = 0;
    leftDOGEValue: number = 0;
    leftDOTValue: number = 0;
    btcminvalue: number = 50;
    ethminvalue: number = 25;
    xrpminvalue: number = 25;
    adaminvalue: number = 1;
    dogeminvalue: number = 1;
    dotminvalue: number = 1;
    roundedshares: any;
    roundedtotal: number;
    profilestat: any;
    transferdisable;
    offeravailable:any =[];
    totalCurrentPrice: number;
    currencyName: any;
    currencyValue: any;
    constructor(
        private authService: AuthguardService,
        private router: Router,
        private cardService: GiftcardService,
        private cryptoService: CryptoService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe,
        // private rxStompService: RxStompService,
        private toastr: ToastrService
    ) {
        // this.rxStompService.connectionState$.subscribe(state => {
        //     // state is an Enum (Integer), RxStompState[state] is the corresponding string
        //     // console.log(RxStompState[state]+'checking state');
        //     if(RxStompState[state]==='CONNECTING'){
        //       // alert(RxStompState[state]);
        //       this.toastr.info('Info','Please wait, we are fetching live market values. Meanwhile you can use non-crypto features.',{
        //         closeButton: true,
        //         timeOut :5000,
        //         progressBar : true,
        //         progressAnimation :'increasing',
        //         positionClass	:"toast-top-right",
        //         tapToDismiss: true,
        //       }
        //       );
      
        //     }
        //   });
        this.cardService.profilestatus.subscribe(data => {
            this.profilestat = data;
            if(this.profilestat !== 1){
                this.transferdisable = true;
            } else{
                this.transferdisable = false;
            }
        });

      
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
    }

    ngOnInit() {
        this.getCryptoData();
        this.getFeatures(); // get features API fetch data
        this.getProcessingFee(); // get processing fee API fetch data
        // fetch crypto shares wallet
        this.cryptoService.connectToStomp();
        this.cryptoService.getSharesWallet().subscribe(data => {
            if (data != undefined) {
                this.cryptoWallet = data;
                // console.log(this.cryptoWallet);
            }
        });

        this.authService.getToken().subscribe(token => {
            this.token = token;
        });

        this.authService.getuserInfo().subscribe(info => {
            if (info != undefined) {
                this.userInfo = info.user;
                //console.log('userInfo', this.userInfo);
                this.walletAmount = info.giftCardsValue;
                this.userWalletInfo = info.userCryptoCurrency;
            }
        });
        this.getCryptoGiftImages();
        if (sessionStorage.Session) {
            let session = JSON.parse(sessionStorage.Session);
            if (session.buy && session.buy.selectedCurrency != "") {
                this.amount = session.buy.amount;
                this.amountEntered =
                    typeof session.buy.amount == "string"
                        ? parseFloat(session.buy.amount)
                        : session.buy.amount;
                this.selectedCurrency = session.buy.selectedCurrency;
                this.selectCurrency();
            }
        }
       if(localStorage.getItem("offers") !== " "){
            let offerData = JSON.parse(localStorage.getItem("offers"));
            if (offerData[0].offer_for === 'cryptobuy')
            {
                this.offeravailable = offerData;
            }
       }
    }

    ngOnDestroy() {
        this.cardService.fromRedeemPage.next(false);
    }

    cancel() {
        Swal({
            title: "",
            text: "Do you really want to cancel?",
            type: "error",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#053a6a",
            cancelButtonColor: "#eb6363",
            confirmButtonText: "YES",
            cancelButtonText: "No"
        }).then(result => {
            if (result && result.value) {
                this.amount = "";
                this.amountEntered = 0;
                this.selectedCurrency = "";
                this.shares = 0;
                this.processingCharges = parseFloat(
                    this.processingFeeData.cryptocurrency_fee
                );
                this.total = 0;
                this.selectedCrypto = "BTC";
                this.selectedCryptoInfo = undefined;
            }
        });
    }

    selectCurrency() {
        if (this.selectedCurrency) {
            this.selectedCrypto = this.selectedCurrency;
            if (this.selectedCrypto =='BTC') {
                this.buy_crypto_min = this.btcminvalue;
            }else if (this.selectedCrypto =='ETH') {
                this.buy_crypto_min = this.ethminvalue;
            }else if (this.selectedCrypto =='XRP') {
                this.buy_crypto_min = this.xrpminvalue;
            }else if (this.selectedCrypto =='LTC') {
                this.buy_crypto_min = this.xrpminvalue;
            }else if (this.selectedCrypto =='XLM') {
                this.buy_crypto_min = this.xrpminvalue;
            }else if (this.selectedCrypto =='BCH') {
                this.buy_crypto_min = this.xrpminvalue;
            }else if (this.selectedCrypto =='ADA') {
                this.buy_crypto_min = this.adaminvalue;
            }else if (this.selectedCrypto =='DOGE') {
                this.buy_crypto_min = this.dogeminvalue;
            }else if (this.selectedCrypto =='DOT') {
                this.buy_crypto_min = this.dotminvalue;
            }
            else {
                this.buy_crypto_min = this.buy_crypto_min;
            }
            if (this.cryptoWallet && this.cryptoWallet[this.selectedCurrency]) {
                this.selectedCryptoInfo = this.cryptoWallet[
                    this.selectedCurrency
                ];
                if (this.selectedCryptoInfo) {
                    this.currentPrice =  Math.round(this.selectedCryptoInfo.ask * 100) / 100;
                    this.showSummary = true;
                    if (
                        this.amount != "" &&
                        this.amount != "0" &&
                        this.amount != null
                    )
                        this.calculateShares();
                    if (Array.isArray(this.userWalletInfo)) {
                        let sharesWallet = this.userWalletInfo.find(
                            cur => cur.currency == this.selectedCurrency
                        );
                        if (sharesWallet) {
                            this.totalShares = sharesWallet.shares;
                        } else {
                            this.totalShares = 0;
                        }
                    }
                } else {
                }
            }
        }
    }

    validateAmount() {
        let component = this;
        //console.log(component);
        if(component.selectedCurrency =='BTC'){
            component.buy_crypto_min = this.btcminvalue;
            if (parseInt(this.amount) < this.btcminvalue) {
                // this.amount = '25';
                this.calculateShares();
            } else if (parseInt(this.amount) > this.walletAmount) {
                this.insufficientBalanceError = true;
                // this.amount = (parseInt(this.walletAmount.toString())).toString();
                this.calculateShares();
            } else {
                this.calculateShares();
            }
        }else if(component.selectedCurrency =='ETH'){
            component.buy_crypto_min = this.ethminvalue;
            if (parseInt(this.amount) < this.ethminvalue) {
                // this.amount = '25';
                this.calculateShares();
            } else if (parseInt(this.amount) > this.walletAmount) {
                this.insufficientBalanceError = true;
                // this.amount = (parseInt(this.walletAmount.toString())).toString();
                this.calculateShares();
            } else {
                this.calculateShares();
            }
        } else if(component.selectedCurrency =='XRP'){
            component.buy_crypto_min = this.xrpminvalue;
            if (parseInt(this.amount) < this.xrpminvalue) {
                // this.amount = '25';
                this.calculateShares();
            } else if (parseInt(this.amount) > this.walletAmount) {
                this.insufficientBalanceError = true;
                // this.amount = (parseInt(this.walletAmount.toString())).toString();
                this.calculateShares();
            } else {
                this.calculateShares();
            }
        } else if(component.selectedCurrency =='XLM'){
            component.buy_crypto_min = this.xrpminvalue;
            if (parseInt(this.amount) < this.xrpminvalue) {
                // this.amount = '25';
                this.calculateShares();
            } else if (parseInt(this.amount) > this.walletAmount) {
                this.insufficientBalanceError = true;
                // this.amount = (parseInt(this.walletAmount.toString())).toString();
                this.calculateShares();
            } else {
                this.calculateShares();
            }
        } else if(component.selectedCurrency =='LTC'){
            component.buy_crypto_min = this.xrpminvalue;
            if (parseInt(this.amount) < this.xrpminvalue) {
                // this.amount = '25';
                this.calculateShares();
            } else if (parseInt(this.amount) > this.walletAmount) {
                this.insufficientBalanceError = true;
                // this.amount = (parseInt(this.walletAmount.toString())).toString();
                this.calculateShares();
            } else {
                this.calculateShares();
            }
        }
        else if(component.selectedCurrency =='BCH'){
            component.buy_crypto_min = this.xrpminvalue;
            if (parseInt(this.amount) < this.xrpminvalue) {
                // this.amount = '25';
                this.calculateShares();
            } else if (parseInt(this.amount) > this.walletAmount) {
                this.insufficientBalanceError = true;
                // this.amount = (parseInt(this.walletAmount.toString())).toString();
                this.calculateShares();
            } else {
                this.calculateShares();
            }
        } 
        else if(component.selectedCurrency =='ADA'){
            component.buy_crypto_min = this.adaminvalue;
            if (parseInt(this.amount) < this.adaminvalue) {
                // this.amount = '25';
                this.calculateShares();
            } else if (parseInt(this.amount) > this.walletAmount) {
                this.insufficientBalanceError = true;
                // this.amount = (parseInt(this.walletAmount.toString())).toString();
                this.calculateShares();
            } else {
                this.calculateShares();
            }
        } 
        else if(component.selectedCurrency =='DOGE'){
            component.buy_crypto_min = this.dogeminvalue;
            if (parseInt(this.amount) < this.dogeminvalue) {
                // this.amount = '25';
                this.calculateShares();
            } else if (parseInt(this.amount) > this.walletAmount) {
                this.insufficientBalanceError = true;
                // this.amount = (parseInt(this.walletAmount.toString())).toString();
                this.calculateShares();
            } else {
                this.calculateShares();
            }
        } 
        else if(component.selectedCurrency =='DOT'){
            component.buy_crypto_min = this.dotminvalue;
            if (parseInt(this.amount) < this.dotminvalue) {
                // this.amount = '25';
                this.calculateShares();
            } else if (parseInt(this.amount) > this.walletAmount) {
                this.insufficientBalanceError = true;
                // this.amount = (parseInt(this.walletAmount.toString())).toString();
                this.calculateShares();
            } else {
                this.calculateShares();
            }
        }  
    }

        calculateShares() {
        this.showSummary = false;
        if (this.amount != "" && this.amount != "0" && this.amount != null) {
            this.amountEntered = parseFloat(this.amount);
            if (this.amountEntered > this.walletAmount) {
                this.insufficientBalanceError = true;
                this.showSummary = true;
            } else {
                this.insufficientBalanceError = false;
                let amount = this.amountEntered;
                // this.processingCharges = 1.99;
                let currentPrice = this.currentPrice;
                let processingCharges = this.processingCharges;
                let shares = amount / currentPrice;
                this.shares = shares;
                let component = this;
                console.log(component);
                if(component.selectedCurrency === 'BTC'){
                    this.roundedshares = this.shares.toFixed(4);
                    this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
                } else if(component.selectedCurrency === 'ETH'){
                    this.roundedshares = this.shares.toFixed(3);
                    this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
                }else if(component.selectedCurrency === 'XRP'){
                    this.roundedshares = this.shares.toFixed(2);
                    this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
                } else if(component.selectedCurrency === 'XLM'){
                    this.roundedshares = this.shares.toFixed(0);
                    this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
                } else if(component.selectedCurrency === 'LTC'){
                    this.roundedshares = this.shares.toFixed(3);
                    this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
                } else if(component.selectedCurrency === 'BCH'){
                    this.roundedshares = this.shares.toFixed(3);
                    this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
                } else if(component.selectedCurrency === 'ADA'){
                    this.roundedshares = this.shares.toFixed(0);
                    this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
                } else if(component.selectedCurrency === 'DOGE'){
                    this.roundedshares = this.shares.toFixed(3);
                    this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
                } else if(component.selectedCurrency === 'DOT'){
                    this.roundedshares = this.shares.toFixed(3);
                    this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
                }
                let total = amount + processingCharges;
                this.total = total;
                if(this.offeravailable.length !== 0){       
                 this.totalCurrentPrice = this.roundedtotal;
                 console.log("with offer", this.totalCurrentPrice);
                }else{
                    this.totalCurrentPrice = this.roundedtotal;
                    console.log("with out offer", this.totalCurrentPrice);
                }
            }
        } else {
            this.shares = 0;
            this.total = 0;
        }
    }

    placeOrder() {
        sessionStorage.removeItem("Session");
        if (this.total == 0 || this.total <= 0) {
            alert("Plese enter amount to buy crypto currency");
        } else {
            let token = {
                user_id: this.userInfo.id,
                type: "Purchased",
                crypto_currency: this.selectedCurrency,
                amount: this.roundedtotal,
                transaction_fee: this.processingCharges,
                currentPrice: this.currentPrice,
                // tslint:disable-next-line: radix
                shares_quantity: parseInt(this.roundedshares),
                request_type : sessionStorage.getItem("requestType").length !== 0 ? sessionStorage.getItem("requestType") : 0
            };
            if (this.offeravailable)
            {
                token.request_type = 0;
            }
            if(this.selectedCurrency === 'BTC'){
                token.shares_quantity = + this.shares.toFixed(4);
            } else if(this.selectedCurrency === 'ETH'){
                token.shares_quantity = + this.shares.toFixed(3);
            } else if(this.selectedCurrency === 'XRP'){
                token.shares_quantity = + this.shares.toFixed(2);
            } else if(this.selectedCurrency === 'XLM'){
                token.shares_quantity = + this.shares.toFixed(0);
            } else if(this.selectedCurrency === 'LTC'){
                    token.shares_quantity = + this.shares.toFixed(3);
            } else if(this.selectedCurrency === 'BCH'){
                token.shares_quantity = + this.shares.toFixed(3);
            } else if(this.selectedCurrency === 'ADA'){
                token.shares_quantity = + this.shares.toFixed(2);
            } else if(this.selectedCurrency === 'DOGE'){
                    token.shares_quantity = + this.shares.toFixed(2);
            } else if(this.selectedCurrency === 'DOT'){
                token.shares_quantity = + this.shares.toFixed(2);
            }
            // console.log('this.leftBTCValue', this.leftBTCValue);
            // console.log('this.cardService.decrypt(token.shares_quantity)', this.cardService.decrypt(token.shares_quantity));
            // return ;
           if(token.crypto_currency == 'BTC'){
               let btcValue = this.cardService.decrypt(token.shares_quantity);
               this.totalBtcValue = parseFloat(btcValue);
               if(this.totalBtcValue > this.leftBTCValue){
                    Swal({
                        type: "warning",
                        title: "Crypto Gift card",
                        text: `BTC available shares are ${this.leftBTCValue} and your BTC cards shares ${this.totalBtcValue.toFixed(3)}`,
                        allowOutsideClick: false,          
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                    return false;
               }
           }else if(token.crypto_currency == 'XRP'){
                let xrpvalue = this.cardService.decrypt(token.shares_quantity);
                this.totalXrpValue = parseFloat(xrpvalue);
                if(this.totalXrpValue > this.leftXRPValue){
                    Swal({
                        type: "warning",
                        title: "Crypto Gift card",
                        text: `XRP available shares are ${this.leftXRPValue} and your XRP cards shares ${this.totalXrpValue.toFixed(3)}`,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                    return false;
                }
           }else if(token.crypto_currency == 'ETH'){
                let ethValue = this.cardService.decrypt(token.shares_quantity);
                this.totalEthValue = parseFloat(ethValue);
                if(this.totalEthValue > this.leftETHValue){
                    Swal({
                        type: "warning",
                        title: "Crypto Gift card",
                        text: `ETH available shares are ${this.leftXRPValue} and your ETH cards shares ${this.totalEthValue.toFixed(3)}`,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                    return false;
                }
           }else if(token.crypto_currency == 'ADA'){
            let adavalue = this.cardService.decrypt(token.shares_quantity);
            this.totalAdaValue = parseFloat(adavalue);
            if(this.totalAdaValue > this.leftADAValue){
                Swal({
                    type: "warning",
                    title: "Crypto Gift card",
                    text: `ADA available shares are ${this.leftADAValue} and your ADA cards shares ${this.totalAdaValue.toFixed(3)}`,
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#053a6a"
                });
                return false;
            }
       }else if(token.crypto_currency == 'DOGE'){
        let dogevalue = this.cardService.decrypt(token.shares_quantity);
        this.totalDogeValue = parseFloat(dogevalue);
        if(this.totalDogeValue > this.leftDOGEValue){
            Swal({
                type: "warning",
                title: "Crypto Gift card",
                text: `DOGE available shares are ${this.leftDOGEValue} and your DOGE cards shares ${this.totalDogeValue.toFixed(3)}`,
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            });
            return false;
        }
   }else if(token.crypto_currency == 'DOT'){
    let dotvalue = this.cardService.decrypt(token.shares_quantity);
    this.totalDotValue = parseFloat(dotvalue);
    if(this.totalDotValue > this.leftDOTValue){
        Swal({
            type: "warning",
            title: "Crypto Gift card",
            text: `DOT available shares are ${this.leftDOTValue} and your DOT cards shares ${this.totalDotValue.toFixed(3)}`,
            allowOutsideClick: false,
            customClass: "swal-custom",
            confirmButtonColor: "#053a6a"
        });
        return false;
    }
}

           let strdata = JSON.stringify(token);
           let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.buyCryptoCurrency(dataenc).subscribe(response => {
                let data = (<any>response)
                if(data.length !== 0){
                    localStorage.setItem("offers", "[ ]");
                    sessionStorage.setItem("showOffer", "false");
                   console.log(localStorage.getItem("offers"));
                }
                let decdata = this.decryptmethod(data, this.global.publicKey);
                let resp = JSON.parse(decdata);
                let currency = this.currencies.find(
                    currency => currency.id == this.selectedCurrency
                );
                let currencyName = "Bitcoin";
                if (currency) {
                    currencyName = currency.name;
                }
                if (<any>resp) {
                    console.log("response", resp);
                    // let total = this.processingCharges + this.amountEntered;
                    let total = this.roundedtotal;
                    this.transactionDetails = {
                        orderId: data.transaction_id,
                        currency: currencyName,
                        message: data.msg,
                        amount: this.amount,
                        total: total.toString(),
                        shares: this.roundedshares.toString(),
                        fee: this.processingCharges.toString()
                    };
                    console.log(this.transactionDetails, resp.success);
                    if(<any>resp.success == true){      
                    this.transactionDone = true;
                    }else{
                        let msg = resp?.error?.crypto_currency[0];
                        this.transactionDone = false;
                        Swal({
                            title: "",
                            text: resp.msg || msg,
                            type: "error",
                            allowOutsideClick: false,
                            showCancelButton: false,
                            confirmButtonColor: "#053a6a",
                            confirmButtonText: "OK"
                        });
                    }
                    window.scroll(0,0);
                    // reset wallet
                    this.authService
                        .fetchWallet({
                            user_id: this.cardService.encrypt(
                                this.userInfo.id.toString()
                            )
                        })
                        .subscribe(response => {});
                } else if (resp.verified == false) {
                    Swal({
                        title: "",
                        text: resp.msg,
                        type: "error",
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: "#053a6a",
                        confirmButtonText: "OK"
                    }).then(result => {
                        if (result.value == true) {
                            this.router.navigateByUrl("/dashboard/settings");
                        }
                    });
                } else {
                    Swal({
                        title: "",
                        text: resp.msg,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#053a6a",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                    }).then(result => {});
                }
            });
        }
    }

    moveToAddMoneyWallet() {
        let session = JSON.stringify({
            buy: {
                amount: this.amount,
                selectedCurrency: this.selectedCurrency
            }
        });
        sessionStorage.setItem("Session", session);
        this.router.navigateByUrl("/dashboard/addmoney");
    }

    reset() {
        this.transactionDone = false;
        this.showSummary = true;
        this.amount = "";
        this.amountEntered = 0;
        this.total = 0;
        this.selectedCurrency = "";
        this.selectedCrypto = "BTC";
        this.processingCharges = parseFloat(
            this.processingFeeData.cryptocurrency_fee
        );
        this.shares = 0;
        this.cardService.fromRedeemPage.next(true);
        this.router.navigateByUrl("/dashboard/home");
    }

    //get  features active or block

    getFeatures() {
        this.cardService.acceptBlockFeatures().subscribe(response => {
            let body = (<any>response)

            if ((<any>body).success == true) {

                // update user feature
                sessionStorage.setItem("features", JSON.stringify(body));

                this.userFeatures = body;
                // console.log(this.userFeatures);

                if (this.userFeatures.features.buy_crypto != "1") {
                    this.router.navigateByUrl("dashboard/home");
                }
            }
        });
    }

    // procesing fee

    getProcessingFee() {
        this.cardService.getProcessingFeeData().subscribe(response => {
            let body = (<any>response)
            if ((<any>body).success == true) {
                this.processingFeeData = body.payment;
                console.log("this.processingFeeData", this.processingFeeData);
                this.processingCharges = parseFloat(
                    this.processingFeeData.cryptocurrency_fee
                );
                this.buy_crypto_max = parseFloat(this.processingFeeData.buy_crypto_max);
                // this.buy_crypto_min = parseFloat(this.processingFeeData.buy_crypto_min);
            }
        });

        if (this.processingCharges) {
        } else {
            this.processingCharges = 1.99;
        }
    }

    getCryptoData(){
        this.cardService.getCryptoGiftImg().subscribe(response => {
            let body = (<any>response)
            if ((<any>body).success == true) {
               this.processingCryptoData = body.crypto;
               if(this.processingCryptoData != undefined){
                   console.log('this.processingCryptoData', this.processingCryptoData);
                  this.processingCryptoData.forEach(data => {
                      if(data.keyword == 'BTC'){
                         this.leftBTCValue = data.left_quanity;
                      }else if(data.keyword == 'ETH') {
                         this.leftETHValue = data.left_quanity;
                      }else if(data.keyword == 'XRP'){
                         this.leftXRPValue = data.left_quanity;
                      }else if(data.keyword == 'ADA') {
                        this.leftADAValue = data.left_quanity;
                     }else if(data.keyword == 'DOGE'){
                        this.leftDOGEValue = data.left_quanity;
                     }else if(data.keyword == 'DOT'){
                        this.leftDOTValue = data.left_quanity;
                     }
                  })
               }
            }
        })
    }
    getCryptoGiftImages() {
        this.cardService.getCryptoGiftImg().subscribe(response => {
            let body = (<any>response)
            let decdata = this.decryptmethod(body, this.global.publicKey);
            let resp= JSON.parse(decdata);
            if(<any>resp.success == true){
                console.log(resp);
                this.btcminvalue = resp.crypto[0].min_value;
                this.ethminvalue = resp.crypto[1].min_value;
                this.xrpminvalue = resp.crypto[2].min_value;
                this.adaminvalue = resp.crypto[3].min_value;
                this.dogeminvalue = resp.crypto[4].min_value;
                this.dotminvalue = resp.crypto[5].min_value;
            }
        });
    }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data" : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
        decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
            return JSON.stringify(inputdata);
    }

}
