import { Injectable } from "@angular/core";
import { Observable, pipe, Subject, of, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import Swal from "sweetalert2";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class DataService {
    SERVER_HOST: string = "";
    constructor(
        private router: Router,
        private httpClient: HttpClient
    ) {
        this.SERVER_HOST = environment.serverHost;
    }

    private static token = new BehaviorSubject<string>("");
    private static userInfo = new BehaviorSubject<any>(undefined);
    private static userWallet = new BehaviorSubject<any>(undefined);
    private static userId = new BehaviorSubject<any>(undefined);

    private messageSource = new BehaviorSubject<any>(undefined);
    currentMessage = this.messageSource.asObservable();

    /******************************
     *  Set Http headers
     *******************************/

    changeMessage(message: string) {
        this.messageSource.next(message);
    }

    getHTTPHeaders() {
        let headers = new HttpHeaders();
        headers.append("Authorization", "Bearer " + sessionStorage.token);
        headers.set("content-type", "application/json");
        headers.append("accept", "application/json");
       
        return {headers};
    }

    /**********************************************
     *  Getters and Setters for Static properties
     **********************************************/
    static getToken() {
        // getter for login Token
        return DataService.token.asObservable();
    }
    setToken(data) {
        // setter for login token
        return DataService.token.next(data);
    }
    static getUserInfo() {
        // getter for user Info
        return DataService.userInfo.asObservable();
    }
    setUserInfo(data) {
        // setter for user Info
        return DataService.userInfo.next(data);
    }
    static getUserId() {
        // getter for user Id
        return DataService.userId.asObservable();
    }
    setUserId(data) {
        // setter for user Id
        return DataService.userId.next(data);
    }
    static getUserWallet() {
        // getter for userWallet
        return DataService.userWallet.asObservable();
    }
    setUserWallet(data) {
        // setter for userWallet
        return DataService.userWallet.next(data);
    }

    /**********************************************
     *  Getters and Setters for Static properties End
     **********************************************/

    /**********************************************
     *  Common
     **********************************************/

    /**********************************************
     *  Common End
     **********************************************/

    /**********************************************
     *  Profile settings
     **********************************************/

    /**********************************************
     *  Profile settings End
     **********************************************/
}
