import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
import { AuthguardService } from "../../../services/authguard.service";
import { GiftcardService } from "../../../services/giftcard.service";
import { Router } from "@angular/router";
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
@Component({
  selector: "app-money-history",
  templateUrl: "./add-money-history.component.html",
  styleUrls: ["./add-money-history.component.scss"]
})
export class AddMoneyHistoryComponent implements OnInit {
  message: string = "";
  userId: string = "";
  history: any;
  bulkHistory: any = [];
  search: string = "";
  showOrderInfo: boolean = false;
  orderInfo = [];
  token: string = '';
  filters: any = {
    id: "",
    eventName: "",
    eventType: "",
    email: "",
    transactionType: "",
    date: "",
    amount: "",
    count: "",
    recipient: "",
    shipping: "",
  };
  @ViewChild("cardHistory") cardHistory;
  currencyName:any;
  currencyValue: any;
  constructor(
    private authService: AuthguardService,
    private giftCardService: GiftcardService,
    private router: Router,
    private apienc: ApiencryptService,
    private apidec: ApidecryptService,
    private global: GlobalPipe
  ) { 
        this.currencyName =sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
  }

  ngOnInit() {
    // Fetch Card History
    this.authService.getToken().subscribe(token => {
      this.token = token;
    });
    this.fetchAddAmountToWalletHistory();
  }

  fetchAddAmountToWalletHistory() {
    this.userId = sessionStorage.userId;
    let body = {
      user_id: this.giftCardService.encrypt(this.userId)
    };
    this.authService.fetchAddMoneyHistory(body).subscribe(response => {
      let data = (<any>response)
      let decdata = this.decryptmethod(data, this.global.publicKey);
      let resp = JSON.parse(decdata);
      if (<any>resp) {
        let responseData = resp;
        if (responseData.toWallet && Array.isArray(responseData.toWallet)) {
          this.history = responseData.toWallet;
        }
      }
    });
  }

  filterHistory() {
    let tmpHistoryData = this.history;
    console.log('tmpHistoryData', tmpHistoryData);
    let filteredHistoryData = [];
    // filter from id
    if (this.filters.id != '') {
      let searchRegex = new RegExp(this.filters.id, "g");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.transactionId.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from event name
    if (this.filters.eventName != '') {
      let searchRegex = new RegExp(this.filters.eventName, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.event_name));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    if (this.filters.email != '') {
      let searchRegex = new RegExp(this.filters.email, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.email));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    if (this.filters.transactionType != '') {
      let searchRegex = new RegExp(this.filters.transactionType, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.transactionMessage));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from sender name
    if (this.filters.sender != '') {
      let searchRegex = new RegExp(this.filters.sender, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.sender_name));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from event type
    if (this.filters.eventType != '') {
      let searchRegex = new RegExp(this.filters.eventType, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.event_type));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from event date
    if (this.filters.date != '') {
      let searchRegex = new RegExp(this.filters.date, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.createdAt));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from event amount
    if (this.filters.amount != '') {
      let searchRegex = new RegExp(this.filters.amount, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.amount.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from card count
    if (this.filters.count != '') {
      let searchRegex = new RegExp(this.filters.count, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.no_of_cards.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from card recipient
    if (this.filters.recipient != '') {
      let searchRegex = new RegExp(this.filters.recipient, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => data.recepient_name && searchRegex.test(data.recepient_name));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from card shipping status
    if (this.filters.shipping != '') {
      let searchRegex = new RegExp(this.filters.shipping, "i");
      let allstatus = ['Proccessing', 'Initiated'];
      let filteredStatus = allstatus.filter(filter => searchRegex.test(filter));
      let status = -1;
      if (filteredStatus) {
        if (filteredStatus.indexOf('Initiated') > -1) {
          status = 0;
        } else if (filteredStatus.indexOf('Proccessing') > -1) {
          status = 1;
        }
        filteredHistoryData = tmpHistoryData.filter(data => data.is_shipping == status);
        tmpHistoryData = filteredHistoryData;
      }
      else {
        filteredHistoryData = tmpHistoryData;
      }
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    this.cardHistory.inputData = filteredHistoryData;
  }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
      if(environment.requestEnv == ''){
        let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
        let encobj = {"enc_data" : encnoobj};
        return encobj;
      }
      return JSON.parse(inputdata);
    }

    //decrypt method
      decryptmethod(inputdata, key) {
      if(environment.requestEnv == ''){
        return this.apidec.decrypt(inputdata.enc_data, key);
      }
        return JSON.stringify(inputdata);
    }
  
  }
