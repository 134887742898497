import {Component, OnInit, ViewChild, ElementRef} from "@angular/core";
import {AuthguardService} from "../../../services/authguard.service";
import {NgSelectComponent} from "@ng-select/ng-select";
import {GiftcardService} from "../../../services/giftcard.service";
import {Router} from "@angular/router";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import {DomSanitizer} from "@angular/platform-browser";
import {QRCodeModule} from "angularx-qrcode";
import {environment} from "../../../../environments/environment";
import {ApiencryptService} from '../../../services/apiencrypt.service';
import {ApidecryptService} from '../../../services/apidecrypt.service';
import {GlobalPipe} from '../../../shared/globalpipes/global.pipe';
import Swal from "sweetalert2";
import {FormGroup, FormBuilder, Validators, AbstractControl} from "@angular/forms";
@Component({
    selector: "app-mycards",
    templateUrl: "./mycards.component.html",
    styleUrls: ["./mycards.component.scss"]
})
export class MycardsComponent implements OnInit {
    @ViewChild("cardsTable", {static: false}) cardsTable;
    @ViewChild("closeModalOnClick") closeModalOnClick: ElementRef

    cards: any;
    printcards: any;
    giftTemplates: any[] = [];
    cryptoTemplates: any[] = [];
    stocksTemplates: any[] = [];
    marketingFlyerTemplates: any[] = [];
    filters: any = {
        recipient: {
            firstname: "",
            lastname: "",
            email: "",
            phone: ""
        },
        status: "",
        cardname: "",
        amount: "",
        expiry: "",
        type: ""
    };
    selectedCard: any = undefined;
    currentVisibleCard: number = 0;
    bussinessImage: any = undefined;
    cardBackgroundImage: any = undefined;
    selectedTemplate: any = undefined;

    allowCardEdit: boolean = false;
    allowCardPreview: boolean = false;
    allowCardPreviewBackside: boolean = false;
    showTransactionDialog: boolean = false;
    printInProgress: boolean = false;
    logo: any;
    cryptoGiftImages: any;
    stocksGiftImages: any;
    allowCardFlyerPreview: boolean = false;
    selectedFlyer: any;
    selectedFlyerTemplate: any;
    printFlyers: any;
    SERVER_IMG_HOST: any;
    tempName: string = '';
    orderData: any;
    // qrcode: any;
    isOrderDataLoading: boolean = false;
    selectedAction: string = '';
    userId: string = '';
    showrecepientEmailfield: boolean = false;
    hasCardExpiryDate: boolean = false;
    showresend: boolean = false;
    revockedCardview : boolean = false;
    public newResendCardForm: FormGroup;
    comparableEmail: string = '';
    resendtoken: {id: any; user_id: any; email: string;};
    revokedToken: any;
    currencyName: any;
    currencyValue: any;
   
    constructor(
        private cardService: GiftcardService,
        private router: Router,
        private authService: AuthguardService,
        private domSanitizer: DomSanitizer,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe,
        private fb: FormBuilder,
    ) {
        this.SERVER_IMG_HOST = environment.serverImgHost;
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
    }

    ngOnInit() {
        this.newResendCardForm = this.fb.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)])],
        });
        // fetch gift card templates
        this.giftTemplates = this.cardService.getGiftCardTemplates();
        // fetch marketing Flyer templates
        this.marketingFlyerTemplates = this.cardService.getGiftCardTemplatesFlyer();
        // fetch crypto card templates
        //  this.cryptoTemplates = this.cardService.getCryptoCardTemplates();

        // get crypto images API
        this.getCryptoGiftImages();
        // this.getStocksGiftImages();
        this.fetchMyCards();

        //get logo base64
        // this.logo = this.cardService.logo;
    }
    resendupdate() {
        let form = this.newResendCardForm.value;
        if (form.email !== "") {
            this.resendtoken.email = form.email;
            Swal({
                title: `Mail will been sent to ${form.email}`,
                type: 'success',
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: 'ok',
            }).then(result => {
                if (result.value == true) {
                    this.cardHistoryActionResend(this.resendtoken);
                }
            });
        }
    }
    resend() {
        let temptoken: any;
        temptoken = this.resendtoken;
        delete temptoken.email;
        let body = temptoken;
        this.cardHistoryActionResend(body);
    }
    revoke(){
        let temptoken: any;
        temptoken = this.revokedToken;
        this.fetchMyCards();
    this.authService.revokeGiftCard(temptoken).subscribe(response => {
        let data = (<any>response)
        let decdata = this.decryptmethod(data, this.global.publicKey);
        let resp = JSON.parse(decdata);
        if (<any>resp) {
            let responseData = resp;
            //   this.message = responseData.msg;
            Swal({
                type: 'success',
                title: responseData.msg,
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 2000
            })
            this.fetchMyCards();
            this.selectedAction = '';
            this.fetchWallet();
            this.close()
        }
    },err => {
            0
            Swal({

                type: 'error',
                title: 'Failed to revoked the gift card:',
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 2000
            })
            this.selectedAction = '';
        },
    )
 
    }
    cardHistoryActionResend(data) {
        let body = data
        this.authService.sendResendCard(body).subscribe(response => {
            let data = (<any>response)
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let resp = JSON.parse(decdata);
            if (<any>resp.success == true) {
                this.hasCardExpiryDate = false;
                this.close();
                this.fetchMyCards();
                Swal({
                    title: 'Mail has been sent',
                    type: 'success',
                    text: resp.msg,
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonText: 'ok',
                });

            } else {
                this.close();
                this.hasCardExpiryDate = false;
                Swal({
                    title: 'No recepient found',
                    type: 'warning',
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonText: 'ok',
                });
            }
        })
    }
    compareEmail(form: AbstractControl) {
        if (this.comparableEmail !== '') {
            let email = form.get('email').value; // to get value in input tag
            if (email !== this.comparableEmail) {
                form.get('email').setErrors(null);
                return null;
            } else {
                form.get('email').setErrors({matchEmail: true})
            }
        }
    }
    filterSpecialCharacterInEmail() {
        let input = this.newResendCardForm.controls['email'].value;
        if (input.indexOf('@') == 0) {
            input = '';
        }
        let parsedValue = input.replace(/[!#$^&%*\(\)+=\-\[\]\/\{\}|:<>?,]/g, '');
        this.newResendCardForm.controls['email'].setValue(parsedValue);
    }
    fetchWallet() {
        let userInfo = JSON.parse(sessionStorage.userInfo);
        this.authService
            .fetchWallet({
                user_id: this.cardService.encrypt(userInfo.id.toString())
            })
            .subscribe(response => {
                // wallet info fetched
            });
    }
    fetchMyCards() {

        if (sessionStorage.getItem("userId")) {
            this.userId = sessionStorage.getItem("userId");
            let token = {
                user_id: this.cardService.encrypt(this.userId)
            };
            let strdata = JSON.stringify(token);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.fetchMyCards(dataenc).subscribe(response => {
                let dataobtained = <any>response;
                if ((dataobtained.success == true && dataobtained.msg !== "No data found")) {
                    let data = dataobtained.cards;
                    let cardsdata = [];
                    if (Array.isArray(data)) {
                        data.forEach((card, index) => {
                            let newCard = {};
                            let code =
                                card.redeemCode.length == 12
                                    ? card.redeemCode.slice(3, 6) +
                                    "-" +
                                    card.redeemCode.slice(6, 9) +
                                    "-" +
                                    card.redeemCode.slice(9, 12)
                                    : card.redeemCode.length == 12
                                        ? card.redeemCode.slice(0, 3) +
                                        "-" +
                                        card.redeemCode.slice(3, 6) +
                                        "-" +
                                        card.redeemCode.slice(6, 9)
                                        : "xxx-xxx-xxx";
                            newCard = {
                                ...card,
                                index: index,
                                code: code,
                                amount: parseInt(card.amount),
                                templateId: parseInt(card.templateId)
                            };
                            cardsdata.push(newCard);
                        });
                        this.cards = cardsdata.sort(function (a, b) {
                            return a.index - b.index;
                        });
                    }
                } else {
                    this.cards = [];
                }


            });
        }
    }

    showCard(item, event) {

        this.closeModalOnClick.nativeElement.style.display = 'block';
        let self = this;
        this.printInProgress = false;
        this.printcards = [];
        this.printFlyers = [];
        if (item.event_type == "marketing") {
            this.printFlyers.push(item);
            this.selectedFlyer = item;
            this.allowCardFlyerPreview = true;
            this.selectedFlyerTemplate = this.marketingFlyerTemplates.find(
                template => template.id == this.selectedFlyer.templateId
            );
            event.stopPropagation();

        } else {
            console.log(item)
            this.printcards.push(item);
            this.selectedCard = item;
            let templateImage = this.selectedCard.template_image;
            let tempData = templateImage.split('/');
            let data = tempData[tempData.length - 1];
            if (data == 'black.png') {
                this.tempName = tempData[tempData.length - 1];
            } else {
                this.tempName = '';
            }

            this.allowCardPreview = true;
            this.selectTemplate();
            if (this.selectedCard.gift_type == 'stocks') {
                this.selectStocksTemplate();
            }
            event.stopPropagation();

        }

    }

    selectTemplate() {
        let cTemplate = this.selectedCard.templateId;
        let currency = this.selectedCard.currency;
        // this.selectedTemplate.image = "";

        if (
            this.selectedCard.gift_type == "crypto" ||
            this.selectedCard.cardName == "Crypto Card"
        ) {
            this.selectedTemplate = this.cryptoTemplates.find(
                template => template.keyword == currency
            );
        } else {
            this.selectedTemplate = this.giftTemplates.find(
                template => template.id == cTemplate
            );
            // this.selectedTemplate.image =
            //     this.SERVER_IMG_HOST + this.selectedTemplate.image;
        }

    }

    selectStocksTemplate() {
        let cTemplate = this.selectedCard.templateId;
        let currency = this.selectedCard.currency;
        // this.selectedTemplate.image = "";

        if (
            this.selectedCard.gift_type == "stocks" ||
            this.selectedCard.cardName == "Stocks Card"
        ) {
            this.selectedTemplate = this.stocksTemplates.find(
                template => template.currency == currency
            );
        } else {
            this.selectedTemplate = this.giftTemplates.find(
                template => template.id == cTemplate
            );
            // this.selectedTemplate.image =
            //     this.SERVER_IMG_HOST + this.selectedTemplate.image;
        }
    }

    close() {
        this.closeModalOnClick.nativeElement.style.display = 'none';
        this.allowCardPreview = false;
        this.allowCardFlyerPreview = false;
        this.selectedAction = '';
        this.revockedCardview = false
    }

    editCard() {
        this.allowCardEdit = !this.allowCardEdit;
    }

    adedCardLogoImageUpdatedBack($event) {
        // ne.log($event);
    }

    updateAmountLabels(amount) {
        // console.log(amount);
    }

    slideLeft() {
        this.allowCardEdit = false;
        if (this.cards.length > 0) {
            if (this.currentVisibleCard == 0) {

            } else {
                this.currentVisibleCard--;
            }
        }
        this.selectedCard = this.cards[this.currentVisibleCard];
        this.selectTemplate();
        if (this.selectedCard.gift_type == 'stocks') {
            this.selectStocksTemplate();
        }
    }

    slideRight() {
        this.allowCardEdit = false;
        if (this.cards.length > 0) {
            if (this.currentVisibleCard < this.cards.length - 1) {
                this.currentVisibleCard++;
            } else {
                this.currentVisibleCard = this.cards.length - 1;
                // console.log("last card");
            }
            this.selectedCard = this.cards[this.currentVisibleCard];
            this.selectTemplate();
            if (this.selectedCard.gift_type == 'stocks') {
                this.selectStocksTemplate();
            }
        }
    }

    printCard() {

        // print card in pdf
        this.printInProgress = true;
        try {
            setTimeout(_ => {
                try {
                    console.log(document.querySelector("#contentToConvert"));
                    html2canvas(document.querySelector("#contentToConvert"), {
                        logging: false,
                        useCORS: true,
                        letterRendering: true,
                        allowTaint: false
                    }).then(canvas => {
                        var imgData = canvas.toDataURL("image/jpeg");
                        var tWindow = window.open("");
                        $(tWindow.document.body)
                            .html(
                                "<div style='width:350px;margin:auto;object-fit: scale-down;'>" +
                                "<img id='Image' src=" +
                                imgData +
                                " style='width:100%;'></img> </div>"
                            )
                            .ready(function () {
                                tWindow.focus();
                                tWindow.print();
                            });
                        // let doc = new jsPDF({
                        //     orientation: "landscape",
                        //     unit: "in",
                        //     format: [3.5, 2]
                        // });
                        // console.log("imgData", imgData); // var canvas = doc.canvas;
                        // canvas.height = 1050;
                        // canvas.width = 600;
                        // doc.addImage(imgData, "JPEG", 0, 0, 3.5, 2); // change parameters to change size of print pdf
                        // doc.save("certificate.pdf");
                        // this.showPreview = false;
                        //this.printInProgress = false;
                        this.allowCardPreview = false;
                    });
                } catch (e) {
                    console.log('e', e);
                    // this.showPreview = false;
                    this.allowCardPreview = false;
                    alert("Unable to print. Please try again");
                    // this.printInProgress = false;
                }
            }, 1000);
            setTimeout(_ => {
                this.close();
            }, 2000);
        } catch (e) {
            this.printInProgress = false;

            alert("Unable to print. Please try again outside");
        }
    }

    // crypto gift card Image

    getCryptoGiftImages() {
        this.cardService.getCryptoGiftImg().subscribe(response => {

            let body = (<any>response)
            if ((<any>body).success == true) {
                this.cryptoGiftImages = body;
                let cryptoImg = [];
                this.cryptoGiftImages.crypto.map(image => {
                    // console.log(image);
                    image.color = "#f89f36";
                    image.type = "crypto";
                    image.currency = image.keyword;
                    image.font = "white";
                    image.icon = image.logo;
                    image.image = image.template;
                    cryptoImg.push(image);
                });

                this.cryptoTemplates = cryptoImg;
            }
        });
    }

    getStocksGiftImages() {
        this.cardService.getStocksGiftImg().subscribe(response => {
            if ((<any>response).status == 200) {
                let body = (<any>response)
                if ((<any>body).success == true) {
                    this.stocksGiftImages = body;
                    let stocksImg = [];
                    this.stocksGiftImages.stock.map(image => {
                        image.color = '#f89f36';
                        image.type = 'stocks';
                        image.currency = image.stock_name;
                        image.currency_name = image.stock_name;
                        image.font = 'white';
                        image.icon = image.logo;
                        image.image = image.template;
                        image.quantity = image.quantity;
                        stocksImg.push(image);
                    })
                    this.stocksTemplates = stocksImg;
                    console.log('this.stocksTemplates', this.stocksTemplates);
                }
            }
        })
    }

    printMarketFlyer() {
        this.printInProgress = true;

        try {
            setTimeout(_ => {
                try {
                    html2canvas(document.querySelector("#flyermodalId"), {
                        logging: false,
                        useCORS: true,
                        letterRendering: true,
                        allowTaint: false
                    }).then(canvas => {
                        var imgData = canvas.toDataURL("image/jpeg");

                        var tWindow = window.open("");
                        $(tWindow.document.body)
                            .html(
                                "<div style='width:600px;margin:auto'>" +
                                "<img id='Image' src=" +
                                imgData +
                                " style='width:100%;'></img> </div>"
                            )
                            .ready(function () {
                                tWindow.focus();
                                tWindow.print();
                            });

                        this.printInProgress = false;
                    });
                } catch (e) {
                    // this.showPreview = false;
                    alert("Unable to print. Please try again");
                    this.printInProgress = false;
                }
            }, 1000);
        } catch (e) {
            this.printInProgress = false;

            alert("Unable to print. Please try again");
        }
    }

    ActionChanged(item, event) {
        console.log(item, this.selectedAction);
        if (item && this.selectedAction == 'preview') {
            this.showCard(item, event);
            this.showresend = false;
            this.revockedCardview = false;
        } else if (item && this.selectedAction == 'revoke') {
            this.showresend = false;
            this.allowCardPreview = false;
            this.revockedCardview = true;
            this.showCard(item, event);
            this.revokedToken = {
                action: this.selectedAction,
                id: item.gift_card_id,
                user_id: this.cardService.encrypt(this.userId)
            };
             console.log(this.revokedToken);
            // Swal({
            //     title: 'Are you sure you want to revoke this card',
            //     text: 'You will not be able to recover this order!',
            //     type: 'warning',
            //     showCancelButton: true,
            //     allowOutsideClick: false,
            //     confirmButtonText: 'Yes, Revoke it',
            //     cancelButtonText: 'No, Cancel it',
            //     imageUrl: item.template_image,
            //     imageWidth: 1000,
            //     imageHeight: 200 
            // }).then((result) => {
            //     this.userId = sessionStorage.userId;
            //     this.fetchMyCards();
            //     let body = {
            //         user_id: this.cardService.encrypt(this.userId)
            //     };
            //     if (result.value) {
            //         this.authService.revokeGiftCard(token).subscribe(response => {
            //             let data = (<any>response)
            //             let decdata = this.decryptmethod(data, this.global.publicKey);
            //             let resp = JSON.parse(decdata);
            //             if (<any>resp) {
            //                 let responseData = resp;
            //                 //   this.message = responseData.msg;
            //                 Swal({
            //                     type: 'success',
            //                     title: responseData.msg,
            //                     showConfirmButton: false,
            //                     timer: 2000
            //                 })
            //                 this.fetchMyCards();
            //                 this.selectedAction = '';
            //                 this.fetchWallet();
            //             }
            //         },
            //             err => {
            //                 0
            //                 Swal({

            //                     type: 'error',
            //                     title: 'Failed to revoked the gift card:',
            //                     showConfirmButton: false,
            //                     timer: 2000
            //                 })
            //                 this.selectedAction = '';
            //             }
            //         );
            //     } else if (result.dismiss === Swal.DismissReason.cancel) {
            //         Swal({
            //             type: 'error',
            //             title: 'Your card is safe :)',
            //             showConfirmButton: false,
            //             timer: 2000
            //         })
            //         this.selectedAction = '';
            //     }
            // });

        } else if (item && this.selectedAction == 'resend') {
            this.resendtoken = {
                id: item.gift_card_id,
                user_id: this.cardService.encrypt(this.userId),
                email: ''
            };
            this.showCard(item, event);
            if (item.recepientEmail !== '') {
                this.showrecepientEmailfield = true;
                this.showresend = true;
                this.comparableEmail = item.recepientEmail;
            } else {
                this.showrecepientEmailfield = false;
                this.showresend = false;
            }

        } else {
            return false;
        }
    }
    hasCardExpiry(flag) {
        this.hasCardExpiryDate = flag;
    }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if (environment.requestEnv == '') {
            let encnoobj = this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data": encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
    decryptmethod(inputdata, key) {
        if (environment.requestEnv == '') {
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
        return JSON.stringify(inputdata);
    }
}
