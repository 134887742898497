/****************************************
 * Application routes controller
 * **************************************/
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { AuthguardService } from "./services/authguard.service";
import { SigninComponent } from "./signin/signin.component";
import { SignupComponent } from "./signup/signup.component";
import { RedeemComponent } from "./redeem/redeem.component";
import { ContactComponent } from "./contact/contact.component";
import { PressComponent } from "./press/press.component";
import { CareersComponent } from "./careers/careers.component";
import { BlogComponent } from "./blog/blog.component";
import { TermsconditionComponent } from "./termscondition/termscondition.component";
import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { DisclosuresComponent } from "./disclosures/disclosures.component";
import { TeenSigninComponent } from "./teen-signin/teen-signin.component";
import { ChooseLoginAccountComponent } from "./choose-login-account/choose-login-account.component";
import { TeenForgetComponent } from "./teen-forget/teen-forget.component";
import {DisclaimerComponent } from "./disclaimer/disclaimer.component";
import { AmlpolicyComponent } from "./amlpolicy/amlpolicy.component";
import { CcpaComponent } from "./ccpa/ccpa.component";

// application main routes
const routes: Routes = [
    // { path: "", redirectTo: "home", pathMatch: "full" },
    
    {
        path: "",
        loadChildren: "./home/home.module#HomeModule",
        runGuardsAndResolvers: "always"
    },

    {
        path: "login-type",
        component: ChooseLoginAccountComponent,
        pathMatch: "full"
    },
    { path: "login", component: SigninComponent, pathMatch: "full" },
    { path: "teen-login", component: TeenSigninComponent, pathMatch: "full" },
    { path: "teen-forget", component: TeenForgetComponent },
    { path: "register", component: SignupComponent, pathMatch: "full" },
    { path: "redeem", component: RedeemComponent },
    { path: "contact-us", component: ContactComponent },
    { path: "press", component: PressComponent },
    { path: "careers", component: CareersComponent },
    { path: "blog", component: BlogComponent },
    { path: "disclosures", component: DisclosuresComponent },
    { path: "disclaimer",component: DisclaimerComponent },
    { path: "privacy-policy", component: PrivacypolicyComponent },
    { path: "terms-and-conditions", component: TermsconditionComponent },
    { path: "aml-policy", component: AmlpolicyComponent },
    { path: "ccpa-policy", component: CcpaComponent },
    {
        path: "about-us",
        loadChildren: "./about/about.module#AboutModule",
        pathMatch: "full"
    },
    {
        path: "home",
        loadChildren: "./home/home.module#HomeModule",
        pathMatch: "full"
    },
    {
        path: "giftcard",
        loadChildren:
            "./gift-card-editor/gift-card-editor.module#GiftCardEditorModule",
        runGuardsAndResolvers: "always"
    },
    {
        path: "dashboard",
        loadChildren: "./dashboard/dashboard.module#DashboardModule"
    },
    {
        path: "how-it-works",
        loadChildren: "./howitworks/howitworks.module#HowitworksModule",
        pathMatch: "full"
    },

    // {
    //     path: "contact-us",
    //     loadChildren: "./contact-us/contact-us.module#contact-usModule",
    //     pathMatch: "full"
    // },
    {
        path: "not-found",
        loadChildren: "./not-found/not-found.module#NotFoundModule"
    },
    { path: "**", redirectTo: "not-found" }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
            onSameUrlNavigation: "reload"
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
