import { Component, OnInit } from "@angular/core";
import { GiftcardService } from "../services/giftcard.service";
@Component({
    selector: "app-termscondition",
    templateUrl: "./termscondition.component.html",
    styleUrls: ["./termscondition.component.scss"]
})
export class TermsconditionComponent implements OnInit {
    termsData: any;
    constructor(private giftService: GiftcardService) {}

    ngOnInit() {
        this.getTermsDataDetails();
    }

    getTermsDataDetails() {
        this.giftService.getTermsPageData().subscribe(response => {
            // let body = JSON.parse((<any>response)._body);

            if ((<any>response).success == true) {
                this.termsData = response;
                //console.log(this.termsData);
            }
        });
    }
}
