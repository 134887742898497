import { Component, OnInit } from '@angular/core';
import { GiftcardService } from 'src/app/services/giftcard.service';
import Swal from "sweetalert2";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-link-accounts',
  templateUrl: './link-accounts.component.html',
  styleUrls: ['./link-accounts.component.scss']
})
export class LinkAccountsComponent implements OnInit {
  userId: string;
  accountsdata: { user_id: any; accountName: any; publicToken: any; accountId: any; };
  accountcount: number = 0;
  accountslist;
  constructor(
    private cardService: GiftcardService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.fetchMyAccounts();
  }
  fetchMyAccounts() {
    if (sessionStorage.getItem('userId')) {
      this.userId = sessionStorage.getItem("userId");
      let token = {
        user_id: this.userId
      };
      this.cardService.fetchMyAccounts(token).subscribe(response => {
        let dataobtained = <any>response;
        if ((dataobtained.success == true)) {
          this.accountcount = 1;
          this.accountslist = dataobtained.data;
        } else {
          this.accountcount = 0;
        }


      });
    }
  }
  onPlaidSuccess(event) {
    console.log(event);
    // Send the public token to your server so you can do the token exchange.
    if (event.token && event.metadata) {
      this.accountsdata = {
        user_id: sessionStorage.userId,
        accountName: event.metadata.account.name,
        publicToken: event.token,
        accountId: event.metadata.account.id
      }
      this.cardService.linkbankaccount(this.accountsdata).subscribe(resp => {
        let token1 = {
          user_id: this.userId
        };
        console.log(resp);
        let dataobtained = (<any>resp)
        if (dataobtained.success == true) {
          Swal({
            type: "success",
            title: "Account Linked successfully now you can use wallet amount",
            text: 'Account Linked successfully',
            customClass: "swal-custom",
            confirmButtonColor: "#053a6a",
            allowOutsideClick: false,
          });
          this.fetchMyAccounts();
          window.scroll(0, 0);
          this.router.navigateByUrl("/dashboard/home");
        } else if (dataobtained.success == false) {
          Swal({
            type: "error",
            title: "${dataobtained.msg}",
            allowOutsideClick: false,
          })
        }
      });

    }
  }

  onPlaidExit(event) {
    // Get errors or exit reason.
    console.log(event);
  }

  onPlaidEvent(event) {
    // Log events so you can have insight into how your users are using plaid link.
  }

  onPlaidLoad(event) {
    // Do something when the iframe loads.
  }

  onPlaidClick(event) {
    // Do something when the button is clicked.
  }

}
