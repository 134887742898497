import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GiftcardService } from '../services/giftcard.service';
import { AuthguardService } from '../services/authguard.service';
import Swal from 'sweetalert2';
import { ApiencryptService } from '../services/apiencrypt.service';
import { ApidecryptService } from '../services/apidecrypt.service';
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
import { from } from 'rxjs';
// import { RxStompService, InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss']
})
export class RedeemComponent implements OnInit {
  public redeemform: FormGroup;
  public cardInfo: boolean = false;
  public giftCardInfo: any = {};
  giftCardAmount = '';
  invalidRedeemCode = '';
  alreadyRedeemed = '';
  msg = '';
  giftCardMessage = '';
  loginError: string = '';
  otp: string = '';
  userInfo: any = undefined;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cardService: GiftcardService,
    private authService: AuthguardService,
    private apienc: ApiencryptService,
    private apidec: ApidecryptService,
    private global: GlobalPipe,
    // private rxStompService: RxStompService,
    private toastr: ToastrService) {
     }

  ngOnInit() {
    this.redeemform = this.fb.group({
      code: ["", Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11), Validators.pattern(/^\d{3}-\d{3}-\d{3}$/)])],
      redeem: [false,]
    });

    // detect if user came from signin or signup screen to auto redeem code
    this.activatedRoute.params.subscribe(params => {
      if (params.code) {
        this.redeemform.controls["code"].setValue(params.code);
        this.redeemAutomatically(params.code);
      }
    });

    // fetch redeem code from url for user who came from mail invitation link
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.code) {
        this.redeemform.controls["code"].setValue(params.code);
      }
    });

  }

  verifyRedeemCode() {
    // verify redeem code
    let form = this.redeemform.value;
    let code = form.code.split("-").join('');
    let encCode = this.cardService.encrypt(code);
    let data = {
      is_confirm: "0",
      redeem_code: code
    };
        let strdata = JSON.stringify(data);
        let encdata = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION); 
    this.cardService.getRedeemCardInfo(encdata).subscribe(response => {
      // let body = JSON.parse((<any>response)._body);
      let decdata = this.decryptmethod(response, this.global.publicKey);
      let resp = JSON.parse(decdata);
      if (resp.success == true) {
        this.cardInfo = true;
        this.giftCardInfo = resp;
        this.giftCardMessage = resp.msg;
        this.giftCardAmount = resp.amount;
      } else {
        this.invalidRedeemCode = resp.msg;
      }
    });
  }

  cancel() {
    // cancel redeem code
    this.cardInfo = false;
    this.giftCardInfo = undefined;
    this.redeemform.reset();
    this.router.navigateByUrl("/home");
  }

  redeemCode() {
    // checked if logged in 
    let form = this.redeemform.value;
    let code = form.code.split("-").join('');
    let encCode = this.cardService.encrypt(code);
    let userId = sessionStorage.getItem('userId');
    if (code.length == 9) {
      let component = this;
      if (sessionStorage.isLoggedIn == 'true') {
        let data = {
          is_confirm: "1",
          redeem_code: code,
          user_id: userId
        };
        let strdata = JSON.stringify(data);
        let encdata = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION); 
        this.cardService.redeemCard(encdata).subscribe(response => {
          // let data = JSON.parse((<any>response)._body);
          let decdata = this.decryptmethod(response, this.global.publicKey);
          let body = JSON.parse(decdata);
          if (body.success) {
            this.fetchWalletDetails();
            Swal({
              type: 'success',
              title: 'Success',
              html: `${body.msg} </br> <img *ngIf="body.redeem_image != undefined && body?.redeem_image" style="width:80px; height:60px;" src="${body.redeem_image}" alt="">`,
              allowOutsideClick: false,
              customClass: 'swal-custom',
              confirmButtonColor: '#053a6a',
            }).then(result => {
              component.router.navigateByUrl("/dashboard");
            });
          } else {
            this.alreadyRedeemed = body.error;
            this.msg = body.msg;
          }
        });
      } else {
        this.loginError = 'You must be logged in to redeem this gift card.';
      }
    }
  }

  redeemAutomatically(redeemCode) {
    let code = redeemCode.split("-").join('');
    let encCode = this.cardService.encrypt(code);
    if (code.length == 9) {
      let component = this;
      // verify and get info
      let data = {
        is_confirm: "0",
        redeem_code: code
      };
      let strdata = JSON.stringify(data);
      let encdata = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION); 
      this.cardService.getRedeemCardInfo(encdata).subscribe(response => {
        // let body = JSON.parse((<any>response)._body);
        let decdata = this.decryptmethod(response, this.global.publicKey);
        let resp = JSON.parse(decdata);
        if (resp.success == true) {
          this.giftCardInfo = resp;
          this.giftCardMessage = resp.msg;
          this.giftCardAmount = resp.amount;
          let userId = sessionStorage.getItem('userId');
          if (sessionStorage.isLoggedIn == 'true') {
            let data = {
              is_confirm: "1",
              redeem_code: code,
              user_id: userId
            };
            this.cardService.redeemCard(data).subscribe(response => {
              let body = (<any>response);
              if (body.success) {
                this.fetchWalletDetails();
                Swal({
                  type: 'success',
                  title: 'Success',
                  text: body.msg,
                  allowOutsideClick: false,
                  customClass: 'swal-custom',
                  confirmButtonColor: '#053a6a',
                }).then(result => {
                  component.router.navigateByUrl("/dashboard");
                });
              } else {
                Swal({
                  type: 'error',
                  title: 'Error',
                  text: body.error,
                  allowOutsideClick: false,
                  customClass: 'swal-custom',
                  showCancelButton: true,
                  confirmButtonColor: '#053a6a',
                  cancelButtonColor: '#d33',
                });
              }
            });
          }
        }
      });
    }
  }

  preventAlhpabets($event) {
    let value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, "");
    if ($event.inputType == "deleteContentBackward") {
    } else if ($event.inputType == "insertFromPaste") {
      value = value.replace(/-/g, '');
      value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6, 9);
    } else {
      if (value.length == 3 || value.length == 7) {
        value += '-';
      }
    }
    this.redeemform.controls["code"].setValue(value);
  }

  fetchWalletDetails() {
    if (sessionStorage.length > 0 && sessionStorage.isLoggedIn) {
      this.userInfo = JSON.parse(sessionStorage.userInfo);
      this.authService.fetchWallet({
        user_id: this.cardService.encrypt(this.userInfo.id.toString())
      }).subscribe(response => {
        if ((<any>response).status == 200) {
        }
      });
    }
  }

  navigateToLogin() {
    this.router.navigate(["/login", { code: this.redeemform.value.code }]);
  }
  navigateToSignup() {
    this.router.navigate(["/register", { code: this.redeemform.value.code }]);
  }
  //encrypt method
  encryptmethod(inputdata, key, schema_version) {
    if(environment.requestEnv == ''){
        let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
        let encobj = {"enc_data" : encnoobj};
        return encobj;
    }
    return JSON.parse(inputdata);
}

//decrypt method
 decryptmethod(inputdata, key) {
    if(environment.requestEnv == ''){
        return this.apidec.decrypt(inputdata.enc_data, key);
    }
        return JSON.stringify(inputdata);
}
}
