import { Injectable } from "@angular/core";

@Injectable()
export class GlobalPipe{
  public ENC_KEY_TWO = 'vybx%WB*3428009wLgERlH';
  public DEFAULT_SCHEMA_VERSION = 3;
  public algo = 'sha1';
  public iterations = 10000;
  public length = 32;
  public saltLength = 8;
  public hmacLength = 32;
  public ivLength = 16;
  // public publicKey = 'HI368DHG9GhT834hdQwR2n9nV';
  public publicKey = 'e398763c567b6aa0f94e9f633399bf33db9764b1c26326506e0291dae2e8c29ab6f8e12e6ae184e5b00db659d54bd5cd';
}

