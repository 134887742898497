import { ErrorInterceptor } from './error.interceptor';
import { StatesService } from './services/states.service';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
// import { TokenService } from './token.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule,APP_INITIALIZER, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared/header/header.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthguardService } from "./services/authguard.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FooterComponent } from "./shared/footer/footer.component";
import { SigninComponent } from "./signin/signin.component";
import { SignupComponent } from "./signup/signup.component";
import { RedeemComponent } from "./redeem/redeem.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//  import { NgDatepickerModule } from "ng2-datepicker";
import { DashboardModule } from "./dashboard/dashboard.module";
import { NgxMaskModule } from "ngx-mask";
import { HttpLoaderComponent } from "./shared/http-loader/http-loader.component";
import {
    NgbDatepickerConfig,
    NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateUSAParserFormatter } from "./services/dateformater.service";
import { ContactComponent } from "./contact/contact.component";
import { PressComponent } from "./press/press.component";
import { CareersComponent } from "./careers/careers.component";
import { BlogComponent } from "./blog/blog.component";
import { TermsconditionComponent } from "./termscondition/termscondition.component";
import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { DisclosuresComponent } from "./disclosures/disclosures.component";
import { TeenSigninComponent } from './teen-signin/teen-signin.component';
import { ChooseLoginAccountComponent } from './choose-login-account/choose-login-account.component';
import { TeenForgetComponent } from './teen-forget/teen-forget.component';
import { InactivityTimerComponent } from "./inactivity-timer.component";
import { GlobalPipe } from './shared/globalpipes/global.pipe';
import { ApiencryptService } from "./services/apiencrypt.service";
import { ApidecryptService } from './services/apidecrypt.service';
import { DeviceDetectorModule} from 'ngx-device-detector';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
// import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
// import { AppLoadModule } from './app-load/app-load.module';
// import { myRxStompConfig } from './my-rx-stomp.config';
import { NumberDirective } from './dashboard/numbers-only.directive';
import { ToastrModule } from 'ngx-toastr';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CcpaComponent } from './ccpa/ccpa.component';
import { AmlpolicyComponent } from './amlpolicy/amlpolicy.component';


/**************************
 *  Declare app module    *
 ***************************/
var options = {};
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SigninComponent,
        FooterComponent,
        SignupComponent,
        RedeemComponent,
        HttpLoaderComponent,
        ContactComponent,
        PressComponent,
        CareersComponent,
        BlogComponent,
        TermsconditionComponent,
        PrivacypolicyComponent,
        DisclosuresComponent,
        TeenSigninComponent,
        ChooseLoginAccountComponent,
        TeenForgetComponent,
        InactivityTimerComponent,
        DisclaimerComponent,
        NumberDirective,
        CcpaComponent,
        AmlpolicyComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        // AppLoadModule,
        NgbModule,
        HttpClientModule,
        // HttpModule,
        FormsModule,
        ReactiveFormsModule,
        // NgDatepickerModule,
         DashboardModule,
        NgxMaskModule.forRoot(options),
        DeviceDetectorModule.forRoot(),
        AccordionModule.forRoot(),
        ToastrModule.forRoot(
          {
            positionClass: 'toast-top-right',
            preventDuplicates: true,
          }
        ), // ToastrModule added
        TabsModule
    ],
    providers: [
        GlobalPipe,
        AuthguardService,
        // TokenService,
        ApiencryptService,
        ApidecryptService,
        StatesService,
        { provide: NgbDateParserFormatter, useClass: NgbDateUSAParserFormatter },
        { provide : HTTP_INTERCEPTORS , useClass :ErrorInterceptor, multi :true}
        // {
        //     provide: InjectableRxStompConfig,
        //     useValue: myRxStompConfig,
        //   },
        //   {
        //     provide: RxStompService,
        //     useFactory: rxStompServiceFactory,
        //     deps: [InjectableRxStompConfig]
        //   }
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
