import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthguardService } from "../../../services/authguard.service";
import { GiftcardService } from "../../../services/giftcard.service";
import { Router, NavigationExtras } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../../../environments/environment";
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import Swal from "sweetalert2";

@Component({
  selector: 'app-customer-relationship',
  templateUrl: './customer-relationship.component.html',
  styleUrls: ['./customer-relationship.component.scss']
})
export class CustomerRelationshipComponent implements OnInit {
    @ViewChild("usersTable",{static:false})usersTable;
    @ViewChild("closeModalOnClick") closeModalOnClick: ElementRef

    users: any;

    cards: any;
    printcards: any;
    giftTemplates: any[] = [];
    cryptoTemplates: any[] = [];
    stocksTemplates: any[] = [];
    marketingFlyerTemplates: any[] = [];
    filters: any = {
        recipient: {
            firstname: "",
            lastname: "",
            email: "",
            phone: ""
        },
        status: "",
        cardname: "",
        amount: "",
        expiry: "",
        type: ""
    };

    selectedCard: any = undefined;
    currentVisibleCard: number = 0;
    bussinessImage: any = undefined;
    cardBackgroundImage: any = undefined;
    selectedTemplate: any = undefined;

    allowCardEdit: boolean = false;
    allowCardPreview: boolean = false;
    allowCardPreviewBackside: boolean = false;
    printInProgress: boolean = false;
    logo: any;
    cryptoGiftImages: any;
    stocksGiftImages: any;
    showMassEmailDialog: boolean = false;
    showUserFilterDialog: boolean = false;
    selectedFlyer: any;
    selectedFlyerTemplate: any;
    printFlyers: any;
    SERVER_IMG_HOST: any;
    tempName: string = '';
    // qrcode: any;

    searchUserForm = new FormGroup({
        first_name: new FormControl('', Validators.compose([
            Validators.pattern(/^[a-zA-Z]*$/),
            Validators.maxLength(15),
        ])),
        last_name: new FormControl('', Validators.compose([
            Validators.pattern(/^[a-zA-Z]*$/),
            Validators.maxLength(15),
        ])),
        email: new FormControl('', Validators.compose([
            Validators.maxLength(20),
        ])),

        phone: new FormControl('', Validators.compose([
            Validators.pattern(/^[0-9]+$/),
            Validators.maxLength(10),
            Validators.minLength(1)
          ])),
    });

    massEmailForm = new FormGroup({
        eventName: new FormControl('', Validators.compose([
            Validators.maxLength(20),
            Validators.minLength(3)
        ])),
        amount: new FormControl('', Validators.compose([
            Validators.required,
            Validators.maxLength(7),
            Validators.minLength(1)
        ])),
    });

    constructor(
        private cardService: GiftcardService,
        private router: Router,
        private authService: AuthguardService,
        private domSanitizer: DomSanitizer,
    ) {
        this.SERVER_IMG_HOST = environment.serverImgHost;
    }

    ngOnInit() {
        this.getUsers();
    }

    clearMassEmailForm(){
        this.searchUserForm.patchValue({
            first_name : '',
            last_name: '',
            email: '',
            phone: '',
        });
    }

    closeUserFilterDialog(){
        this.closeModalOnClick.nativeElement.style.display = 'none';
        this.showUserFilterDialog = false;
    }


    enableSendMailButton (){
        let count = 0;
        this.users.map( user => {   if(user.selected){  count = count + 1;  }});
        if(count == 0){
            return true;
        }else{
            return false;
        }
    }

    getUsers() {
        const user = JSON.parse(sessionStorage.userInfo);
        const data = {
            user_id: user.id,
            first_name : this.searchUserForm.value.first_name,
            last_name: this.searchUserForm.value.last_name,
            email:this.searchUserForm.value.email,
            phone: this.searchUserForm.value.phone,
        }
        this.authService.getUsers(data).subscribe(users => {
          this.users = users;
          this.setSelectedFlags();
          this.closeUserFilterDialog();
        });
    }

    sendMassEmails(){
        const user = JSON.parse(sessionStorage.userInfo);
        const data = {
            redeemurl: location.href.replace("dashboard/customer-relationship", "redeem"),
            eventName: this.massEmailForm.value.eventName,
            amount: this.cardService.encrypt(this.massEmailForm.value.amount.toString()),
            user_id: user.id,
            senderName : user.first_name+' '+user.last_name,
            users : this.users
        }

        this.cardService.sendMassEmails(data).subscribe(response => {
            let body = (<any>response)
            if ((<any>body).success == true) {
                Swal({
                    title: "Success",
                    text:
                        "Gift Cards sent Successfully",
                    type: "success",
                    confirmButtonColor: "#053a6a",
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                }).then(_ => {});
                this.showMassEmailDialog = false;
                this.setSelectedFlags();
            }
        });
    }

    selectAllUsers(event){
        this.users.map(user => {
            if(event.target.checked){
                user.selected = true;
            }else{
                user.selected = false;
            }
        });
    }

    selectUser(user, event) {
        if(event.target.checked){
            user.selected = true;
        }else {
            user.selected = false;
        }
        event.stopPropagation();
    }



    setSelectedFlags(){
        this.users.map(user => {
            user.selected = false;
        });
    }

    closeDialog() {
        this.closeModalOnClick.nativeElement.style.display = 'none';
        this.showMassEmailDialog = false;
    }

    showCustomerDetails(user) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                userId: user.id,
                userName: user.first_name + ' ' + user.last_name,
            },
        };
        this.router.navigate(['dashboard/customer-details'], navigationExtras);
    }

    preventText($event){
        const value = $event.target.value.replace(/[a-zA-Z%@*^$!)(_+=#&\s]/g, '');
        $event.target.value = value;
    }

    preventNumeric($event){
        const value = $event.target.value.replace(/[0-9%@*^$!)(_+=#&]/g, '');
        $event.target.value = value;
    }

    showSendMailSection() {
        this.closeModalOnClick.nativeElement.style.display = 'block';
        this.massEmailForm.reset();
        this.showMassEmailDialog = !this.showMassEmailDialog;
    }

    showUserFilterSection() {
        this.closeModalOnClick.nativeElement.style.display = 'block';
        this.showUserFilterDialog = !this.showUserFilterDialog;
    }

    close(){
        this.closeModalOnClick.nativeElement.style.display = 'none';
        this.showMassEmailDialog =  false;
        this.showUserFilterDialog = false;
    }
}
