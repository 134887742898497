import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthguardService } from '../../../services/authguard.service';
import { GiftcardService } from '../../../services/giftcard.service';
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
@Component({
  selector: 'app-withdraw-transfer-history',
  templateUrl: './withdraw-transfer-history.component.html',
  styleUrls: ['./withdraw-transfer-history.component.scss']
})
export class WithdrawTransferHistoryComponent implements OnInit {
  token: string = '';
  visibleTab: string = 'withdraw';
  withdrawHistory: any;
  transferHistory: any;
  filtersWithdraw: any = {
    id: "",
    amount: "",
    mode: "",
    createdAt: "",
    status: "",
  };
  filtersTransfer: any = {
    cardType: "",
    name: "",
    amount: "",
    email: "",
    phone: "",
    createdAt: "",
  };
  currencyName: any;
  currencyValue: any;
  @ViewChild("withrawHistory") withrawHistory;
  @ViewChild("transactionsHistory") transactionsHistory;
  userEmail:string;
  
  constructor(
    private authService: AuthguardService,
    private cardService: GiftcardService,
    private apienc: ApiencryptService,
    private apidec: ApidecryptService,
    private global: GlobalPipe
  ) {
    this.currencyName = sessionStorage.getItem("currencyName");
    this.currencyValue = sessionStorage.getItem("currencyValue");
    //abhay@keyutech.com: 03/25/22: Now getting logged in user email
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.userEmail = (userInfo && userInfo.email) ? userInfo.email : '';
   }

  ngOnInit() {
    this.authService.getToken().subscribe(token => {
      this.token = token;
    });
    this.withdrawTransferHistory();
  }

  withdrawTransferHistory() {
    let user_id = sessionStorage.getItem('userId');
    let body = {
      user_id: this.cardService.encrypt(user_id)
    };
    let strdata = JSON.stringify(body);
    let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
    this.authService.withdrawTransferHistory(dataenc).subscribe(response => {
      //response decrypt
      let data = (<any>response)
      let decdata = this.decryptmethod(data, this.global.publicKey);
      let resp = JSON.parse(decdata);
      if (<any>resp) {
        let responseData = resp;
        if (Array.isArray(responseData.withdraw)){
          this.withdrawHistory = responseData.withdraw;
        }
        if (Array.isArray(responseData.transfer)){
          this.transferHistory = responseData.transfer;
        }
          
      }
    })
  }

  showTab(tab: string) {
    this.visibleTab = tab;
  }

  filterWithdrawHistory() {
    let tmpHistoryData = this.withdrawHistory;
    let filteredHistoryData = [];
    // filter from id
    if (this.filtersWithdraw.id != '') {
      let searchRegex = new RegExp(this.filtersWithdraw.id, "g");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.transactionId.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from amount
    if (this.filtersWithdraw.amount != '') {
      let searchRegex = new RegExp(this.filtersWithdraw.amount, "g");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.amount.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from Transaction fee
    if (this.filtersWithdraw.transaction_fee != '') {
      let searchRegex = new RegExp(this.filtersWithdraw.transaction_fee, "g");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.transaction_fee.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from mode
    if (this.filtersWithdraw.mode != '') {
      let searchRegex = new RegExp(this.filtersWithdraw.mode, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.mode));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from createdAt
    if (this.filtersWithdraw.createdAt != '') {
      let searchRegex = new RegExp(this.filtersWithdraw.createdAt, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.createdAt));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    if (this.filtersWithdraw.status != '') {
      let searchRegex = new RegExp(this.filtersWithdraw.status, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.status));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    this.withrawHistory.inputData = filteredHistoryData;
  }

  filterTransferHistory() {
    let tmpHistoryData = this.transferHistory;
    let filteredHistoryData = [];
    // filter from cardType
    if (this.filtersTransfer.cardType != '') {
      let searchRegex = new RegExp(this.filtersTransfer.cardType, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.type));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from name
    if (this.filtersTransfer.name != '') {
      let searchRegex = new RegExp(this.filtersTransfer.name, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.name));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from amount
    if (this.filtersTransfer.amount != '') {
      let searchRegex = new RegExp(this.filtersTransfer.amount, "g");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.amount.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from amount
    if (this.filtersTransfer.transaction_fee != '') {
      let searchRegex = new RegExp(this.filtersTransfer.transaction_fee, "g");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.transaction_fee.toString()));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from email
    if (this.filtersTransfer.email != '') {
      let searchRegex = new RegExp(this.filtersTransfer.email, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.email));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from createdAt
    if (this.filtersTransfer.createdAt != '') {
      let searchRegex = new RegExp(this.filtersTransfer.createdAt, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.createdAt));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    // filter from phone
    if (this.filtersTransfer.phone != '') {
      let searchRegex = new RegExp(this.filtersTransfer.phone, "ig");
      filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.phone));
      tmpHistoryData = filteredHistoryData;
    } else {
      filteredHistoryData = tmpHistoryData;
    }
    this.transactionsHistory.inputData = filteredHistoryData;
  }

  //encrypt method
  encryptmethod(inputdata, key, schema_version) {
    if(environment.requestEnv == ''){
        let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
        let encobj = {"enc_data" : encnoobj};
        return encobj;
    }
    return JSON.parse(inputdata);
}

//decrypt method
 decryptmethod(inputdata, key) {
    if(environment.requestEnv == ''){
        return this.apidec.decrypt(inputdata.enc_data, key);
    }
        return JSON.stringify(inputdata);
}
}
