
/**************************************************************************************************
 * Authentication service for managing user signin, signup,
 * token validation, authentications
 **************************************************************************************************/
import { Injectable, HostListener } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
// import {
//     Http,
//     Request,
//     Response,
//     Headers,
//     RequestOptions,
//     RequestMethod
// } from "@angular/http";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, pipe, Subject, of, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { post } from "selenium-webdriver/http";
import Swal from "sweetalert2";
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
import { ApiencryptService } from './apiencrypt.service';
import { ApidecryptService } from './apidecrypt.service';
import { DeviceInfoService } from './device-info.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MethodService } from './method.service';
@Injectable({
    providedIn: "root"
})
export class AuthguardService implements CanActivate {

    _userActionOccured: Subject<void> = new Subject();
    public deviceInfo = null;
    // application api server base url
    SERVER_HOST: string = "";
    // token model to track user jwt token
    private token = new BehaviorSubject<string>("");
    // subject to track loginstatus of user
    private static loggedIn = new BehaviorSubject<boolean>(false);
    // subject to track wallet of user
    // private walletAmount = new BehaviorSubject<string>("0");
    private userInfo = new BehaviorSubject<any>(undefined);
    public static user = new BehaviorSubject<any>(undefined);
    private static sharedWalletFetched = new BehaviorSubject<any>(undefined);
    public static userNotifications = new BehaviorSubject<any>(undefined);
    public static userType = new BehaviorSubject<any>(undefined);
    public static sessionTimeOut = new BehaviorSubject<any>(undefined);
    private static httpRequestProcessingStatus = new BehaviorSubject<boolean>(
        false
    );
    userInactive: Subject<any> = new Subject();
    /// in activity
    // _userActionOccured: Subject<void> = new Subject();
    private tokenTimer: any;
    userActivity;
    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe,
        private devicedetail : DeviceInfoService,
        private deviceService: DeviceDetectorService,
        private methodtype :MethodService
    ) {
        this.SERVER_HOST = environment.serverHost;
        this.detectDevice();
        // this.logoutUserAfterSessionTime();
    }

    public detectDevice() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
    }

    get userActionOccured(): Observable<void> {
        return this._userActionOccured.asObservable()
    };

    notifyUserAction() {
      this._userActionOccured.next();
    }

    loginUser() {
      let isLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"));
    }

    logOutUser() {
        let isParent = JSON.parse(sessionStorage.getItem("userInfo"));
        let isNewUser = localStorage.getItem("isNewUser");
        //abhay@keyutech:03/06/22 - Added check to check new user flag is Y or not. isNewUser is Y then It is a new registerd user. Check for null as isParent was null
        if(isNewUser != 'Y' && sessionStorage.length  > 1 && isParent != null && isParent.parent_user_id == 0){
            $("#otpVerity .close").click();
                Swal({
                    title: "Error",
                    text:
                        "Your session expired due to security reasons. Please login again.",
                    type: "error",
                    allowOutsideClick: false,
                    confirmButtonColor: "#053a6a",
                    confirmButtonText: "OK"
            }).then(_ => {});
        }else{

        }
        
        //AD:03/06/22 - Added check to check new user flag is Y or not and check for null as isParent was null
        if(isNewUser != 'Y' && isParent != null && isParent.parent_user_id == 0) {
            this.loggedOutSet(null);
        }
    }


    canActivate() {
        // validate user route navigation with auth guard
        let isLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"));
        if (isLoggedIn) {
            return true;
        } else {
            this.loggedOutSet(null);
            return false;
        }
    }

    // getHTTPHeaders() {
    //     let headers = new Headers();
    //     headers.append("Authorization", "Bearer " + sessionStorage.token);
    //     headers.set("content-type", "application/json");
    //     headers.append("accept", "application/json");
    //     let options = new RequestOptions({
    //         headers: headers
    //     });
    //     return options;
    // }
   getHTTPHeaders() {
        let headers = new HttpHeaders();

        headers.append("Authorization", "Bearer " + sessionStorage.token);
        headers.set("content-type", "application/json");
        headers.append("accept", "application/json");
        headers.append("language", "enLang");
        headers.append("deviceInfo", "deviceinfo");
        headers.append("IPAddress", "");
        headers.append("requestType", "W");
        headers.append("userString", "");
        headers.append("session-token", "");
        headers.append("requestToken", "");
        headers.append("requestEnv", "");

        return {headers};
    }

    getHTTPHeaders1(){
        let headers = new HttpHeaders();
        headers.append("Authorization", "Bearer " + sessionStorage.token);
        headers.set("content-type", "application/json");
        headers.append("accept", "application/json");
        headers.append("language", "enLang");
        headers.append("deviceInfo", "deviceinfo");
        headers.append("IPAddress", "");
        headers.append("requestType", "");
        headers.append("userString", "");
        headers.append("session-token", "");
        headers.append("requestToken", "");
        headers.append("requestEnv", "dev");

        return {headers};
    }

    checkAlreadyExist(data) {

        return this.httpClient.post(`${this.SERVER_HOST}checkAlreadyExist`, data, this.methodtype.getheadersmethod()).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(false);

                }
            )
        );
    }
    signUpOTP(data) {
        this.setHttpRequestProcessing(true);
        // send otp cretae request to api server
        return this.httpClient.post(`${this.SERVER_HOST}signup_otp`, data, this.methodtype.getheadersmethod()).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(false);
                    this.loggedOutSet(error);
                }
            )
        );
    }


    signInOTP(data) {
        this.setHttpRequestProcessing(true);
        // let headers = new HttpHeaders();
        // let ip =
        //     sessionStorage && sessionStorage.ip ? sessionStorage.ip : "0.0.0.0";
        // headers.append("Authorization", "Bearer " + sessionStorage.token)
        // headers.set("content-type", "application/json")
        // headers.append("accept", "application/json")
        // headers.append("language", "enLang")
        // headers.append("deviceInfo", "deviceinfo")
        // headers.append("IPAddress", "")
        // headers.append("requestType", "W")
        // headers.append("userString", "")
        // headers.append("session-token", "")
        // headers.append("requestToken", "")
        // headers.append("requestEnv", "");
        // send otp cretae request to api server
        return this.httpClient
            .post(`${this.SERVER_HOST}login_otp`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        //console.log(response);
                        this.setHttpRequestProcessing(false);

                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    getUsers(data) {
        return this.httpClient
            .post(this.SERVER_HOST + `getUsers`,data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        let resp = (<any>response)
                        let decdata = this.decryptmethod(resp, this.global.publicKey);
                        let data = JSON.parse(decdata);
                        return data;
                        /*let users = [];
                        // we can do customizations on response
                        if (Array.isArray(data)) {
                            data.forEach((user, index) => {
                                users.push(user);
                            });
                        }
                        return users;*/
                    },
                    error => {
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    sendOTP(data) {
        this.setHttpRequestProcessing(true);
        // let headers = new Headers();
        // let ip =
        //     sessionStorage && sessionStorage.ip ? sessionStorage.ip : "0.0.0.0";
        // headers.set("content-type", "application/json");
        // headers.append("ip", ip);
        // headers.append("accept", "application/json");
        // let options = new RequestOptions({
        //     headers: headers
        // });

        // send otp cretae request to api server
        return this.httpClient
            .post(`${this.SERVER_HOST}send_otp`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    signUp(data): any {
        this.setHttpRequestProcessing(true);
        // send sign up new user request
        return this.httpClient.post(`${this.SERVER_HOST}signup`, data,this.methodtype.getheadersmethod()).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    let data = (<any>response);
                    let decdata = this.decryptmethod(data, this.global.publicKey);
                    let resp = JSON.parse(decdata);
                    if (<any>resp) {
                        let userData = resp;
                        if (userData.success == true) {
                            AuthguardService.userType.next(userData);
                        }
                    }
                    return resp;

                },
                error => {
                    this.setHttpRequestProcessing(false);
                    this.loggedOutSet(error);
                }
            )
        );
    }

    static getUserType(): Observable<any> {
        return AuthguardService.userType.asObservable();
    }

    updateReceivedGiftValue(data): any {
        // send sign up new user request
        return this.httpClient
            .post(`${this.SERVER_HOST}receivedGiftValue`, data, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {}
                )
            );
    }

    signin(data): any {
        this.setHttpRequestProcessing(true);
        // sign in user
        return this.httpClient.post(`${this.SERVER_HOST}login`, data,this.methodtype.getheadersmethod()).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    if((<any>response).access_token){
                        let body = (<any>response);
                        const expireInDuartion = parseFloat(body.expires_in);
                        AuthguardService.sessionTimeOut.next(expireInDuartion);
                        this.saveAuthData(expireInDuartion);
                    }
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(false);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    static getSessionTime(): Observable<any> {
        return AuthguardService.sessionTimeOut.asObservable();
    }

    saveAuthData(expireInDuartion){
       sessionStorage.setItem('expiration', expireInDuartion);
    }

    static isLoggedIn() {
        // getter for login status
        return AuthguardService.loggedIn.asObservable();
    }

    static getHttpRequestProcessing() {
        // getter for login status
        return AuthguardService.httpRequestProcessingStatus.asObservable();
    }
    setHttpRequestProcessing(data) {
        // getter for login status
        return AuthguardService.httpRequestProcessingStatus.next(data);
    }

    getToken() {
        // getter for login status
        return this.token.asObservable();
    }

    setToken(value) {
        // getter for login status
        this.token.next(value);
    }

    loggedInSet() {
        // set user login status true
        AuthguardService.loggedIn.next(true);
    }

    loggedOutSet(error) {
        //abhay@keyutech.com:03/06/22- Added a key to identify this is a new registerd user
        localStorage.removeItem("isNewUser");
        // set user logout
        if (error == null) {
            sessionStorage.clear();
            AuthguardService.loggedIn.next(false);
            this.userInfo.next(undefined);
            AuthguardService.sharedWalletFetched.next(undefined);
            AuthguardService.httpRequestProcessingStatus.next(false);
            this.router.navigateByUrl("/home");
        } else {
            let data = error;
            let errorObj = JSON.parse((<any>data)._body);
            if (
                data.status === 401 &&
                errorObj &&
                errorObj.error === "Unauthenticated user"
            ) {
                sessionStorage.clear();
                AuthguardService.loggedIn.next(false);
                this.userInfo.next(undefined);
                AuthguardService.sharedWalletFetched.next(undefined);
                AuthguardService.httpRequestProcessingStatus.next(false);
                this.router.navigateByUrl("/home");
                $("#otpVerity .close").click();
                Swal({
                    title: "Error",
                    text:
                        "Your session expired due to security reasons. Please login again.",
                    type: "error",
                    allowOutsideClick: false,
                    confirmButtonColor: "#053a6a",
                    confirmButtonText: "OK"
                }).then(_ => {});
            } else {
            }
        }
    }

    getuserInfo() {
        // user info getter
        return this.userInfo.asObservable();
    }

    //  getUserInfodata(){
    //          // user info getter
    //          return this.userInfo.asObservable();
    //     }

    getuserId() {
        // user info getter
        if (sessionStorage.length > 0)
            return JSON.parse(sessionStorage.userInfo.id);
        else return false;
    }

    setuserInfo(data: any) {
        this.userInfo.next(data);
    }

    verifyEmail(body: any, id:string): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `verifyEmail/${id}`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    sentOTP(body: any, id: any): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `verifyPhone/${id}`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    verifyOTP(body: any): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `emailPhoneVerificationByOtp`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    fetchWallet(data) {
         let strdata = JSON.stringify(data);
         let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}userWalletInfo`,
                dataenc,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        let responsedata = (<any>response);
                        let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                        let resp = JSON.parse(decdata);
                        let data = resp;
                        this.userInfo.next(data);
                        AuthguardService.sharedWalletFetched.next(data);
                        AuthguardService.user.next(data.user);
                        // this.setHttpRequestProcessing(false);
                        return resp;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);

                    }
                )
            );
    }

    static getSharedWallet(): Observable<any> {
        return AuthguardService.sharedWalletFetched.asObservable();
    }

    fetchCardHistory(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}giftHistoryDetails`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    // Fetch all transaction history
    fetchAllTransactionHistory(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}allTransactionHistoryDetails`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }


    fetchCertificatesHistory(body: any): Observable<any> {
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}getCertificateData`,
                dataenc,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    fetchAddMoneyHistory(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}addAmtToWltTransaction`,
                dataenc,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }
    fetchDashboardMyEStocks(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient.post(`${this.SERVER_HOST}cryptoHistoryDetails`,body,this.methodtype.getheadersmethod());
    }
    fetchDashboardRedeemHistory(body: any): Observable<any> {
        return this.httpClient.post(`${this.SERVER_HOST}fetchRedeemList`,body,this.methodtype.getheadersmethod());
    }
    fetchRedeemHistoryOfUser(body: any): Observable<any> {
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}bulkRedeemUsers`,
                dataenc,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    fetchChartDataRange(): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}cryptoCurrencyGraph`,
                {},
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        let data = JSON.parse((<any>response)._body).chartData
                            .data.columns;
                        let length = data[0].length;
                        let chartData = [
                            {
                                name: data[1][0],
                                series: []
                            },
                            {
                                name: data[2][0],
                                series: []
                            },
                            {
                                name: data[3][0],
                                series: []
                            }
                        ];
                        for (let dataIndex = 1; dataIndex <= 3; dataIndex++) {
                            for (
                                let itemIndex = 1;
                                itemIndex < length;
                                itemIndex++
                            ) {
                                let value =
                                    typeof data[dataIndex][itemIndex] ==
                                    "string"
                                        ? parseFloat(data[dataIndex][itemIndex])
                                        : data[dataIndex][itemIndex];
                                chartData[dataIndex - 1].series.push([
                                    Date.parse(data[0][itemIndex]),
                                    value
                                ]);
                            }
                        }
                        return chartData;
                    },
                    error => {
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    withDrawl(body) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}withdrawByUser`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    revokeGiftCard(body: any): Observable<any> {
        //data encrypt
     let strdata = JSON.stringify(body);
     let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}revokedGiftCard`,
                dataenc,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    sendResendCard(body: any): Observable<any> {
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}resendGiftCard`,
                dataenc,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    buyCryptoCurrency(body: any): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}buyCryptoCurrency`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    sellCryptoCurrency(body: any): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}sellCryptoCurrency`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    withdraw(body: any): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}withdrawByUser`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    chooseCardValue(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}listCards`, body, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    addMoneyWalletUser(body: any): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}addAmountToWallet`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    buyCertificate(body: any): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}creatCertificateCards`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }
    withdrawTransferHistory(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}withdrawAndTransferHistory`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    cryptoSellBuyHistory(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}cryptoSellBuyHistory`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        // this.loggedOutSet(error);
                    }
                )
            );
    }

    stocksSellBuyHistory(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}stocksSellBuyHistory`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        // this.loggedOutSet(error);
                    }
                )
            );
    }

    certificateHistory(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}certificateHistory`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        // this.loggedOutSet(error);
                    }
                )
            );
    }

    refreshToken() {
        let data = {};
        let strdata = JSON.stringify(data);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        return this.httpClient
            .post(`${this.SERVER_HOST}refresh`,dataenc, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        //response decrypt
                        let data = (<any>response);
                        let decdata = this.decryptmethod(data, this.global.publicKey);
                        let resp = JSON.parse(decdata);
                        if (<any>resp) {
                            var newToken = resp.access_token;
                            if (newToken) {
                                sessionStorage.setItem("token", newToken);
                            }
                            return newToken;
                        }
                    },
                    error => {
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    userProfileDetails(body: any) {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                `${this.SERVER_HOST}userProfileDetails`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    updateSettingsdata(body: any, id: string) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `updateProfileSettings/${id}`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    deleteSettingsDocument(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `unlinkUserDocument`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    deleteProfileImage(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `removeProfileImage`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }
    deleteTeenProfileImage(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `removeTeenProfileImage`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    toDataURL = url => {
        try {
            return fetch(url)
                .then(response => response.blob())
                .then(
                    blob =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        })
                );
        } catch (e) {}
    };

    fetchMyCards(body: any) {
        return this.httpClient
            .post(this.SERVER_HOST + `myCards`, body, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        //response decrypt
                        let dataobtained = (<any>response);
                        let decdata = this.decryptmethod(dataobtained, this.global.publicKey);
                        let resp = JSON.parse(decdata);
                        return resp;
                    },
                    error => {
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    transferGiftCryptoShares(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(this.SERVER_HOST + `addToWallet`, body, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        if ((<any>error).status == 500)
                            Swal({
                                title: "Error",
                                text: error.message,
                                type: "error",
                                allowOutsideClick: false,
                                confirmButtonColor: "#053a6a",
                                confirmButtonText: "OK"
                            });
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    transferGiftStocksShares(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `transferStock`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        if ((<any>error).status == 500)
                            Swal({
                                title: "Error",
                                text: error.message,
                                type: "error",
                                allowOutsideClick: false,
                                confirmButtonColor: "#053a6a",
                                confirmButtonText: "OK"
                            });
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    showNotifications(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `allNotifications`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }
    deleteNotification(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `removeNotification`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }
    clearAllNotifications(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `clearAllNotifications`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    generatePdf(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `generate-pdf`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    disableOrUnableNotification(body: any) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `disableOrUnableNotification`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(response => {
                    if ((<any>response).status == 200) {
                        let userData = JSON.parse((<any>response)._body);
                        if (userData.success == true) {
                            AuthguardService.userNotifications.next(userData);
                        }
                    }
                    this.setHttpRequestProcessing(false);
                    return response;
                })
            );
    }

    static getNotifications(): Observable<any> {
        return AuthguardService.userNotifications.asObservable();
    }

    // get Stocks closing price
    getStocksPrice(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .get(
                "https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=" +
                    body +
                    "&interval=5min&apikey=YJ3N7EXGHCTPARCR",
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(response => {
                    // this.setHttpRequestProcessing(false);

                    return response;
                })
            );
    }


    getNewStocksPriceApi(body:any) : Observable<any>{
        return this.httpClient
        .post(`${this.SERVER_HOST}iexTrading` , body, this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(response => {
                this.setHttpRequestProcessing(false);

                return response;
            })
        );
    }

    getStockGraphData(body:any) : Observable<any>{
        return this.httpClient
        .post(this.SERVER_HOST + 'stocksGraphApi' , body, this.methodtype.getheadersmethod()
        )
        .pipe(
            tap(response => {
                // this.setHttpRequestProcessing(false);

                return response;
            })
        );
    }

    // buy stock shares
    buyStockShares(body: any): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}buy-stock`, body, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    // get user stocks shares
    getUserStockShares(body: any): Observable<any> {
        // this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}userStocks`, body, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        // this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        // this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    // sell stock shares
    sellStockShares(body: any): Observable<any> {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(`${this.SERVER_HOST}sell-stock`, body, this.methodtype.getheadersmethod())
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    updateTeenSettingsdata(body: any, id: string) {
        this.setHttpRequestProcessing(true);
        return this.httpClient
            .post(
                this.SERVER_HOST + `updateTeen/${id}`,
                body,
                this.methodtype.getheadersmethod()
            )
            .pipe(
                tap(
                    response => {
                        this.setHttpRequestProcessing(false);
                        return response;
                    },
                    error => {
                        this.setHttpRequestProcessing(false);
                        this.loggedOutSet(error);
                    }
                )
            );
    }

    teenForget(data): any {
        this.setHttpRequestProcessing(true);
        // sign in user
        return this.httpClient.post(`${this.SERVER_HOST}forgotPassword`, data).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(false);
                    this.loggedOutSet(error);
                }
            )
        );
    }

    teenSignin(data): any {
        this.setHttpRequestProcessing(true);
        // sign in user
        return this.httpClient.post(`${this.SERVER_HOST}users-teen-login`, data).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(false);
                    this.loggedOutSet(error);
                }
            )
        );
    }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data" : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
            return JSON.stringify(inputdata);
    }
    removeteen(body:any){
        return this.httpClient.post(`${this.SERVER_HOST}remove-teen`, body, this.methodtype.getheadersmethod()).pipe(
            tap(
                response => {
                    this.setHttpRequestProcessing(false);
                    return response;
                },
                error => {
                    this.setHttpRequestProcessing(false);

                }
            )
        );
    }

getcurrency(country_id){
    this.setHttpRequestProcessing(true);
    return this.httpClient.get(`${this.SERVER_HOST}currency-conversion?country_id=${country_id}`, this.methodtype.getheadersmethod()).pipe(
        tap(
            response => {
                this.setHttpRequestProcessing(false);
                return response;
            },
            error => {
                this.setHttpRequestProcessing(false);
                this.loggedOutSet(error);
            })
    );
    }

}
