import { CommonApiService } from './../services/common-api.service';
import { environment } from './../../environments/environment';
/****************************************
 * User Sign In component
 ****************************************/
import { Component, OnInit } from "@angular/core";
import { AuthguardService } from "../services/authguard.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from "@angular/forms";
import { GiftcardService } from "../services/giftcard.service";
import { ApiencryptService } from '../services/apiencrypt.service';
import { ApidecryptService } from '../services/apidecrypt.service';
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
@Component({
    selector: "app-teen-signin",
    templateUrl: "./teen-signin.component.html",
    styleUrls: ["./teen-signin.component.scss"]
})
export class TeenSigninComponent implements OnInit {
    // sing in form declare
    public teenSigninForm: FormGroup;
    // boolean flags set
    invalidInput: boolean = false;
    signinToken: any = undefined;
    invalidMessage: string = "";
    invalidInputError: string = "";
    agreeTerms: boolean = false;
    isChecked: boolean = false;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private authService: AuthguardService,
        private cardService: GiftcardService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe,
        private _common: CommonApiService
    ) { }

    ngOnInit() {
        // initialize login form
        this.teenSigninForm = this.fb.group({
            input: ["", Validators.compose([Validators.required])],
            username: ["", Validators.compose([Validators.required])],
            password: ["", Validators.compose([Validators.required])]
        });
        if (sessionStorage.token) {
            this.router.navigateByUrl("/dashboard");
        }

        this.activatedRoute.params.subscribe(params => {
            if (params.email) {
                this.teenSigninForm.controls["input"].setValue(params.email);
            } else if (params.phone) {
                this.teenSigninForm.controls["input"].setValue(params.phone);
            } else {
            }
        });

        let isCheckedRememberUsername = localStorage.getItem("teenUsername");
        if (isCheckedRememberUsername) {
            this.isChecked = true;
            this.teenSigninForm.controls["username"].setValue(
                isCheckedRememberUsername
            );
        } else {
            this.isChecked = false;
        }
    }

    navigateToForget() {
        // /register
        this.activatedRoute.params.subscribe(params => {
            console.log(params);
            if (params.code) {
                this.router.navigate(["/teen-forget", { code: params.code }]);
            } else {
                this.router.navigate(["/teen-forget"]);
            }
        });
    }

    remeberUsername(event) {
        // console.log(event);
        if (event.target.checked) {
            if (this.teenSigninForm.value.username != "") {
                window.localStorage.setItem(
                    "teenUsername",
                    this.teenSigninForm.value.username
                );
                this.invalidMessage = "";
            } else {
                event.target.checked = false;
                this.invalidMessage = "please enter username first";
            }
        } else {
            window.localStorage.removeItem("teenUsername");
        }
    }
    validateInput() {
        // validate user input to check if valid email or password is entered
        let input = this.teenSigninForm.value.input.trim();
        let emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if (emailReg.test(input) || phoneReg.test(input)) {
            this.invalidInput = false;
            this.teenSigninForm.controls["input"].setErrors(null);
            this.invalidInputError = "";
        } else {
            this.teenSigninForm.controls["input"].setErrors({
                incorrect: true
            });
            this.invalidInput = true;
            this.invalidInputError =
                "Please enter your registered phone number/email";
        }
    }

    loginFormSubmit() {
        // submint login form to verify user

        if (
            this.teenSigninForm.value.input != "" &&
            this.teenSigninForm.value.username != "" &&
            this.teenSigninForm.value.password != ""
        ) {
            let body = {
                parentmobile: "",
                parentemail: "",
                username: this.teenSigninForm.value.username,
                password: this.teenSigninForm.value.password

            };

            let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            let input = this.teenSigninForm.value.input;
            if (emailReg.test(input)) {
                // email entered
                body = {
                    ...body,
                    parentemail: input
                };
            } else if (phoneReg.test(input)) {
                body = {
                    ...body,
                    parentmobile: input
                };
            }
            //data encrypt
            let strdata = JSON.stringify(body);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.teenSignin(dataenc).subscribe(data => {
                //response decrypt
                // let dataobtained = JSON.parse((<any>data)._body);
                let decdata = this.decryptmethod(data, this.global.publicKey);
                var logindata = JSON.parse(decdata);
                if (logindata.success == false || logindata.success == "0") {
                    this.invalidMessage = logindata.msg;
                } else {
                    this._common.checkCookieAndCallCampaignApi('Login-Success');

                    let component = this;
                    sessionStorage.setItem(
                        "userInfo",
                        JSON.stringify(logindata.user)
                    );
                    sessionStorage.setItem("token", logindata.access_token);
                    sessionStorage.setItem(
                        "tokenType",
                        JSON.stringify(logindata.token_type)
                    );
                    sessionStorage.setItem("isLoggedIn", "true");
                    sessionStorage.setItem("userId", logindata.user.id);
                    component.authService.loggedInSet();
                    component.authService.setuserInfo(logindata);
                    component.activatedRoute.params.subscribe(params => {
                        if (params.code) {
                            // redirect if user
                            component.router.navigate([
                                "/redeem",
                                { code: params.code }
                            ]);
                        } else {
                            component.router.navigateByUrl("/dashboard");
                        }
                    });
                }
            });
        } else {
            // invalid Input entered show error message
            this.invalidMessage = "Invalid input";
        }
    }

    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if (environment.requestEnv == '') {
            let encnoobj = this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = { "enc_data": encnoobj };
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
    decryptmethod(inputdata, key) {
        if (environment.requestEnv == '') {
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
        return JSON.stringify(inputdata);
    }
}
