import { Component, OnInit } from "@angular/core";
import { AuthguardService } from "src/app/services/authguard.service";
import { Router } from "@angular/router";
import { CryptoService } from "src/app/services/crypto.service";
import { GiftcardService } from "src/app/services/giftcard.service";
import Swal from "sweetalert2";
import { Observable } from "rxjs";

@Component({
    selector: "app-buy-stocks",
    templateUrl: "./buy-stocks.component.html",
    styleUrls: ["./buy-stocks.component.scss"]
})
export class BuyStocksComponent implements OnInit {
    selectedStockFromAllAvailable: any;
    transactionDone: boolean = false;
    selectedStock = "";
    selectedData: string;
    selectedDataInfo: any = undefined;
    walletAmount: number;
    date = new Date();
    insufficientBalanceError: boolean = false;
    showSummary: boolean = true;
    amount: string = "";
    cryptoWallet: any;
    stockOptions: any = undefined;
    processingFeeData: any;
    totalStockShares:any;
    processingCharges: number;
    amountEntered: number = 0;
    shares: number = 0;
    total: number = 0;
    currentPrice: number = 0;
    totalShares: number = 0;
    userInfo: any = null;
    userWalletInfo: any = null;
    is_buy_stocks: any;
    transactionDetails: any = {
        orderId: "",
        message: "",
        stock: "",
        amount: "",
        total: "",
        shares: "",
        fee: ""
    };
    observ
    currencyName: any;
    currencyValue: any;
    constructor(
        private authServices: AuthguardService,
        private router: Router,
        private cryptoService: CryptoService,
        private cardService: GiftcardService
    ) {
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
        
    }

    ngOnInit() {
        // fetch processing Fee
        this.getProcessingFee();
        // fetch wallet Amount
        this.authServices.getuserInfo().subscribe(info => {
            if (info != undefined) {
                //   console.log("info", info);
                this.userInfo = info.user;
                this.walletAmount = info.giftCardsValue;
                //   this.userWalletInfo = info.userCryptoCurrency;
                //user shares
                // this.getUserShares();
            }
        });
        // fetch all stocks data
        // this.getAllStocksData();

      
    } // ngOnint end here

    // on cancel order
    cancel() {
        Swal({
            title: "",
            text: "Do you really want to cancel?",
            type: "error",
            showCancelButton: true,
            confirmButtonColor: "#053a6a",
            cancelButtonColor: "#eb6363",
            confirmButtonText: "YES",
            cancelButtonText: "No",
            allowOutsideClick: false,
        }).then(result => {
            if (result && result.value) {
                this.amount = "";
                this.amountEntered = 0;
                this.selectedStock = "";
                this.shares = 0;
                this.processingCharges = parseFloat(
                    this.processingFeeData.cryptocurrency_fee
                );
                this.total = 0;
                this.selectedData = this.stockOptions[0].stock_name;
                this.selectedDataInfo = undefined;
            }
        });
    }

    //on select stocks
    selectStocks(selected) {
        this.getStockFee(selected);

        this.selectedStockFromAllAvailable = this.stockOptions.find(
            data => data.stock_name == selected
        );

        if (this.selectedStock) {
            this.selectedData = this.selectedStock;
            if (this.cryptoWallet && this.selectedData) {
                this.selectedDataInfo = this.cryptoWallet;
                if (this.selectedDataInfo) {
                    this.currentPrice = this.selectedDataInfo.Close;
                    //   console.log(" this.currentPrice", this.currentPrice);
                    this.showSummary = true;
                    if (
                        this.amount != "" &&
                        this.amount != "0" &&
                        this.amount != null
                    )
                        this.calculateShares();
                    if (Array.isArray(this.stockOptions)) {
                        let sharesWallet = this.userWalletInfo.find(
                            stok => stok.stock == this.selectedStock
                        );
                        if (sharesWallet) {
                            this.totalShares = sharesWallet.shares;
                        } else {
                            this.totalShares = 0;
                        }
                    }
                } else {
                }
            }
        }
    }

    calculateShares() {
        this.showSummary = false;
        if (this.amount != "" && this.amount != "0" && this.amount != null) {
            this.amountEntered = parseFloat(this.amount);
            if (this.amountEntered > this.walletAmount) {
                this.insufficientBalanceError = true;
                this.showSummary = true;
            } else {
                this.insufficientBalanceError = false;
                let amount = this.amountEntered;
                // this.processingCharges = 1.99;
                let currentPrice = this.cryptoWallet.Close;
                let processingCharges = this.processingCharges;
                let shares = amount / currentPrice;
                this.shares = shares;
                let total = amount + processingCharges;
                this.total = total;
            }
        } else {
            this.shares = 0;
            this.total = 0;
        }
        this.is_buy_stocks = 1;
        
        localStorage['total']=this.total;
        localStorage['is_buy_stocks']=this.is_buy_stocks;
    }

    // getAllStocksData() {
    //     this.cardService.getStockData().subscribe(response => {
    //         if ((<any>response).status == 200) {
    //             let stocksResponse = (<any>response)
    //             this.stockOptions = stocksResponse.stock;
    //             this.selectedStockFromAllAvailable = this.stockOptions[0];
    //             this.selectedData = this.stockOptions[0].stock_name;
    //             // fetch stocks Price
    //             this.getStockFee(this.selectedData);
    //             // console.log(" this.stockOptions", this.stockOptions);
    //         }
    //     });
    // }

    moveToAddMoneyWallet() {
        let session = JSON.stringify({
            buy: {
                amount: this.amount,
                selectedStock: this.selectedStock
            }
        });
        sessionStorage.setItem("Session", session);
        this.router.navigateByUrl("/dashboard/addmoney");
    }

    getProcessingFee() {
        this.cardService.getProcessingFeeData().subscribe(response => {
            let body = (<any>response)
            if ((<any>body).success == true) {
                this.processingFeeData = body.payment;
                //  console.log("this.processingFeeData", this.processingFeeData);
                this.processingCharges = parseFloat(
                    this.processingFeeData.transfer_fee
                );
            }
        });
    }
    // get stock price
    getStockFee(selected) {
        let body ={
            stock_name: selected
        }
        // this.authServices.getNewStocksPriceApi(body).subscribe(response => {
        //     let body =(<any>response)
        //     console.log('body', body);
        //     let dateObjectArray = Object.keys(body.chart[0]);
        //     console.log('dateObjectArray', dateObjectArray);
        //         this.cryptoWallet = body.quote
        //         this.cryptoWallet.Open = this.cryptoWallet.open
        //         this.cryptoWallet.High = this.cryptoWallet.high
        //         this.cryptoWallet.Low = this.cryptoWallet.low
        //         this.cryptoWallet.Close = this.cryptoWallet.close
        //         this.cryptoWallet.Supply = this.cryptoWallet.latestVolume
        // })

    }

    placeOrder() {
        //  this.transactionDone = true;
        sessionStorage.removeItem("Session");
        if (this.total == 0 || this.total <= 0) {
            alert("Plese enter amount to buy crypto currency");
        } else {
            let token = {
                user_id: this.cardService.encrypt(this.userInfo.id.toString()),
                type: "Purchased",
                stock: this.selectedData,
                amount: this.cardService.encrypt(this.amount.toString()),
                transaction_fee: this.cardService.encrypt(
                    this.processingCharges.toString()
                ),
                shares_quantity: this.cardService.encrypt(
                    this.shares.toString()
                ),
                currentPrice: this.cardService.encrypt(
                    this.currentPrice.toString()
                )
            };
       
            this.authServices.buyStockShares(token).subscribe(response => {
                let data = (<any>response)
                let stock = this.stockOptions.find(
                    stock => stock.id == this.selectedStock
                );
                let stockName = this.stockOptions[0].stock_name;
                if (stock) {
                    stockName = stock.stock_name;
                }
                if (data.success) {
                    let total = this.processingCharges + this.amountEntered;
                    this.transactionDetails = {
                        orderId: data.transaction_id,
                        stock: stockName,
                        message: data.msg,
                        amount: this.amount,
                        total: total.toString(),
                        shares: this.shares.toString(),
                        fee: this.processingCharges.toString()
                    };
                    this.transactionDone = true;
                    window.scroll(0,0);
                    // reset wallet
                    this.authServices
                        .fetchWallet({
                            user_id: this.cardService.encrypt(
                                this.userInfo.id.toString()
                            )
                        })
                        .subscribe(response => {});
                } else if (data.verified == false) {
                    Swal({
                        title: "",
                        text: data.msg,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#053a6a",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                    }).then(result => {
                        if (result.value == true) {
                            this.router.navigateByUrl("/dashboard/settings");
                        }
                    });
                } else {
                    Swal({
                        title: "",
                        text: data.msg,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#053a6a",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                    }).then(result => {});
                }
            });
        }
    }

    validateAmount() {
        let component = this;
        if (parseInt(this.amount) < 25) {
            // this.amount = '25';
            this.calculateShares();
        } else if (parseInt(this.amount) > this.walletAmount) {
            this.insufficientBalanceError = true;
            // this.amount = (parseInt(this.walletAmount.toString())).toString();
            this.calculateShares();
        } else {
            this.calculateShares();
        }
    }

    // reset form

    reset() {
        this.transactionDone = false;
        this.showSummary = true;
        this.amount = "";
        this.amountEntered = 0;
        this.total = 0;
        this.selectedStock = "";
        this.selectedData = this.stockOptions[0].stock_name;
        this.processingCharges = parseFloat(
            this.processingFeeData.cryptocurrency_fee
        );
        this.shares = 0;
        this.router.navigateByUrl("/dashboard");
    }
    // fetch user shares of stocks
    getUserShares() {
        let body = {
            user_id: this.cardService.encrypt(this.userInfo.id.toString())
        };
        this.authServices.getUserStockShares(body).subscribe(response => {
            let body = (<any>response)
            if ((<any>body).success == true) {
               
                this.userWalletInfo = body.stocks;
               
            }
        });
    }
}
