import { environment } from './../../../../environments/environment';
import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthguardService } from "../../../services/authguard.service";
import Swal from "sweetalert2";
import { GiftcardService } from "../../../services/giftcard.service";
import { Router } from "@angular/router";
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
@Component({
    selector: "app-teen-profile-settings",
    templateUrl: "./teen-profile-settings.component.html",
    styleUrls: ["./teen-profile-settings.component.scss"]
})
export class TeenProfileSettingsComponent implements OnInit {
    showChildImageCropper: boolean = false;
    childEmailReg: boolean = true;
    childImageChangedEvent = "";
    userId: any;
    userInfo: any;
    child: any = {
        user_id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        dob: "",
        gender: "",
        username: "",
        profile_pic: ""
    };
    profileImageName = "";
    oldDocumentName: string = "";
    oldImage: string = "";
    profileImageUploaded: boolean = false;
    childstartdate : any = {year: new Date().getFullYear() - 18, month: new Date().getMonth() + 1};
    enddate: any = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
    };
    constructor(
        private authService: AuthguardService,
        private cardService: GiftcardService,
        private router: Router,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe
    ) {}

    ngOnInit() {
        if (sessionStorage) {
            this.userId = sessionStorage.userId;
            // this.userInfo = JSON.parse(sessionStorage.userInfo);

            //  this.child.email = this.userInfo.email;
        }
        this.fetchUserInfo();
    }
    
    deleteProfileImage() {
       
            Swal({
                title: "Are you sure you want to delete your profile image.",
                type: "warning",
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonText: "Yes, Delete it",
                cancelButtonText: "No, Cancel it"
            }).then(result => {
               
                let body = {
                    user_id: this.userId
                };
                if (result.value) {
                    this.authService.deleteTeenProfileImage(body).subscribe(
                        response => {
                            Swal({
                                type: "success",
                                title:
                                    "You have successfully deleted your profile image.",
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                timer: 2000
                            });
                            if ((<any>response).status == 200) {
                                let responseData = (<any>response)
                                if (responseData.success == true) {
                                    this.oldDocumentName = "";
                                    this.fetchUserInfo();
                                }
                            }
                        },
                        err => {
                            Swal({
                                type: "error",
                                title: "Failed to delete the document:)",
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                timer: 2000
                            });
                        }
                    );
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal({
                        type: "error",
                        title: "Your document is safe :)",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        timer: 2000
                    });
                }
            });
       
    }
    allowAlphabetsEmailOnlyInput(event) {
        let parsedValue = event.target.value.replace(
            /[`~#$^&%*\(\)+=\-\[\]\/\{\}|:<>?,\s]/g,
            ""
        );
        event.target.value = parsedValue;
        this.childEmailReg = false;
        let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!emailReg.test(event.target.value)) {
            this.childEmailReg = true;
        }
    }

    preventSemicolon(e) {
        if (e.keyCode == 222 || e.keyCode == 186) {
            let parsedValue = e.target.value.replace(/[;'"]/g, "");
            e.target.value = parsedValue;
        }
    }
    allowAlphabetsOnlyInput(event) {
        let parsedValue = event.target.value.replace(
            /[`~#$^@&%*\(\)+=!\-\[\]\/\{\}|:<>?.,0-9]/g,
            ""
        );
        event.target.value = parsedValue;
    }

    // prevent keys except alphabets
    allowAlphabetsOnlyKeyup(event) {
        if (
            (event.keyCode >= 65 && event.keyCode <= 90) ||
            event.keyCode == 32 ||
            event.keyCode == 9 ||
            event.keyCode == 190 ||
            event.keyCode == 13 ||
            (event.keyCode == 8 ||
                event.keyCode == 46 ||
                (event.keyCode >= 37 && event.keyCode <= 40))
        ) {
        } else {
            event.preventDefault();
        }
    }
    imageLoaded() {}
    loadImageFailed() {}

    openChildCropper(event: any) {
        //  this.showChildImageCropper = true;
        let files = event.target.files;
        if (
            files[0] &&
            (files[0].name.indexOf(".jpg") > -1 ||
                files[0].name.indexOf(".jpeg") > -1 ||
                files[0].name.indexOf(".png") > -1)
        ) {
            this.showChildImageCropper = true;
            this.childImageChangedEvent = event;
            if (event.target.files.length > -1) {
                this.child.profile_name = event.target.files[0].name;
                // console.log(
                //     " this.addChild[index].profile_pic",
                //     this.addChild[index].profile_pic
                // );
            }
        } else {
            Swal({
                title: "Please upload only image (JPEG, PNG or JPG).",
                type: "warning",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: "OK"
            });
        }
    }
    childCropImage(image: string) {
        this.child.profile_pic = image;
        console.log(" this.child", this.child.profile_pic);
        this.oldImage = image;
    }

    validateForm(form) {
        let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        // let formData = form.value;
        let valid = true;
        // this.errors = null;

        if (this.child.first_name.length < 3) {
            valid = false;
        }
        if (this.child.last_name.length < 3) {
            valid = false;
        }
        if (this.child.username.length < 3) {
            valid = false;
        } //profile_pic

        if (this.child.gender == undefined || this.child.gender == "") {
            valid = false;
        }
        // if (
        //     this.child.email.length < 3 ||
        //     !emailReg.test(this.child.email)
        // ) {
        //     // error.firstname = "Please enter your email.";
        //     valid = false;
        // }
        if (this.child.dob == null || this.child.dob == "") {
            valid = false;
        }
        // if (this.child.password.length < 3) {
        //     valid = false;
        // }
        // if (this.child.confirmPassword.length < 3) {
        //     valid = false;
        // }
        // if (this.child.confirmPassword != this.child.password) {
        //     //  error.lastname = "Please enter your firstname.";
        //     valid = false;
        // }
        // if (this.child.phone.length < 10) {
        //     // error.firstname = "Please enter your firstname.";
        //     valid = false;
        // }

        //  console.log(this.errors);
        if (valid) {
            this.updateTeenProfileSettings();
        } else {
            //  this.errors = error;
            Swal({
                type: "error",
                title: "Error",
                text: "Please fill all required fields",
                allowOutsideClick: false,
                customClass: "swal-custom",
                confirmButtonColor: "#053a6a"
            });
        }
    }

    fetchUserInfo() {
        if (sessionStorage.length > 1) {
            this.userId = sessionStorage.userId;
            let token = {
                user_id: this.cardService.encrypt(this.userId)
            };
            let strdata = JSON.stringify(token);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION); 
            this.authService.userProfileDetails(dataenc).subscribe(res => {
                let responsedata = (<any>res);
                let decdata = this.decryptmethod(responsedata, this.global.publicKey);
                let resp = JSON.parse(decdata);
                let response = resp;
                AuthguardService.user.next(response.user);
                if (response.success) {
                    let user = response.user;
                    this.child = {
                        first_name: user.first_name != "0" ? user.first_name : "",
                        last_name: user.last_name != "0" ? user.last_name : "",
                        email: user.email != "0" ? user.email : "",
                        phone: user.phone != "0" ? user.phone.slice(-10) : "",
                        dob: {year: parseInt(user.dob.split("-")[0]), month: parseInt(user.dob.split("-")[1]), day: parseInt(user.dob.split("-")[2])},
                        gender: user.gender != "0" ? user.gender : "",
                        username:user.username != "0" ? user.username : "",
                    }
                    this.child.profile_pic = "";
                    if(this.child.phone == 0)
                    this.child.phone = "";
                    if(this.child.email == 0)
                    this.child.email = "";
                    if(this.child.profile_pic == 0) {
                     this.child.profile_name = "";
                    }
                    else{
                        this.child.profile_name = this.child.profile_pic;
                    }
                    this.child.dobBackup = this.child.dob;

                    // console.log(user);

                    // let userData = JSON.parse(
                    //     sessionStorage.getItem("userInfo")
                    // );
                    // if (userData) {
                    //     userData.show_notifications = user.show_notifications;
                    //     sessionStorage.setItem(
                    //         "userInfo",
                    //         JSON.stringify(userData)
                    //     );
                    // }
                    // let dobDate = {};
                    if (user.dob) {
                        let dob = user.dob.split("-");
                        let dobDate = {
                            year: parseInt(dob[0]),
                            month: parseInt(dob[1]),
                            day: parseInt(dob[2])
                        };
                        this.child.dob = dobDate;
                    }
                }
            });
        }
    }

    updateTeenProfileSettings() {
        //   console.log(this.child);
        this.child.user_id = this.cardService.encrypt(this.userId);

        let date = "";
        if (this.child.dob != "") {
            let month =
                this.child.dob.month < 10
                    ? "0" + this.child.dob.month
                    : this.child.dob.month;
            let day =
                this.child.dob.day < 10
                    ? "0" + this.child.dob.day
                    : this.child.dob.day;
            this.child.dob = this.child.dob.year + "-" + month + "-" + day;
        }

        // console.log(this.child);
        //data encrypt
        let strdata = JSON.stringify(this.child);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService
            .updateTeenSettingsdata(dataenc, this.userId)
            .subscribe(response => {
                let resp = (<any>response)
                let decdata = this.decryptmethod(resp, this.global.publicKey);
                let data = JSON.parse(decdata);
                if (data.success == false) {
                    //this.invalidPhoneNumber = data.msg;
                }

                if (data.success == true && data.msg) {
                    this.fetchUserInfo();
                    //    this.invalidPhoneNumber = "";
                    Swal({
                        type: "success",
                        title: "Success",
                        text: data.msg,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                } else if (data.error) {
                    Swal({
                        type: "error",
                        title: "Error",
                        text: data.error,
                        allowOutsideClick: false,
                        customClass: "swal-custom",
                        confirmButtonColor: "#053a6a"
                    });
                }
            });
    }

     //encrypt method
     encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data" : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
            return JSON.stringify(inputdata);
    }
}
