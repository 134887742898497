import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ImageCropperModule } from "ngx-image-cropper";
import {DataTableModule} from 'angular-6-datatable';
import { NgSelectModule } from "@ng-select/ng-select";
import { NouisliderModule } from "ng2-nouislider";
import { NgxMaskModule } from "ngx-mask";
import { ChartModule, HIGHCHARTS_MODULES } from "angular-highcharts";
import * as highstock from "highcharts/modules/stock.src";
import * as exporting from "highcharts/modules/exporting.src";
import { AuthguardService } from "../services/authguard.service";
import { CryptoService } from "../services/crypto.service";
import { MyDatePickerModule } from 'mydatepicker';
import { NgMarqueeModule } from 'ng-marquee';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import {
    NgbDatepickerConfig,
    NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateUSAParserFormatter } from "../services/dateformater.service";
import { DashboardComponent } from "./dashboard.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { HistoryComponent } from "./components/history/history.component";
import { AllTransactionHistoryComponent } from "./components/alltransactionhistory/alltransactionhistory.component";
import { GifthistoryComponent } from "./components/gifthistory/gifthistory.component";
import { ProfileSettingsComponent } from "./components/profile-settings/profile-settings.component";
import { NavbarComponent } from "../shared/navbar/navbar.component";
import { WithdrawComponent } from "./components/withdraw/withdraw.component";
import { HomeComponent } from "./components/home/home.component";
import { CryptohistoryComponent } from "./components/cryptohistory/cryptohistory.component";
import { StockhistoryComponent } from "./components/stockhistory/stockhistory.component";
import { CertificatehistoryComponent } from "./components/certificatehistory/certificatehistory.component";
import { TransferComponent } from "./components/transfer/transfer.component";
import { BuyComponent } from "./components/buy/buy.component";
import { SellComponent } from "./components/sell/sell.component";
import { AddMoneyComponent } from "./components/add-money/add-money.component";
import { WithdrawTransferHistoryComponent } from "./components/withdraw-transfer-history/withdraw-transfer-history.component";
import { MycardsComponent } from "./components/mycards/mycards.component";
import { AddMoneyHistoryComponent } from "./components/add-money-history/add-money-history.component";
import { CreateCertificateComponent } from "./components/create-certificate/create-certificate.component";
import { SendCertificatesComponent } from "./components/send-certificates/send-certificates.component";
import { AllNotificationsComponent } from "./components/all-notifications/all-notifications.component";
import { TransferStocksComponent } from "./components/transfer-stocks/transfer-stocks.component";
import { QRCodeModule } from "angularx-qrcode";
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { BuyStocksComponent } from "./components/buy-stocks/buy-stocks.component";
import { SellStocksComponent } from "./components/sell-stocks/sell-stocks.component";
import { TeenProfileSettingsComponent } from './components/teen-profile-settings/teen-profile-settings.component';
import { OutsideDirective } from './outside.directive';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { CustomerRelationshipComponent } from './components/customer-relationship/customer-relationship.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { StoreCardsWalletComponent } from './components/store-cards-wallet/store-cards-wallet.component';
import { RounddownPipe } from "../rounddown.pipe";
import { BtcComponent } from './components/btc/btc.component';
import { EthComponent } from './components/eth/eth.component';
import { XrpComponent } from './components/xrp/xrp.component';
import { LinkAccountComponent } from './components/link-account/link-account.component';
import { LinkAccountsComponent } from './components/link-accounts/link-accounts.component';
import { LtcComponent } from './components/ltc/ltc.component';
import { XlmComponent } from './components/xlm/xlm.component';
import { BchComponent } from './components/bch/bch.component';
import { AdaComponent } from './components/ada/ada.component';
import { DogeComponent } from './components/doge/doge.component';
import { DotComponent } from './components/dot/dot.component';
// import { CardsCertificatesComponent } from './components/cards-certificates/cards-certificates.component';
// import { AlltransactionsComponent } from './components/alltransactions/alltransactions.component';
// import { NumberDirective } from './numbers-only.directive';
@NgModule({
    imports: [
        QRCodeModule,
        CommonModule,
        DashboardRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ImageCropperModule,
        DataTableModule,
        NgSelectModule,
        ChartModule,
        NouisliderModule,
        NgxMaskModule,
        NgbModule,
        NgMarqueeModule,
        MyDatePickerModule,
        NgxPlaidLinkModule,
        TabsModule,
        Ng2SearchPipeModule
    ],
    declarations: [
        DashboardComponent,
        HistoryComponent,
        AllTransactionHistoryComponent,
        GifthistoryComponent,
        ProfileSettingsComponent,
        NavbarComponent,
        WithdrawComponent,
        HomeComponent,
        CryptohistoryComponent,
        StockhistoryComponent,
        CertificatehistoryComponent,
        TransferComponent,
        BuyComponent,
        SellComponent,
        AddMoneyComponent,
        WithdrawTransferHistoryComponent,
        MycardsComponent,
        AddMoneyHistoryComponent,
        CreateCertificateComponent,
        SendCertificatesComponent,
        AllNotificationsComponent,
        TransferStocksComponent,
        BuyStocksComponent,
        SellStocksComponent,
        TeenProfileSettingsComponent,
        OutsideDirective,
        // NumberDirective,
        TwoDigitDecimaNumberDirective,
        CustomerRelationshipComponent,
        CustomerDetailsComponent,
        StoreCardsWalletComponent,
        RounddownPipe,
        BtcComponent,
        EthComponent,
        XrpComponent,
        LinkAccountsComponent,
        LinkAccountComponent,
        LtcComponent,
        XlmComponent,
        BchComponent,
        AdaComponent,
        DogeComponent,
        DotComponent
        // CardsCertificatesComponent,
        // AlltransactionsComponent
    ],
    providers: [
        AuthguardService,
        CryptoService,
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateUSAParserFormatter
        },
        {
            provide: HIGHCHARTS_MODULES,
            useFactory: () => [exporting, highstock]
        }
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class DashboardModule {}
