import { Injectable } from '@angular/core';
import * as forge from 'node-forge';
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
@Injectable()
export class ApidecryptService {

  constructor(private global: GlobalPipe) { }
  decrypt(encryptedBase64Data, password) {
    var OPENSSL_RAW_DATA = 1;
    var components = this.unpackEncryptedBase64Data(encryptedBase64Data);
    var key = this.makeKey(components.header.encSalt, password);
    var iv = String(components.header.iv);
    //var method = 'aes-256-cbc';
    var decipher = forge.cipher.createDecipher('AES-CBC', key);
    decipher.start({ iv: iv });
    decipher.update(forge.util.createBuffer(components.ciphertext));
    decipher.finish();

    var decrypted64 = decipher.output.getBytes();
    return decrypted64;
  }

  unpackEncryptedBase64Data(encryptedData) {
    //var hmacLength = 32;
    var binaryData = forge.util.decode64(encryptedData);
    var component = {
      'header': this.parseHeaders(binaryData, true),
      'hmac': '',
      'ciphertext': ''
    }

    component.hmac = binaryData.substr(-this.global.hmacLength);

    var offset = component.header.length;
    var length = (binaryData.length) - offset - (component.hmac.length);

    component.ciphertext = binaryData.substr(offset, length)// substr($binaryData, $offset, $length);

    return component;
  }

  parseHeaders(binaryData, isPasswordBased) {
    var offset = 0;
    //var saltLength = 8;
    //var ivLength = 16;
    var versionChr = binaryData[0];
    offset += versionChr.length;
    var optionsChr = binaryData[1];
    offset += optionsChr.length;
    var encSalt = null;
    var hmacSalt = null;
    if (isPasswordBased) {

      encSalt = binaryData.substr(offset, this.global.saltLength);
      offset += encSalt.length;

      hmacSalt = binaryData.substr(offset, this.global.saltLength);
      offset += hmacSalt.length;
    }

    var iv = binaryData.substr(offset, this.global.ivLength);
    offset += iv.length;
    var headers = {
      'version': versionChr,
      'options': optionsChr,
      'encSalt': encSalt,
      'hmacSalt': hmacSalt,
      'iv': iv,
      'length': offset
    }


    return headers
  }

  makeKey(salt, password) {
    var truncatesMultibytePasswords;
    /*if (truncatesMultibytePasswords) {
      var utf8Length = mb_strlen(password, 'utf-8');
      password = password.substr(0, utf8Length);
    }*/

    /*var algo = 'sha1';
    var iterations = 10000;
    var length = 32;*/

    return this.hash_pbkdf2(this.global.algo, password, salt, this.global.iterations, this.global.length, true);
  }

  hash_pbkdf2(algo, password, salt, iterations, length, raw_output) {
    return forge.pkcs5.pbkdf2(password, salt, iterations, length, algo)
  }
}
