import { environment } from './../../environments/environment';
/****************************************
 * User Sign In component
 ****************************************/
import { Component, OnInit } from "@angular/core";
import { AuthguardService } from "../services/authguard.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from "@angular/forms";
import { GiftcardService } from "../services/giftcard.service";
import Swal from "sweetalert2";
//encrypt decrypt import
import { ApiencryptService } from '../services/apiencrypt.service';
import { ApidecryptService } from '../services/apidecrypt.service';
import { GlobalPipe } from '../shared/globalpipes/global.pipe';
@Component({
    selector: "app-teen-forget",
    templateUrl: "./teen-forget.component.html",
    styleUrls: ["./teen-forget.component.scss"]
})
export class TeenForgetComponent implements OnInit {
    // sing in form declare
    public teenForgetForm: FormGroup;
    // boolean flags set
    invalidInput: boolean = false;
    invalidMessage: string = "";
    invalidInputError: string = "";
    agreeTerms: boolean = false;
    isChecked: boolean = false;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private authService: AuthguardService,
        private cardService: GiftcardService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe

    ) {}

    ngOnInit() {
        // initialize login form
        this.teenForgetForm = this.fb.group({
            input: ["", Validators.compose([Validators.required])],
            username: ["", Validators.compose([Validators.required])]
        });
        if (sessionStorage.token) {
            this.router.navigateByUrl("/dashboard");
        }

        this.activatedRoute.params.subscribe(params => {
            if (params.email) {
                this.teenForgetForm.controls["input"].setValue(params.email);
            } else if (params.phone) {
                this.teenForgetForm.controls["input"].setValue(params.phone);
            } else {
            }
        });
    }

    validateInput() {
        // validate user input to check if valid email or password is entered
        let input = this.teenForgetForm.value.input.trim();
        let emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        if (emailReg.test(input) || phoneReg.test(input)) {
            this.invalidInput = false;
            this.teenForgetForm.controls["input"].setErrors(null);
            this.invalidInputError = "";
        } else {
            this.teenForgetForm.controls["input"].setErrors({
                incorrect: true
            });
            this.invalidInput = true;
            this.invalidInputError =
                "Please enter your registered phone number/email";
        }
    }

    forgetFormSubmit() {
        // submint login form to verify user

        if (
            this.teenForgetForm.value.input != "" &&
            this.teenForgetForm.value.username != ""
        ) {
            let body = {
                parent_email: "",
                username: this.teenForgetForm.value.username,
                parent_phone: ""
            };

            // let otpToken = {
            //     email: "",
            //     phone: ""
            // };
            let emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            let input = this.teenForgetForm.value.input;
            if (emailReg.test(input)) {
                // email entered
                body = {
                    ...body,
                    parent_email: input
                };

            } else if (phoneReg.test(input)) {
                body = {
                    ...body,
                    parent_phone: input
                };
            }
            //data encrypt
            let strdata = JSON.stringify(body);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.teenForget(dataenc).subscribe(data => {
                // let res = JSON.parse((<any>data)._body);
                let decdata = this.decryptmethod(data, this.global.publicKey);
                let resp = JSON.parse(decdata);
                let forgetData = resp;
                if (forgetData.success == false || forgetData.success == "0") {
                    this.invalidMessage = forgetData.msg;
                } else {
                    this.teenForgetForm.reset();
                    Swal({
                        title: "",
                        text: forgetData.msg,
                        type: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#053a6a",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                    }).then(result => {});
                }
            });
        } else {
            // invalid Input entered show error message
            this.invalidMessage = "Invalid Input";
        }
    }
    
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data" : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
            return JSON.stringify(inputdata);
    }
}
