import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  public deviceInfo = null;
  constructor(
    private deviceService: DeviceDetectorService
  ) {  
    this.detectDevice();
  }
  detectDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
}
}
