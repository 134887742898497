import { Component, OnInit } from "@angular/core";
import { AuthguardService } from "src/app/services/authguard.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-all-notifications",
    templateUrl: "./all-notifications.component.html",
    styleUrls: ["./all-notifications.component.scss"]
})
export class AllNotificationsComponent implements OnInit {
    token: string = "";
    notifications: string[] = [];
    constructor(private authService: AuthguardService) {}

    ngOnInit() {
        this.authService.getToken().subscribe(response => {
            this.token = response;
        });
        this.allNotifications();
    }

    allNotifications() {
        const user_id = sessionStorage.getItem("userId");
        let body = {
            user_id: user_id
        };
        this.authService.showNotifications(body).subscribe(response => {
            if ((<any>response).status == 200) {
                let data = (<any>response)
                if (data.success == true) {
                    this.notifications = data.notifications;
                }
            }
        });
    }

    deletenotification(id) {
        const user = JSON.parse(sessionStorage.userInfo);
        let body = {
            user_id: user.id,
            notification_id: id
        };
        this.authService.deleteNotification(body).subscribe(response => {
            let data = (<any>response)
            if (data.success == true && data.msg) {
                Swal({
                    type: "success",
                    title: "Success",
                    text: data.msg,
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#053a6a"
                });
                this.allNotifications();
            } else if (data.error) {
                Swal({
                    type: "error",
                    title: "Error",
                    text: data.error,
                    allowOutsideClick: false,
                    customClass: "swal-custom",
                    confirmButtonColor: "#053a6a"
                });
            }
        });
    }
}
