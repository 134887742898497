import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
import { AuthguardService } from "../../../services/authguard.service";
import { GiftcardService } from "../../../services/giftcard.service";
import { Router } from "@angular/router";
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';

@Component({
  selector: "app-alltransactionhistory",
  templateUrl: "./alltransactionhistory.component.html",
  styleUrls: ["./alltransactionhistory.component.scss"]
})
export class AllTransactionHistoryComponent implements OnInit {
  message: string = "";
  userId: string = "";
  history: any = null;
  bulkHistory: any = [];
  search: string = "";
  filter: string = "";
  showOrderInfo: boolean = false;
  orderInfo = [];
  token: string = '';
  // filters: any = {
  //   id: "",
  //   eventName: "",
  //   eventType: "",
  //   date: "",
  //   amount: "",
  //   count: "",
  //   recipient: "",
  //   shipping: "",
  // };
  @ViewChild("cardHistory") cardHistory;
  currencyName:any;
  currencyValue:any; 

  constructor(
    private authService: AuthguardService,
    private giftCardService: GiftcardService,
    private router: Router,
    private apienc: ApiencryptService,
    private apidec: ApidecryptService,
    private global: GlobalPipe
  ) {
    this.currencyName = sessionStorage.getItem("currencyName");
    this.currencyValue = sessionStorage.getItem("currencyValue");
   }

  ngOnInit() {
    // Fetch Card History
    this.authService.getToken().subscribe(token => {
      this.token = token;
    });
    this.fetchHistory();
  }

  fetchHistory() {
    this.userId = sessionStorage.userId;
    let body = {
      user_id: this.userId
    };
    //data encrypt
    let strdata = JSON.stringify(body);
    let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
    this.authService.fetchAllTransactionHistory(dataenc).subscribe(response => {
      //response decrypt
      let data = (<any>response)
      let decdata = this.decryptmethod(data, this.global.publicKey);
      let resp = JSON.parse(decdata);

      if (<any>resp) {
        let responseData = resp;
        if (responseData.msg != "No data found") {
          // let giftData = {};
          // let cryptoData = {};
          // this.history = [];
          if (responseData.giftResponse && Array.isArray(responseData.giftResponse)) {
            this.history = responseData.giftResponse;
          }
          // if (responseData.cryptoResponse || responseData.cryptoSellPurch)
          // {
          //    let cryptoData = responseData.cryptoResponse.concat(responseData.cryptoSellPurch);
          // }
          // this.history = [giftData, cryptoData];
        }
        else {
          this.history = [];
        }
      }
    });
  }

  cardHistoryActionResend(cardId) {
    let body = {
      id: cardId,
      user_id: this.giftCardService.encrypt(this.userId)
    }
    this.authService.sendResendCard(body).subscribe(response => {
      let data = (<any>response)
      let decdata = this.decryptmethod(data, this.global.publicKey);
      let resp = JSON.parse(decdata);
      if (<any>resp) {
        Swal({
          title: 'Mail has been sent',
          type: 'success',
          showCancelButton: false,
          confirmButtonText: 'ok',
          allowOutsideClick: false
        });
      } else {
        Swal({
          title: 'No recepient found',
          type: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ok',
          allowOutsideClick: false
        });
      }
    })
  }

  cardHistoryAction(action, cardId) {
    let actionClass = "";
    let user_id = sessionStorage.getItem('userId');
    let token = {
      action: action,
      id: cardId,
      user_id: this.giftCardService.encrypt(user_id)
    };
    switch (action) {
      case "Resend":
        actionClass = "resend";
        break;
      case "Sent":
        actionClass = "send";
        break;
      case "Recieve":
        actionClass = "recieve";
        break;
      case "Revoke":
        actionClass = "revoke";
        Swal({
          title: 'Are you sure you want to revoke this card',
          text: 'You will not be able to recover this order!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Revoke',
          cancelButtonText: 'No, Cancel it',
          allowOutsideClick: false,
        }).then((result) => {
          this.userId = sessionStorage.userId;
          let body = {
            user_id: this.giftCardService.encrypt(this.userId)
          };
          if (result.value) {
            this.authService.revokeGiftCard(token).subscribe(response => {
              let data = (<any>response)
              let decdata = this.decryptmethod(data, this.global.publicKey);
              let resp = JSON.parse(decdata);
              if (<any>resp) {
                Swal({
                  type: 'success',
                  title: 'You have successfully revoked this card',
                  showConfirmButton: false,
                  timer: 2000,
                  allowOutsideClick: false,
                })
                let responseData = resp;
                this.message = responseData.msg;
                this.fetchWalletDetails();
                this.fetchHistory();
              }
            },
              err => {
                Swal({
                  type: 'error',
                  title: 'Failed to revoked the gift card:)',
                  showConfirmButton: false,
                  timer: 2000,
                  allowOutsideClick: false,
                })
              }
            );
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal({
              type: 'error',
              title: 'Your card is safe :)',
              showConfirmButton: false,
              timer: 2000,
              allowOutsideClick: false,
            })
          }
        });
        break;
      case "Accepted":
        actionClass = "accepted";
        break;
    }
    //this.showOrderInfo = true;
    return actionClass;
  }

  showBulkRedeemUsers(id) {
    this.bulkHistory = [];
    let body = {
      id: id,
    };
    this.authService.fetchRedeemHistoryOfUser(body).subscribe(response => {
      let data = (<any>response)
      let decdata = this.decryptmethod(data, this.global.publicKey);
      let resp = JSON.parse(decdata);
      if (<any>resp) {
        let responseData = resp;
        if (responseData.giftCardBulk) {
          if (Array.isArray(responseData.giftCardBulk))
            this.bulkHistory = responseData.giftCardBulk;
          else
            this.bulkHistory = [];
        }
        this.showOrderInfo = true;
      }
    });
  }

  closeBulkRedeem() {
    this.showOrderInfo = false;
  }

  // filterHistory() {
  //   let tmpHistoryData = this.history;
  //   let filteredHistoryData = [];
  //   // filter from id
  //   if (this.filters.id != '') {
  //     let searchRegex = new RegExp(this.filters.id, "g");
  //     filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.order_id.toString()));
  //     tmpHistoryData = filteredHistoryData;
  //   } else {
  //     filteredHistoryData = tmpHistoryData;
  //   }
  //   // filter from event name
  //   if (this.filters.eventName != '') {
  //     let searchRegex = new RegExp(this.filters.eventName, "ig");
  //     filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.event_name));
  //     tmpHistoryData = filteredHistoryData;
  //   } else {
  //     filteredHistoryData = tmpHistoryData;
  //   }
  //   // filter from sender name
  //   if (this.filters.sender != '') {
  //     let searchRegex = new RegExp(this.filters.sender, "ig");
  //     filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.sender_name));
  //     tmpHistoryData = filteredHistoryData;
  //   } else {
  //     filteredHistoryData = tmpHistoryData;
  //   }
  //   // filter from event type
  //   if (this.filters.eventType != '') {
  //     let searchRegex = new RegExp(this.filters.eventType, "ig");
  //     filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.event_type));
  //     tmpHistoryData = filteredHistoryData;
  //   } else {
  //     filteredHistoryData = tmpHistoryData;
  //   }
  //   // filter from event date
  //   if (this.filters.date != '') {
  //     let searchRegex = new RegExp(this.filters.date, "ig");
  //     filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.date));
  //     tmpHistoryData = filteredHistoryData;
  //   } else {
  //     filteredHistoryData = tmpHistoryData;
  //   }
  //   // filter from event amount
  //   if (this.filters.amount != '') {
  //     let searchRegex = new RegExp(this.filters.amount, "ig");
  //     filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.amount.toString()));
  //     tmpHistoryData = filteredHistoryData;
  //   } else {
  //     filteredHistoryData = tmpHistoryData;
  //   }
  //   // filter from card count
  //   if (this.filters.count != '') {
  //     let searchRegex = new RegExp(this.filters.count, "ig");
  //     filteredHistoryData = tmpHistoryData.filter(data => searchRegex.test(data.no_of_cards.toString()));
  //     tmpHistoryData = filteredHistoryData;
  //   } else {
  //     filteredHistoryData = tmpHistoryData;
  //   }
  //   // filter from card recipient
  //   if (this.filters.recipient != '') {
  //     let searchRegex = new RegExp(this.filters.recipient, "ig");
  //     filteredHistoryData = tmpHistoryData.filter(data => data.recepient_name && searchRegex.test(data.recepient_name));
  //     tmpHistoryData = filteredHistoryData;
  //   } else {
  //     filteredHistoryData = tmpHistoryData;
  //   }
  //   // filter from card shipping status
  //   if (this.filters.shipping != '') {
  //     let searchRegex = new RegExp(this.filters.shipping, "i");
  //     let allstatus = ['Proccessing', 'Initiated'];
  //     let filteredStatus = allstatus.filter(filter => searchRegex.test(filter));
  //     let status = -1;
  //     if (filteredStatus) {
  //       if (filteredStatus.indexOf('Initiated') > -1) {
  //         status = 0;
  //       } else if (filteredStatus.indexOf('Proccessing') > -1) {
  //         status = 1;
  //       }
  //       filteredHistoryData = tmpHistoryData.filter(data => data.is_shipping == status);
  //       tmpHistoryData = filteredHistoryData;
  //     }
  //     else {
  //       filteredHistoryData = tmpHistoryData;
  //     }
  //   } else {
  //     filteredHistoryData = tmpHistoryData;
  //   }
  //   this.cardHistory.inputData = filteredHistoryData;
  // }

  cardAction(item, action) { }

  fetchWalletDetails() {
    let userInfo = JSON.parse(sessionStorage.userInfo);
    this.authService.fetchWallet({
      user_id: userInfo.id.toString()
    }).subscribe(response => {
      if ((<any>response).status == 200) {
        this.authService.getuserInfo().subscribe(info => {
        });
      }
    });
  }
  //encrypt method
  encryptmethod(inputdata, key, schema_version) {
    if(environment.requestEnv == ''){
      let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
      let encobj = {"enc_data" : encnoobj};
      return encobj;
    }
    return JSON.parse(inputdata);
  }

  //decrypt method
   decryptmethod(inputdata, key) {
    if(environment.requestEnv == ''){
      return this.apidec.decrypt(inputdata.enc_data, key);
    }
      return JSON.stringify(inputdata);
  }
}
