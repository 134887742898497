import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, pipe, Subject, of, BehaviorSubject } from 'rxjs';
import { map, tap, retryWhen, delayWhen, takeUntil, delay } from 'rxjs/operators';
import { QueueingSubject } from "queueing-subject";
import websocketConnect from 'rxjs-websockets';
import { environment } from '../../environments/environment';
// import { RxStompService, InjectableRxStompConfig } from '@stomp/ng2-stompjs';
// import { Message } from '@stomp/stompjs';
import { Subscription } from 'rxjs';
// import { myRxStompConfig } from '../my-rx-stomp.config';
// import { RxStompState } from '@stomp/rx-stomp';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private ws: WebSocket;
  SERVER_HOST: string = "";
  bitfinexSocketUrl: string = "";
  private btcStream: QueueingSubject<any>;
  private ethStream: QueueingSubject<any>;
  private xrpStream: QueueingSubject<any>;
  private ltcStream: QueueingSubject<any>;
  private bchStream: QueueingSubject<any>;
  private xlmStream: QueueingSubject<any>;
  public messagesBTC: Observable<any>;
  public messagesETH: Observable<any>;
  public messagesXRP: Observable<any>;
  public messagesLTC: Observable<any>;
  public messagesBCH: Observable<any>;
  public messagesXLM: Observable<any>;
  public cryptoData = {
    BTC: undefined,
    ETH: undefined,
    XRP: undefined,
    LTC: undefined,
    BCH: undefined,
    XLM: undefined,
    ADA: undefined,
    DOGE: undefined,
    DOT: undefined
  };
  public sharesWallet = new BehaviorSubject<any>(undefined);
  public topicSubscription: Subscription;
  public receivedMessages: string[] = [];
  templates: any;
  connectionStatus$: Observable<string>;
  // private _xrpinfo = new BehaviorSubject<any>(undefined);
  // private _btcinfo = new BehaviorSubject<any>(undefined);
  // private _ethinfo = new BehaviorSubject<any>(undefined);
  // xrpinfo: {
  //   bid : 0,
  //   ask : 0
  // };
  // btcinfo: {
  //   bid : 0,
  //   ask : 0;
  // };
  // ethinfo: {
  //   bid : 0,
  //   ask : 0;
  // };
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    // private rxStompService: RxStompService,
    // private rxStompConfig: InjectableRxStompConfig,
    private toastr: ToastrService
  ) {

    this.SERVER_HOST = environment.serverHost;
    this.bitfinexSocketUrl = environment.bitfinexSocketUrl;
    // myRxStompConfig.connectHeaders.Authorization = localStorage.getItem('resp_token');
    // myRxStompConfig.brokerURL = 'wss://trade-api.nexus.trade/websocket/v1';
    // this.initStomp();
    // this.checkConnection();
    // this.connectWebSocket();
  }

  // private initStomp() {
  //     // this.checkConnection();
  //     const stompConfig: InjectableRxStompConfig = Object.assign({}, myRxStompConfig, {
  //         connectHeaders: {
  //             Authorization: localStorage.getItem('resp_token')
  //         },
  //         beforeConnect: () => {
  //             console.log('%c called before connect', 'color: blue');
  //             // console.log(myRxStompConfig);
  //         }
  //     });

  //     this.rxStompService.configure(stompConfig);
  //     this.rxStompService.activate();
  //     // other things to do
  // }

  getSharesWallet(): Observable<any> {
    return this.sharesWallet.asObservable();
  }


  // connectToStompp() {
  //     this.initStomp();
  //   this.topicSubscription = this.rxStompService.watch('/user/v1/securities/statistics', { 'X-Deltix-Nonce': JSON.stringify(Date.now())  }).subscribe((message: Message) => {
  //       this.receivedMessages.push(message.body);

  //       let btctemp = JSON.parse(this.receivedMessages[0]).find(o => o.security_id === 'BTCUSD');
  //       this.cryptoData.BTC = {
  //         name: 'BTC',
  //         currencyName: 'Bitcoin',
  //         //dailyChange: btctemp.volume_24h_change,
  //         //dailyChangePer: feach.price_24h_change,
  //         lastPrice: btctemp.price_24h_max,
  //         volume: btctemp.ask,
  //         high: btctemp.price_24h_max,
  //         low: btctemp.price_24h_min,
  //         bid: btctemp.bid,
  //         ask: btctemp.ask,
  //       };
  //       this.sharesWallet.next(this.cryptoData);
  //       let ethtemp = JSON.parse(this.receivedMessages[0]).find(o => o.security_id === 'ETHUSD');
  //       this.cryptoData.ETH = {
  //         name: 'ETH',
  //         currencyName: 'Ethereum',
  //         //dailyChange: ethtemp.volume_24h_change,
  //         // dailyChangePer: feach.price_24h_change,
  //         lastPrice: ethtemp.price_24h_max,
  //         volume: ethtemp.ask,
  //         high: ethtemp.price_24h_max,
  //         low: ethtemp.price_24h_min,
  //         bid: ethtemp.bid,
  //         ask: ethtemp.ask,
  //       };
  //       this.sharesWallet.next(this.cryptoData);
  //       let xrptemp = JSON.parse(this.receivedMessages[0]).find(o => o.security_id === 'XRPUSD');
  //       this.cryptoData.XRP = {
  //         name: 'XRP',
  //         currencyName: 'Ripple',
  //         //dailyChange: xrptemp.volume_24h_change,
  //         //dailyChangePer: feach.price_24h_change,
  //         lastPrice: xrptemp.price_24h_max,
  //         volume: xrptemp.ask,
  //         high: xrptemp.price_24h_max,
  //         low: xrptemp.price_24h_min,
  //         bid: xrptemp.bid,
  //         ask: xrptemp.ask,
  //       };
  //       this.sharesWallet.next(this.cryptoData);
  //     });

  // }
  connectToStomp() {

    this.ws = new WebSocket('wss://vakotrade-nexus.cryptosrvc.com/graphql', ['graphql-transport-ws']);
    let btcpair = JSON.stringify({
      "id": "195d63a0-5dd7-4e64-8714-30b884cd01de",
      "type": "subscribe",
      "payload": {
        "query": "subscription ($instrument_id: String!, $periodicity: InstrumentHistoryPeriodicity!) {instrument_price_bar (instrument_id: $instrument_id, periodicity: $periodicity) {instrument_id, high, low, ts, close, open }}",
        "variables": {
          "instrument_id": "BTCUSD",
          "periodicity": "minute15"
        }
      }
    });

    // now call method
    this.getAllCoinsData(btcpair);
  }

  // method to get and draw all coins data
  getAllCoinsData(btcpair: any) {
    this.ws.onopen = (event) => {
      this.ws.send(JSON.stringify({
        "type": "connection_init",
        "payload": {
          "authorization": ""
        }
      }));
    };

    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data && data?.payload?.data?.instrument_price_bar && data?.payload?.data?.instrument_price_bar['instrument_id'] == 'BTCUSD') {
        let response = data?.payload?.data?.instrument_price_bar;
        this.cryptoData.BTC = {
          name: 'BTC',
          currencyName: 'Bitcoin',
          lastPrice: response['close'],
          volume: response['open'],
          high: response['high'],
          low: response['low'],
          bid: response['open'],
          ask: response['open'],
        };
        this.sharesWallet.next(this.cryptoData);
      } else if (data && data?.payload?.data?.instrument_price_bar && data?.payload?.data?.instrument_price_bar['instrument_id'] == 'ETHUSD') {
        let response = data?.payload?.data?.instrument_price_bar;
        this.cryptoData.ETH = {
          name: 'ETH',
          currencyName: 'Ethereum',
          lastPrice: response['close'],
          volume: response['open'],
          high: response['high'],
          low: response['low'],
          bid: response['open'],
          ask: response['open'],
        };
        this.sharesWallet.next(this.cryptoData);
      } else if (data && data?.payload?.data?.instrument_price_bar && data?.payload?.data?.instrument_price_bar['instrument_id'] == 'LTCUSD') {
        let response = data?.payload?.data?.instrument_price_bar;
        this.cryptoData.LTC = {
          name: 'LTCUSD',
          currencyName: 'Ripple',
          lastPrice: response['close'],
          volume: response['open'],
          high: response['high'],
          low: response['low'],
          bid: response['open'],
          ask: response['open'],
        };
        this.sharesWallet.next(this.cryptoData);
      } else if (data && data?.payload?.data?.instrument_price_bar && data?.payload?.data?.instrument_price_bar['instrument_id'] == 'BCHUSD') {
        let response = data?.payload?.data?.instrument_price_bar;
        this.cryptoData.BCH = {
          nname: 'BCH',
          currencyName: 'BitcoinCash',
          lastPrice: response['close'],
          volume: response['open'],
          high: response['high'],
          low: response['low'],
          bid: response['open'],
          ask: response['open'],
        };
        this.sharesWallet.next(this.cryptoData);
      } else if (data && data?.payload?.data?.instrument_price_bar && data?.payload?.data?.instrument_price_bar['instrument_id'] == 'XRPUSD') {
        let response = data?.payload?.data?.instrument_price_bar;
        this.cryptoData.XRP = {
          name: 'XRP',
          currencyName: 'Stellar',
          lastPrice: response['close'],
          volume: response['open'],
          high: response['high'],
          low: response['low'],
          bid: response['open'],
          ask: response['open'],
        };
        this.sharesWallet.next(this.cryptoData);
      } else if (data && data?.payload?.data?.instrument_price_bar && data?.payload?.data?.instrument_price_bar['instrument_id'] == 'ADAUSD') {
        let response = data?.payload?.data?.instrument_price_bar;
        this.cryptoData.ADA = {
          name: 'ADA',
          currencyName: 'Cardano',
          lastPrice: response['close'],
          volume: response['open'],
          high: response['high'],
          low: response['low'],
          bid: response['open'],
          ask: response['open'],
        };
        this.sharesWallet.next(this.cryptoData);
      } else if (data && data?.payload?.data?.instrument_price_bar && data?.payload?.data?.instrument_price_bar['instrument_id'] == 'DOGEUSD') {
        let response = data?.payload?.data?.instrument_price_bar;
        this.cryptoData.DOGE = {
          name: 'DOGE',
          currencyName: 'Dogecoin',
          lastPrice: response['close'],
          volume: response['open'],
          high: response['high'],
          low: response['low'],
          bid: response['open'],
          ask: response['open'],
        };
        this.sharesWallet.next(this.cryptoData);
      } else if (data && data?.payload?.data?.instrument_price_bar && data?.payload?.data?.instrument_price_bar['instrument_id'] == 'DOTUSDT') {
        let response = data?.payload?.data?.instrument_price_bar;
        this.cryptoData.DOT = {
          name: 'DOT',
          currencyName: 'Polkadot',
          lastPrice: response['close'],
          volume: response['open'],
          high: response['high'],
          low: response['low'],
          bid: response['open'],
          ask: response['open'],
        };
        this.sharesWallet.next(this.cryptoData);
      }
      if (data.type === "connection_ack") {
        this.ws.send(btcpair);
        let ethpair = JSON.stringify({
          "id": "1b4ceeaf-40bd-4344-85f2-2c9876d69e3c",
          "type": "subscribe",
          "payload": {
            "query": "subscription ($instrument_id: String!, $periodicity: InstrumentHistoryPeriodicity!) {instrument_price_bar (instrument_id: $instrument_id, periodicity: $periodicity) {instrument_id, high, low, ts, close, open }}",
            "variables": {
              "instrument_id": "ETHUSD",
              "periodicity": "minute15"
            }
          }
        });

        // this.ethUsdData(ethpair);
        this.ws.send(ethpair);
        let ltcpair = JSON.stringify({
          "id": "d3a199dd-31d6-4310-8c49-9c2102e21b5c",
          "type": "subscribe",
          "payload": {
            "query": "subscription ($instrument_id: String!, $periodicity: InstrumentHistoryPeriodicity!) {instrument_price_bar (instrument_id: $instrument_id, periodicity: $periodicity) {instrument_id, high, low, ts, close, open }}",
            "variables": {
              "instrument_id": "LTCUSD",
              "periodicity": "minute15"
            }
          }
        });
        // this.ws.send(xrppair);
        this.ws.send(ltcpair);
        let bchpair = JSON.stringify({
          "id": "4a94dce9-9046-42f8-88f6-66f0b9217275",
          "type": "subscribe",
          "payload": {
            "query": "subscription ($instrument_id: String!, $periodicity: InstrumentHistoryPeriodicity!) {instrument_price_bar (instrument_id: $instrument_id, periodicity: $periodicity) {instrument_id, high, low, ts, close, open }}",
            "variables": {
              "instrument_id": "BCHUSD",
              "periodicity": "minute15"
            }
          }
        });
        this.ws.send(bchpair);
        let adapair = JSON.stringify({
          "id": "eabb972a-19d5-4b41-9cc3-d5709cabfbee",
          "type": "subscribe",
          "payload": {
            "query": "subscription ($instrument_id: String!, $periodicity: InstrumentHistoryPeriodicity!) {instrument_price_bar (instrument_id: $instrument_id, periodicity: $periodicity) {instrument_id, high, low, ts, close, open }}",
            "variables": {
              "instrument_id": "ADAUSD",
              "periodicity": "minute15"
            }
          }
        });
        this.ws.send(adapair);
        let dogepair = JSON.stringify({
          "id": "48d3a465-ec0a-431f-9302-4badbe5c197a",
          "type": "subscribe",
          "payload": {
            "query": "subscription ($instrument_id: String!, $periodicity: InstrumentHistoryPeriodicity!) {instrument_price_bar (instrument_id: $instrument_id, periodicity: $periodicity) {instrument_id, high, low, ts, close, open }}",
            "variables": {
              "instrument_id": "DOGEUSD",
              "periodicity": "minute15"
            }
          }
        });
        this.ws.send(dogepair);
        let dotpair = JSON.stringify({
          "id": "74c2544b-99db-4e86-91b0-c12af6351c78",
          "type": "subscribe",
          "payload": {
            "query": "subscription ($instrument_id: String!, $periodicity: InstrumentHistoryPeriodicity!) {instrument_price_bar (instrument_id: $instrument_id, periodicity: $periodicity) {instrument_id, high, low, ts, close, open }}",
            "variables": {
              "instrument_id": "DOTUSDT",
              "periodicity": "minute15"
            }
          }
        });
        this.ws.send(dotpair);
        /*let xlmpair = JSON.stringify({
          "id": "195d63a0-5dd7-4e64-8714-30b884cd01de",
          "type": "subscribe",
          "payload": {
            "query": "subscription ($instrument_id: String!, $periodicity: InstrumentHistoryPeriodicity!) {instrument_price_bar (instrument_id: $instrument_id, periodicity: $periodicity) {instrument_id, high, low, ts, close, open }}",
            "variables": {
              "instrument_id": "XRPUSD",
              "periodicity": "minute15"
            }
          }
        });
       this.ws.send(xlmpair);
       */
      }
    };
  }
}
