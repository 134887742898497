import { environment } from './../../../../environments/environment';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthguardService } from "../../../services/authguard.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { GiftcardService } from "../../../services/giftcard.service";
import { CryptoService } from "../../../services/crypto.service";
//encrypt decrypt import
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
// import { RxStompService, InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { ToastrService } from 'ngx-toastr';
// import { RxStompState } from '@stomp/rx-stomp';
@Component({
    selector: "app-sell",
    templateUrl: "./sell.component.html",
    styleUrls: ["./sell.component.scss"]
})
export class SellComponent implements OnInit, OnDestroy {
    showSummary: boolean = true;
    token: string = "";
    walletAmount: string = "";
    userFeatures: any;
    currencies = [
        { name: "Bitcoin", id: "BTC", disabled: false },
        { name: "Ethereum", id: "ETH", disabled: false },
        { name: "Ripple", id: "XRP", disabled: false },
        // { name: "Stellar", id: "XLM", disabled: false },
        { name: "Litecoin", id: "LTC", disabled: false },
        { name: "Bitcoincash", id: "BCH", disabled: false },
        { name: "Cardano", id: "ADA", disabled: false },
        { name: "Dogecoin", id: "DOGE", disabled: false },
        { name: "Polkadot", id: "DOT", disabled: false }
    ];
    minvaluelist = [];
    ethminvalue;
    btcminvalue;
    xrpminvalue;
    adaminvalue;
    dogeminvalue;
    dotminvalue;
    minvalue: any;
    selectedCrypto: string = "BTC";
    selectedCurrency: string = "";
    selectedCurrencyInfo: any = undefined;
    transactionFee: number;
    shares: number = 0;
    date = new Date();
    userCryptoWallet: any = null;
    percentage: number = 0;
    currentPrice: number = 0;
    total: number = 0;
    sliderConfig: any = {
        connect: true,
        margin: 1,
        range: {
            min: 0,
            max: 100
        },
        pips: {
            mode: 'count',
            density: 10,
            values: 5,
            stepped: true
        },
        tooltips: true
    };
    transactionDone: boolean = false;
    userInfo: any = null;
    transactionDetails: any = undefined;
    cryptoWallet: any;
    processingFeeData: any; //  processing  fee api data
    roundedshares: any;
    roundedtotal: number;
    sellwholecrypto : boolean = false;
    currencyName: any;
    currencyValue: any;

    constructor(
        private authService: AuthguardService,
        private router: Router,
        private cardService: GiftcardService,
        private cryptoService: CryptoService,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe,
        // private rxStompService: RxStompService,
        private toastr: ToastrService
    ) {
        // this.rxStompService.connectionState$.subscribe(state => {
        //     // state is an Enum (Integer), RxStompState[state] is the corresponding string
        //     // console.log(RxStompState[state]+'checking state');
        //     if(RxStompState[state]==='CONNECTING'){
        //       this.toastr.info('Info','Please wait, we are fetching live market values. Meanwhile you can use non-crypto features.',{
        //         closeButton: true,
        //         timeOut :5000,
        //         progressBar : true,
        //         progressAnimation :'increasing',
        //         positionClass	:"toast-top-right",
        //         tapToDismiss: true,
        //       }
        //       );
      
        //     }
        //   });
        
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
    }

    ngOnInit() {

        // get features API data fetch
        this.getFeatures();
        // get processing fee API data fetch
        this.getProcessingFee();

        // fetch crypto shares latest info from socket
        this.cryptoService.connectToStomp();
        this.cryptoService.getSharesWallet().subscribe(data => {
            if (data != undefined) {
                this.cryptoWallet = data;
            }
        });

        // fetch user info
        this.authService.getuserInfo().subscribe(info => {
            if (info != undefined) {
                this.userInfo = info.user;
                //console.log('User',this.userInfo);
                this.walletAmount = info.giftCardsValue;
                this.userCryptoWallet = info.userCryptoCurrency;
            }
        });

        // fetch token from service
        this.authService.getToken().subscribe(token => {
            this.token = token;
        });
        this.getCryptoGiftImages();
    }

    ngOnDestroy() {}

    cancel() {
        Swal({
            title: "",
            text: "Do you really want to cancel?",
            type: "error",
            showCancelButton: true,
            confirmButtonColor: "#053a6a",
            cancelButtonColor: "#eb6363",
            confirmButtonText: "YES",
            cancelButtonText: "No",
            allowOutsideClick: false,
        }).then(result => {
            if (result && result.value) {
                this.percentage = 0;
                this.selectedCurrency = "";
                this.showSummary = true;
                this.shares = 0;
                // this.transactionFee = 0;
                this.total = 0;
            }
        });
    }

    selectCurrency(event) {
        let message = "";
        this.percentage = 0;
        this.showSummary = false;
        switch (this.selectedCurrency) {
            case "BTC":
                this.selectedCrypto = "BTC";
                this.selectedCurrency = "BTC";
                this.minvalue = this.btcminvalue;
                if (
                    this.userCryptoWallet &&
                    Array.isArray(this.userCryptoWallet)
                ) {
                    this.selectedCurrencyInfo = this.userCryptoWallet.find(
                        currency => currency.currency == this.selectedCurrency
                    );
                    if (this.selectedCurrencyInfo) {
                    } else {
                        message = "You don't have Bitcoin shares.";
                    }
                }
                break;
            case "ETH":
                this.selectedCrypto = "ETH";
                this.selectedCurrency = "ETH";
                this.minvalue = this.ethminvalue;
                if (
                    this.userCryptoWallet &&
                    Array.isArray(this.userCryptoWallet)
                ) {
                    this.selectedCurrencyInfo = this.userCryptoWallet.find(
                        currency => currency.currency == this.selectedCurrency
                    );
                    if (this.selectedCurrencyInfo) {
                    } else {
                        message = "You don't have Ethereum shares.";
                    }
                }
                break;
            case "XRP":
                this.selectedCrypto = "XRP";
                this.selectedCurrency = "XRP";
                this.minvalue = this.xrpminvalue;
                if (
                    this.userCryptoWallet &&
                    Array.isArray(this.userCryptoWallet)
                ) {
                    this.selectedCurrencyInfo = this.userCryptoWallet.find(
                        currency => currency.currency == this.selectedCurrency
                    );
                    if (this.selectedCurrencyInfo) {
                    } else {
                        message = "You don't have Ripple shares.";
                    }
                }
                break;
            case "XLM":
                this.selectedCrypto = "XLM";
                this.selectedCurrency = "XLM";
                this.minvalue = this.xrpminvalue;
                if (
                    this.userCryptoWallet &&
                    Array.isArray(this.userCryptoWallet)
                ) {
                    this.selectedCurrencyInfo = this.userCryptoWallet.find(
                        currency => currency.currency == this.selectedCurrency
                    );
                    if (this.selectedCurrencyInfo) {
                    } else {
                        message = "You don't have Stellar shares.";
                    }
                }
                break;
            case "LTC":
                    this.selectedCrypto = "LTC";
                    this.selectedCurrency = "LTC";
                    this.minvalue = this.xrpminvalue;
                    if (
                        this.userCryptoWallet &&
                        Array.isArray(this.userCryptoWallet)
                    ) {
                        this.selectedCurrencyInfo = this.userCryptoWallet.find(
                            currency => currency.currency == this.selectedCurrency
                        );
                        if (this.selectedCurrencyInfo) {
                        } else {
                            message = "You don't have Litecoin shares.";
                        }
                    }
                break;
            case "BCH":
                    this.selectedCrypto = "BCH";
                    this.selectedCurrency = "BCH";
                    this.minvalue = this.xrpminvalue;
                    if (
                        this.userCryptoWallet &&
                        Array.isArray(this.userCryptoWallet)
                    ) {
                        this.selectedCurrencyInfo = this.userCryptoWallet.find(
                            currency => currency.currency == this.selectedCurrency
                        );
                        if (this.selectedCurrencyInfo) {
                        } else {
                            message = "You don't have Bitcoin shares.";
                        }
                    }
                break;
                case "ADA":
                    this.selectedCrypto = "ADA";
                    this.selectedCurrency = "ADA";
                    this.minvalue = this.adaminvalue;
                    if (
                        this.userCryptoWallet &&
                        Array.isArray(this.userCryptoWallet)
                    ) {
                        this.selectedCurrencyInfo = this.userCryptoWallet.find(
                            currency => currency.currency == this.selectedCurrency
                        );
                        if (this.selectedCurrencyInfo) {
                        } else {
                            message = "You don't have Cardano shares.";
                        }
                    }
                break;
                case "DOGE":
                    this.selectedCrypto = "DOGE";
                    this.selectedCurrency = "DOGE";
                    this.minvalue = this.dogeminvalue;
                    if (
                        this.userCryptoWallet &&
                        Array.isArray(this.userCryptoWallet)
                    ) {
                        this.selectedCurrencyInfo = this.userCryptoWallet.find(
                            currency => currency.currency == this.selectedCurrency
                        );
                        if (this.selectedCurrencyInfo) {
                        } else {
                            message = "You don't have Dogecoin shares.";
                        }
                    }
                break;
                case "DOT":
                    this.selectedCrypto = "DOT";
                    this.selectedCurrency = "DOT";
                    this.minvalue = this.dotminvalue;
                    if (
                        this.userCryptoWallet &&
                        Array.isArray(this.userCryptoWallet)
                    ) {
                        this.selectedCurrencyInfo = this.userCryptoWallet.find(
                            currency => currency.currency == this.selectedCurrency
                        );
                        if (this.selectedCurrencyInfo) {
                        } else {
                            message = "You don't have Polkadot shares.";
                        }
                    }
                break;
            default:
                this.showSummary = true;
                this.percentage = 0;
                this.selectedCurrency = "";
                this.selectedCurrencyInfo = undefined;
                this.shares = 0;
                this.transactionFee = parseFloat(
                    this.processingFeeData.cryptocurrency_fee
                );
                this.total = 0;
        }
        if ((message! = "")) {
            Swal({
                title: "Error",
                text: message,
                type: "error",
                confirmButtonColor: "#053a6a",
                confirmButtonText: "OK",
                allowOutsideClick: false,
            });
        }
    }

    calculateSharePrice() {
        if (this.selectedCurrencyInfo && this.selectedCurrencyInfo.shares) {
            this.showSummary = false;
            // this.transactionFee = 1.99;

            let totalShares = parseFloat(this.selectedCurrencyInfo.shares);
            let percentage = this.percentage;
            let transactionFee = this.transactionFee;
            let shares = (percentage / 100) * totalShares;
            this.shares = shares;
            let currentPrice = this.cryptoWallet[this.selectedCrypto].ask;
            let component = this;
            if(component.selectedCurrency === 'BTC'){
                // this.shares = + shares.toFixed(4);
                this.roundedshares = this.shares.toFixed(4);
                this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
            } else if(component.selectedCurrency === 'ETH'){
                // this.shares = + shares.toFixed(3);
                this.roundedshares = this.shares.toFixed(3);
                this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
            } else if(component.selectedCurrency === 'XRP'){
                // this.shares = + shares.toFixed(2);
                this.roundedshares = this.shares.toFixed(2);
                this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
            } else if(component.selectedCurrency === 'XLM'){
                // this.shares = + shares.toFixed(0);
                this.roundedshares = this.shares.toFixed(0);
                this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
            } else if(component.selectedCurrency === 'LTC'){
                // this.shares = + shares.toFixed(3);
                this.roundedshares = this.shares.toFixed(3);
                this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
            } else if(component.selectedCurrency === 'BCH'){
                // this.shares = + shares.toFixed(3);
                this.roundedshares = this.shares.toFixed(2);
                this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
            } else if(component.selectedCurrency === 'ADA'){
                // this.shares = + shares.toFixed(0);
                this.roundedshares = this.shares.toFixed(0);
                this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
            } else if(component.selectedCurrency === 'DOGE'){
                // this.shares = + shares.toFixed(3);
                this.roundedshares = this.shares.toFixed(3);
                this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
            } else if(component.selectedCurrency === 'DOT'){
                // this.shares = + shares.toFixed(3);
                this.roundedshares = this.shares.toFixed(2);
                this.roundedtotal =+(this.roundedshares * this.currentPrice).toFixed(0);
            }
            this.currentPrice = currentPrice;
            let total = this.roundedshares * parseFloat(currentPrice) + transactionFee;
            this.roundedtotal = total;
        }
    }

    placeOrder() {
        if (this.percentage == 0 || this.currentPrice <= 0) {
            alert("Plese select enough shares to sell.");
        } else {
            // call api to purchase
            let currPrice = parseFloat(
                this.cryptoWallet[this.selectedCrypto].ask
            );
            let amount = currPrice * this.shares;
            let user_id = sessionStorage.getItem("userId");
            let body = {
                user_id: user_id,
                amount: this.roundedtotal.toFixed(2),
                // tslint:disable-next-line: radix
                shares_quantity: this.roundedshares,
                crypto_currency: this.selectedCurrency,
                type: "Sold",
                transaction_fee: this.transactionFee,
                currentPrice: currPrice.toFixed(2)
            };
            //data encrypt
            let strdata = JSON.stringify(body);
            let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
            this.authService.sellCryptoCurrency(dataenc).subscribe(response => {
                //response decrypt
            let res = (<any>response)
            let decdata = this.decryptmethod(res, this.global.publicKey);
            let resp = JSON.parse(decdata);
                let data = resp;
                if (data.success) {
                    this.transactionDetails = {
                        orderId: data.transaction_id,
                        message: data.msg,
                        currency: this.currencies.find(
                            currency => currency.id == this.selectedCurrency
                        ).name,
                        total: this.roundedtotal.toString(),
                        shares: this.roundedshares.toString(),
                        amount: this.roundedtotal.toString(),
                        fee: this.transactionFee.toString()

                    };
                    window.scroll(0,0);

                    this.authService
                        .fetchWallet({
                            user_id: this.cardService.encrypt(body.user_id)
                        })
                        .subscribe(data => {});
                    this.transactionDone = true;
                } else if (data.verified == false) {
                    Swal({
                        title: "",
                        text: data.msg,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#053a6a",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                    }).then(result => {
                        if (result.value == true) {
                            this.router.navigateByUrl("/dashboard/settings");
                        }
                    });
                } else {
                    let msg = data?.error?.crypto_currency[0];
                    Swal({
                        title: "",
                        text: data.msg || msg,
                        type: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#053a6a",
                        confirmButtonText: "OK",
                        allowOutsideClick: false,
                    });
                }
            });
            // show thankyou on success
        }
    }
    sellwholeCrypto($event){
        // console.log($event.target.value);
        let target = $event.target;
        if(this.sellwholecrypto == true){
            Swal({
                title: "",
                text: "Do you really want to Sell Whole crypto ?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#053a6a",
                cancelButtonColor: "#eb6363",
                confirmButtonText: "Sell",
                cancelButtonText: "No,Cancel",
                allowOutsideClick: false,
                onClose() {
                    if(target) target.click();
                },
            }).then(result => {
                if (result && result.value == true) {
                    this.placeSellOrderwholecrypto();
                } else {
                    this.sellwholecrypto == false;
                }
            });
        }
    }
    moveToAddMoneyWallet() {
        this.router.navigateByUrl("/dashboard/addmoney");
    }

    reset() {
        this.transactionDone = false;
        this.showSummary = true;
        this.selectedCrypto = "BTC";
        this.percentage = 0;
        this.total = 0;
        this.selectedCurrency = "";
        this.transactionFee = parseFloat(
            this.processingFeeData.cryptocurrency_fee
        );
        this.shares = 0;
        this.cardService.fromRedeemPage.next(true);
        this.router.navigateByUrl("/dashboard/home");
    }

    //get  features active or block

    getFeatures() {
        this.cardService.acceptBlockFeatures().subscribe(response => {
            let body = (<any>response)

            if ((<any>body).success == true) {

                // update user feature
                sessionStorage.setItem("features", JSON.stringify(body));

                this.userFeatures = body;
                console.log(this.userFeatures);

                if (this.userFeatures.features.sell_crypto != "1") {
                    this.router.navigateByUrl("dashboard/home");
                }
            }
        });
    }

    // procesing fee

    getProcessingFee() {
        this.cardService.getProcessingFeeData().subscribe(response => {
            console.log("response", response);
            let body = (<any>response)
            if ((<any>body).success == true) {
                this.processingFeeData = body.payment;
                //  console.log("this.processingFeeData", this.processingFeeData);
                this.transactionFee = parseFloat(
                    this.processingFeeData.cryptocurrency_fee
                );
            }
        });

        if (this.transactionFee) {
        } else {
            this.transactionFee = 1.99;
        }
    }

    getCryptoGiftImages() {
        this.cardService.getCryptoGiftImg().subscribe(response => {
            let body = (<any>response)
            let decdata = this.decryptmethod(body, this.global.publicKey);
            let resp= JSON.parse(decdata);
            if(<any>resp.success == true){
                this.btcminvalue = resp.crypto[0].min_value;
                this.ethminvalue = resp.crypto[1].min_value;
                this.xrpminvalue = resp.crypto[2].min_value;
                this.adaminvalue = resp.crypto[3].min_value;
                this.dogeminvalue = resp.crypto[4].min_value;
                this.dotminvalue = resp.crypto[5].min_value;
            }
        });
    }

    
    placeSellOrderwholecrypto(){
        let currPrice = parseFloat(
            this.cryptoWallet[this.selectedCrypto].ask
        );
        let amount = currPrice * this.shares;
        let user_id = sessionStorage.getItem("userId");
        //console.log("aaa", this.roundedtotal.toFixed(4));
        //return false;
        let body = {
            user_id: user_id,
            amount: this.roundedtotal.toFixed(2),
            // tslint:disable-next-line: radix
            shares_quantity: this.roundedshares,
            crypto_currency: this.selectedCurrency,
            type: "Sold",
            transaction_fee: this.transactionFee,
            currentPrice: currPrice.toFixed(2)
        };
        //data encrypt
        let strdata = JSON.stringify(body);
        let dataenc = this.encryptmethod(strdata, this.global.publicKey, this.global.DEFAULT_SCHEMA_VERSION);
        this.authService.sellCryptoCurrency(dataenc).subscribe(response => {
            //response decrypt
        let res = (<any>response)
        let decdata = this.decryptmethod(res, this.global.publicKey);
        let resp = JSON.parse(decdata);
            let data = resp;
            if (data.success) {
                this.transactionDetails = {
                    orderId: data.transaction_id,
                    message: data.msg,
                    currency: this.currencies.find(
                        currency => currency.id == this.selectedCurrency
                    ).name,
                    total: this.roundedtotal.toString(),
                    shares: this.roundedshares.toString(),
                    amount: this.roundedtotal.toString(),
                    fee: this.transactionFee.toString()

                };
                window.scroll(0,0);

                this.authService
                    .fetchWallet({
                        user_id: this.cardService.encrypt(body.user_id)
                    })
                    .subscribe(data => {});
                this.transactionDone = true;
            } else if (data.verified == false) {
                Swal({
                    title: "",
                    text: data.msg,
                    type: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#053a6a",
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                }).then(result => {
                    if (result.value == true) {
                        this.router.navigateByUrl("/dashboard/settings");
                    }
                });
            } else {
                Swal({
                    title: "",
                    text: data.msg,
                    type: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#053a6a",
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                });
            }
        });
        // show thankyou on success
    }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data" : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
            return JSON.stringify(inputdata);
    }
}
