import { Component, OnInit } from '@angular/core';
declare const TradingView: any;

@Component({
  selector: 'app-xrp',
  templateUrl: './xrp.component.html',
  styleUrls: ['./xrp.component.scss']
})
export class XrpComponent implements OnInit   {

  constructor() { }

  ngOnInit() {
    let graphData = {
      "autosize": true,
      "symbol": "BITFINEX:XRPUSD",
      "timezone": "America/Los_Angeles",
      "theme": "Light",
      "style": "9",
      "locale": "en",
      "toolbar_bg": "#f1f3f6",
      "enable_publishing": false,
      "withdateranges": true,
      "range": "1d",
      "hide_side_toolbar": false,
      "save_image": false,
      "container_id": "tradingview_e42a9"
    }

    if(sessionStorage.getItem("countryName") === "IND" ){
      graphData['symbol'] = "BITFINEX:XRPINR";
      graphData['timezone'] = "India/Asia_Kolkata";
    }

    new TradingView.widget(graphData);
  }

}
