import { CommonApiService } from './services/common-api.service';
import { AuthguardService } from 'src/app/services/authguard.service';
// import { TokenService } from './token.service';
/**************************
 *  declare app component
 ***************************/
import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy
} from "@angular/common";
import { GiftcardService } from "./services/giftcard.service";
import { DataService } from "./services/data.service";
import { Observable, pipe, Subject, of, BehaviorSubject } from "rxjs";
import Swal from "sweetalert2";


@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

    @HostListener('document:keyup', ['$event'])
    @HostListener('document:click', ['$event'])
    @HostListener('document:wheel', ['$event'])
    @HostListener('document:mousemove', ['$event'])

    resetTimer() {
        this.authService.notifyUserAction();
    }
    isloggedIn: Observable<boolean>;
    httpRequestProcessing: boolean = false;
    hideDropdowns = new BehaviorSubject<boolean>(true);
    activityTrackerInterval: any = undefined;
    userIsActive: boolean = false;


    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _location: Location,
        private authService: AuthguardService,
        private cardService: GiftcardService,
        private dataService: DataService,
        private _common: CommonApiService
    ) {

    }

    ngOnInit() {
        this._common.getIpAddress();
        /******************************************************
         *    load application configuration
         *******************************************************/
        // check if user is logged in
        if (sessionStorage.length > 0) {
            if (sessionStorage.token) {
                // check if token is saved/exists
                let token = "";
                if (sessionStorage.token.indexOf('"') == 0)
                    token = JSON.parse(sessionStorage.token);
                else token = sessionStorage.token;
                this.authService.setToken(token);
                this.logoutOnAndroid();
            } else {
            }
        }
        AuthguardService.getHttpRequestProcessing().subscribe(data => {
            // subscribe for auth service loader
            this.httpRequestProcessing = data;
        });
        GiftcardService.getHttpRequestProcessing().subscribe(data => {
            // subscribe for giftcard service loader
            this.httpRequestProcessing = data;
        });
        /********************************************************************
         * Subscribe to route end event to scroll top when route changes
         ********************************************************************/
        this.router.events.subscribe(data => {
            if (data instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });
        /****************************************************************************
         * Subscribe to user activity to check if user is active or not for 3 hours
         *****************************************************************************/
        AuthguardService.isLoggedIn().subscribe(status => {
            let events = ["click", "mousemove", "keypress"];
            if (status == true) {
                let $this = this;
                events.forEach(event => {
                    document.body.addEventListener(event, _ => {
                        if ($this.activityTrackerInterval) {
                            clearInterval($this.activityTrackerInterval);
                        }
                        $this.userIsActive = true;
                        $this.activityTrackerInterval = setInterval(__ => {
                            if (!$this.userIsActive) {
                                $this.authService.loggedOutSet(null);
                            }
                        }, 10000);
                    });
                });
            } else {
                if (this.activityTrackerInterval) {
                    this.userIsActive = false;
                    // this.authService.loggedOutSet(null);
                    clearInterval(this.activityTrackerInterval);
                }
            }
        });

        /*fetch('https://static-global-s-msn-com.akamaized.net/hp-neu/sc/2b/a5ea21.ico?d=' + Date.now())
            .then(response => {
                // Check if the response is successful
                if (!response.ok)
                    throw new Error('Network response was not ok');
                // At this point we can safely say the user has connection to the internet
                //console.log("Internet available");
            })
            .catch(error => {
                // The resource could not be reached
                console.log("No Internet connection", error);
                Swal({
                    type: 'warning',
                    title: 'Oops...',
                    text: 'Please check your Internet connection.',
                    allowOutsideClick: false,
                    customClass: 'swal-custom',
                    confirmButtonColor: '#053a6a'
                });
                return false;

            }); */

    }

    handleGlobalClicks() {
        // handle click on page to hide navbar and dropdowns in header
        this.hideDropdowns.next(true);
    }

    logoutOnAndroid() {
        // log out on android to start new session everytime application starts
        if (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/iPhone|iPad|iPod/i)
        ) {
            // this.authService.loggedOutSet(null);
        }
    }

    goBack() {
        // go back button functionality for android
        this._location.back();
    }

    ngOnDestroy() {
        // end subscriptions on close of application
        this.authService.loggedOutSet(null);
        if (this.activityTrackerInterval) {
            clearInterval(this.activityTrackerInterval);
        }
    }
}
