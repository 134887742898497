import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

// if (environment.production) {
enableProdMode();
// }
if (environment.production) {
window.console.log = () => {};
window.console.error = () => {};
window.console.info = () => {};
window.onerror = function() {};
}
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(_ => {})
    .catch(err => {});
