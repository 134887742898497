import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
import { AuthguardService } from "../../../services/authguard.service";
import { GiftcardService } from "../../../services/giftcard.service";
import { Router } from "@angular/router";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import { ApiencryptService } from '../../../services/apiencrypt.service';
import { ApidecryptService } from '../../../services/apidecrypt.service';
import { GlobalPipe } from '../../../shared/globalpipes/global.pipe';
@Component({
    selector: "app-send-certificates",
    templateUrl: "./send-certificates.component.html",
    styleUrls: ["./send-certificates.component.scss"]
})
export class SendCertificatesComponent implements OnInit {
    message: string = "";
    userId: string = "";
    certificates: any = null;
    selectedTemplateIndex: number = 0;
    myImg: any;
    allTemplates: any; //     {
    //         id: 1,
    //         background: "assets/certificates/bitcoin.png",
    //         label: "Bitcoin",
    //         color: "white"
    //     },
    //     {
    //         id: 2,
    //         background: "assets/certificates/ethereum.png",
    //         label: "Ethereum",
    //         color: "white"
    //     },
    //     {
    //         id: 3,
    //         background: "assets/certificates/ripple.png",
    //         label: "Ripple",
    //         color: "white"
    //     },
    //     {
    //         id: 4,
    //         background: "assets/certificates/gift-card-1.png",
    //         label: "Gift Card 1",
    //         color: "white"
    //     },
    //     {
    //         id: 5,
    //         background: "assets/certificates/gift-card-2.png",
    //         label: "Gift Card 2",
    //         color: "black"
    //     },
    //     {
    //         id: 6,
    //         background: "assets/certificates/gift-card-3.png",
    //         label: "Gift Card 3",
    //         color: "black"
    //     }
    // ];
    certificate: any = {};
    showPreview: boolean = false;
    token: string = ""; //// preview Certificates
    printcards: any;
    selectedCard: any = undefined;
    selectedTemplate: any = undefined;
    bitcoinTemplates: any[] = [];
    etheriumTemplates: any[] = [];
    rippleTemplates: any[] = [];
    printInProgress: boolean = false;
    currencyName: any;
    currencyValue: any;

    // qrcode: any;
    @ViewChild("certificatesHistory") certificatesHistory;
    constructor(
        private authService: AuthguardService,
        private giftCardService: GiftcardService,
        private router: Router,
        private apienc: ApiencryptService,
        private apidec: ApidecryptService,
        private global: GlobalPipe
    ) {
        this.currencyName = sessionStorage.getItem("currencyName");
        this.currencyValue = sessionStorage.getItem("currencyValue");
    }
    ngOnInit() {
        this.authService.getToken().subscribe(token => {
            this.token = token;
        });
        this.fetchCertificates(); // get certificates
        this.bitcoinTemplates = this.giftCardService.getCryptoCertificatesBitcoin();
        this.etheriumTemplates = this.giftCardService.getCryptoCertificatesEtherium();
        this.rippleTemplates = this.giftCardService.getCryptoCertificatesRipple();
        this.myImg = this.bitcoinTemplates[1].background;
        console.log(this.bitcoinTemplates);
        console.log(this.myImg);
    }

    // fetch certificates
    fetchCertificates() {
        this.userId = sessionStorage.userId;
        let body = {
            user_id: this.giftCardService.encrypt(this.userId)
        };
        this.authService.fetchCertificatesHistory(body).subscribe(response => {
            let data = (<any>response)
            let decdata = this.decryptmethod(data, this.global.publicKey);
            let resp = JSON.parse(decdata);
            if (<any>resp) {
                let responseData = resp;
                if (
                    responseData.certificatesResponse &&
                    Array.isArray(responseData.certificatesResponse)
                ) {
                    this.certificates = responseData.certificatesResponse; // console.log("Certificates", this.certificates);
                }
            }
        });
    } // printCertificate(certificate) {
    //     console.log(certificate);
    //     // console.log(certificate);
    //     this.certificate = {
    //         ...this.certificate,
    //         ...certificate
    //     };
    //     let url = `https://api.qrserver.com/v1/create-qr-code/?size=50x50&data=${
    //         certificate.redeemCode
    //     }`;
    //     this.authService.toDataURL(url).then(data => {
    //         console.log(data);
    //         this.certificate.logo.image = data;
    //         this.selectedTemplateIndex = this.allTemplates.findIndex(
    //             template => template.id == certificate.templateId
    //         );
    //         // this.showPreview = true;
    //         setTimeout(_ => {
    //             try {
    //                 html2canvas(document.querySelector(".certificate"), {
    //                     logging: false,
    //                     useCORS: false,
    //                     letterRendering: 1,
    //                     allowTaint: false
    //                 }).then(canvas => {
    //                     var imgData = canvas.toDataURL("image/jpeg");
    //                     let doc = new jsPDF({
    //                         orientation: "landscape",
    //                         unit: "in",
    //                         format: [3.5, 2]
    //                     });
    //                     var canvas = doc.canvas;
    //                     canvas.height = 1050;
    //                     canvas.width = 600;
    //                     doc.addImage(imgData, "JPEG", 0, 0, 3.5, 2); // change parameters to change size of print pdf
    //                     doc.save("certificate.pdf");
    //                     this.showPreview = false;
    //                 });
    //             } catch (e) {
    //                 this.showPreview = false;
    //                 alert("Unable to print. Please try again");
    //             }
    //         }, 1000);
    //     });
    // }

    // preview certificates
    showCard(item, event) {
        console.log('item', item);
        let self = this;
        //  console.log(item);
        this.showPreview = true;
        this.printcards = [];
        this.printcards.push(item);

        this.selectedCard = item;
        // let date = this.selectedCard.date;

        // function formatDate(date) {
        //     var d = new Date(date),
        //         month = "" + (d.getMonth() + 1),
        //         day = "" + d.getDate(),
        //         year = d.getFullYear();

        //     if (month.length < 2) month = "0" + month;
        //     if (day.length < 2) day = "0" + day;

        //     return [month, day, year].join("/");
        // }
        // this.selectedCard.date = formatDate(date);
      //  console.log(this.selectedCard.date);
        this.selectTemplate();  
        event.stopPropagation();
        // function toDataURL(url, callback) {
        //     var xhr = new XMLHttpRequest();
        //     xhr.onload = function() {
        //         var reader = new FileReader();
        //         reader.onloadend = function() {
        //             self.qrcode = reader.result;
        //             //  console.log("reader.result:", reader.result);
        //             // self.showPreview = true;
        //             callback(reader.result);
        //         };
        //         reader.readAsDataURL(xhr.response);
        //     };
        //     xhr.open("GET", url);
        //     xhr.responseType = "blob";
        //     xhr.send();
        // }

        // toDataURL(
        //     "http://api.qrserver.com/v1/create-qr-code/?size=50x50&data=" +
        //         this.selectedCard.redeemCode,
        //     function(dataUrl) {
        //         console.log("RESULT:", dataUrl);
        //         if (dataUrl) {
        //             this.qrcode = dataUrl;
        //         }
        //         this.showPreview = true;
        //     }
        // );
    }
    selectTemplate() {
        let cTemplate = parseInt(this.selectedCard.template_id) + 1;
        let currency = this.selectedCard.currency;
        switch (currency) {
            case "BTC":
                this.allTemplates = this.bitcoinTemplates;
                console.log("templates", this.allTemplates);
                console.log("cTemplate", cTemplate);
                this.selectedTemplate = this.allTemplates.find(
                    template => template.id == 1
                );
                console.log(" selected", this.selectedTemplate);
                break;
            case "ETH":
                this.allTemplates = this.etheriumTemplates;
                this.selectedTemplate = this.etheriumTemplates.find(
                    template => template.id == cTemplate
                );
                console.log(" selected", this.selectedTemplate);
                break;
            case "XRP":
                this.allTemplates = this.rippleTemplates;
                this.selectedTemplate = this.rippleTemplates.find(
                    template => template.id == cTemplate
                );
                console.log("selected", this.selectedTemplate);
                break;
        }
    }
    close() {
        // toggle preview in popup
        this.showPreview = false;
    }
    printCertificate() {
        // print card in pdf
        this.printInProgress = true;
        try {
            setTimeout(_ => {
                try {
                    html2canvas(document.querySelector("#contentToConvert"), {
                        logging: false,
                        useCORS: true,
                        letterRendering: true,
                        allowTaint: false
                    }).then(canvas => {
                        var imgData = canvas.toDataURL("image/jpeg");

                        var tWindow = window.open("");
                        $(tWindow.document.body)
                            .html(
                                "<div style='width:600px;margin:auto'>" +
                                    "<img id='Image' src=" +
                                    imgData +
                                    " style='width:100%;'></img> </div>"
                            )
                            .ready(function() {
                                tWindow.focus();
                                tWindow.print();
                            });

                        // let doc = new jsPDF({
                        //     orientation: "landscape",
                        //     unit: "in",
                        //     format: [3.5, 2]
                        // });
                        // console.log("imgData", imgData); // var canvas = doc.canvas;
                        // canvas.height = 1050;
                        // canvas.width = 600;
                        // doc.addImage(imgData, "JPEG", 0, 0, 3.5, 2); // change parameters to change size of print pdf
                        // doc.save("certificate.pdf");
                        this.showPreview = false;
                    });
                } catch (e) {
                    this.showPreview = false;
                    alert("Unable to print. Please try again");
                }
            }, 1000);
        } catch (e) {
            this.printInProgress = false;
            console.log(e);
            alert("Unable to print. Please try again");
        } // try {
        //     // let data = document.querySelector(".cardPreviewModal");
        //     // console.log("element", data);
        //     html2canvas(document.querySelector(".cardPreviewModal"), {
        //         logging: false,
        //         useCORS: false,
        //         letterRendering: 1,
        //         allowTaint: false // true to allow print cross domain images
        //         // onclone: function(canvas) {
        //         //     // document.querySelector("#block").style.background = "green";
        //         // }
        //         // onrendered: function(canvas) {
        //         //     document.body.appendChild(canvas);
        //         // }
        //     }).then(canvas => {
        //         var imgData = canvas.toDataURL("image/jpeg");
        //         console.log("imgData", imgData);
        //         let doc = new jsPDF({
        //             orientation: "landscape",
        //             unit: "in",
        //             format: [3.5, 2]
        //         });
        //         // let doc = new jsPDF("p", "mm", "a4");
        //         console.log("canvas", canvas);
        //         var canvas = doc.canvas;
        //         console.log("var canvas", canvas);
        //         canvas.height = 500;
        //         canvas.width = 400;
        //         doc.addImage(imgData, "JPEG", 0, 0, 211, 298);
        //         doc.save("certificate.pdf");
        //         this.printInProgress = false;
        //     });
        // } catch (e) {
        //     this.printInProgress = false;
        //     console.log(e);
        //     alert("Unable to print. Please try again");
        // }
    } // printCertificate() {
    //     var data = document.getElementById("certificatePreviewSend");
    //     console.log(data);
    //     html2canvas(data, {
    //         logging: true,
    //         useCORS: true,
    //         letterRendering: true,
    //         allowTaint: false
    //     }).then(canvas => {
    //         // Few necessary setting options
    //         var imgWidth = 208;
    //         var pageHeight = 295;
    //         var imgHeight = (canvas.height * imgWidth) / canvas.width;
    //         var heightLeft = imgHeight;
    //         console.log("canvas", canvas);
    //         const contentDataURL = canvas.toDataURL("image/png");
    //         console.log("contentDataURL", contentDataURL);
    //         let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
    //         var position = 0;
    //         pdf.addImage(
    //             contentDataURL,
    //             "png",
    //             0,
    //             position,
    //             imgWidth,
    //             imgHeight
    //         );
    //         pdf.save("MYPdf.pdf"); // Generated PDF
    //     });
    // }
    //encrypt method
    encryptmethod(inputdata, key, schema_version) {
        if(environment.requestEnv == ''){
            let encnoobj =  this.apienc.encrypt(inputdata, key, schema_version);
            let encobj = {"enc_data" : encnoobj};
            return encobj;
        }
        return JSON.parse(inputdata);
    }

    //decrypt method
     decryptmethod(inputdata, key) {
        if(environment.requestEnv == ''){
            return this.apidec.decrypt(inputdata.enc_data, key);
        }
            return JSON.stringify(inputdata);
    }
}
